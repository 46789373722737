// @flow
import React, { type Node } from 'react';
import { Link, type Route } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import classNames from 'classnames';
import { t } from '../../../components';
import styles from './auth.module.css';

type Props = {
  toLogin: Route,
  align?: boolean
};

export default (props: Props): Node => {
  const { toLogin, align } = props;

  const linkStyle: string = classNames({
    [styles.link]: true,
    [styles.align]: align
  });

  return (
    <Row>
      <Col>
        <Link to={toLogin} className={linkStyle}>
          {t('auth.forgot-password.back-to-sign-in')}
        </Link>
      </Col>
    </Row>
  );
};
