// @flow
import React, { useState, type Node } from 'react';
import { Row, Col, Form } from 'reactstrap';
import { Button, Checkbox, t } from '../../../../../components';
import { withFormik } from '../../../../../hoc';
import CardsList from './CardsList';
import NewCard from './NewCard';
import PaymentError from '../PaymentError';
import DeleteCard from '../../../../Modals/DeleteCardModal';
import cardFormConfig from './cardFormConfig';
import styles from '../../../payment.module.css';
import type { Formik } from '../../../../../utils/types';
import type { PaymentModel } from '../../../../../models/payment/types';
import { PAYMENT_TYPE } from 'config/payment.config';

type Props = {
  formik: Formik,
  payment: PaymentModel
};

export const CardDetails = (props: Props): Node => {
  const {
    formik,
    formik: {
      handleSubmit,
      values: { cardId }
    },
    payment: { cards, error, method, editMode, paymentType }
  } = props;
  const [isDeleteCard, setIsDeleteCard] = useState(false);

  const oneTimePayment = paymentType === PAYMENT_TYPE.ONE_TIME_PAYMENT;

  const getPaymentTypeDetails = (paymentType) => {
    if (paymentType === PAYMENT_TYPE.ONE_TIME_PAYMENT) {
      return 'payment.pay';
    } else if (paymentType === PAYMENT_TYPE.RECURRING_PAYMENT) {
      return editMode
        ? 'payment.update-recurring-payment'
        : 'payment.create-recurring-payment';
    } else if (paymentType === PAYMENT_TYPE.SCHEDULED_PAYMENT) {
      return 'payment.create-scheduled-payment';
    }
  };

  // eslint-disable-next-line no-console
  console.log('Card Details Props', props);
  // eslint-disable-next-line no-console
  console.log('Card Details Error', error);

  return (
    <Form onSubmit={handleSubmit}>
      <PaymentError {...error} />
      {cards.length ? <CardsList {...props} /> : <NewCard {...props} />}
      <Row data-testid="card-details">
        {cardId && (
          <Col>
            <Button
              title="payment.delete-card"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setIsDeleteCard(true);
              }}
            />
          </Col>
        )}
        <Col className={styles.submitButton}>
          {oneTimePayment && !cardId && (
            <Checkbox
              className="saveCheckbox"
              name="saveToProfile"
              label={t('payment.save-card')}
              {...formik}
            />
          )}
          {!oneTimePayment && !cardId && (
            <span className={styles.savedPaymentInfo}>
              {t(`payment.${method}-saved`)}
            </span>
          )}
          <Button
            primary
            type="submit"
            title={getPaymentTypeDetails(paymentType)}
          />
        </Col>
      </Row>
      {isDeleteCard && (
        <DeleteCard {...props} closeModal={() => setIsDeleteCard(false)} />
      )}
    </Form>
  );
};

export default withFormik(cardFormConfig)(CardDetails);
