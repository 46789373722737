// @flow
import React, { type Node } from 'react';
import { Col, Row } from 'reactstrap';
import { t, Button } from '../../../../components';
import styles from '../app-layout.module.css';
import type { Formik } from '../../../../utils/types';

export default ({
  formik: { handleSubmit, resetForm, initialValues }
}: Formik): Node => (
  <Row>
    <Col md={6} className={`${styles.controls} ${styles.message}`}>
      <span className={styles.unsaved} data-cy="unsaved-changes">
        {t('settings.header.unsaved-changes')}
      </span>
    </Col>
    <Col sm={12} md={6} className={`${styles.controls} ${styles.buttons}`}>
      <Button
        primary
        cy="save"
        size="sm"
        type="submit"
        title="settings.header.save"
        onClick={handleSubmit}
      />
      <Button
        title="settings.header.discard-changes"
        cy="discard-changes"
        type="button"
        size="sm"
        onClick={() => resetForm({ values: initialValues })}
      />
    </Col>
  </Row>
);
