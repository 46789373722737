// @flow
import React, { useEffect, Fragment, type Node } from 'react';
import { Row, Col } from 'reactstrap';
import type { Route } from 'react-router-dom';
import { t, Status, ErrorView, Button, Loader } from '../../../components';
import BackToLogin from './BackToLogin';
import { parseSearchQuery } from '../../../utils';
import styles from './auth.module.css';
import type { Actions } from '../../../utils/types';
import type { AuthModel } from '../../../models/auth/types';

type Props = {
  actions: Actions,
  auth: AuthModel,
  toLogin: Route,
  location: Location,
  viewError: boolean
};

export default (props: Props): Node => {
  const {
    actions: {
      auth: { confirmRegistration }
    },
    auth: {
      data: { email, userLocation },
      error
    },
    location: { search },
    toLogin,
    viewError
  } = props;

  useEffect((): void => {
    if (userLocation !== null) {
      const { kt } = parseSearchQuery(search);
      confirmRegistration(kt);
    }
  }, [confirmRegistration, search, userLocation]);

  return (
    <Row className={styles.registration}>
      <Col xs={11} md={10} lg={8} xl={5}>
        {!email && !viewError && <Loader message="loaders.verifying" />}
        {email && (
          <Status header="auth.feedback.email-confirmed">
            <p>{t('auth.feedback.sent-temporary-password', { email })}</p>
            <Button link={toLogin} title="auth.register.back-to-sign-in" />
          </Status>
        )}
        {viewError && (
          <Fragment>
            <BackToLogin {...props} />
            <ErrorView error={error} />
          </Fragment>
        )}
      </Col>
    </Row>
  );
};
