// @flow
export default {
  EN: {
    key: 'en',
    label: 'English'
  },
  ES: {
    key: 'es',
    label: 'Español'
  },
  ZH: {
    key: 'zh',
    label: '中文'
  },
  TL: {
    key: 'tl',
    label: 'Tagalog'
  },
  VI: {
    key: 'vi',
    label: 'Tiếng Việt'
  },
  FR: {
    key: 'fr',
    label: 'Français'
  },
  KO: {
    key: 'ko',
    label: '한국어'
  },
  DE: {
    key: 'de',
    label: 'Deutsche'
  },
  AR: {
    key: 'ar',
    label: 'عربى'
  },
  RU: {
    key: 'ru',
    label: 'Pусский'
  },
  UK: {
    key: 'uk',
    label: 'Українська'
  }
};
