import { object, string, number } from 'yup';
import REG_EXP from '../../config/regular-expressions.config';

export default object().shape({
  echeckId: number().nullable(),
  accountNumber: string().when(['echeckId'], {
    is: (echeckId) => !echeckId,
    then: string()
      .matches(REG_EXP.accountNumber, {
        message: 'payment.errors.invalid-account-number'
      })
      .required('payment.errors.account-num-required'),
    otherwise: string()
  }),
  routingNumber: string().when(['echeckId'], {
    is: (echeckId) => !echeckId,
    then: string()
      .matches(REG_EXP.nineDigits, {
        message: 'payment.errors.invalid-routing-number'
      })
      .required('payment.errors.routing-num-required'),
    otherwise: string()
  })
});
