// @flow
import { connect } from 'react-redux';
import type { Actions, Models } from '../utils/types';

export const mapState = ({
  auth,
  loader,
  member,
  payment,
  toasts
}: Models): Models => ({
  auth,
  loader,
  member,
  payment,
  toasts
});

export const mapDispatch = (
  { auth, loader, member, payment, toasts }: Models,
  { actions }: Actions
): Actions => ({
  actions: { ...actions, auth, loader, member, payment, toasts }
});

export default connect(mapState, mapDispatch);
