import { ref, string, object } from 'yup';
import REG_EXP from '../../config/regular-expressions.config';
import { emailSchemaDefinition } from './emailSchema';

export default object({
  ...emailSchemaDefinition,
  confirmEmail: string()
    .oneOf([ref('email')], 'errors.email-confirm')
    .required('errors.email-required')
});

export const memberRegistrationSchemaWithOktaLogin = object({
  ...emailSchemaDefinition,
  oktaLogin: string()
    .required()
    .min(3, 'errors.okta-login-min')
    .max(16, 'errors.okta-login-max')
    .matches(REG_EXP.oktaUserName, 'errors.okta-login-invalid-character'),
  confirmEmail: string()
    .oneOf([ref('email')], 'errors.email-confirm')
    .required('errors.email-required')
});
