// @flow
import { compose, withHandlers, type HOC } from 'recompose';
import classNames from 'classnames';
import withNamespace from '../../../hoc/withNamespace';
import styles from '../payment.module.css';
import type { Formik } from '../../../utils/types';

export default (compose(
  withHandlers({
    isChecked: ({
      formik: {
        values: { type }
      }
    }: Formik) => (value: string): boolean => type === value,
    helperTextStyle: () => (a: string, b: string): string =>
      classNames(styles.helperText, { [styles.checked]: a === b })
  }),
  withNamespace('handlers', ['isChecked', 'helperTextStyle'])
): HOC<*, any>);
