// @flow
import React from 'react';
import { withProps, type HOC } from 'recompose';
import { keys } from 'ramda';
import type { Models } from '../../../utils/types';
import SETTINGS from 'config/navigation.config';
import { Address, Phone, SimpleDate, SimpleValue } from 'utils/renderUtils';
import {
  Access,
  CommunicationEmail,
  CommunicationPhone,
  Statements,
  Username
} from 'containers/Settings/components';

export default (withProps((props: Models): Object => {
  const {
    member: {
      data: { member }
    }
  } = props;
  const { contactInfo = {}, address = {}, employment = {} } = member || {};

  const settings = keys(SETTINGS).map((key) => {
    let config = null;

    switch (key) {
      case SETTINGS.PERSONAL_INFORMATION.key:
        config = {
          config: [
            {
              title: 'settings.settings-blocks.personal-info.firstName',
              render: <SimpleValue value={member.firstName} />
            },
            {
              title: 'settings.settings-blocks.personal-info.lastName',
              render: <SimpleValue value={member.lastName} />
            },
            {
              title: 'settings.settings-blocks.personal-info.midName',
              render: <SimpleValue value={member.middleName} />
            },
            {
              title: 'settings.settings-blocks.personal-info.ssn',
              render: <SimpleValue value={member.ssn} />
            },
            {
              title: 'settings.settings-blocks.personal-info.dateOfBirth',
              render: <SimpleDate value={member.dateOfBirth} />
            },
            {
              title: 'settings.settings-blocks.personal-info.accountNumber',
              render: <SimpleValue value={member.accountNumber} />
            }
          ]
        };
        break;
      case SETTINGS.CONTACT_INFORMATION.key:
        config = {
          config: [
            {
              render: <CommunicationPhone {...props} />
            },
            {
              title: 'settings.settings-blocks.contact-info.homePhone',
              render: <Phone phone={contactInfo.homePhone} />
            },
            {
              title: 'settings.settings-blocks.contact-info.workPhone',
              render: <Phone phone={contactInfo.workPhone} />
            },
            {
              title: 'settings.settings-blocks.contact-info.cellPhone',
              render: <Phone phone={contactInfo.cellPhone} />
            },
            {
              title: 'settings.settings-blocks.contact-info.username',
              render: <Username {...props} />
            },
            {
              render: <CommunicationEmail {...props} />
            }
          ]
        };
        break;
      case SETTINGS.ADDRESS_DETAILS.key:
        config = {
          config: [
            {
              title: 'settings.settings-blocks.address-details.address',
              render: <Address address={address} />
            },
            {
              title: 'settings.settings-blocks.address-details.zip',
              render: <SimpleValue value={address.zip} />
            }
          ]
        };
        break;
      case SETTINGS.EMPLOYMENT_INFORMATION.key:
        config = {
          config: [
            {
              title: 'settings.settings-blocks.employerInfo.employer',
              render: <SimpleValue value={employment.employerName} />
            },
            {
              title: 'settings.settings-blocks.employerInfo.location',
              render: <SimpleValue value={employment.location} />
            },
            {
              title: 'settings.settings-blocks.employerInfo.benefitBeginDate',
              render: <SimpleDate value={employment.benefitDate.startDate} />
            },
            {
              title: 'settings.settings-blocks.employerInfo.benefitEndDate',
              render: <SimpleDate value={employment.benefitDate.endDate} />
            }
          ]
        };
        break;
      case SETTINGS.STATEMENTS.key:
        config = {
          render: <Statements {...props} />
        };
        break;
      case SETTINGS.ACCESS_TO_ACCOUNT.key:
        config = {
          render: <Access {...props} />
        };
        break;
      default:
        config = {};
    }

    return Object.assign({}, SETTINGS[key], config);
  });

  return { settings };
}): HOC<*, any>);
