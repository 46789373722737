// @flow
import React, { type Node } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { useRemoveToast } from '../../hooks';
import { t } from '../i18n';
import styles from './error.module.css';
import type { Toast } from '../../models/toasts';
import type { Actions } from '../../utils/types';

type Props = {
  actions: Actions,
  toast: Toast
};

export default (props: Props): Node => {
  const {
    toast: { message, rawMessage }
  } = props;

  useRemoveToast(props);

  return (
    <Container fluid className="p-0">
      <div className={styles.systemError}>
        <Row>
          <Col>
            <Container>
              <p className={styles.message}>
                {rawMessage ? message : t(message)}
              </p>
            </Container>
          </Col>
        </Row>
      </div>
    </Container>
  );
};
