// @flow
import React, { Fragment, useState, type Node } from 'react';
import { Tooltip } from 'reactstrap';
import classNames from 'classnames';
import styles from './tooltip.module.css';

type Props = {
  message: string,
  target: any,
  icon?: string,
  label?: string,
  pointer: boolean
};

export default (props: Props): Node => {
  const { icon, message, target, label, pointer } = props;
  const [isOpen, toggle]: Object = useState(false);

  const tooltipIconStyles: string = classNames({
    [styles.icon]: true,
    [styles[icon]]: !!icon
  });

  const toolTipLabelStyles = classNames({
    [styles.pointer]: pointer
  });
  return (
    <Fragment>
      {icon && <span id={target} className={tooltipIconStyles} />}
      {label && (
        <span className={toolTipLabelStyles} id={target}>
          {label}
        </span>
      )}
      <Tooltip
        placement="bottom"
        isOpen={isOpen}
        target={target}
        hideArrow
        delay={100}
        autohide={false}
        toggle={() => toggle(!isOpen)}
      >
        {message}
      </Tooltip>
    </Fragment>
  );
};
