// @flow
import React, { type Node } from 'react';
// NOTE: injectIntl can be used alternatively as the hook useIntl() but only in functional components in version 3
import { injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import {
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import styles from './dropdowm.module.css';

type Props = {
  options: Array<{ key: string, link: string, label: string }>
};

const OptionsDropDown = ({ options = [] }: Props): Node => (
  <UncontrolledButtonDropdown>
    <DropdownToggle
      className={`options-drop-down ${styles.minWidth}`}
      outline
    />

    <DropdownMenu className={styles.toggle} right>
      {options.map(({ label, key, link }) => (
        <DropdownItem
          tag={Link}
          to={link}
          role="menuitem"
          aria-label={label}
          key={key}
        >
          {label}
        </DropdownItem>
      ))}
    </DropdownMenu>
  </UncontrolledButtonDropdown>
);

export default injectIntl(OptionsDropDown);
