// @flow
import React, { type Node } from 'react';
import { Link } from 'react-router-dom';
import { Nav, NavLink, NavItem } from 'reactstrap';
import classNames from 'classnames';
import { t } from './i18n';
import { type Tab } from '../utils/types';

type Props = {
  location: Location,
  config: Array<Tab>
};

export default (props: Props): Node => {
  const {
    config,
    location: { pathname }
  } = props;

  const renderTabs = (tabs) =>
    tabs.map(
      ({ title, path }: Tab, i: number): Node => {
        const active = classNames({ active: pathname.includes(path) });
        return (
          <NavItem key={i}>
            <NavLink tag={Link} className={active} to={path}>
              {t(title)}
            </NavLink>
          </NavItem>
        );
      }
    );

  return config && <Nav tabs>{renderTabs(config)}</Nav>;
};
