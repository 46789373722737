// @flow
import { lifecycle, type HOC } from 'recompose';

export default (lifecycle({
  componentDidMount() {
    const {
      actions: {
        loader,
        member: { getLanguages, getMember }
      },
      match: {
        params: { memberId }
      },
      member: {
        data: { member }
      },
      history: { action }
    } = this.props;

    const userIsLoggingIn =
      Object.keys(member).length === 0 && action === 'REPLACE';

    if (userIsLoggingIn) {
      loader.start('loaders.log-in');
    } else {
      loader.start('loaders.loading');
    }

    Promise.all([getLanguages(), getMember(memberId)]).then(() =>
      loader.stop()
    );
  }
}): HOC<*, any>);
