// @flow
import { compose } from 'recompose';
import PaymentDate from './PaymentDate';
import { withFormik } from '../../../../hoc';
import { withStepSummary } from '../../hoc';
import paymentDateFormConfig from './paymentDateFormConfig';

export default compose(
  withFormik(paymentDateFormConfig),
  withStepSummary
)(PaymentDate);
