// @flow
import { lazy } from 'react';
import { withProps, type HOC } from 'recompose';
import type { Route } from 'react-router-dom';
import ROUTES from '../../../config/routes.config';

const About = lazy(() => import('../../Modals/About'));
const MembershipCard = lazy(() => import('../../Modals/MembershipCard'));
const ExtendRepayment = lazy(() => import('../../Modals/ExtendRepayment'));
const DeleteRecurring = lazy(() => import('../../Modals/DeleteRecurring'));
const Unauthorized = lazy(() => import('../../Modals/Unauthorized'));

export default (withProps((): Object => {
  const routes: Array<Route> = [
    { path: `${ROUTES.PAYMENT}/about`, component: About },
    { path: `${ROUTES.PAYMENT}/membership-card`, component: MembershipCard },
    {
      path: `${ROUTES.PAYMENT}/unauthorized`,
      component: Unauthorized
    },
    {
      path: `${ROUTES.PAYMENT}/delete-recurring-payment`,
      component: DeleteRecurring
    },
    {
      path: `${ROUTES.PAYMENT}/repayment-option`,
      component: ExtendRepayment
    }
  ];

  return { routes };
}): HOC<*, any>);
