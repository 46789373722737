import React, { useState } from 'react';
import classNames from 'classnames';
import styles from '../../payment.module.css';
import PaymentDayList from './PaymentDayList';

export default (props) => {
  const { startDate } = props;

  const [date, setDate] = useState(startDate);
  const nextMonth = new Date(date.getFullYear(), date.getMonth() + 1, 1);
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];

  const currentMonthName = months[date.getMonth()];
  const secondMonthName = months[nextMonth.getMonth()];
  const changeMonth = (e) => {
    const nextMonth = new Date(date.getFullYear(), date.getMonth() + e, 1);
    if (nextMonth.getMonth() === startDate.getMonth()) {
      setDate(startDate);
    } else {
      setDate(nextMonth);
    }
  };

  const currentMonthYearHeader = `${currentMonthName}  ${date.getFullYear()}`;
  const secondMonthYearHeader = `${secondMonthName}  ${nextMonth.getFullYear()}`;
  const leftArrowIsDisabled =
    date.getMonth() === startDate.getMonth() ? styles.disabled : '';
  const leftArrowStyle = classNames(styles.angleLeft, leftArrowIsDisabled);

  return (
    <div className={styles.paymentContainer}>
      <div className={styles.calendar}>
        <div className={styles.month}>
          <div className={styles.calendarHeaderRight}>
            <div
              onClick={() => changeMonth(-1)}
              onKeyDown={() => changeMonth(-1)}
              className={leftArrowStyle}
              role={'button'}
              tabIndex="0"
            ></div>
            <h1 role={'banner'} className={styles.date}>
              {currentMonthYearHeader}
            </h1>
          </div>
          <div className={styles.calendarHeaderLeft}>
            <h1 role={'banner'} className={styles.date}>
              {secondMonthYearHeader}
            </h1>
            <div
              role={'button'}
              onKeyDown={() => changeMonth(-1)}
              onClick={() => changeMonth(1)}
              tabIndex="-1"
              className={styles.angleRight}
            >
              {' '}
            </div>
          </div>
        </div>
        <div className={styles.selectorContainer}>
          <div className={styles.firstCalenderSelector}>
            <div className={styles.weekdays}>
              <div>Sun</div>
              <div>Mon</div>
              <div>Tue</div>
              <div>Wed</div>
              <div>Thu</div>
              <div>Fri</div>
              <div>Sat</div>
            </div>
            <div className={styles.datesContainer}>
              <PaymentDayList {...props} date={date} startDate={startDate} />
            </div>
          </div>

          <div className={styles.secondCalenderSelector}>
            <div className={styles.weekdays}>
              <div>Sun</div>
              <div>Mon</div>
              <div>Tue</div>
              <div>Wed</div>
              <div>Thu</div>
              <div>Fri</div>
              <div>Sat</div>
            </div>
            <div className={styles.datesContainer}>
              <PaymentDayList
                {...props}
                date={nextMonth}
                startDate={startDate}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
