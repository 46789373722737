import React, { useEffect } from 'react';
import { range } from 'ramda';
import { DateTime } from 'luxon';
import PaymentDay from '../PaymentDate/PaymentDay';
import { format, addMinutes, parseISO } from 'date-fns';

export default (props) => {
  const {
    member: {
      data: {
        familyMember: {
          balanceDetails: { dueDate }
        },
        selectedTransaction: { startAfter }
      }
    },
    formik: {
      values: { schedulePaymentDate },
      setFieldValue,
      setFieldTouched,
      setValues
    },
    date,
    startDate,
    match: {
      params: { entityId }
    }
  } = props;

  useEffect(() => {
    if (!entityId) {
      setValues({}, false);
    } else {
      const dateChange = new Date(startAfter);
      setFieldValue(
        'schedulePaymentDate',
        parseISO(
          format(
            addMinutes(dateChange, dateChange.getTimezoneOffset()),
            'yyyy-MM-dd HH:mm:ss'
          )
        )
      );
    }
  }, []);

  const lastDay = new Date(
    date.getFullYear(),
    date.getMonth() + 1,
    0
  ).getDate();

  const prevLastDay = new Date(
    date.getFullYear(),
    date.getMonth(),
    0
  ).getDate();

  const firstDayIndex = new Date(
    date.getFullYear(),
    date.getMonth(),
    1
  ).getDay();
  const carryOverDaysRange = range(prevLastDay - firstDayIndex, prevLastDay);
  const currentMonthDateRange = range(1, lastDay + 1);
  const calendarRange = [...carryOverDaysRange, ...currentMonthDateRange];

  const isSelectedDate = (selectedDate, day) => {
    if (selectedDate) {
      const currentDate = new Date(date.getFullYear(), date.getMonth(), day);
      return (
        selectedDate.getDate() === currentDate.getDate() &&
        selectedDate.getMonth() === currentDate.getMonth() &&
        selectedDate.getFullYear() === currentDate.getFullYear()
      );
    }
    return false;
  };
  return calendarRange.map((day, index) => {
    const dueDateValue = dueDate && DateTime.fromSQL(dueDate).c.day;
    const disabled =
      day <= date.getDate() &&
      startDate.getMonth() === date.getMonth() &&
      startDate.getFullYear() === date.getFullYear();

    const isCarryOver = index < carryOverDaysRange.length;
    const fieldDateValue = new Date(date.getFullYear(), date.getMonth(), day);
    const checked = isSelectedDate(schedulePaymentDate, day);

    return (
      <PaymentDay
        day={day}
        key={index}
        dueDate={day === dueDateValue}
        hidden={isCarryOver}
        disabled={disabled}
        checked={checked}
        onChange={async () => {
          await setFieldValue('schedulePaymentDate', fieldDateValue, true);
          await setFieldTouched('schedulePaymentDate', false, true);
        }}
        medium
      />
    );
  });
};
