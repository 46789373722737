// @flow
import React, { type Node } from 'react';
import withNoData from '../../hoc/withNoData';

type Props = {
  cell: {
    key: number,
    onClick: (eventObject: Object, cell: Object, row: Object) => void,
    render: (data: Object) => Node,
    index: number
  },
  row: Object
};

const TableCell = ({
  cell,
  cell: { key, onClick, render, index },
  row
}: Props): Node => (
  /* eslint-disable */
  <td
    key={`${index}_${key}`}
    onClick={(e) =>
      onClick &&
      onClick({
        eventObject: e,
        cell,
        row
      })
    }
  >
    {render({ cell, row })}
  </td>
  /* eslint-enable */
);

export default withNoData(['cell'])(TableCell);
