const flattenMessages = (nestedMessages, prefix = '') =>
  Object.keys(nestedMessages).reduce((messages, key) => {
    const values = messages;
    const value = nestedMessages[key];
    const prefixedKey = prefix ? `${prefix}.${key}` : key;

    if (typeof value === 'string') {
      values[prefixedKey] = value;
    } else {
      Object.assign(values, flattenMessages(value, prefixedKey));
    }

    return values;
  }, {});

module.exports = flattenMessages;
