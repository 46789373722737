// @flow
import React, { Fragment, type Node, useEffect } from 'react';
import type { Match } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { Button, Checkbox, t } from '../../../../components';
import {
  AMOUNT_OPTIONS,
  PAYMENT_TYPE
} from '../../../../config/payment.config';
import type { Formik } from '../../../../utils/types';
import type { PaymentModel } from '../../../../models/payment/types';
import styles from '../../payment.module.css';

type Props = {
  formik: Formik,
  payment: PaymentModel,
  match: Match
};

export const submitButtonText = (paymentType) => {
  switch (paymentType) {
    case PAYMENT_TYPE.ONE_TIME_PAYMENT:
      return 'payment.to-payment-method';
    case PAYMENT_TYPE.SCHEDULED_PAYMENT:
      return 'payment.to-payment-details';
    default:
      return 'payment.to-payment-date';
  }
};

export default (props: Props): Node => {
  const {
    actions: {
      payment: { setData }
    },
    formik,
    formik: {
      setFieldValue,
      values,
      values: { type, repaymentAccepted },
      errors
    },
    payment: { paymentType, editMode },
    member: {
      data: {
        familyMember: {
          balanceDetails: { balance }
        }
      }
    },
    match: { url },
    history: { push }
  } = props;

  const withRepaymentAccepted: boolean =
    type === AMOUNT_OPTIONS.MINIMUM_PAYMENT_DUE ||
    type === AMOUNT_OPTIONS.OTHER;

  useEffect(() => {
    if (!withRepaymentAccepted) {
      setFieldValue({ repaymentAccepted: true });
    }
  }, [withRepaymentAccepted, type]);

  useEffect(() => {
    setData({ ...values });
  }, [repaymentAccepted]);

  return (
    <Fragment>
      {withRepaymentAccepted && (
        <Checkbox
          className="repaymentAccepted"
          name="repaymentAccepted"
          id="repaymentAccepted"
          label={t('payment.extended-repayment', {
            link: (
              <Link to={`${url}/repayment-option`}>
                {t('payment.extended-repayment-link')}
              </Link>
            )
          })}
          {...formik}
        />
      )}
      <Row>
        <Col>
          {editMode && balance <= 0 && (
            <Button
              type="button"
              title="payment.delete-recurring-payment"
              onClick={() => push(`${url}/delete-recurring-payment`)}
            />
          )}
        </Col>
        <Col className={styles.submitButton}>
          <Button
            primary
            disabled={
              errors.maxAmountFull ||
              errors.maxAmountMin ||
              errors.maxAmountLastStatement ||
              (errors.otherAmount && type === AMOUNT_OPTIONS.OTHER)
            }
            title={submitButtonText(paymentType)}
          />
        </Col>
      </Row>
    </Fragment>
  );
};
