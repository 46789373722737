// @flow
import { compose, withHandlers, withProps, type HOC } from 'recompose';
import { withFormik, withNamespace } from '../../../../hoc';
import { withStepSummary, withCheckHandlers } from '../../hoc';
import PaymentAmount from './PaymentAmount';
import amountFormConfig from './amountFormConfig';
import type { Formik } from '../../../../utils/types';
import type { MemberModel } from '../../../../models/member/types';

type Props = { member: MemberModel, formik: Formik };

export default compose(
  (withProps((props: Props): Object => {
    const {
      currentQuickPay,
      member: {
        data: {
          familyMember: {
            balanceDetails: { currentStatementQuickPay }
          }
        }
      }
    } = props;

    const hasStatement =
      currentStatementQuickPay !== null &&
      currentStatementQuickPay.amount > 0 &&
      currentStatementQuickPay.amount <= currentQuickPay.amount;

    return { hasStatement };
  }): HOC<*, *>),
  withNamespace('payment', [
    'hasStatement',
    'showMinimumPaymentDue',
    'noRecurringMPD'
  ]),
  withFormik(amountFormConfig),
  withCheckHandlers,
  withStepSummary,
  withHandlers({
    setAmount: (props: Props) => (value: number): void => {
      const {
        formik: {
          setFieldTouched,
          setFieldValue,
          updateFormattedValues,
          formattedValues
        },
        member: {
          data: {
            selectedTransaction: { amount }
          }
        },
        match: {
          params: { entityId }
        }
      } = props;

      setFieldTouched('otherAmount', false, false);
      setFieldValue('otherAmount', '', true);
      setFieldValue('amount', entityId ? amount : value, false);
      updateFormattedValues({
        ...formattedValues,
        otherAmount: entityId ? amount : ''
      });
    }
  }),
  withNamespace('handlers', ['setAmount'])
)(PaymentAmount);
