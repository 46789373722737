// @flow
import LANGUAGES from './languages.config';
import TIMEZONES from './timezones.config';
import useCurrentWidth from '../hooks/useCurrentWidth';

export default {
  GOOGLE_API: {
    TRACKING_ID: 'UA-113746714-1'
  },
  BASE_PATH: `${window.location.origin}/api/member/`,
  REQUEST_TIMEOUT: 5000,
  PROMPT_TIMEOUT: 5000
};

export const featureFlagKeys = {
  memberAppleWallet: 'memberAppleWallet',
  oktaLoginMigration: 'oktaLoginMigration',
  scheduledPaymentMember: 'scheduledPaymentMember',
  memberClaimLines: 'memberClaimLines',
  creditHoldTwo: 'creditHoldTwo'
};

export { TIMEZONES, LANGUAGES };

export const MAX_AMOUNT_DECIMAL = 10000.0;
export const MIN_AMOUNT_DECIMAL = 1.0;

export const MOBILE_VIEW = {
  size: 480,
  name: 'MOBILE_VIEW'
};

export const TABLET_VIEW = {
  size: 770,
  name: 'TABLET_VIEW'
};

export const SMALL_DESKTOP_VIEW = {
  size: 1024,
  name: 'SMALL_DESKTOP_VIEW'
};

export const DESKTOP_VIEW = {
  size: 1025,
  name: 'DESKTOP_VIEW'
};

export const SCREEN_SIZES = {
  MOBILE: MOBILE_VIEW.name,
  TABLET: TABLET_VIEW.name,
  SMALL_DESKTOP: SMALL_DESKTOP_VIEW.name,
  DESKTOP: DESKTOP_VIEW.name
};

export function GET_SCREEN_SIZE() {
  const width = useCurrentWidth();
  if (width <= MOBILE_VIEW.size) {
    return MOBILE_VIEW.name;
  } else if (width > MOBILE_VIEW.size && width <= TABLET_VIEW.size) {
    return TABLET_VIEW.name;
  } else if (width > TABLET_VIEW.size && width <= SMALL_DESKTOP_VIEW.size) {
    return SMALL_DESKTOP_VIEW.name;
  } else {
    return DESKTOP_VIEW.name;
  }
}
