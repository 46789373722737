// @flow
import { useEffect } from 'react';
import withMapStateDispatch from '../hoc/withMapStateDispatch';
import type { Actions } from '../utils/types';

const Logout = (props: Actions): null => {
  const {
    actions: {
      auth: { logout }
    }
  } = props;

  useEffect((): void => {
    logout().then(() => {
      window.location.replace('/');
    });
  });

  return null;
};

export default withMapStateDispatch(Logout);
