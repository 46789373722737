// @flow
import React, { type Node } from 'react';
import { compose } from 'recompose';
import { Row, Col } from 'reactstrap';
import { withCardHandlers } from '../../../hoc';
import { Input, MaskInput } from '../../../../../components';
import CardImage from './CardImage';
import { CARD_TYPES } from '../../../../../config/payment.config';
import styles from '../../../payment.module.css';
import type { Formik } from '../../../../../utils/types';
import type {
  Handlers,
  PaymentModel
} from '../../../../../models/payment/types';
import tabTo from '../../../../../utils/tabTo';

type Props = {
  handlers: Handlers,
  formik: Formik,
  payment: PaymentModel
};

const NewCard = (props: Props): Node => {
  const {
    handlers: { getCardType, setHighlight },
    payment: { highlightCardNumber },
    formik,
    formik: {
      changeHandler,
      handleBlur,
      values: { cardType }
    }
  } = props;

  const isAMEX: boolean = cardType === CARD_TYPES.AMEX.KEY;
  const cvvLength = isAMEX ? 4 : 3;

  return (
    <Row className="mt-3">
      <Col>
        <Row>
          <Col>
            <MaskInput
              name="cardNumber"
              label="payment.card-number"
              maskConfig={{
                mask: isAMEX ? '9999 9999 9999 999' : '9999 9999 9999 9999',
                formatChars: { '9': '\\d' },
                maskChar: null
              }}
              required
              className={['cardInput', cardType]}
              {...formik}
              handleChange={async (e): void => {
                getCardType(e.target.value);
                setHighlight(true);
                await changeHandler(e);
                tabTo(e, 'expirationDate', isAMEX ? 18 : 19);
              }}
              handleBlur={(e): void => {
                handleBlur(e);
                setHighlight(false);
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={6} md={7}>
            <MaskInput
              name="expirationDate"
              label="payment.expires"
              maskConfig={{
                mask: '99/99',
                formatChars: { '9': '\\d' },
                maskChar: null
              }}
              required
              placeholder="MM/YY"
              {...formik}
              handleChange={async (e): void => {
                await changeHandler(e);
                tabTo(e, 'cardCode', 5);
              }}
            />
          </Col>
          <Col xs={12} sm={6} md={5}>
            <Input
              type="password"
              autoComplete="new-password"
              name="cardCode"
              title="payment.cvv"
              required
              tabTo={{ field: 'zip', length: cvvLength }}
              {...formik}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={6} md={7}>
            <Input name="fullName" title="payment.cardholder" {...formik} />
          </Col>
          <Col xs={12} sm={6} md={5}>
            <Input
              name="zip"
              required
              title="payment.zip"
              tabTo={{ field: 'fullName', length: 5 }}
              {...formik}
            />
          </Col>
          <input type="hidden" name="dataValue" id="dataValue" />
          <input type="hidden" name="dataDescriptor" id="dataDescriptor" />
        </Row>
      </Col>

      <Col className={styles.paymentImage}>
        <CardImage {...props} highlightCardNumber={highlightCardNumber} />
      </Col>
    </Row>
  );
};

export default compose(withCardHandlers)(NewCard);
