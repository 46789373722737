// @flow
import { compose } from 'recompose';
import { Modal } from '../../../components';
import { withMapStateDispatch, withGoBack } from '../../../hoc';
import {
  withTableConfig,
  withGetDetails,
  withDetailsViewModalConfig
} from './hoc';

import { withGetScheduledPaymentDetailsDetails } from 'hoc';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

export default compose(
  withLDConsumer(),
  withGoBack,
  withMapStateDispatch,
  withGetDetails,
  withGetScheduledPaymentDetailsDetails,
  withTableConfig,
  withDetailsViewModalConfig
)(Modal);
