// @flow
import { useEffect } from 'react';
import { ACCEPTJS_URL } from '../../../config/payment.config';

export default (): void => {
  useEffect((): Function => {
    const loadJS = (src: string, cb: Function): void => {
      const ref: any = document.getElementsByTagName('script')[0];
      const script: HTMLScriptElement = document.createElement('script');
      script.src = src;
      script.async = true;
      ref.parentNode.insertBefore(script, ref);
      if (cb && typeof cb === 'function') {
        script.onload = cb;
      }
    };

    loadJS(ACCEPTJS_URL, (): void => {});

    return (): void => {
      const acceptjsScripts: NodeList<HTMLElement> = document.querySelectorAll(
        '[src*="authorize.net"]'
      );

      acceptjsScripts.forEach((scriptTag: any): void => {
        scriptTag.parentElement.removeChild(scriptTag);
      });
    };
  });
};
