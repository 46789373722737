// @flow
import React, { type Node } from 'react';
import { compose } from 'recompose';
import { FormGroup, Input, Label, FormFeedback } from 'reactstrap';
import InputMask from 'react-input-mask';
import { t } from '../i18n';
import withStyleClasses from './withStyleClasses';
import styles from './form.module.css';
import type { Formik } from '../../utils/types';

type Props = {
  name: string,
  label?: string,
  placeholder?: string,
  maskConfig: Object,
  onPaste?: () => void,
  beforeMaskedValueChange?: () => Object,
  refTo?: Object,
  fieldReferences: any,
  styleClasses?: () => string,
  required?: boolean,
  sup: any
};

const InputElement = (props: Props & Formik): Node => {
  const {
    name,
    label,
    placeholder = '',
    maskConfig,
    onPaste,
    handleBlur,
    handleChange,
    touched,
    value,
    values,
    errors,
    beforeMaskedValueChange,
    fieldReferences,
    styleClasses,
    required,
    sup
  } = props;

  return (
    name && (
      <FormGroup
        required={required}
        cssModule={styles}
        className={styleClasses}
        data-cy={name}
      >
        {label && (
          <Label for={name}>
            {t(label)}
            {sup && <sup>{sup}</sup>}
          </Label>
        )}
        <InputMask
          {...maskConfig}
          type="text"
          name={name}
          id={name}
          autoComplete="off"
          placeholder={placeholder}
          onBlur={handleBlur}
          onChange={(e) => {
            handleChange(e);
          }}
          onPaste={onPaste}
          valid={touched[name] && !errors[name]}
          invalid={touched[name] && !!errors[name]}
          value={value ? value : values[name]}
          beforeMaskedValueChange={beforeMaskedValueChange}
        >
          {(inputProps) => (
            <Input
              {...inputProps}
              innerRef={fieldReferences && fieldReferences[name]}
            />
          )}
        </InputMask>
        {errors[name] && <FormFeedback>{t(errors[name])}</FormFeedback>}
      </FormGroup>
    )
  );
};

export default compose(withStyleClasses)(InputElement);
