// @flow
import { creditCardSchema } from '../../../../../utils/validation';
import { AUTHORIZE, PAYMENT_TYPE } from '../../../../../config/payment.config';
import type { FormikConfig, Models } from '../../../../../utils/types';

const payWithNewCard = (values: Object, props: Object) => {
  const {
    props: {
      actions: {
        loader: { stop },
        payment: { payByCard, setError, saveCard }
      },
      payment: { paymentType }
    }
  } = props;

  const dataToSave: Object = {
    ...values,
    cardNumber: values.cardNumber.replace(/\s+/g, ''),
    month: values.expirationDate.slice(0, 2),
    year: values.expirationDate.slice(3)
  };

  window.Accept.dispatchData(
    { authData: AUTHORIZE, cardData: dataToSave },
    ({ messages = {}, opaqueData = {} }) => {
      if (messages.resultCode === 'Error') {
        const { message = [] } = messages;
        setError({ error: message, storeTo: 'accept' });
        stop();
      } else {
        setError({ error: [], storeTo: 'accept' });
        if (paymentType === PAYMENT_TYPE.ONE_TIME_PAYMENT) {
          values.saveToProfile ? saveCard(opaqueData) : payByCard(opaqueData);
        } else {
          saveCard(opaqueData);
        }
      }
    }
  );
};

const payWithSavedCard = (values: Object, props: Object) => {
  const {
    props: {
      actions: {
        payment: { payBySavedCard, recurringPayment, scheduledPayment }
      },
      payment: { paymentType, editMode }
    }
  } = props;

  const method: string = editMode ? 'PUT' : 'POST';

  if (paymentType === PAYMENT_TYPE.ONE_TIME_PAYMENT) {
    payBySavedCard(values.cardId);
  } else if (paymentType === PAYMENT_TYPE.RECURRING_PAYMENT) {
    recurringPayment({
      paymentProfileId: values.cardId,
      method
    });
  } else if (paymentType === PAYMENT_TYPE.SCHEDULED_PAYMENT) {
    scheduledPayment({
      paymentProfileId: values.cardId,
      method
    });
  }
};

export default ({
  mapPropsToValues: (props: Models) => {
    const { payment } = props;

    return payment;
  },
  validationSchema: creditCardSchema,
  handleSubmit: (values: Object, props: Object): void => {
    const {
      props: {
        actions: {
          loader: { start },
          payment: { setError, setData }
        },
        payment: { paymentType }
      }
    } = props;

    setData(values);
    setError();

    if (paymentType && paymentType === PAYMENT_TYPE.RECURRING_PAYMENT) {
      start('loaders.saving-payment');
    } else {
      start('loaders.processing-payment');
    }

    if (!values.cardId) {
      payWithNewCard(values, props);
    }

    if (values.cardId) {
      payWithSavedCard(values, props);
    }
  },
  enableReinitialize: true,
  validateOnChange: false
}: FormikConfig);
