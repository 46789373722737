// @flow
import { useEffect } from 'react';
import type { Actions, Models } from '../../../utils/types';

export default (props: Actions | Models): void => {
  const {
    actions: {
      payment: { getCards, getEchecks }
    },
    payment: { editMode }
  } = props;

  useEffect((): void => {
    Promise.all([getCards(), getEchecks()]).then(() => null);
  }, [editMode, getCards, getEchecks]);
};
