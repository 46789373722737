// @flow
import { compose, withHandlers, withProps } from 'recompose';
import { mergeDeepRight } from 'ramda';
import { DateTime } from 'luxon';
import SignUp from './SignUp';
import { withFormik, withNamespace } from '../../../../hoc';
import signUpFormConfig from './signUpFormConfig';
import type { Formik } from '../../../../utils/types';
import { dateValidation } from '../../../../utils';

let originSsnValue: Object = {};

export default compose(
  withFormik(signUpFormConfig),
  withProps((props: Formik): Object => {
    const {
      formik: {
        values: { dateOfBirth }
      }
    } = props;

    let dateValue = new Date(dateOfBirth);
    if (!DateTime.fromJSDate(dateValue).isValid) {
      dateValue = new Date();
    }

    return {
      calendar: {
        maxDate: new Date(),
        minDate: DateTime.fromJSDate(new Date())
          .minus({ years: 94 })
          .toJSDate(),
        dateValue
      }
    };
  }),
  withHandlers({
    maskValues: () => (value: string): string => value
  }),
  withHandlers({
    parseSsnInput: ({ maskValues }) => (
      { value, selection }: Object,
      currentValue: number
    ): string => {
      const lastAsterisk = value.lastIndexOf(currentValue);
      const ssnBeforeUserInput = value.slice(0, lastAsterisk);
      const ssnAfterUserInput = value.slice(lastAsterisk);
      const ssnMaxLength = 11;

      return selection.start === ssnMaxLength || ssnAfterUserInput.includes('*')
        ? maskValues(value)
        : `${maskValues(ssnBeforeUserInput)}${ssnAfterUserInput}`;
    }
  }),
  withHandlers({
    formatDateDisplay: () => (date = new Date()) => {
      const newDate =
        typeof date === 'object'
          ? DateTime.fromJSDate(date).toFormat('MM/dd/yyyy')
          : date;
      const isDateValid =
        typeof date === 'string'
          ? dateValidation(date)
          : dateValidation(newDate.toString());
      return isDateValid ? newDate : null;
    },
    maskSsn: ({ formik: { setFieldValue }, parseSsnInput }: Object) => (
      newState: Object,
      oldState: Object,
      userInput: string
    ): Object => {
      const { selection } = newState;
      const currentValue = +userInput;

      if (userInput !== null && !Number.isNaN(currentValue)) {
        originSsnValue = mergeDeepRight(originSsnValue, {
          [selection.start]: currentValue
        });

        setFieldValue('ssn', originSsnValue, true);

        return { selection, value: parseSsnInput(newState, currentValue) };
      }

      return newState;
    }
  }),
  withNamespace('handlers', [
    'formatDateDisplay',
    'maskSsn',
    'maskValues',
    'parseSsnInput'
  ])
)(SignUp);
