import { usernameSchema } from '../../../../utils/validation';

const usernameConfig = {
  mapPropsToValues: (props: Models): Object => {
    const {
      member: {
        data: {
          member: {
            contactInfo: { login }
          }
        }
      }
    } = props;

    return { login };
  },

  validationSchema: usernameSchema,

  handleSubmit: async (values: Object, props: Object): void => {
    const {
      props: {
        actions: {
          member: { updateLogin }
        }
      }
    } = props;
    updateLogin({ username: values.login });
  },
  enableReinitialize: true,
  validateOnChange: true
};
export default usernameConfig;
