// @flow
import { propEq, reject } from 'ramda';
import CONFIG from '../config';

export type Toast = {
  id: number,
  message: string,
  type: string,
  rawMessage: boolean,
  delay: number,
  icon: string
};

export type ToastsModel = Array<Toast>;

export type ToastsActions = {
  showToast: (state: ToastsModel, toast: Toast) => ToastsModel,
  hideToast: (state: ToastsModel, id: number) => ToastsModel,
  removeAllToasts: () => ToastsModel
};

export default {
  state: [],
  reducers: {
    showToast: (
      state: ToastsModel,
      {
        message,
        type = 'SUCCESS',
        rawMessage = false,
        delay = CONFIG.PROMPT_TIMEOUT,
        icon = 'SAVE'
      }: Toast
    ): ToastsModel => [
      ...state,
      { id: new Date().getTime(), message, type, rawMessage, delay, icon }
    ],
    hideToast: (state: ToastsModel, id: number): ToastsModel =>
      reject(propEq('id', id))(state),
    removeAllToasts: (): ToastsModel => []
  }
};
