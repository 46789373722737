// flow
import React, { Fragment, type Node, type AbstractComponent } from 'react';
import { Col, Row } from 'reactstrap';
import { is } from 'ramda';
import classNames from 'classnames';
import { compose, withProps } from 'recompose';
import { withShadowBox, withNamespace } from '../../../hoc';
import { t, Button } from '../../../components';
import styles from '../payment.module.css';
import type { Actions } from '../../../utils/types';
import type { PaymentModel, Step } from '../../../models/payment/types';

type Props = {
  actions: Actions,
  payment: PaymentModel,
  step: Step
};

const withStepSummary = (Component: AbstractComponent) => (
  props: Props
): Node => {
  const {
    actions: {
      payment: { setData, setError }
    },
    payment: {
      stepIndex,
      isActiveStep,
      isPassedStep,
      editMode,
      disableEditPaymentType
    },
    step
  } = props;

  const headerStyles: string = classNames({
    [styles.blockHeader]: true,
    [styles.active]: isActiveStep,
    [styles.passedStep]: isPassedStep
  });

  const stepView: string = classNames({
    [styles.stepNumber]: true,
    [styles.passed]: isPassedStep
  });

  return (
    <Fragment>
      <header className={headerStyles}>
        <Row>
          <Col className={styles.paymentStep}>
            <h1>
              <span className={stepView}>{!isPassedStep && stepIndex + 1}</span>
              {t(step.header)}
            </h1>
            {isPassedStep && (
              <Button
                onClick={() => {
                  setData({ step: stepIndex });
                  setError({ accept: [], payment: null, transaction: null });
                }}
                title="payment.change"
                size="sm"
                disabled={editMode && stepIndex === 0 && disableEditPaymentType}
              />
            )}
          </Col>
        </Row>
        {isPassedStep && (
          <Row>
            <Col>
              <p className={styles.stepSummary}>
                {step.label && is(String, step.label)
                  ? t(step.label)
                  : t(step.label.id, step.label)}
              </p>
            </Col>
          </Row>
        )}
      </header>

      {isActiveStep && <Component {...props} />}
    </Fragment>
  );
};

export default compose(
  withNamespace('payment', ['stepIndex']),
  withProps(({ payment: { stepIndex, step } }: Props): Object => ({
    isActiveStep: stepIndex === step,
    isPassedStep: stepIndex < step,
    boxConfig: { small: false, active: stepIndex === step }
  })),
  withNamespace('payment', ['isActiveStep', 'isPassedStep']),
  withShadowBox,
  withStepSummary
);
