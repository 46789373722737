// @flow
import { useEffect } from 'react';
import { type Match } from 'react-router-dom';
import type { Actions } from '../../../utils/types';

export default (props: Match | Actions): void => {
  const {
    match: {
      params: { memberId }
    },
    actions: {
      payment: { setData }
    }
  } = props;

  useEffect((): Function => {
    return (): void => {
      setData();
    };
  }, [memberId, setData]);
};
