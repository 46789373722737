// @flow
import React, { type Node } from 'react';
import type { Formik } from '../../../../../../utils/types';

export default (props: Formik): Node => {
  const {
    formik: {
      touched,
      errors,
      values: { cardCode }
    }
  } = props;

  return (
    <g
      id="CCV"
      transform="translate(180.300000, 70.100000)"
      fill={touched.cardCode && errors.cardCode ? '#c9364d' : '#FFFFFF'}
    >
      <text
        id="XXX"
        fontFamily="Roboto, sans-serif"
        fontSize="14.4"
        fontWeight="400"
      >
        <tspan x="0" y="21">
          {cardCode ? cardCode.replace(/./g, 'X') : 'XXX'}
        </tspan>
      </text>
      <text
        id="CVV"
        fontFamily="Roboto, sans-serif"
        fontSize="6"
        fontWeight="normal"
        letterSpacing="1.285714"
      >
        <tspan x="0" y="6">
          CVV
        </tspan>
      </text>
    </g>
  );
};
