// @flow
import { object } from 'yup';
import { oktaLogin } from 'utils/validation/memberRegistrationWithOktaSchema';

const initialValue = {
  oktaLogin: ''
};

export default {
  mapPropsToValues: () => initialValue,
  validationSchema: object({ oktaLogin }),
  handleSubmit: async (values, { props }) => {
    const {
      actions: {
        member: { updateLogin }
      }
    } = props;

    await updateLogin({
      username: values.oktaLogin
    });
  },
  enableReinitialize: true,
  validateOnChange: true
};
