// @flow
import React, { Fragment, type AbstractComponent, type Node } from 'react';
import { IntlProvider, defineMessages } from 'react-intl';
import { flattenMessages } from '../utils';
import LANGUAGES from '../config/languages.config';
import languages from '../assets/i18n';
import type { Models } from '../utils/types';
import { EST_TIME_ZONE } from '../config/timezones.config';

const messages = defineMessages(languages);

export default (Component: AbstractComponent<Object>) =>
  (props: Models): Node => {
    const {
      member: {
        data: {
          member: { language = 'EN' }
        }
      }
    } = props;

    const currentLang: string = LANGUAGES[language].key;

    return (
      <IntlProvider
        locale={currentLang}
        defaultLocale="en"
        timeZone={EST_TIME_ZONE}
        textComponent={Fragment}
        messages={flattenMessages(messages[currentLang].default)}
      >
        <Component />
      </IntlProvider>
    );
  };
