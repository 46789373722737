import { Col, Row } from 'reactstrap';
import { GET_SCREEN_SIZE, SCREEN_SIZES } from '../../../../config';
// @flow
import React, { Fragment, type Node } from 'react';

import { Dropdown } from '../../../../components';
import type { MemberModel } from '../../../../models/member/types';
import Navigation from './Navigation';
import classNames from 'classnames';
import logo from '../../../../assets/images/HBLogo.svg';
import styles from '../app-layout.module.css';

type Props = {
  membersWidget: boolean,
  headerConfig: Object,
  member: MemberModel,
  dropDownConfig: Object
};

export default (props: Props): Node => {
  const {
    membersWidget,
    headerConfig: { breadcrumbConfig = [], withMembers = false } = {},
    member: {
      data: {
        member,
        member: {
          employment: { employerLogoRectangle, employerName } = {}
        } = {}
      }
    },
    dropDownConfig
  } = props;

  const SCREEN_SIZE = GET_SCREEN_SIZE();
  const IS_MOBILE_VIEW = SCREEN_SIZE === SCREEN_SIZES.MOBILE;
  const IS_TABLET_VIEW = SCREEN_SIZE === SCREEN_SIZES.TABLET;
  const IS_SMALL_DESKTOP_VIEW = SCREEN_SIZE === SCREEN_SIZES.SMALL_DESKTOP;
  const IS_DESKTOP_VIEW = SCREEN_SIZE === SCREEN_SIZES.DESKTOP;

  const mainRowStyles: string = classNames({
    [styles.mainHeaderRow]: true,
    'justify-content-end': !membersWidget
  });

  const withMembersStyles: string = classNames({
    [styles.logoBreadcrumb]: !withMembers,
    [styles.employerData]: withMembers
  });
  const featureWithOutMemberWidgetXS = 4;
  const featureWithOutMemberWidgetML = 3;

  const membersWidgetXSWidth: number = membersWidget
    ? 4
    : featureWithOutMemberWidgetXS;
  const membersWidgetMDWidth: number = membersWidget
    ? 3
    : featureWithOutMemberWidgetML;
  const membersWidgetLGWidth: number = membersWidget
    ? 4
    : featureWithOutMemberWidgetML;

  const withLogo: boolean = breadcrumbConfig.length === 0;
  const employerData: Node = employerLogoRectangle ? (
    <img
      src={employerLogoRectangle}
      alt={employerName}
      title={employerName}
      className={styles.employerLogo}
    />
  ) : (
    <h1 className={styles.employerName}>{employerName}</h1>
  );

  return (
    <Col>
      <Row className={mainRowStyles}>
        <Col
          xs={membersWidgetXSWidth}
          md={membersWidgetMDWidth}
          className={withMembersStyles}
        >
          {withLogo && (
            <Fragment>
              <img
                src={logo}
                alt="HealthBridge"
                title="HealthBridge"
                className={styles.hbfLogo}
              />
              {employerData}
            </Fragment>
          )}
        </Col>
        {IS_MOBILE_VIEW && (
          <Col>
            <img
              src={logo}
              alt="HealthBridge"
              title="HealthBridge"
              className={styles.hbfLogo}
            />
          </Col>
        )}

        {(IS_DESKTOP_VIEW || IS_SMALL_DESKTOP_VIEW) && (
          <Fragment>
            <Col className="d-flex justify-content-center">
              <Navigation {...props} />
            </Col>
            <Col xs={2} md={2} className="d-flex justify-content-end">
              {member && <Dropdown {...dropDownConfig.settings} />}
            </Col>
          </Fragment>
        )}
        {(IS_MOBILE_VIEW || IS_TABLET_VIEW) && (
          <Fragment>
            <Col className="d-flex justify-content-end">
              <Navigation {...props} />
            </Col>
            <Col
              xs={2}
              md={2}
              lg={membersWidgetLGWidth}
              className="d-flex justify-content-end"
            >
              {member && <Dropdown {...dropDownConfig.settings} />}
            </Col>
          </Fragment>
        )}
      </Row>
    </Col>
  );
};
