import { environmentName } from '../utils/environment';

export const rollbarForEnv = () => ({
  accessToken: '005804228b3c405281d2dc4208e4dcfd',
  captureUncaught: true,
  captureUnhandledRejections: true,
  autoInstrument: true,
  payload: {
    environment: environmentName(),
    context: 'react',
    ignoredMessages: [/^.*acsb.*$/, /^.*acsbJS.*$/, /^.*DataCloneError.*$/]
  }
});
