// @flow
import React, { Fragment, type Node, useState } from 'react';
import { Button, Col, FormText, Label, Row } from 'reactstrap';
import { MaskInput } from 'components/Form';
import { t, Checkbox } from 'components';
import { HEALTH_BRIDGE } from 'config/terms.config';
import type { Formik } from 'utils/types';
import styles from 'containers/Settings/settings.module.css';

export default (props): Node => {
  const {
    formik,
    formik: { dirty, setFieldValue, values },
    actions: {
      member: { resendCommunicationSms }
    },
    member: {
      data: {
        member: { smsMessagingConfig = {} }
      }
    }
  }: Formik = props;
  const [isSent, setIsSent] = useState(false);

  const smsWasConfirming = smsMessagingConfig?.config?.status === 'CONFIRMING';
  const isSmsChecked =
    values.smsMessagingConfig === 'CONFIRMING' ||
    values.smsMessagingConfig === 'ENABLED';

  return (
    <Fragment>
      <Row>
        <Col sm={12} md={9}>
          <MaskInput
            name="communicationPhone"
            className="communication"
            label="settings.settings-blocks.contact-info.communication-phone"
            maskConfig={{
              mask: '999-999-9999',
              formatChars: { '9': '\\d' },
              maskChar: null,
              'data-testid': 'communication-phone-input'
            }}
            {...formik}
            handleChange={(e) => {
              formik.handleChange(e);
              if (values.smsMessagingConfig === 'ENABLED') {
                setFieldValue('smsMessagingConfig', 'CONFIRMING');
              }
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <FormText
            data-testid="communication-phone"
            className="pt-1"
            data-cy="communication-phone-description"
          >
            {t(
              'settings.settings-blocks.contact-info.communication-phone-description-new',
              { phone: HEALTH_BRIDGE.PHONE_NUMBER }
            )}
          </FormText>
        </Col>
      </Row>
      <Fragment>
        <Row data-testid="sms-notifications" className="mt-4">
          <Col>
            <Label className={styles.label} for={'smsMessagingConfig'}>
              {t('settings.settings-blocks.contact-info.sms-notifications')}
            </Label>
          </Col>
        </Row>
        <Row>
          <Col>
            <Checkbox
              id="smsMessagingConfig"
              name="smsMessagingConfig"
              className="smsMessagingConfig"
              label={
                <span>
                  {t(
                    'settings.settings-blocks.contact-info.enable-notification'
                  )}
                </span>
              }
              {...formik}
              values={{
                ...formik.values,
                smsMessagingConfig: isSmsChecked
              }}
              handleChange={(e) => {
                formik.handleChange(e);
                setFieldValue(
                  'smsMessagingConfig',
                  e.target.value === 'true' ? 'DISABLED' : 'CONFIRMING'
                );
                formik.setFieldTouched('communicationPhone');
              }}
            />
          </Col>
        </Row>
        {values.smsMessagingConfig === 'CONFIRMING' && (
          <Row>
            <Col>
              <div
                className={styles.emailSent}
                data-testid="sms-confirmation-message"
              >
                <p className="m-0">
                  {t('settings.settings-blocks.contact-info.sms-confirmation', {
                    action: dirty
                      ? t(
                          'settings.settings-blocks.contact-info.sms-confirmation-will-send'
                        )
                      : t(
                          'settings.settings-blocks.contact-info.sms-confirmation-has-sent'
                        ),
                    phone: <strong>{values.communicationPhone}</strong>
                  })}
                </p>
                {smsWasConfirming && (
                  <Button
                    data-testid="resend-confirmation-button"
                    disabled={isSent}
                    className="m-2"
                    onClick={() => {
                      resendCommunicationSms();
                      setIsSent(true);
                    }}
                  >
                    {isSent
                      ? t(
                          'settings.settings-blocks.contact-info.resend-sms-sent'
                        )
                      : t(
                          'settings.settings-blocks.contact-info.resend-sms-resend'
                        )}
                  </Button>
                )}
              </div>
            </Col>
          </Row>
        )}
      </Fragment>
    </Fragment>
  );
};
