// @flow
import React, { Fragment, type AbstractComponent, type Node } from 'react';
import classNames from 'classnames';
import type { Match } from 'react-router-dom';
import Header from './Headers';
import { useScroll } from '../../../hooks';
import styles from './app-layout.module.css';
import type { MemberModel } from '../../../models/member/types';
import type { ToastsModel } from '../../../models/toasts';
import type { Actions, Formik } from '../../../utils/types';

type Props = {
  actions: Actions,
  dropDownConfig: Object,
  match: Match,
  formik: Formik,
  headerConfig: Object,
  member: MemberModel,
  toasts: ToastsModel
};

export default (Component: AbstractComponent<Object>) => (
  props: Props
): Node => {
  const {
    headerConfig: { fixedHeader = false, withMembers = false } = {},
    member: {
      data: { member: { familyMembers = [] } = {} }
    },
    toasts
  } = props;

  const isSystemError: boolean = toasts.some(
    ({ type }) => type === 'SYSTEM_ERROR'
  );
  const scroll: Object = useScroll();

  const fullMembersView: boolean =
    familyMembers.length > 1 &&
    familyMembers.length < 8 &&
    withMembers &&
    fixedHeader;

  const mainContentStyles: string = classNames({
    [styles.mainContent]: fixedHeader,
    [styles.withErrors]: isSystemError,
    [styles.fullMembersView]: fullMembersView,
    [styles.fullMembersViewWithError]: fullMembersView && isSystemError
  });

  return (
    <Fragment>
      <Header
        {...props}
        isSystemError={isSystemError}
        fullMembersView={fullMembersView}
        scroll={scroll}
        role="banner"
      />
      <main className={mainContentStyles} role="main">
        <Component
          {...props}
          scroll={scroll}
          systemError={isSystemError}
          fullMembersView={fullMembersView}
        />
      </main>
    </Fragment>
  );
};
