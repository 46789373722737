import { withStateHandlers } from 'recompose';

export default withStateHandlers(
  () => ({
    errorCode: '',
    duplicationPin: null,
    invalidEmail: null
  }),
  {
    showError: () => (errorCode) => ({ errorCode }),
    setDuplicationPin: () => (duplicationPin) => ({ duplicationPin }),
    setInvalidEmail: () => (invalidEmail) => ({ invalidEmail })
  }
);
