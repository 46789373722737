import { replace, startsWith } from 'ramda';
/**
 * Get the number of days in any particular month
 * Modified from:  https://stackoverflow.com/a/1433119/1293256
 */
const daysInMonth = (month, year) => {
  switch (month) {
    case 2:
      return (year % 4 === 0 && year % 100) || year % 400 === 0 ? 29 : 28;
    case 9:
    case 4:
    case 6:
    case 11:
      return 30;
    default:
      return 31;
  }
};

/**
 * Check if a date is valid
 * Modified from https://stackoverflow.com/a/1433119/1293256
 */
export default (date: string) => {
  const datePieces = date.split('/');
  const parsedDay = parseInt(datePieces[1], 10);
  const parsedMonth = parseInt(datePieces[0], 10);
  const year = date ? datePieces[2] : '';
  const validYear = replace(/_/g, '', year);
  return (
    parsedMonth >= 1 &&
    parsedMonth <= 12 &&
    parsedDay > 0 &&
    parsedDay <= daysInMonth(parsedMonth, year) &&
    validYear.length === 4 &&
    (startsWith('19', validYear) || startsWith('20', validYear))
  );
};
