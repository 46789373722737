// @flow
import { type Node } from 'react';
import { ENTITY_TYPE } from '../../../../config/payment.config';
import { t } from '../../../../components';
import { formatDate, linkTo, parseAmount } from '../utils';
import type { DataConfig } from './types';

export const amountRender = (dataConfig: DataConfig): Node => {
  const { row, cell } = dataConfig;
  const { data: rowData } = row || {};
  const { key } = cell || {};

  return parseAmount(rowData[key]);
};

export default ({ data, entityType, basePath }: Object): Object => ({
  className: 'modal-table',
  label: {
    title: `home.details-view.reversal-table.${entityType}`,
    quantity: data.length
  },
  header: [
    {
      title: 'home.details-view.reversal-table.transaction-date',
      width: '25%'
    },
    {
      title: 'home.details-view.reversal-table.amount',
      width: '20%'
    },
    {
      title: 'home.details-view.reversal-table.details',
      width: '30%'
    }
  ],
  data,
  body: [
    {
      key: 'date',
      render: (dataConfig: DataConfig): any => {
        const { row, cell } = dataConfig;
        const { data: rowData } = row || {};
        const { key } = cell || {};

        switch (entityType) {
          case ENTITY_TYPE.INTEREST_REVERSAL:
            return linkTo(
              `${basePath}/${ENTITY_TYPE.INTEREST}/${rowData.id}`,
              rowData[key]
            );
          case ENTITY_TYPE.PAPER_FEE_REVERSAL:
            return linkTo(
              `${basePath}/${ENTITY_TYPE.PAPER_FEE}/${rowData.id}`,
              rowData[key]
            );
          case ENTITY_TYPE.LATE_FEE_REVERSAL:
            return linkTo(
              `${basePath}/${ENTITY_TYPE.LATE_FEE}/${rowData.id}`,
              rowData[key]
            );
          default:
            return null;
        }
      }
    },
    {
      key: 'amount',
      render: amountRender
    },
    {
      key: 'datePeriodDto',
      render: (dataConfig: DataConfig): Node => {
        const { row, cell } = dataConfig;
        const { data: rowData } = row || {};
        const { key } = cell || {};

        return t('home.details-view.reversal-table.statement', {
          startDate: formatDate(rowData[key].startDate),
          endDate: formatDate(rowData[key].endDate)
        });
      }
    }
  ]
});

export const paymentInterestReversalTable = ({
  data,
  entityType
}: Object): Object => {
  return {
    className: 'modal-table',
    label: {
      title: `home.details-view.reversal-table.${entityType}`,
      quantity: data.length
    },
    header: [
      {
        title: 'home.details-view.reversal-table.claim-id',
        width: '50%'
      },
      {
        title: 'home.details-view.reversal-table.amount',
        width: '50%'
      }
    ],
    data,
    body: [
      {
        key: 'controlNumber',
        render: amountRender
      },
      {
        key: 'amount',
        render: amountRender
      }
    ]
  };
};
