// @flow
import { type Node } from 'react';
import { t } from '../../../../components';
import {
  ENTITY_TYPE,
  INTEREST_REVERSAL_TYPE
} from '../../../../config/payment.config';
import {
  formatDate,
  dateTime,
  parsePeriod,
  parseAmount,
  parseAmountAbs,
  parseDiscount,
  linkTo,
  OOP_TYPE
} from '../utils';
import { featureFlagKeys } from '../../../../config';

const REVERSAL_BODY_DATE = t('home.details-view.interest-reversal-body.date');
const PAYMENT_DETAILS = t(
  'home.scheduled-payment-details-view.payment-details'
);

export const INTEREST_REVERSAL_CONFIG = {
  [INTEREST_REVERSAL_TYPE.PAYMENT]: [
    {
      title: t('home.details-view.interest-reversal-body.amount'),
      render: ({ amount }): Node => parseAmount(amount)
    },
    {
      title: REVERSAL_BODY_DATE,
      render: ({ date }): Node => dateTime(date)
    },
    {
      title: t('home.details-view.interest-reversal-body.details'),
      render: ({ interestReversalType }): Node => interestReversalType
    }
  ]
};
//Claim body parts
const claimAmount = {
  title: t('home.details-view.claim-body.claim-amount'),
  render: ({ claimAmount }): Node => parseAmount(claimAmount)
};

const amount = {
  title: t('home.details-view.claim-body.amount'),
  render: ({ originalClaimAmount }): Node => parseAmount(-originalClaimAmount)
};
const serviceDate = {
  title: t('home.details-view.claim-body.service-date'),
  render: ({ serviceDate }): Node => formatDate(serviceDate)
};
const claimId = {
  title: t('home.details-view.claim-body.claim-id'),
  render: ({ claimId }): Node => claimId
};

const policyClaimNumber = {
  title: t('home.details-view.claim-body.policy-claim-number'),
  render: ({ policyClaimNumber }): Node => policyClaimNumber
};
const claimType = {
  title: t('home.details-view.claim-body.claim-type'),
  render: ({ claimType }): Node =>
    t(`home.details-view.claim-body.${claimType}`)
};

const creditType = {
  title: t('home.details-view.claim-body.credit-type'),
  render: ({ type }): Node => t(`home.details-view.${type}`)
};

const hospital = {
  title: t('home.details-view.claim-body.hospital'),
  render: ({ hospital }): Node => hospital
};
const serviceProviderType = {
  title: t('home.details-view.claim-body.service-provider'),
  render: (data, props): Node => {
    const { serviceProvider } = data;
    const { flags } = props;
    return flags[featureFlagKeys.memberClaimLines] ? serviceProvider : null;
  }
};
const lobType = {
  title: t('home.details-view.claim-body.lobType'),
  render: ({ lobType }): Node => lobType
};
const oopType = {
  title: t('home.details-view.claim-body.oopType'),
  render: ({ oopType }): Node => OOP_TYPE[oopType]
};
const transactionDate = {
  title: t('home.details-view.claim-body.transaction-date'),
  render: ({ transactionDate }): Node => dateTime(transactionDate)
};

const adjudicationDate = {
  title: t('home.details-view.claim-body.adjudication-date'),
  render: ({ adjudicationDate }): Node => dateTime(adjudicationDate)
};
const disputedStart = {
  title: t('home.details-view.claim-body.disputed-start'),
  render: ({ disputeStartDate }): Node => dateTime(disputeStartDate)
};
const disputedEnd = {
  title: t('home.details-view.claim-body.disputed-end'),
  render: ({ disputeEndDate }): Node =>
    disputeEndDate ? dateTime(disputeEndDate) : '-'
};
const paymentSource = {
  title: t('home.details-view.paper-check-body.payment_source'),
  render: ({ paymentSource }): Node => paymentSource
};

const processedBy = {
  title: t('home.details-view.paper-check-body.processed_by'),
  render: ({ processedBy }): Node => processedBy
};

const originalClaimAmount = {
  title: t('home.details-view.claim-body.originalClaimAmount'),
  render: ({ originalClaimAmount }): Node => {
    return originalClaimAmount !== null
      ? parseAmount(originalClaimAmount)
      : null;
  }
};

const partialAdjustmentAmount = {
  title: t('home.details-view.claim-body.partialAdjustmentAmount'),
  render: ({ partialAdjustmentAmount }): Node => {
    return partialAdjustmentAmount !== null
      ? parseAmountAbs(partialAdjustmentAmount)
      : null;
  }
};
const partialAdjustmentReason = {
  title: t('home.details-view.claim-body.partialAdjustmentReason'),
  render: ({ partialAdjustmentReason }): Node => {
    return partialAdjustmentReason !== null
      ? t(`home.details-view.claim-body.${partialAdjustmentReason}`)
      : null;
  }
};

export default {
  [ENTITY_TYPE.CLAIM]: [
    claimAmount,
    partialAdjustmentReason,
    partialAdjustmentAmount,
    originalClaimAmount,
    serviceDate,
    claimId,
    claimType,
    hospital,
    serviceProviderType,
    lobType,
    oopType,
    transactionDate
  ],
  [ENTITY_TYPE.CLAIM_DISPUTED]: [
    claimAmount,
    serviceDate,
    claimId,
    claimType,
    hospital,
    lobType,
    oopType,
    transactionDate,
    disputedStart,
    disputedEnd
  ],
  [ENTITY_TYPE.CREDIT_HOLD_HRA]: [
    amount,
    serviceDate,
    policyClaimNumber,
    creditType,
    hospital,
    lobType,
    oopType,
    adjudicationDate
  ],
  [ENTITY_TYPE.CREDIT_HOLD_INDEMNIFICATION]: [
    amount,
    serviceDate,
    policyClaimNumber,
    creditType,
    hospital,
    lobType,
    oopType,
    adjudicationDate
  ],
  [ENTITY_TYPE.CREDIT_HOLD_WELLNESS]: [
    amount,
    serviceDate,
    policyClaimNumber,
    creditType,
    hospital,
    lobType,
    oopType,
    adjudicationDate
  ],

  [ENTITY_TYPE.CREDIT_CARD]: [
    {
      title: t('home.details-view.credit-card-body.amount'),
      render: ({ amount }): Node => parseAmount(amount)
    },
    {
      title: t('home.details-view.credit-card-body.discount'),
      render: ({ discount }): Node => parseDiscount(discount)
    },
    {
      title: t('home.details-view.credit-card-body.date'),
      render: ({ eventDate }): Node => dateTime(eventDate)
    },
    {
      title: t('home.details-view.credit-card-body.payment-method'),
      render: (): Node => t('home.details-view.credit-card-body.credit_card')
    },
    {
      title: t('home.details-view.credit-card-body.card'),
      render: ({ accountType, accountNumber, expirationDate }): Node =>
        t('home.details-view.credit-card-body.card-details', {
          accountType,
          accountNumber,
          expirationDate
        })
    },
    {
      title: t('home.details-view.credit-card-body.transaction_id'),
      render: ({ transactionId }): Node => transactionId
    },
    processedBy,
    paymentSource
  ],
  [ENTITY_TYPE.OVER_PAYMENT_REFUND]: [
    {
      title: t('home.details-view.adjustment-body.amount'),
      render: ({ amount }): Node => parseAmount(amount)
    },
    {
      title: t('home.details-view.adjustment-body.date'),
      render: ({ eventDate }): Node => dateTime(eventDate)
    },
    {
      title: t('home.details-view.adjustment-body.type'),
      render: ({ entityType }): Node =>
        t(`home.transaction-heading.${entityType}`)
    }
  ],
  [ENTITY_TYPE.MANUAL_ADJUSTMENT]: [
    {
      title: t('home.details-view.manual-adjustment-body.amount'),
      render: ({ amount }): Node => parseAmount(amount)
    },
    {
      title: t('home.details-view.manual-adjustment-body.date'),
      render: ({ date }): Node => dateTime(date)
    },
    {
      title: t('home.details-view.manual-adjustment-body.details'),
      render: (): Node => t(`home.transaction-heading.manual_adjustment`)
    }
  ],
  [ENTITY_TYPE.ECHECK]: [
    {
      title: t('home.details-view.echeck-body.amount'),
      render: ({ amount }): Node => parseAmount(amount)
    },
    {
      title: t('home.details-view.echeck-body.discount'),
      render: ({ discount }): Node => parseDiscount(discount)
    },
    {
      title: t('home.details-view.echeck-body.date'),
      render: ({ eventDate }): Node => dateTime(eventDate)
    },
    {
      title: t('home.details-view.echeck-body.payment-method'),
      render: (): Node => t('home.details-view.echeck-body.echeck')
    },
    {
      title: t('home.details-view.echeck-body.echeck'),
      render: ({ accountType, accountNumber }): Node =>
        `${accountType} •••• ${accountNumber}`
    },
    {
      title: t('home.details-view.echeck-body.transaction_id'),
      render: ({ transactionId }): Node => transactionId
    },
    processedBy,
    paymentSource
  ],
  [ENTITY_TYPE.PAPER_CHECK]: [
    {
      title: t('home.details-view.paper-check-body.amount'),
      render: ({ amount }): Node => parseAmount(amount)
    },
    {
      title: t('home.details-view.paper-check-body.discount'),
      render: ({ discount }): Node => parseDiscount(discount)
    },
    {
      title: t('home.details-view.paper-check-body.date'),
      render: ({ eventDate }): Node => dateTime(eventDate)
    },
    {
      title: t('home.details-view.paper-check-body.payment-method'),
      render: (): Node => t('home.transaction-description.paper_check')
    },
    {
      title: t('home.details-view.paper-check-body.paper_check'),
      render: ({ accountNumber }): string => `•••• ${accountNumber}`
    },
    {
      title: t('home.details-view.paper-check-body.transaction_id'),
      render: ({ transactionId }): Node => transactionId
    },
    processedBy,
    paymentSource
  ],
  [ENTITY_TYPE.QUICK_PAY_DISCOUNT]: [
    {
      title: t('home.details-view.quick_pay_discount-body.amount'),
      render: ({ amount }): Node => parseAmount(amount)
    },
    {
      title: t('home.details-view.quick_pay_discount-body.date'),
      render: ({ date: dateValue }): Node => dateTime(dateValue)
    },
    {
      title: t('home.details-view.quick_pay_discount-body.details'),
      render: (data, props) => {
        const { payment } = data;
        const { type, date: dateValue, id } = payment || {};

        const {
          match: {
            params: { user, memberId, tab, entityType }
          }
        } = props;

        return t(`home.transaction-heading.${entityType}`, {
          date: linkTo(`/${user}/${memberId}/${tab}/${type}/${id}`, dateValue)
        });
      }
    }
  ],
  [ENTITY_TYPE.QUICK_PAY_DISCOUNT_REVERSAL]: [
    {
      title: t('home.details-view.quick_pay_discount_reversal-body.amount'),
      render: ({ amount }): Node => parseAmount(amount)
    },
    {
      title: t('home.details-view.quick_pay_discount_reversal-body.date'),
      render: ({ date: dateValue }): Node => dateTime(dateValue)
    },
    {
      title: t('home.details-view.quick_pay_discount_reversal-body.details'),
      render: (data, props) => {
        const { quickPayDiscount } = data;
        const { id, date: dateValue } = quickPayDiscount || {};

        const {
          match: {
            params: { user, memberId, tab, entityType }
          }
        } = props;

        return t(`home.transaction-heading.${entityType}`, {
          date: linkTo(
            `/${user}/${memberId}/${tab}/${ENTITY_TYPE.QUICK_PAY_DISCOUNT}/${id}`,
            dateValue
          )
        });
      }
    }
  ],
  [ENTITY_TYPE.PAPER_FEE]: [
    {
      title: t('home.details-view.paper-fee-body.amount'),
      render: ({ amount }): Node => parseAmount(amount)
    },
    {
      title: t('home.details-view.paper-fee-body.date'),
      render: ({ date }): Node => dateTime(date)
    },
    {
      title: t('home.details-view.paper-fee-body.statement'),
      render: (data, props): Node => parsePeriod(data, props)
    }
  ],
  [ENTITY_TYPE.PAPER_FEE_REVERSAL]: [
    {
      title: t('home.details-view.paper-fee-reversal-body.amount'),
      render: ({ amount }): Node => parseAmount(amount)
    },
    {
      title: t('home.details-view.paper-fee-reversal-body.date'),
      render: ({ date }): Node => dateTime(date)
    },
    {
      title: t('home.details-view.paper-fee-reversal-body.details'),
      render: (): Node => t(`home.transaction-heading.paper_fee_reversal`)
    }
  ],
  [ENTITY_TYPE.LATE_FEE]: [
    {
      title: t('home.details-view.late-fee-body.amount'),
      render: ({ amount }): Node => parseAmount(amount)
    },
    {
      title: t('home.details-view.late-fee-body.date'),
      render: ({ date }): Node => dateTime(date)
    },
    {
      title: t('home.details-view.late-fee-body.statement'),
      render: (data, props): Node => parsePeriod(data, props)
    }
  ],
  [ENTITY_TYPE.LATE_FEE_REVERSAL]: [
    {
      title: t('home.details-view.late-fee-reversal-body.amount'),
      render: ({ amount }): Node => parseAmount(amount)
    },
    {
      title: t('home.details-view.late-fee-reversal-body.date'),
      render: ({ date }): Node => dateTime(date)
    },
    {
      title: t('home.details-view.late-fee-reversal-body.details'),
      render: (): Node => t('home.transaction-heading.late_fee_reversal')
    }
  ],
  [ENTITY_TYPE.INTEREST]: [
    {
      title: t('home.details-view.interest-body.amount'),
      render: ({ amount }): Node => parseAmount(amount)
    },
    {
      title: t('home.details-view.interest-body.date'),
      render: ({ date }): Node => dateTime(date)
    },
    {
      title: t('home.details-view.interest-body.statement'),
      render: (data, props): Node => parsePeriod(data, props)
    }
  ],
  [ENTITY_TYPE.INTEREST_REVERSAL]: [
    {
      title: t('home.details-view.interest-reversal-body.amount'),
      render: ({ amount }): Node => parseAmount(amount)
    },
    {
      title: REVERSAL_BODY_DATE,
      render: ({ date }): Node => dateTime(date)
    },
    {
      title: t('home.details-view.interest-reversal-body.details'),
      render: ({ policyClaimNumber, claimEntityId }: Object, props): Node => {
        const {
          match: {
            params: { user, memberId, tab }
          }
        } = props;

        return t(`home.details-view.interest-reversal-body.claim-link`, {
          claimId: linkTo(
            `/${user}/${memberId}/${tab}/claim/${claimEntityId}`,
            policyClaimNumber,
            true
          )
        });
      }
    }
  ],
  [ENTITY_TYPE.REVERSAL]: [
    {
      title: t('home.details-view.reversal-body.amount'),
      render: ({ amount }): Node => parseAmount(amount)
    },
    {
      title: t('home.details-view.reversal-body.discount'),
      render: ({ discount }): Node => parseDiscount(discount)
    },
    {
      title: t('home.details-view.reversal-body.date'),
      render: ({ eventDate }): Node => dateTime(eventDate)
    },
    {
      title: t('home.details-view.reversal-body.details'),
      render: (data): Node => {
        const { paymentDetails } = data;
        const { date } = paymentDetails || {};

        return t('home.transaction-heading.reversal', {
          date: formatDate(date)
        });
      }
    },
    {
      title: t('home.details-view.reversal-body.payment-date'),
      render: (data, props) => {
        const { paymentDetails } = data;
        const { type, date, id } = paymentDetails || {};
        const {
          match: {
            params: { user, memberId, tab }
          }
        } = props;

        return linkTo(`/${user}/${memberId}/${tab}/${type}/${id}`, date);
      }
    }
  ],
  [ENTITY_TYPE.SCHEDULED_PAYMENT_TRANSACTIONS]: [
    {
      title: t('home.scheduled-payment-details-view.type-payment'),
      render: ({ type }): Node => type
    },
    {
      title: t('home.scheduled-payment-details-view.amount'),
      render: ({ amount }): Node => parseAmount(amount)
    },
    {
      title: t('home.scheduled-payment-details-view.scheduled-date'),
      render: ({ startAfter }): Node => dateTime(startAfter)
    },
    {
      title: t('home.scheduled-payment-details-view.type-operation'),
      render: ({ transactionType }): Node => transactionType
    },
    {
      title: PAYMENT_DETAILS,
      render: ({ details }): Node => details
    },
    {
      title: t('home.scheduled-payment-details-view.delete-confirmation'),
      render: ({ details }): Node => details,
      isWarning: true
    }
  ],
  [ENTITY_TYPE.PARTIAL_ADJUSTMENT]: [
    {
      title: t('home.details-view.late-fee-reversal-body.amount'),
      render: ({ amount }): Node => parseAmount(amount)
    },
    {
      title: REVERSAL_BODY_DATE,
      render: ({ date }): Node => dateTime(date)
    },
    {
      title: PAYMENT_DETAILS,
      render: ({ description }): Node => description
    },
    {
      title: t('home.details-view.claim-body.reason'),
      render: ({ entityDescription }): Node =>
        t(`home.details-view.claim-body.${entityDescription}`)
    }
  ]
};
