// @flow
import initialState from './state';
import { setError } from '../../utils/errorInterceptor';
import type { PaymentModel } from './types';

export default {
  setData: (state: PaymentModel, data: Object = initialState): PaymentModel =>
    Object.assign({}, state, data),
  setError: (state: PaymentModel, errors: Object): PaymentModel =>
    setError(state, errors),
  addRetry: (state: PaymentModel) =>
    Object.assign({}, state, {
      retries: state.retries + 1
    }),
  resetRetries: (state: PaymentModel) =>
    Object.assign({}, state, {
      retries: 0
    }),
  resetPaymentDetails: (state: PaymentModel, data: Object): PaymentModel =>
    Object.assign({}, state, {
      ...data,
      cardNumber: '',
      cardType: '',
      month: '',
      year: '',
      cardCode: '',
      fullName: '',
      zip: '',
      accountNumber: '',
      routingNumber: '',
      expirationDate: ''
    })
};
