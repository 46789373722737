// @flow
import React, { Suspense, lazy, type Node } from 'react';
import { Switch, Route, Redirect } from 'react-router';
import { compose } from 'recompose';
import { withMapStateDispatch, withI18n } from './hoc';
import { Home } from './containers';
import ROUTES from './config/routes.config';
import withLDProvider from './hoc/withLDProvider';
import { Provider } from '@rollbar/react';
import { rollbarForEnv } from './config/rollbarConfig';

const Settings = lazy(() => import('./containers/Settings'));
const TermsAndConditions = lazy(() =>
  import('./containers/TermsAndConditions')
);
const Payment = lazy(() => import('./containers/Payment'));
const Auth = lazy(() => import('./containers/Auth'));
const Processing = lazy(() => import('./components/Processing'));
const Logout = lazy(() => import('./components/Logout'));
const NotFound = lazy(() => import('./components/ErrorView/NotFound'));
const Error400 = lazy(() => import('./components/ErrorView/ErrorPage400'));
const Error500 = lazy(() => import('./components/ErrorView/ErrorPage500'));
const MembershipResources = lazy(() =>
  import('./containers/MembershipResources')
);

let App;

App = (): Node => (
  <Provider config={rollbarForEnv()}>
    <Suspense fallback={null}>
      <Switch>
        <Route path={ROUTES.LOG_OUT} component={Logout} />
        <Route path={ROUTES.PROCESSING} component={Processing} />
        <Route path={ROUTES.NOT_FOUND} component={NotFound} />
        <Route path={ROUTES.ERROR_400} component={Error400} />
        <Route path={ROUTES.ERROR_401} component={Error400} />
        <Route path={ROUTES.ERROR_403} component={Error400} />
        <Route path={ROUTES.ERROR_404} component={Error400} />
        <Route path={ROUTES.ERROR_500} component={Error500} />
        <Route path={ROUTES.ERROR_501} component={Error500} />
        <Route path={ROUTES.ERROR_502} component={Error500} />
        <Route path={ROUTES.ERROR_503} component={Error500} />
        <Route path={ROUTES.SETTINGS} component={Settings} />
        <Route path={ROUTES.TERMS} component={TermsAndConditions} />
        <Route path={ROUTES.EDIT_SCHECULED_PAYMENT} component={Payment} />
        <Route path={ROUTES.PAYMENT} component={Payment} />
        <Route path={ROUTES.AUTH} component={Auth} />
        <Route path={ROUTES.HOME} component={Home} />
        <Route
          path={ROUTES.MEMBERSHIP_RESOURCES}
          component={MembershipResources}
        />
        <Redirect to={ROUTES.BASE_PATH} />
      </Switch>
    </Suspense>
  </Provider>
);

export default compose(withLDProvider, withMapStateDispatch, withI18n)(App);
