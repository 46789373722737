// @flow
import React, { type Node } from 'react';
import { Col, Form, Row } from 'reactstrap';
import type { Route } from 'react-router-dom';
import { t, Status, ErrorView, Button, Input } from '../../../../components';
import BackToLogin from '../BackToLogin';
import styles from '../auth.module.css';
import type { Formik } from '../../../../utils/types';
import type { AuthModel } from '../../../../models/auth/types';

type Props = {
  formik: Formik,
  auth: AuthModel,
  toLogin: Route,
  viewError: boolean
};

export default (props: Props): Node => {
  const {
    formik,
    formik: { values, handleSubmit },

    auth: {
      data: { checkInbox },
      error
    },
    viewError
  } = props;

  const registerMember: Node = (
    <Status header="auth.register.message">
      <Row>
        <Col>
          <p>{t('auth.register.description')}</p>
        </Col>
      </Row>
      {viewError && <ErrorView error={error} className="mb-3" />}
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col>
            <Input name="email" title="auth.register.email" {...formik} />
          </Col>
        </Row>
        <Row>
          <Col>
            <Input
              name="confirmEmail"
              title="auth.register.confirm-email"
              {...formik}
            />
          </Col>
        </Row>
        <Button
          className="mt-4"
          primary
          type="submit"
          title="auth.register.register-btn"
        />
      </Form>
    </Status>
  );

  const checkInboxView: Node = (
    <Status header="auth.feedback.check-inbox" icon="sent-email">
      {t('auth.feedback.register-email-sent', { email: values.email })}
    </Status>
  );

  return (
    <Row className={styles.registration}>
      <Col xs={11} md={10} lg={8} xl={5}>
        {!checkInbox && <BackToLogin {...props} align />}
        {checkInbox ? checkInboxView : registerMember}
      </Col>
    </Row>
  );
};
