// @flow
import React, { type Node } from 'react';
import { t } from '../i18n';
import loadingImage from '../../assets/images/icons/loading.svg';
import styles from './loader.module.css';

type Props = { loader?: { message?: string } };

export default (props: Props): Node => {
  const { loader, defaultMessage = 'loaders.default' } = props;

  const messageToFormat =
    loader && loader.message !== '' ? loader.message : defaultMessage;
  const loadingMessage: Node = t(messageToFormat);

  return (
    <div className={styles.loader}>
      <div>
        <h1>{loadingMessage}...</h1>
        <img src={loadingImage} alt={loadingMessage} />
      </div>
    </div>
  );
};
