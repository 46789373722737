import React, { type Node } from 'react';
import { truncateDescription } from '../utils';
import type { DataConfig } from './types';
import { number } from '../../../../components';
import Tooltip from '../../../../components/Tooltip';

export default ({ data }: Object): Object => ({
  data,
  className: 'modal-table',
  label: {
    title: 'home.details-view.claim-body.table.claim-lines-title'
  },
  header: [
    {
      title: 'home.details-view.claim-body.table.procedure-code',
      width: '25%'
    },
    {
      title: 'home.details-view.claim-body.table.medical-description',
      width: '50%'
    },
    {
      title: 'home.details-view.claim-body.table.liability',
      width: '25%'
    }
  ],
  body: [
    {
      render: (dataConfig: DataConfig): Node => {
        const { row, cell } = dataConfig;
        const { data: rowData } = row || {};
        const { key } = cell || {};
        if (rowData.cptCode === null) {
          return '-';
        }
        return rowData[key];
      },
      key: 'cptCode'
    },
    {
      render: (dataConfig: DataConfig): Node => {
        const { row, cell } = dataConfig;
        const { data: rowData } = row || {};
        const { key } = cell || {};
        if (rowData.cptCodeDescription === null) {
          return 'Private';
        }
        const tooltip = {
          message: rowData[key],
          target: `${key}${rowData.cptCode}`,
          label: truncateDescription(rowData[key], 75),
          pointer: true
        };
        return <Tooltip {...tooltip}></Tooltip>;
      },
      key: 'cptCodeDescription'
    },
    {
      render: (dataConfig: DataConfig): Node => {
        const { row, cell } = dataConfig;
        const { data: rowData } = row || {};
        const { key } = cell || {};

        return number(rowData[key]);
      },
      key: 'liability'
    }
  ]
});
