// @flow
import { concat, flatten, path, values } from 'ramda';
import { DateTime } from 'luxon';

export default (
  content: Array<Object>,
  result: Object,
  sortPath: Array<string>
): Object => {
  const sortedListByYear = {};

  const dataList: Array<Object> = concat(
    flatten(values(content)),
    result.content
  );

  dataList.forEach((item: Object, i: number, list: Array<Object>): void => {
    const year: number = DateTime.fromISO(path(sortPath, item)).year;
    sortedListByYear[year] = list.filter(
      (listItem): boolean =>
        DateTime.fromISO(path(sortPath, listItem)).year === year
    );
  });

  return sortedListByYear;
};
