// @flow
import React, { type Node } from 'react';
import { ButtonGroup, FormGroup, Label } from 'reactstrap';
import Button from './Button';
import { t } from './i18n';

type Props = {
  size?: string,
  config: Array<{
    title: string,
    value: string
  }>,
  value: string,
  vertical?: boolean,
  className?: string,
  label?: string,
  name: string,
  required?: boolean
};

export default (props: Props) => {
  const {
    size = 'sm',
    vertical = false,
    config,
    value: currentValue,
    className,
    label,
    name,
    required = false
  } = props;

  const buttons = config.map(
    (button: Object, index: number): Node => {
      const { onClick, title, value, disabled } = button;
      const isActive = currentValue === value;

      return (
        <Button
          key={index}
          active={isActive}
          type="button"
          disabled={disabled}
          primary={isActive}
          title={title}
          onClick={(e) => onClick(e, { index, ...button })}
        />
      );
    }
  );

  return (
    <FormGroup required={required}>
      {label && <Label for={name}>{t(label)}</Label>}
      <ButtonGroup size={size} vertical={vertical} className={className}>
        {buttons}
      </ButtonGroup>
    </FormGroup>
  );
};
