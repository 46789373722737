// @flow
import React, { Fragment, useState, useEffect } from 'react';
import { Col, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { t, Button, Input } from '../../../../../components';
import AccessErrors from '../../AccessErrors';
import getMemberAccessFromState from '../../../../../utils/getMemberAccessFromState';
import ERRORS_CODE, {
  handleGoBack,
  ACCESS_TYPE,
  denyAccess
} from '../../utils';
import styles from '../../../../../components/Modal/modals.module.css';

type acceptAccessModal = {
  formik: Object,
  match: {
    params: { familyMemberId: number }
  },
  showAgreement: Function,
  errorCode: string
};

export default (props: acceptAccessModal) => {
  const {
    formik,
    errorCode,
    showAgreement,
    match: {
      params: { familyMemberId }
    }
  } = props;

  const { handleSubmit, isValid } = formik;

  /**
   * Find current family member in list
   */
  const [name, setName] = useState();
  const [isPinConfirmed, setPinConfirm] = useState(false);

  useEffect(() => {
    const item = getMemberAccessFromState(props, familyMemberId);

    if (item) {
      const { member, status } = item;

      setName(member.name);
      setPinConfirm(status === ERRORS_CODE.PIN_CONFIRMED);
    }
  }, [familyMemberId, props]);

  const onSubmit = (values) => {
    isPinConfirmed ? showAgreement(true) : handleSubmit(values);
  };

  const disabledDenyButton =
    errorCode === ERRORS_CODE.PIN_MAX_ATTEMPTS_EXCEEDED;

  const disableSubmitButton =
    disabledDenyButton || (!isPinConfirmed && !isValid);

  return (
    <Fragment>
      <ModalHeader
        tag="h1"
        data-cy="modal-header"
        toggle={() => handleGoBack({ type: ACCESS_TYPE.REQUEST, ...props })}
      >
        {t('home.accept-access-modal.header')}
      </ModalHeader>

      <ModalBody data-cy="modal-body">
        <Row>
          <Col sm="12">
            {t('home.accept-access-modal.description', {
              name: <b>{name}</b>
            })}
          </Col>

          <AccessErrors errorCode={errorCode} name={name}>
            <Col sm="12" className="mt-2">
              {!isPinConfirmed ? (
                <Input
                  name="pin"
                  required
                  maxLength={4}
                  title="home.request-access-modal.form.pin.title"
                  {...formik}
                />
              ) : (
                <Input
                  required
                  disabled
                  name="confirmedPin"
                  title="home.request-access-modal.form.pin.title"
                  {...formik}
                />
              )}
            </Col>
          </AccessErrors>
        </Row>
      </ModalBody>
      <ModalFooter className={styles.modalControls} data-cy="modal-footer">
        <Button
          primary
          disabled={disableSubmitButton}
          onClick={onSubmit}
          title="home.accept-access-modal.buttons.submit"
        />

        <Button
          type="submit"
          disabled={disabledDenyButton}
          title="home.accept-access-modal.buttons.cancel"
          cy="close"
          onClick={() => denyAccess({ type: ACCESS_TYPE.REQUEST, ...props })}
        />
      </ModalFooter>
    </Fragment>
  );
};
