export const environmentName = (forUrl: string) => {
  const url = forUrl || window.location.href;
  if (url.indexOf('myhealthbridge.com') > -1) {
    return 'prod';
  }
  const envs = [
    'blue-test',
    'red-test',
    'staging',
    'dev',
    'localhost',
    'prod-mirror',
    'hotfix'
  ];
  return envs.filter((v) => url.indexOf(v) > -1)[0] || 'unknown';
};
