// @flow
import {
  AMOUNT_OPTIONS,
  PAYMENT_TYPE
} from '../../../../config/payment.config';
import { amountSchema } from '../../../../utils/validation';
import type { FormikConfig, Models } from '../../../../utils/types';

export const getDefaultType = (payStatement, paymentType) => {
  if (
    paymentType === PAYMENT_TYPE.RECURRING_PAYMENT ||
    payStatement === PAYMENT_TYPE.RECURRING_PAYMENT
  ) {
    return AMOUNT_OPTIONS.FULL_STATEMENT;
  } else if (paymentType === PAYMENT_TYPE.SCHEDULED_PAYMENT) {
    return AMOUNT_OPTIONS.OTHER;
  }
  return AMOUNT_OPTIONS.FULL_STATEMENT;
};

export default ({
  mapPropsToValues: (props: Models): Object => {
    const {
      currentQuickPay,
      payment: {
        type,
        repaymentAccepted,
        paymentType,
        otherAmount,
        maxAmount,
        maxAmountMin,
        maxAmountFull,
        maxAmountLastStatement,
        editMode,
        hasStatement
      },
      member: {
        data: {
          familyMember: {
            balanceDetails: {
              balance,
              currentBalanceQuickPay,
              currentStatementQuickPay,
              minimumPaymentDue,
              disputedClaimCount
            }
          }
        }
      }
    } = props;

    const payStatement = hasStatement && disputedClaimCount <= 0;
    const defaultType = getDefaultType(payStatement, paymentType);

    let selectedType =
      type !== AMOUNT_OPTIONS.MINIMUM_PAYMENT_DUE &&
      type !== AMOUNT_OPTIONS.OTHER &&
      type !== AMOUNT_OPTIONS.FULL
        ? defaultType
        : type;
    const oneTimePayment = paymentType === PAYMENT_TYPE.ONE_TIME_PAYMENT;
    const amount: number =
      type === AMOUNT_OPTIONS.MINIMUM_PAYMENT_DUE
        ? minimumPaymentDue
          ? minimumPaymentDue.remaining
          : ''
        : +(balance / 3).toFixed(2);

    const recurringAmount: number =
      type === AMOUNT_OPTIONS.MINIMUM_PAYMENT_DUE
        ? 0
        : +(balance / 3).toFixed(2);

    const otherAmountValue: number | string =
      !oneTimePayment && editMode && type === AMOUNT_OPTIONS.MINIMUM_PAYMENT_DUE
        ? ''
        : otherAmount;

    let oneTimeAmount;
    switch (selectedType) {
      case AMOUNT_OPTIONS.FULL:
        oneTimeAmount = currentBalanceQuickPay && currentBalanceQuickPay.amount;
        break;
      case AMOUNT_OPTIONS.MINIMUM_PAYMENT_DUE:
        oneTimeAmount = amount;
        break;
      case AMOUNT_OPTIONS.OTHER:
        oneTimeAmount = otherAmountValue;
        break;
      case AMOUNT_OPTIONS.FULL_STATEMENT:
        oneTimeAmount =
          currentStatementQuickPay && currentStatementQuickPay.amount;
        break;
      default:
        oneTimeAmount = currentQuickPay && currentQuickPay.amount;
        break;
    }

    return {
      paymentType,
      type: selectedType,
      otherAmount: otherAmountValue,
      amount: oneTimePayment ? oneTimeAmount : recurringAmount,
      maxAmount,
      maxAmountMin,
      maxAmountFull,
      maxAmountLastStatement,
      repaymentAccepted,
      balanceDue:
        currentQuickPay && currentQuickPay.amount > 0
          ? currentQuickPay.amount
          : balance
    };
  },
  validationSchema: amountSchema,
  handleSubmit: (values: Object, props: Object): void => {
    const {
      props: {
        actions: {
          payment: { setData, setError }
        },
        payment: { step }
      }
    } = props;

    setData({ ...values, step: step + 1 });
    setError();
  },
  enableReinitialize: true,
  validateOnChange: true
}: FormikConfig);
