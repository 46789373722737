// @flow
import React, { type Node } from 'react';
import { Row, Col } from 'reactstrap';
import { Input, ButtonGroup, t } from '../../../../../components';
import eCheck from '../../../../../assets/images/e-check.svg';
import styles from '../../../payment.module.css';
import { withAccountTypeButtonsConfig } from '../../../hoc';
import type { Formik, Models } from '../../../../../utils/types';

const NewECheck = (props: Models | Formik): Node => {
  const {
    payment: { accountTypeButtonsConfig },
    formik,
    formik: {
      values: { accountType }
    }
  } = props;

  return (
    <Row className="mt-3">
      <Col>
        <Row>
          <Col>
            <Input
              name="routingNumber"
              title="payment.routing-number"
              required
              {...formik}
            />
            <Input
              name="accountNumber"
              required
              title="payment.account-number"
              {...formik}
            />
            <input type="hidden" name="dataValue" id="dataValue" />
            <input type="hidden" name="dataDescriptor" id="dataDescriptor" />
          </Col>
        </Row>
        <Row>
          <Col>
            <ButtonGroup
              required
              name="accountType"
              label="payment.account-type"
              className={styles.accountTypeSwitcher}
              config={accountTypeButtonsConfig}
              value={accountType}
            />
          </Col>
        </Row>
      </Col>
      <Col className={styles.paymentImage}>
        <img
          src={eCheck}
          className={styles.cardImage}
          alt={t('payment.echeck')}
        />
      </Col>
    </Row>
  );
};

export default withAccountTypeButtonsConfig(NewECheck);
