// @flow
import React, { type Node } from 'react';
import { compose } from 'recompose';
import TableCell from './TableCell';
import withNoData from '../../hoc/withNoData';

const TableRow = ({ config, row }: Object): Node => (
  <tr>
    {config.map(
      (data: Object, index: number, list: Array<Object>): Node => (
        <TableCell key={index} row={row} cell={{ ...data, index, list }} />
      )
    )}
  </tr>
);

export default compose(withNoData(['row']))(TableRow);
