// @flow
import React, { Fragment, type Node, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { Input } from 'components/Form';
import { Button } from 'components';
import type { Formik } from 'utils/types';
import styles from 'containers/Auth/components/auth.module.css';
import REG_EXP from 'config/regular-expressions.config';
import { t } from 'components/i18n';
import { SimpleValue } from 'utils/renderUtils';
import settingsStyles from '../../settings.module.css';

type Props = {
  formik: Formik
};

export default (props: Props): Node => {
  const {
    formik,
    formik: {
      handleSubmit,
      isValid,
      setFieldValue,
      values,
      values: { login }
    },
    member: {
      data: {
        member: {
          contactInfo: { login: initialLogin }
        }
      }
    }
  } = props;
  const [isEdit, setIsEdit] = useState(false);

  const isMatchingLength = login?.length >= 8 && login?.length <= 100;
  const isOktaLoginMatchingSymbols =
    isMatchingLength && REG_EXP.oktaUserName.test(login);

  if (isEdit) {
    return (
      <Fragment>
        <Row data-testid="edit-username">
          <Col sm={12} md={7}>
            <Input
              className="communication"
              type="text"
              name="login"
              {...formik}
            />
          </Col>
          <Col sm={6} md={2}>
            <Button
              primary
              title={t('settings.settings-blocks.contact-info.save-username')}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handleSubmit(values);
              }}
            />
          </Col>
          <Col sm={6} md={1}>
            <Button
              type="button"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setFieldValue('login', initialLogin);
                setIsEdit(false);
              }}
              title={t('settings.settings-blocks.contact-info.cancel-username')}
            />
          </Col>
        </Row>
        {!isValid && (
          <Row data-testid="username-help-text" className="mt-2">
            <ul className={styles.userNameHelpers}>
              <li
                className={isMatchingLength ? styles.oktaLoginPassesRule : ''}
              >
                {t('auth.register.okta-login-min-max')}
              </li>
              <li
                className={
                  isOktaLoginMatchingSymbols ? styles.oktaLoginPassesRule : ''
                }
              >
                {t('auth.register.okta-login-symbols')}
              </li>
            </ul>
          </Row>
        )}
      </Fragment>
    );
  }
  return (
    <Row data-testid="username-value" className={settingsStyles.userNameRow}>
      <Col sm={9} md={9}>
        <SimpleValue value={login} />
      </Col>
      <Col sm={3} md={3}>
        <Button
          className="ml-2"
          type="button"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setIsEdit(true);
          }}
          title={t('settings.settings-blocks.contact-info.edit-username')}
        />
      </Col>
    </Row>
  );
};
