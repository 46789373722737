// @flow
import { useEffect } from 'react';
import type { Actions } from '../utils/types';
import type { Toast } from '../models/toasts';

type Props = {
  toast: Toast,
  actions: Actions
};

export default (props: Props): void => {
  const {
    toast: { delay, id },
    actions: {
      toasts: { hideToast }
    }
  } = props;

  useEffect((): void => {
    const toast = setTimeout((): void => {
      hideToast(id);
      clearTimeout(toast);
    }, delay);
  }, [delay, hideToast, id]);
};
