// @flow
import { withProps, type HOC } from 'recompose';
import type { Match } from 'react-router-dom';

export default (withProps(
  ({
    match: {
      params: { user, memberId }
    }
  }: Match): Object => ({
    tabsConfig: [
      { title: 'home.tabs.activity', path: `/${user}/${memberId}/activity` },
      {
        title: 'home.tabs.statements',
        path: `/${user}/${memberId}/statements`
      },
      {
        title: 'home.tabs.scheduled-transactions',
        path: `/${user}/${memberId}/scheduled-transactions`
      },
      {
        title: 'home.tabs.transactions',
        path: `/${user}/${memberId}/transactions`
      }
    ]
  })
): HOC<*, any>);
