// @flow
import React, { type Node } from 'react';
import { FormGroup } from 'reactstrap';
import { STATEMENT } from '../../../config/member.config';
import { Radio } from '../../../components';
import type { Formik, Setting } from '../../../utils/types';

type Props = {
  settings: Setting,
  formik: Formik,
  boxConfig: Object
};

export default (props: Props): Node => {
  const {
    formik: {
      handleChange,
      values: { statementType }
    }
  } = props;

  return (
    <FormGroup tag="fieldset" className="m-0" data-cy="statements">
      <div className="mb-3">
        <Radio
          id="online"
          name="statementType"
          value={STATEMENT.ELECTRONIC}
          checked={statementType === STATEMENT.ELECTRONIC}
          label="settings.settings-blocks.statements.electronic"
          onChange={handleChange}
        />
      </div>
      <div>
        <Radio
          id="paper"
          name="statementType"
          value={STATEMENT.PAPER}
          checked={statementType === STATEMENT.PAPER}
          label="settings.settings-blocks.statements.paper"
          onChange={handleChange}
        />
      </div>
    </FormGroup>
  );
};
