import { withLDProvider } from 'launchdarkly-react-client-sdk';

const isProd = process.env.NODE_ENV === 'production';

// If you include the camel case name of a flag in the following for a non-prod
// env, you will override that flag with the given boolean value
// If flags is set to null only LaunchDarkly values are used

// In the LaunchDarkly Configurator the client id for this app has a set of
// flags like "membership-card", when the app is launched the application will
// grab the currently active flags and build a struct with keys in camel case
// membership-card => membershipCard

const defaultFlags = {
  memberAppleWallet: true,
  oktaLoginMigration: true,
  scheduledPaymentMember: false,
  memberClaimLines: true,
  creditHoldTwo: false
};

const flags = isProd ? undefined : defaultFlags;

export default withLDProvider({
  clientSideID:
    process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID ||
    '5d0cf23f9293dc07724999c9',
  user: {
    key: 'Administrator'
  },
  flags
});
