// @flow
import {
  ACCOUNT_TYPES,
  AMOUNT_OPTIONS,
  PAYMENT_METHODS,
  PAYMENT_TYPE
} from '../../config/payment.config';

export default {
  disableButton: false,
  editMode: false,
  disableEditPaymentType: false,
  paymentType: PAYMENT_TYPE.ONE_TIME_PAYMENT,
  type: AMOUNT_OPTIONS.PAY_IN_FULL,
  maxAmount: null,
  amount: '',
  otherAmount: '',
  repaymentAccepted: true,
  method: PAYMENT_METHODS.CARD,
  saveToProfile: false,
  accountNumber: '',
  routingNumber: '',
  accountType: ACCOUNT_TYPES.CHECKING,
  paymentDay: 0,
  echeckId: '',
  cardNumber: '',
  cardType: '',
  month: '',
  year: '',
  cardCode: '',
  fullName: '',
  zip: '',
  cardId: '',
  expirationDate: '',

  error: {
    accept: null,
    payment: null,
    transaction: null
  },
  retries: 0,

  step: 0,
  successView: false,
  cards: [],
  echecks: []
};
