import { type Node } from 'react';
import { t } from '../../../../components';
import { parseAmount, linkTo } from '../utils';
import type { DataConfig } from './types';

export default ({ data, basePath }: Object): Object => ({
  className: 'modal-table',
  label: {
    title: 'home.details-view.claim-body.table.title'
  },
  header: [
    {
      title: 'home.details-view.claim-body.table.transaction-date',
      width: '25%'
    },
    {
      title: 'home.details-view.claim-body.table.claim-id',
      width: '20%'
    },
    {
      title: 'home.details-view.claim-body.table.claim-type',
      width: '30%'
    },
    { title: 'home.details-view.claim-body.table.amount', width: '25%' }
  ],
  data,
  body: [
    {
      key: 'transactionDate',
      render: (dataConfig: DataConfig): Node => {
        const { row, cell } = dataConfig;
        const { data: rowData } = row || {};
        const { key } = cell || {};

        return linkTo(`${basePath}/claim/${rowData.id}`, rowData[key]);
      }
    },
    {
      key: 'claimId',
      render: (dataConfig: DataConfig): number => {
        const { row, cell } = dataConfig;
        const { data: rowData } = row || {};
        const { key } = cell || {};

        return rowData[key];
      }
    },
    {
      key: 'claimType',
      render: (dataConfig: DataConfig): Node => {
        const { row, cell } = dataConfig;
        const { data: rowData } = row || {};
        const { key } = cell || {};

        return t(`home.details-view.claim-body.table.${rowData[key]}`);
      }
    },
    {
      key: 'claimAmount',
      render: (dataConfig: DataConfig): Node => {
        const { row, cell } = dataConfig;
        const { data: rowData } = row || {};
        const { key } = cell || {};

        return parseAmount(rowData[key]);
      }
    }
  ]
});
