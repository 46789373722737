/**
 * Interceptor to redirect to logout if the login page is sent as redirect
 * Hard navigation when the server is trying to redirect to login page bc
 * either the token has expired or the server session was invalidated
 */
export default (response) => {
  if (response.request.responseURL.includes('/login?response_type=code')) {
    window.location.href = response.request.responseURL;
    return;
  }
  return response;
};
