// @flow
import React, { type Node, type AbstractComponent } from 'react';
import { Col, Container, Row } from 'reactstrap';
import styles from './background.module.css';
import bigLogo from '../../../assets/images/health-bridge-logo.svg';
import smallLogo from '../../../assets/images/HealthBridge19_SM.png';
import { t } from '../../../components';

export default (Component: AbstractComponent<Object>) => (
  props: Object
): Node => (
  <Container fluid className={styles.fill}>
    <Row className={`${styles.fill} ${styles.row}`}>
      <Col md={3} className={styles.logoWrapper}>
        <div className={styles.pageLogo}>
          <div className={styles.pageLogoContainer}>
            <img
              src={bigLogo}
              className={styles.bigScreenLogo}
              alt="HealthBridge Logotype"
            />
            <img
              src={smallLogo}
              className={styles.smallScreenLogo}
              alt="HealthBridge Logotype"
            />
          </div>
          <div className={styles.questions}>
            {t('auth.sign-in.questions')} <br />
            {t('auth.sign-in.phone-number')}
          </div>
        </div>
        <div className={styles.phoneNumber}></div>
      </Col>
      <Col md={9} className={styles.mainContainer}>
        <Component {...props} />
      </Col>
    </Row>
  </Container>
);
