// @flow
import { useEffect } from 'react';

/*
  I renamed this hook from useGetBalance because its old name was not accurate.

  If this isn't used in conjunction with some components like the carousel, the member data isn't refetched
 */
export default ({
  getFamilyMember,
  getMember,
  familyMemberId,
  memberId
}): void => {
  useEffect((): void => {
    if (familyMemberId !== +memberId) {
      memberId !== 'me' ? getFamilyMember(memberId) : getMember();
    }
  }, [familyMemberId, getFamilyMember, getMember, memberId]);
};
