// @flow
import React, { type Node, Fragment } from 'react';
import { path, is } from 'ramda';
import { FormattedMessage, FormattedNumber, FormattedDate } from 'react-intl';
import messages from '../assets/i18n';
import { DateTime } from 'luxon';
import { EST_TIME_ZONE, UTC_TIME_ZONE } from '../config/timezones.config';

const number = (
  value: number,
  params?: Object = { style: 'currency', currency: 'USD' }
): Node => {
  const { style, currency } = params;
  const isNegative = Math.sign(value) === -1;
  const positiveValue = Math.abs(value);

  return (
    <Fragment>
      {isNegative && <span>-</span>}
      <FormattedNumber
        value={positiveValue} /* eslint-disable */
        style={style} /* eslint-enable */
        currency={currency}
        currencyDisplay="symbol"
      />
    </Fragment>
  );
};

const date = (params: Object): Node => {
  let { value } = params;
  if (is(String, value) && value.includes('T')) {
    value = DateTime.fromISO(value, { zone: UTC_TIME_ZONE });
  } else {
    //Date only values are treated as EST time zone
    value = DateTime.fromISO(value, { zone: EST_TIME_ZONE });
  }
  return <FormattedDate {...params} value={value} />;
};

const time = (value: string): Node => DateTime.fromISO(value).time;

const t = (text: string, values?: Object): Node => {
  const id = `member-app.${text}`;
  const defaultId = id.split('.');
  const defaultMessage = path(defaultId, messages.en);

  return (
    <FormattedMessage id={id} values={values} defaultMessage={defaultMessage} />
  );
};

export { t, date, number, time };
