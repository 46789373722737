// @flow
import React, { Fragment } from 'react';
import { Form, Col, ModalBody, ModalHeader, Row } from 'reactstrap';
import { t, Button, Input, ErrorView } from 'components';
import REG_EXP from 'config/regular-expressions.config';
import styles from 'containers/Auth/components/auth.module.css';

export default (props) => {
  const {
    formik,
    formik: { values, handleSubmit },
    member: { error }
  } = props;
  const { oktaLogin } = values;
  const isMatchingLength = oktaLogin?.length >= 3 && oktaLogin?.length <= 16;
  const isOktaLoginMatchingSymbols =
    isMatchingLength && REG_EXP.oktaUserName.test(oktaLogin);
  return (
    <Fragment>
      <ModalHeader close={<span />} tag="h1" data-cy="modal-header">
        {t('username.heading')}
      </ModalHeader>

      <ModalBody data-cy="modal-body">
        <Row>
          <Col>
            <p>{t('username.description')}</p>
          </Col>
        </Row>
        {error && <ErrorView error={error} className="mb-3" />}
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col>
              <Input
                name="oktaLogin"
                title="auth.register.okta-login"
                {...formik}
              />
            </Col>
          </Row>
          <Row>
            <ul className={styles.userNameHelpers}>
              <li
                className={isMatchingLength ? styles.oktaLoginPassesRule : ''}
              >
                {t('auth.register.okta-login-min-max')}
              </li>
              <li
                className={
                  isOktaLoginMatchingSymbols ? styles.oktaLoginPassesRule : ''
                }
              >
                {t('auth.register.okta-login-symbols')}
              </li>
            </ul>
          </Row>
          <Row>
            <Col>
              <p>{t('username.note')}</p>
            </Col>
          </Row>
          <Button
            className="mt-4"
            primary
            type="submit"
            title="username.save"
          />
        </Form>
      </ModalBody>
    </Fragment>
  );
};
