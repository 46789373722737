// @flow
import { compose } from 'recompose';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import AppLayout from './AppLayout';
import withNotifications from '../withNotifications';
import withLoader from '../withLoader';
import withNoData from '../../withNoData';
import withDropDownConfig from './withDropDownConfig';
import withTabsConfig from '../../withTabsConfig';
import withRoutes from '../withRoutes';
import withMapStateDispatch from '../../withMapStateDispatch';
import withGetMember from './withGetMember';
import withErrorBoundary from '../../withErrorBoundary';

export default (
  hocs: Array<Function>,
  property: string = 'familyMember'
): Function =>
  compose(
    withLoader,
    withMapStateDispatch,
    withGetMember,
    withTabsConfig,
    withNoData(['member', 'data', property]),
    withErrorBoundary,
    ...hocs,
    withLDConsumer(),
    withDropDownConfig,
    withNotifications,
    withRoutes,
    AppLayout
  );
