// @flow
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { init } from '@rematch/core';
import history from './browserHistory';
import * as models from './models';

const store = init({
  models,
  redux: {
    reducers: {
      router: connectRouter(history)
    },
    middlewares: [routerMiddleware(history)],
    devtoolOptions: {}
  }
});

export default store;
