// @flow
import React, { type Node } from 'react';
import { Col, Row } from 'reactstrap';
import { type Match } from 'react-router-dom';
import { Loader } from '../../../../components';
import styles from '../../../../components/Modal/modals.module.css';
import type { MemberModel, Transaction } from '../../../../models/member/types';
import type { Handlers } from '../../types';
import type { TableConfig } from '../../../../components/Table/types';
import MODAL_CONFIG from './details';
import { INTEREST_REVERSAL_CONFIG } from './details';
import {
  ENTITY_TYPE,
  INTEREST_REVERSAL_TYPE,
  PARTIAL_ADJUSTMENT_TYPES
} from '../../../../config/payment.config';

type Props = {
  match: Match,
  member: MemberModel,
  handlers: Handlers,
  table?: TableConfig
};

export default (props: Props): Object => {
  const {
    match: {
      params: { entityType }
    },
    handlers: { goBack },
    member: {
      data: { selectedTransaction }
    },
    table,
    claimLinesTable
  } = props;
  const isDisputed = !!selectedTransaction.disputeStartDate;
  const { recurring }: Transaction = selectedTransaction || {};
  const heading: string = recurring
    ? 'home.details-view.recurring'
    : `home.details-view.${entityType}`;
  const isTransaction: boolean =
    selectedTransaction && Object.keys(selectedTransaction).length !== 0;

  return {
    size: 'md',
    toggle: goBack,
    header: {
      hide: !isTransaction,
      toggle: goBack,
      message: entityType && heading,
      tag: 'h1'
    },
    body: {
      hide: !isTransaction,
      fallback: <Loader message="loaders.loading" />,
      render: (propsData: Props): Node => {
        const {
          match: {
            params: { entityType: entType }
          },
          member: {
            data: { selectedTransaction: transaction }
          }
        } = propsData;

        const { interestReversalType } = transaction;
        const isInterestReversal =
          interestReversalType &&
          interestReversalType === INTEREST_REVERSAL_TYPE.PAYMENT;
        const CONFIG = isInterestReversal
          ? INTEREST_REVERSAL_CONFIG
          : MODAL_CONFIG;

        let type = entType;
        if (isInterestReversal) {
          type = transaction.interestReversalType;
        }
        if (isDisputed) {
          type = ENTITY_TYPE.CLAIM_DISPUTED;
        }
        if (PARTIAL_ADJUSTMENT_TYPES.includes(type)) {
          type = ENTITY_TYPE.PARTIAL_ADJUSTMENT;
        }
        return type && CONFIG[type].map(buildContent(transaction, props));
      }
    },
    table,
    claimLinesTable,
    footer: {
      hide: !isTransaction,
      buttons: [
        {
          title: 'home.details-view.close',
          onClick: goBack
        }
      ]
    }
  };
};

export function buildContent(transaction, props, isConfirmationSelected) {
  return (data): Node => {
    const { title, render, isWarning } = data;
    const content: Node = render(transaction, props);
    if (isWarning && isConfirmationSelected) {
      return (
        content && (
          <Row key={title.props.id} className={styles.detailsView}>
            <Col sm={12} className={styles.warningMessage} data-cy="info-value">
              {title}
            </Col>
          </Row>
        )
      );
    }
    return (
      content &&
      !isWarning && (
        <Row key={title.props.id} className={styles.detailsView}>
          <Col sm={5} className={styles.label} data-cy="info-label">
            {title}
          </Col>
          <Col sm={7} className={styles.value} data-cy="info-value">
            {content}
          </Col>
        </Row>
      )
    );
  };
}
