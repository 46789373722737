// @flow
import React, { type Node } from 'react';
import { Col, Row } from 'reactstrap';
import type { Match } from 'react-router-dom';
import classNames from 'classnames';
import { Breadcrumb, Button, Dropdown } from '../../../../components';
import styles from '../app-layout.module.css';
import type { Actions } from '../../../../utils/types';

type Props = {
  actions: Actions,
  headerConfig: Object,
  dropDownConfig: Object,
  match: Match,
  membersWidget: boolean
};

export default (props: Props): Node => {
  const {
    actions: { member: { getMemberStatement } } = {},
    headerConfig: { breadcrumbConfig = [] } = {},
    dropDownConfig,
    match: {
      params: { statementId }
    },
    membersWidget
  } = props;

  const mainRowStyles: string = classNames({
    [styles.alignRow]: true,
    'justify-content-end': !membersWidget
  });

  return (
    <Row className={mainRowStyles}>
      <Col sm={10} md={6}>
        <Breadcrumb config={breadcrumbConfig} />
      </Col>
      <Col sm={2} md={6}>
        <div className={styles.statementsActions}>
          <Button
            title="statement.make-payment"
            primary
            link="/member/me/payment"
          />
          <Button
            icon="download"
            size="sm"
            onClick={() => getMemberStatement(statementId)}
          />
        </div>
        <div className={styles.statementsMobileActions}>
          <Dropdown {...dropDownConfig.statement} />
        </div>
      </Col>
    </Row>
  );
};
