import { object, string } from 'yup';
import REG_EXP from '../../config/regular-expressions.config';

const settingsSchemaSms = object({
  communicationPhone: string().when('smsMessagingConfig', {
    is: (value) => value !== 'DISABLED',
    then: string().required('errors.missing-preferred-phone-number'),
    otherwise: string()
      .matches(REG_EXP.communicationPhone, {
        message: 'errors.invalid-phone',
        excludeEmptyString: true
      })
      .nullable()
  }),
  communicationEmail: string()
    .matches(REG_EXP.communicationEmail, {
      message: 'errors.invalid-email',
      excludeEmptyString: true
    })
    .required('errors.email-required'),
  statementType: string().required(),
  language: string().required('errors.language-required'),
  timeZoneId: string().required('errors.timezone-required')
});

export default settingsSchemaSms;
