// @flow
import { compose, type HOC, withProps } from 'recompose';
import withBackground from './withBackground';
import {
  withLoader,
  withMapStateDispatch,
  withRedirectToLogin
} from '../../../hoc';
import type { Actions } from '../../../utils/types';

export default (compose(
  withLoader,
  withRedirectToLogin,
  withMapStateDispatch,
  withProps(
    ({ auth: { error } }: Actions): Object => ({
      viewError: error !== null && Object.keys(error).length > 0
    })
  ),
  withBackground
): HOC<*, any>);
