// @flow
import React, { type Node } from 'react';
import { Input, FormGroup, Label } from 'reactstrap';
import { is, omit } from 'ramda';
// NOTE: injectIntl can be used alternatively as the hook useIntl() but only in functional components in version 3
import { injectIntl, type intlShape } from 'react-intl';
import type { Formik } from '../../utils/types';

type Props = {
  name: string,
  id: string,
  label: any,
  checked: boolean,
  intl: intlShape
};

const Radio = (props: Props & Formik): Node => {
  const {
    name = 'radio',
    id,
    value,
    label,
    checked = false,
    handleChange,
    handleBlur,
    intl: { formatMessage }
  } = props;

  const labelMessage: string =
    label && is(String, label)
      ? formatMessage({ id: `member-app.${label}` })
      : formatMessage({ id: `member-app.${label.id}` }, label);

  return (
    <FormGroup tag="fieldset" className="custom-radio custom-control">
      <Input
        type="radio"
        name={name}
        id={id}
        value={value}
        checked={checked}
        onChange={handleChange}
        onBlur={handleBlur}
        {...omit(['intl'], props)}
        className="custom-control-input"
      />
      <Label className="custom-control-label" for={id}>
        {labelMessage}
      </Label>
    </FormGroup>
  );
};

export default injectIntl(Radio);
