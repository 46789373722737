// @flow
import { compose, withHandlers, withState, type HOC } from 'recompose';
import { assocPath } from 'ramda';
import { reach } from 'yup';
import { withFormik } from 'formik';
import withNamespace from './withNamespace';
import type { FormikConfig } from '../utils/types';
import numberWithDot from '../utils/formFieldFormatters';

const formikProps = [
  'dirty',
  'errors',
  'handleBlur',
  'handleChange',
  'handleReset',
  'handleSubmit',
  'isSubmitting',
  'isValid',
  'isValidating',
  'resetForm',
  'setErrors',
  'setError',
  'setFieldError',
  'setFieldTouched',
  'submitForm',
  'submitCount',
  'setFieldValue',
  'setStatus',
  'setSubmitting',
  'setTouched',
  'setValues',
  'status',
  'touched',
  'values',
  'validateForm',
  'validateField',
  'enableReinitialize',
  'isInitialValid',
  'initialValues',
  'onReset',
  'onSubmit',
  'validate',
  'validateOnBlur',
  'validateOnChange',
  'validationSchema',
  'unregisterField',
  'registerField',
  'setFormikState',

  'changeHandler',
  'formattedValues',
  'updateFormattedValues'
];

const getSchemaMeta = (fieldSchemaDescription, key) =>
  fieldSchemaDescription &&
  fieldSchemaDescription.meta &&
  fieldSchemaDescription.meta[key];

const parseValue = (validationSchema, fieldPath, fieldValue) => {
  const fieldSchema = reach(validationSchema, fieldPath);
  const fieldSchemaDescription = fieldSchema.describe();
  const type = getSchemaMeta(fieldSchemaDescription, 'type');

  return type === 'currency' ? numberWithDot(fieldValue) : fieldValue;
};

export default (formikConfig: FormikConfig) =>
  (compose(
    withFormik(formikConfig),
    withState('formattedValues', 'updateFormattedValues', ({ values }) => ({
      ...values
    })),
    withHandlers({
      changeHandler: (props) => (e) => {
        const { updateFormattedValues, handleChange, formattedValues } = props;
        const fieldElement = e.target;

        const parsedFieldValue = parseValue(
          formikConfig.validationSchema,
          fieldElement.name,
          fieldElement.value
        );

        const valuesToUpdateFormattedValues = assocPath(
          [fieldElement.name],
          parsedFieldValue,
          formattedValues
        );

        updateFormattedValues(valuesToUpdateFormattedValues);

        e.target.value = parsedFieldValue;

        handleChange(e);
      }
    }),
    withNamespace('formik', formikProps)
  ): HOC<*, any>);
