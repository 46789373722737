import { mergeDeepRight } from 'ramda';
import { setError } from '../../utils/errorInterceptor';

export default {
  setData: (state, data) => mergeDeepRight(state, { data }),
  setError: (state, error) => setError(state, error),
  updateStore: ({ error, data }, payload) =>
    Object.assign(
      {},
      {
        data: {
          ...data,
          ...payload
        },
        error
      }
    )
};
