// @flow
import React, { Fragment, type Node } from 'react';
import { withRouter } from 'react-router';
import { type Match } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { Button, t } from 'components';
import { ACCESS_STATUSES } from 'config/member.config';
import styles from '../../settings.module.css';

type Props = {
  id: number,
  name: string,
  status: string,
  accountNumber: string,
  type: string,
  match: Match,
  handleDownload: Function,
  initiator: Boolean
};

const AccessMember = (props: Props): Node => {
  const {
    id,
    name,
    type,
    status,
    accountNumber,
    match: { url },
    handleDownload,
    initiator
  } = props;

  const pendingLink: string =
    type === 'GRANT'
      ? `${url}/${id}/pending-grant`
      : `${url}/${id}/pending-request`;

  const pendingLabel: string =
    type === 'GRANT'
      ? 'settings.settings-blocks.access-to-account.view-grant-request'
      : 'settings.settings-blocks.access-to-account.view-request';

  const isPending: boolean = status === ACCESS_STATUSES.pending;
  const isApproved: boolean = status === ACCESS_STATUSES.accessesToMe;
  const pendingType: string =
    type === 'GRANT'
      ? 'settings.settings-blocks.access-to-account.grant-access'
      : 'settings.settings-blocks.access-to-account.request-access';
  return (
    <Row className={styles.accessMember}>
      <Col sm={6} md={5} className={styles.memberInfo}>
        <figure className="mb-0 d-flex align-items-center">
          <div className={styles.userShortName}>{name.charAt(0)}</div>
          <figcaption className={styles.userName}>
            {name}
            {
              <span>
                {t(
                  'settings.settings-blocks.access-to-account.account-number',
                  {
                    number: accountNumber
                  }
                )}
              </span>
            }
          </figcaption>
        </figure>
      </Col>
      <Col sm={6} md={7} className={styles.memberControls}>
        {isPending && !initiator && (
          <Button
            title={pendingLabel}
            cy={`${status}-${id}`}
            size="sm"
            link={pendingLink}
          />
        )}
        {isPending && initiator && <span>{t(pendingType)}</span>}
        {isApproved && (
          <Fragment>
            <Button
              type="button"
              cy={`${status}-${id}-download`}
              title="settings.settings-blocks.access-to-account.download-agreement"
              onClick={() => handleDownload(id)}
              size="sm"
            />
            <Button
              title="settings.settings-blocks.access-to-account.revoke-access"
              cy={`${status}-${id}`}
              size="sm"
              link={`${url}/${id}/revoke-access`}
            />
          </Fragment>
        )}
      </Col>
    </Row>
  );
};

export default withRouter(AccessMember);
