// @flow
export const IP_PATH: string = 'https://ipapi.co/json/';

export const STATEMENT: Object = {
  PAPER: 'PAPER',
  ELECTRONIC: 'ELECTRONIC'
};

export const MEMBER_STATUS: Object = {
  WAITING: 'WAITING',
  ACTIVE: 'ACTIVE',
  ACTIVE_BALANCE: 'ACTIVE_BALANCE',
  INACTIVE: 'INACTIVE',
  INACTIVE_BALANCE: 'INACTIVE_BALANCE',
  DEFAULTED: 'DEFAULTED'
};

export const GENDER: Object = {
  MALE: 'MALE',
  FEMALE: 'FEMALE'
};

export const MEMBER_TYPES: Object = {
  SPOUSE: 'SPOUSE',
  EMPLOYEE: 'EMPLOYEE',
  DEPENDENT: 'DEPENDENT',
  DEPENDENT_18: 'DEPENDENT_18'
};

export const ACCESS_STATUSES: Object = {
  accessesToMe: 'APPROVED',
  accessesToOthers: 'APPROVED_BY',
  pending: 'PENDING'
};
