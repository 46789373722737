// @flow
import React, { type Node } from 'react';
import { Form } from 'reactstrap';
import type { Match } from 'react-router-dom';
import CurrentBalance from './CurrentBalance';
import LastStatement from './LastStatement';
import MinimumPaymentDue from './MinimumPaymentDue';
import OtherAmount from './OtherAmount';
import RepaymentAccepted from './RepaymentAccepted';
import { Col, Row } from 'reactstrap';
import { t } from '../../../../components';
import styles from '../../payment.module.css';
import type { Formik, Models } from '../../../../utils/types';
import type { Handlers } from '../../../../models/payment/types';
import { PAYMENT_TYPE } from '../../../../config/payment.config';

type Props = {
  formik: Formik,
  handlers: Handlers,
  match: Match
};

export default (props: Props & Models): Node => {
  const {
    currentQuickPay,
    formik: { handleSubmit },
    payment: { hasStatement, paymentType },
    member: {
      data: {
        familyMember: {
          balanceDetails: {
            balance,
            minimumPaymentDue,
            dueDate,
            currentStatementQuickPay,
            disputedClaimCount
          }
        }
      }
    }
  } = props;
  const noDisputedClaims = disputedClaimCount <= 0;
  const oneTimePayment = paymentType === PAYMENT_TYPE.ONE_TIME_PAYMENT;
  const scheduledPayment = paymentType === PAYMENT_TYPE.SCHEDULED_PAYMENT;
  const showCurrentBalance =
    !scheduledPayment &&
    oneTimePayment &&
    (disputedClaimCount > 0 ||
      (currentStatementQuickPay &&
        currentQuickPay.amount !== currentStatementQuickPay.amount) ||
      (!currentStatementQuickPay && currentQuickPay.amount > 0));

  const showMinimumPaymentDue =
    !scheduledPayment &&
    (paymentType === PAYMENT_TYPE.RECURRING_PAYMENT ||
      (minimumPaymentDue !== null &&
        minimumPaymentDue.remaining > 0 &&
        balance > minimumPaymentDue.remaining &&
        dueDate !== null &&
        noDisputedClaims));

  const showLastStatement =
    !scheduledPayment &&
    ((hasStatement && oneTimePayment && noDisputedClaims) || !oneTimePayment);

  return (
    <Form onSubmit={handleSubmit}>
      {!oneTimePayment && !scheduledPayment && (
        <Row>
          <Col>
            <p className={styles.mpdRecurring}>
              {t('payment.due-date-message')}
            </p>
          </Col>
        </Row>
      )}
      {showCurrentBalance && <CurrentBalance {...props} />}
      {showLastStatement && <LastStatement {...props} />}
      {showMinimumPaymentDue && <MinimumPaymentDue {...props} />}
      <OtherAmount {...props} />
      <RepaymentAccepted {...props} />
    </Form>
  );
};
