// @flow
import React, { type Node } from 'react';
import type { Match } from 'react-router-dom';
import FormHeader from './FormHeader';
import StatementHeader from './StatementHeader';
import InitialHeader from './InitialHeader';
import { Container } from 'reactstrap';
import classNames from 'classnames';
import styles from '../app-layout.module.css';
import type { Actions, Formik } from '../../../../utils/types';
import type { MemberModel } from '../../../../models/member/types';
import { GET_SCREEN_SIZE, SCREEN_SIZES } from 'config';

type Props = {
  actions: Actions,
  scroll: Object,
  isSystemError: boolean,
  fullMembersView: boolean,
  formik: Formik,
  headerConfig: Object,
  member: MemberModel,
  dropDownConfig: Object,
  match: Match
};

export default (props: Props): Node => {
  const {
    scroll,
    isSystemError,
    fullMembersView,
    formik: { dirty } = {},
    headerConfig: {
      fixedHeader = false,
      editForm = false,
      statementView = false,
      withMembers = false,
      withShadow = true
    } = {},
    member: {
      data: { member: { familyMembers = [] } = {} }
    }
  } = props;

  const SCREEN_SIZE = GET_SCREEN_SIZE();
  const IS_MOBILE_VIEW = SCREEN_SIZE === SCREEN_SIZES.MOBILE;

  const membersWidget: boolean = withMembers && familyMembers.length > 1;

  const headerStyles: string = classNames({
    [styles.header]: true,
    [styles.withShadow]: withShadow || scroll.isFixedTarget,
    [styles.members]: withMembers,
    [styles.fixed]: fixedHeader,
    [styles.fixedError]: isSystemError,
    [styles.withFullMembersView]: fullMembersView && !IS_MOBILE_VIEW
  });

  return (
    <header className={headerStyles} role="banner" data-cy="header">
      <Container>
        {editForm && dirty ? (
          <FormHeader {...props} />
        ) : (
          <InitialHeader {...props} membersWidget={membersWidget} />
        )}
        {statementView && (
          <StatementHeader {...props} membersWidget={membersWidget} />
        )}
      </Container>
    </header>
  );
};
