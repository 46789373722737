// @flow
import React, { type Node } from 'react';
import { Row, Col } from 'reactstrap';
import { identity } from 'ramda';
import { t } from '../../../../components/i18n';
import AccessUser from './AccessUser';
import styles from '../../settings.module.css';

type Props = {
  members: Array<Object>,
  title: string,
  status: string,
  handleDownload: Function
};

export default ({
  members = [],
  title,
  status,
  handleDownload = identity
}: Props): Node => (
  <Row data-cy={status}>
    <Col className={styles.accessBlock}>
      <p className={styles.upperCaseLabel}>
        {t(`settings.settings-blocks.access-to-account.${title}`)}
      </p>
      {members.map(({ member, type, accountNumber, initiator }) => (
        <AccessUser
          {...member}
          accountNumber={accountNumber}
          status={status}
          type={type}
          key={`${member.id}_${type}`}
          handleDownload={handleDownload}
          initiator={initiator}
        />
      ))}
    </Col>
  </Row>
);
