// @flow
import React, { type Node } from 'react';
import withNoData from '../../hoc/withNoData';
import { t } from '../i18n';

const TableHeader = ({ header }: Object): Node => (
  <thead>
    <tr>
      {header.map(
        (data: Object, index: number, headers: Array<Object>): Node => (
          <th
            key={`${data.title}_${index}`}
            style={{ width: data.width }}
            onClick={(e) =>
              data.onClick &&
              data.onClick({
                eventObject: e,
                ...data,
                index,
                headers
              })
            }
          >
            {t(data.title)}
          </th>
        )
      )}
    </tr>
  </thead>
);

export default withNoData(['header'])(TableHeader);
