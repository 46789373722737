// @flow
import { lazy } from 'react';
import { withProps, type HOC } from 'recompose';
import { compose, join, values } from 'ramda';

import DetailsView from '../../Modals/DetailsView';

import ROUTES from '../../../config/routes.config';
import { ENTITY_TYPE } from '../../../config/payment.config';

const About = lazy(() => import('../../Modals/About'));
const MembershipCard = lazy(() => import('../../Modals/MembershipCard'));

const ConfirmCommunication = lazy(() =>
  import('../../Modals/ConfirmCommunication')
);
const StatementType = lazy(() => import('../../Modals/StatementType'));
const TermsAndConditions = lazy(() =>
  import('../../Modals/TermsAndConditions')
);
const Unauthorized = lazy(() => import('../../Modals/Unauthorized'));
const AcceptAccess = lazy(() =>
  import('../../Modals/AccessModals/AcceptAccess')
);
const AcceptGrant = lazy(() => import('../../Modals/AccessModals/AcceptGrant'));
const RequestAccess = lazy(() => import('../../Modals/AccessModals/GetAccess'));
const DeleteRecurring = lazy(() => import('../../Modals/DeleteRecurring'));
const RequestGrantAccess = lazy(() =>
  import('../../Modals/AccessModals/GetGrantAccess')
);

export default (withProps((): Object => {
  const entities: string = compose(join('|'), values)(ENTITY_TYPE);

  return {
    routes: [
      {
        path: `**/unauthorized`,
        component: Unauthorized
      },
      {
        path: `${ROUTES.HOME}/terms-and-conditions`,
        component: TermsAndConditions
      },
      {
        path: `${ROUTES.HOME}/statement-type`,
        component: StatementType
      },
      {
        path: `${ROUTES.HOME}/confirm-communication`,
        component: ConfirmCommunication
      },
      { path: `${ROUTES.HOME}/about`, component: About },
      { path: `${ROUTES.MEMBERSHIP_RESOURCES}/about`, component: About },
      { path: `${ROUTES.HOME}/membership-card`, component: MembershipCard },
      {
        path: `${ROUTES.MEMBERSHIP_RESOURCES}/membership-card`,
        component: MembershipCard
      },
      {
        path: `${ROUTES.HOME}/:familyMemberId(\\d+)/accept-access`,
        component: AcceptAccess
      },
      {
        path: `${ROUTES.HOME}/:familyMemberId(\\d+)/accept-grant-access`,
        component: AcceptGrant
      },
      {
        path: `${ROUTES.HOME}/:familyMemberId(\\d+)/request-access`,
        component: RequestAccess
      },
      {
        path: `${ROUTES.HOME}/:familyMemberId(\\d+)/request-grant-access`,
        component: RequestGrantAccess
      },
      {
        path: `${ROUTES.HOME}/:entityType(${entities})/:entityId(\\d+)`,
        component: DetailsView
      },
      {
        path: `${ROUTES.HOME}/:entityId(\\d+)`,
        component: DetailsView
      },
      {
        path: `${ROUTES.HOME}/delete-recurring-payment`,
        component: DeleteRecurring
      }
    ]
  };
}): HOC<*, Object>);
