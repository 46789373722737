// @flow
import React, { type Node, useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import classNames from 'classnames';
import {
  AMOUNT_OPTIONS,
  PAYMENT_TYPE
} from '../../../../config/payment.config';
import { t, number, Input, EnhancedRadio } from '../../../../components';
import ErrorMessage from './ErrorMessage';
import styles from '../../payment.module.css';
import type { MemberModel } from '../../../../models/member/types';
import type { Handlers, PaymentModel } from '../../../../models/payment/types';
import type { Formik } from '../../../../utils/types';

type Props = {
  handlers: Handlers,
  member: MemberModel,
  payment: PaymentModel,
  formik: Formik
};

export default (props: Props): Node => {
  const {
    currentQuickPay,
    handlers: { isChecked },
    member: {
      data: {
        familyMember: {
          balanceDetails: { balance, lastStatementQuickPay }
        },
        selectedTransaction
      }
    },
    payment: { paymentType },
    formik,
    formik: {
      errors,
      values: { type, otherAmount },
      setFieldValue
    },
    match: { url }
  } = props;

  const oneTimePayment = paymentType === PAYMENT_TYPE.ONE_TIME_PAYMENT;
  const scheduledPayment = paymentType === PAYMENT_TYPE.SCHEDULED_PAYMENT;

  const label: string = oneTimePayment
    ? 'payment.other-amount'
    : 'payment.other-monthly-payment';

  const amount: number = oneTimePayment
    ? currentQuickPay.amount
    : +(balance / 3).toFixed(2);

  const messagesStyles: string = classNames({
    [styles.messages]: true,
    'justify-content-start': !oneTimePayment
  });

  useEffect(() => {
    if (url.indexOf('edit-scheduled-payment') && !!selectedTransaction) {
      setFieldValue('type', AMOUNT_OPTIONS.OTHER);
      setFieldValue('amount', selectedTransaction.amount, false);
      setFieldValue('otherAmount', selectedTransaction.amount, true);
    }
  }, [selectedTransaction]);

  return (
    <Row>
      <Col>
        <EnhancedRadio
          name="type"
          id="otherAmountOption"
          label={label}
          value={AMOUNT_OPTIONS.OTHER}
          checked={isChecked(AMOUNT_OPTIONS.OTHER)}
          onChange={() => {
            setFieldValue('type', AMOUNT_OPTIONS.OTHER);
            setFieldValue('repaymentAccepted', false, true);
            setFieldValue('amount', amount, false);
          }}
        >
          <Row>
            <Col sm={12} md={6}>
              <Input
                title={label}
                name="otherAmount"
                className="otherAmount"
                disabled={type !== AMOUNT_OPTIONS.OTHER}
                {...formik}
                value={otherAmount}
              />
            </Col>
            <Col sm={12} md={6} className={styles.selectContent}>
              {!oneTimePayment && !scheduledPayment && (
                <div>
                  <p
                    className={styles.helperText}
                    id="other_monthly_payment_message"
                  >
                    {t('payment.other-monthly-payment-message')}
                  </p>
                  <br />
                  <p
                    className={styles.helperText}
                    id="last_statement_where_amount_0"
                  >
                    {t('errors.last-statement-where-amount-0')}
                  </p>
                </div>
              )}
              {scheduledPayment && (
                <div>
                  <p
                    className={styles.helperText}
                    id="other_scheduled_payment_message"
                  >
                    {t('payment.other-scheduled-payment-message')}
                  </p>
                </div>
              )}

              <div className={messagesStyles}>
                {!errors.otherAmount &&
                  currentQuickPay &&
                  currentQuickPay.amount !== 0 &&
                  +otherAmount === currentQuickPay.amount && (
                    <ErrorMessage
                      message={t('errors.current-statement', {
                        currentBalanceAmount: (
                          <b>{number(currentQuickPay.amount)}</b>
                        ),
                        currentBalanceDiscount: (
                          <b>{number(currentQuickPay.discountAmount)}</b>
                        )
                      })}
                    />
                  )}

                {!errors.otherAmount &&
                  lastStatementQuickPay &&
                  +otherAmount > lastStatementQuickPay.amount &&
                  +otherAmount < currentQuickPay.amount &&
                  lastStatementQuickPay.amount > 0 && (
                    <ErrorMessage
                      message={t('errors.more-then-statement-balance', {
                        lastStatementAmount: (
                          <b>{number(lastStatementQuickPay.amount)}</b>
                        ),
                        lastStatementDiscount: (
                          <b>{number(lastStatementQuickPay.discountAmount)}</b>
                        ),
                        amount: (
                          <b>
                            {number(
                              +otherAmount - lastStatementQuickPay.amount
                            )}
                          </b>
                        )
                      })}
                    />
                  )}

                {lastStatementQuickPay &&
                  +otherAmount === lastStatementQuickPay.amount &&
                  lastStatementQuickPay.amount > 0 && (
                    <ErrorMessage
                      message={t('errors.last-statement', {
                        lastStatementAmount: (
                          <b>{number(lastStatementQuickPay.amount)}</b>
                        ),
                        lastStatementDiscount: (
                          <b>{number(lastStatementQuickPay.discountAmount)}</b>
                        )
                      })}
                    />
                  )}
              </div>
            </Col>
          </Row>
        </EnhancedRadio>
      </Col>
    </Row>
  );
};
