export default {
  PERSONAL_INFORMATION: {
    key: 'PERSONAL_INFORMATION',
    title: 'settings.sidebar-navigation.personal-information',
    command: 'verifyPersonalInfo',
    icon: 'personal'
  },
  CONTACT_INFORMATION: {
    key: 'CONTACT_INFORMATION',
    title: 'settings.sidebar-navigation.contact-information',
    command: 'verifyContactInfo',
    icon: 'contact'
  },
  ADDRESS_DETAILS: {
    key: 'ADDRESS_DETAILS',
    title: 'settings.sidebar-navigation.address-details',
    command: 'verifyAddressDetails',
    icon: 'address'
  },
  EMPLOYMENT_INFORMATION: {
    key: 'EMPLOYMENT_INFORMATION',
    title: 'settings.sidebar-navigation.employment-information',
    command: 'verifyEmploymentInfo',
    icon: 'employer'
  },
  STATEMENTS: {
    key: 'STATEMENTS',
    title: 'settings.sidebar-navigation.statements',
    command: 'verifyStatementInfo',
    icon: 'statement'
  },
  LANGUAGE_AND_TIMEZONE: {
    key: 'LANGUAGE_AND_TIMEZONE',
    title: 'settings.sidebar-navigation.language-and-time-zone',
    command: 'verifyLanguageAndTimezones',
    icon: 'language'
  },
  ACCESS_TO_ACCOUNT: {
    key: 'ACCESS_TO_ACCOUNT',
    title: 'settings.sidebar-navigation.access-to-account',
    command: 'verifyAccess',
    icon: 'blocked'
  }
};
