// @flow
import React, { type Node } from 'react';
import CardNumber from './CardNumber';
import CardHolder from './CardHolder';
import CardType from './CardType';
import Expires from './Expires';
import CVV from './CVV';

const path = [
  'M225.920065,0.478958878 C227.598975,-0.580852846 228.96,0.168484002 228.96,2.15516602 L228.96,140.4048',
  '34 C228.96,142.390389 227.349983,144 225.364121,144 L2.15587917,144 C0.169929941,144 -0',
  '.0724303038,143.136722 1.59993486,142.081041 L225.920065,0.478958878 Z'
].join('');

export default (props: Object): Node => (
  <svg
    width="327px"
    height="184px"
    viewBox="0 0 327 184"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>Image</title>
    <desc>Created with Sketch.</desc>
    <defs>
      <linearGradient x1="100%" y1="0%" x2="0%" y2="100%" id="linearGradient-1">
        <stop stopColor="#6E45E2" offset="0%" />
        <stop stopColor="#88D3CE" offset="100%" />
      </linearGradient>
      <rect id="path-2" x="0" y="0" width="230.4" height="144" rx="4" />
      <filter
        x="-5.9%"
        y="-7.3%"
        width="111.7%"
        height="118.8%"
        filterUnits="objectBoundingBox"
        id="filter-3"
      >
        <feOffset dx="0" dy="3" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          stdDeviation="4"
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
        />
        <feColorMatrix
          values="0 0 0 0 0.619607843   0 0 0 0 0.678431373   0 0 0 0 0.741176471  0 0 0 0.5 0"
          type="matrix"
          in="shadowBlurOuter1"
        />
      </filter>
      <rect id="path-4" x="0" y="0" width="230.4" height="144" rx="4" />
      <filter
        x="-6.1%"
        y="-7.6%"
        width="112.1%"
        height="119.4%"
        filterUnits="objectBoundingBox"
        id="filter-6"
      >
        <feMorphology
          radius="0.45"
          operator="dilate"
          in="SourceAlpha"
          result="shadowSpreadOuter1"
        />
        <feOffset
          dx="0"
          dy="3"
          in="shadowSpreadOuter1"
          result="shadowOffsetOuter1"
        />
        <feGaussianBlur
          stdDeviation="4"
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
        />
        <feComposite
          in="shadowBlurOuter1"
          in2="SourceAlpha"
          operator="out"
          result="shadowBlurOuter1"
        />
        <feColorMatrix
          values="0 0 0 0 0.619607843   0 0 0 0 0.678431373   0 0 0 0 0.741176471  0 0 0 0.5 0"
          type="matrix"
          in="shadowBlurOuter1"
        />
      </filter>
    </defs>
    <g
      id="Payments---One-Time-Payment-only"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Member-Portal---Payments---One-Time-Payment---Step-2---Pay-whole-balance---Step-3---Credit/Debit-Card---Step-4"
        transform="translate(-769.000000, -467.000000)"
      >
        <g id="Image" transform="translate(777.000000, 472.000000)">
          <g id="Credit-Card-back" transform="translate(80.200000, 0.000000)">
            <g id="CC-BG">
              <use
                fill="black"
                fillOpacity="1"
                filter="url(#filter-3)"
                xlinkHref="#path-2"
              />
              <use
                fill="url(#linearGradient-1)"
                fillRule="evenodd"
                xlinkHref="#path-2"
              />
              <use
                fillOpacity="0.4"
                fill="#FFFFFF"
                fillRule="evenodd"
                xlinkHref="#path-2"
              />
            </g>
            <rect
              id="Name-Box"
              fill="#FFFFFF"
              x="18"
              y="71.1"
              width="117"
              height="27"
            />

            <CVV {...props} />

            <rect
              id="Bar"
              fill="#000000"
              x="0"
              y="23.4"
              width="230.4"
              height="27"
            />
          </g>
          <g id="Credit-Card" transform="translate(0.000000, 23.800000)">
            <mask id="mask-5" fill="white">
              <use xlinkHref="#path-4" />
            </mask>
            <g id="Card-BG">
              <use
                fill="black"
                fillOpacity="1"
                filter="url(#filter-6)"
                xlinkHref="#path-4"
              />
              <use fill="#FFFFFF" fillRule="evenodd" xlinkHref="#path-4" />
              <use
                strokeOpacity="0.2"
                stroke="#A3A7BA"
                strokeWidth="0.9"
                fillOpacity="0.548375226"
                fill="#F3F4F7"
                fillRule="evenodd"
                xlinkHref="#path-4"
              />
            </g>
            <g id="Group" mask="url(#mask-5)">
              <g transform="translate(185.000000, 20.000000)" />
            </g>
            <path
              d={path}
              id="tint"
              fillOpacity="0.02"
              fill="#0090B1"
              mask="url(#mask-5)"
              transform="translate(114.724868, 71.998927) scale(-1, -1) translate(-114.724868, -71.998927) "
            />
            <CardType {...props} />
            <Expires {...props} />
            <CardHolder {...props} />
            <CardNumber {...props} />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
