// @flow
import { mergeDeepRight, values, pick, compose, join, replace } from 'ramda';
import { signUpSchema } from '../../../../utils/validation';
import type { FormikConfig } from '../../../../utils/types';
import { DateTime } from 'luxon';

export default ({
  mapPropsToValues: (): Object => ({
    firstName: '',
    lastName: '',
    ssn: '',
    ssnMask: '',
    zip: '',
    dateOfBirth: '',
    accountNumber: '',
    validatorType: 'ssn'
  }),
  handleSubmit: (formValues: Object, props: Object): void => {
    const {
      props: {
        actions: {
          auth: { signUp }
        }
      }
    } = props;

    const allKeys = [
      'firstName',
      'lastName',
      'ssn',
      'zip',
      'dateOfBirth',
      'accountNumber'
    ];

    const pickedValues: Object = pick(allKeys, formValues);

    const dataToSave: Object = mergeDeepRight(pickedValues, {
      dateOfBirth:
        typeof pickedValues.dateOfBirth === 'string'
          ? DateTime.fromFormat(
              pickedValues.dateOfBirth,
              'M/d/yyyy'
            ).toISODate()
          : DateTime.fromJSDate(pickedValues.dateOfBirth).toISODate(),
      ssnEnding: compose(
        replace(/(\d{3})(\d{2})(\d{4})/, '$1-$2-$3'),
        join(''),
        values
      )(pickedValues.ssn)
    });

    delete dataToSave.ssn;
    if (formValues.validatorType === 'ssn') {
      delete dataToSave.accountNumber;
    } else {
      delete dataToSave.ssnEnding;
    }

    signUp(dataToSave);
  },
  validationSchema: signUpSchema,
  validateOnChange: false
}: FormikConfig);
