import React, { Fragment } from 'react';
import { Row, Col } from 'reactstrap';
import { t, Button, Modal } from '../../../components';
import { compose, withProps } from 'recompose';
import type { History } from 'react-router-dom';
import { withMapStateDispatch } from '../../../hoc';
import type { Handlers } from './types';
import type { Actions } from '../../../utils/types';
import type { MemberModel } from '../../../models/member/types';
import styles from './deleteEcheck.module.css';

type Props = {
  history: History,
  handlers: Handlers,
  actions: Actions,
  member: MemberModel
};

export const getDescription = (echeck = {}) => {
  if (echeck.hasRecurringPayments || echeck.hasScheduledPayments) {
    return t('delete-echeck.recurring-scheduled-payment-affected');
  }
  return t('delete-echeck.description');
};

export default compose(
  withMapStateDispatch,
  withProps((props: Props): Object => {
    const {
      actions: {
        member: { updateStore },
        payment: { deletePaymentProfile }
      },
      closeModal,
      history: { push },
      formik: {
        setFieldValue,
        values: { echeckId }
      },
      payment: { echecks, echeckId: paymentEcheckId }
    } = props;
    let description = t('delete-echeck.description');
    let echeckDetails = null;

    if (echeckId && echecks.length) {
      echeckDetails = echecks.find(({ id }) => echeckId === id);
      description = getDescription(echeckDetails);
    }

    return {
      modalConfig: {
        toggle: closeModal,
        header: {
          message: 'delete-echeck.title',
          toggle: closeModal,
          tag: 'h1'
        },
        size: 'md',
        body: {
          render: (): Node => (
            <Fragment>
              <Row data-testid="delete-echeck-modal">
                <Col>
                  <p>{description}</p>
                </Col>
              </Row>
              <Row>
                <Col className={styles.actionFooter}>
                  <Button title="delete-echeck.cancel" onClick={closeModal} />
                  <Button
                    primary
                    title="delete-echeck.confirm"
                    onClick={() => {
                      deletePaymentProfile(echeckId).then(() => {
                        setFieldValue('cardId', null);
                        if (paymentEcheckId === echeckId) {
                          updateStore({ selectedTransaction: {} });
                          push('/member/me');
                        }
                        closeModal();
                      });
                    }}
                  />
                </Col>
              </Row>
            </Fragment>
          )
        }
      }
    };
  })
)(Modal);
