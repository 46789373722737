// @flow
import { withProps, type HOC } from 'recompose';
import type { Match } from 'react-router-dom';
import { t } from '../../../components';
import type { Models } from '../../../utils/types';

export default (withProps((props: Models | Match): Object => {
  const {
    match: {
      params: { user, memberId }
    },
    member: {
      data: {
        familyMember: { firstName }
      }
    }
  } = props;

  return {
    headerConfig: {
      fixedHeader: true,
      breadcrumbConfig: [
        {
          title:
            memberId === 'me'
              ? t('common.home')
              : t('common.family-member', { name: firstName }),
          linkTo: `/${user}/${memberId}/activity`
        },
        { title: t('common.payment') }
      ]
    }
  };
}): HOC<*, any>);
