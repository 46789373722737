// @flow
export default {
  LOG_OUT: '/logout',
  AUTH: '/:tab(auth)',
  HOME:
    '/:user(member)/:memberId(\\d+|me)/:tab(activity|statements|transactions|scheduled-transactions|delete-recurring-payment)',
  MEMBERSHIP_RESOURCES:
    '/:user(member)/:memberId(\\d+|me)/:tab(membership-resources)',
  PAYMENT: '/:user(member)/:memberId(\\d+|me)/:tab(payment)',
  EDIT_SCHECULED_PAYMENT:
    '/:user(member)/:memberId(\\d+|me)/:tab(edit-scheduled-payment)/:entityId',
  SETTINGS: '/:tab(settings)',
  TERMS: '/:tab(terms-and-conditions)',
  NOT_FOUND: '/:tab(not-found)',
  ERROR_400: '/:status(400)',
  ERROR_401: '/:status(401)',
  ERROR_403: '/:status(403)',
  ERROR_500: '/:status(500)',
  ERROR_501: '/:status(501)',
  ERROR_503: '/:status(503)',
  ERROR_502: '/:status(502)',
  ERROR_404: '/:status(404)',
  PROCESSING: '/:tab(processing)',
  BASE_PATH: '/member/me/activity'
};
