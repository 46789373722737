// @flow
import { withProps, type HOC } from 'recompose';
import { is } from 'ramda';
import styles from './form.module.css';

type Props = {
  className: any
};

export default (withProps(({ className }: Props): Object => {
  let styleClasses = className;

  if (className && !is(String, className)) {
    styleClasses = className
      .filter((i) => !!i)
      .map((classItem) => styles[classItem])
      .join(' ');
  }

  return { styleClasses };
}): HOC<*, Props>);
