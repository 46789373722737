// @flow
import React, {
  Fragment,
  useEffect,
  type AbstractComponent,
  type Node
} from 'react';
import { Toast, SystemError } from '../../../components';
import styles from './notifications.module.css';
import type { Actions } from '../../../utils/types';

export default (Component: AbstractComponent<Object>) => (
  props: Actions
): Node => {
  const {
    actions: {
      toasts: { removeAllToasts }
    },
    toasts
  } = props;

  useEffect(
    (): Function => (): void => {
      removeAllToasts();
    },
    [removeAllToasts]
  );

  return (
    <Fragment>
      {toasts.map(
        (toast) =>
          toast.type === 'SYSTEM_ERROR' && (
            <SystemError key={toast.id} toast={toast} {...props} />
          )
      )}
      <Component {...props} />
      {toasts.length > 0 && (
        <div className={styles.toastsContainer}>
          {toasts.map(
            (toast) =>
              toast.type !== 'SYSTEM_ERROR' && (
                <Toast key={toast.id} toast={toast} {...props} />
              )
          )}
        </div>
      )}
    </Fragment>
  );
};
