// @flow
import { compose, withProps } from 'recompose';
import { withFormik } from '../../../../../hoc';
import AuthorizationAgreement from '../../../AuthorizationAgreement/AuthorizationAgreement';
import formConfig from '../../../AuthorizationAgreement/formConfig';
import withAgreement from '../../../AuthorizationAgreement/withAgreement';
import getMemberAccessFromState, {
  showAcceptAccessModal
} from '../../../../../utils/getMemberAccessFromState';
import { ACCESS_TYPE, denyAccess } from '../../utils';

const handleSubmit = (...args) => {
  const [, { props }] = args;

  const {
    actions: {
      member: { acceptAccess }
    },
    match: {
      params,
      params: { familyMemberId }
    },
    handlers: { goBack }
  } = props;

  acceptAccess({ id: familyMemberId }).then(async (member) => {
    const { hipaaAccesses = {} } = member || {};
    await goBack();
    showAcceptAccessModal({ hipaaAccesses, params });
  });
};

const config = formConfig(handleSubmit);

export default compose(
  withAgreement,
  withFormik(config),
  withProps((props) => {
    const {
      match: {
        params: { familyMemberId }
      }
    } = props;

    const { member } = getMemberAccessFromState(props, familyMemberId) || {};

    const { name = '' } = member || {};

    return {
      handleGoBack: () => denyAccess({ type: ACCESS_TYPE.REQUEST, ...props }),
      name
    };
  })
)(AuthorizationAgreement);
