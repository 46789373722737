// @flow
import React, { Fragment, useState, useEffect } from 'react';
import { Col, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { t, Button, Input } from '../../../../components';
import AccessErrors from '../AccessErrors';
import getMemberAccessFromState, {
  getFamilyMemberFromProps
} from '../../../../utils/getMemberAccessFromState';
import ERRORS_CODE, { handleGoBack, denyAccess, ACCESS_TYPE } from '../utils';
import { GENDER } from '../../../../config/member.config';
import styles from '../../../../components/Modal/modals.module.css';

type acceptAccessModal = {
  formik: Object,
  match: {
    params: { familyMemberId: number }
  },
  errorCode: string,
  pin: string
};

export default (props: acceptAccessModal) => {
  const {
    formik,
    errorCode,
    match: {
      params: { familyMemberId }
    }
  } = props;

  const { handleSubmit, isValid } = formik;

  const [name, setName] = useState();
  const [gender, setGender] = useState();

  useEffect(() => {
    const item = getMemberAccessFromState(props, familyMemberId);

    if (item) {
      const { member } = item;
      setName(member.name);

      const { gender: memberGender } =
        getFamilyMemberFromProps(props, familyMemberId) || {};
      setGender(memberGender);
    }
  }, [familyMemberId, props]);

  const disabledDenyButton =
    errorCode === ERRORS_CODE.PIN_MAX_ATTEMPTS_EXCEEDED;

  const disableSubmitButton = disabledDenyButton || !isValid;

  return (
    <Fragment>
      <ModalHeader
        tag="h1"
        toggle={() => handleGoBack({ type: ACCESS_TYPE.GRANT, ...props })}
      >
        {t('home.accept-grant-access-modal.header')}
      </ModalHeader>

      <ModalBody>
        <Row>
          <Col sm="12">
            {t(
              gender === GENDER.MALE
                ? 'home.accept-grant-access-modal.description.male'
                : 'home.accept-grant-access-modal.description.female',
              {
                name: <b>{name}</b>
              }
            )}
          </Col>

          <AccessErrors errorCode={errorCode} name={name}>
            <Col sm="12" className="mt-2">
              <Input
                name="pin"
                required
                maxLength={4}
                title="home.request-access-modal.form.pin.title"
                {...formik}
              />
            </Col>
          </AccessErrors>
        </Row>
      </ModalBody>
      <ModalFooter className={styles.modalControls}>
        <Button
          primary
          disabled={disableSubmitButton}
          onClick={handleSubmit}
          title="home.accept-grant-access-modal.buttons.submit"
        />

        <Button
          type="submit"
          title="home.accept-access-modal.buttons.cancel"
          disabled={disabledDenyButton}
          onClick={() => denyAccess({ type: ACCESS_TYPE.GRANT, ...props })}
        />
      </ModalFooter>
    </Fragment>
  );
};
