// @flow
import React, { Fragment, type Node } from 'react';
import classNames from 'classnames';
import { EnhancedRadio, t } from '../../../../../components';
import NewCard from './NewCard';
import styles from '../../../payment.module.css';
import type { Formik } from '../../../../../utils/types';
import type { Card, PaymentModel } from '../../../../../models/payment/types';

type Props = {
  formik: Formik,
  payment: PaymentModel
};

export default (props: Props): Node => {
  const {
    formik: {
      setFieldValue,
      resetForm,
      initialValues,
      submitCount,
      values: { cardId }
    },
    payment: { cards }
  } = props;

  const cardStyles: Function = ({ type }): string =>
    classNames([styles.card], [styles[type]]);

  const list: Array<Node> = cards.map((card: Card) => (
    <EnhancedRadio
      key={`card${card.id}`}
      name="paymentDetails"
      label={{
        id: 'payment.pay-with-card',
        card: `${card.type} •••• ${card.number}`
      }}
      id={card.id}
      value={card.id}
      checked={card.id === cardId}
      onChange={() => {
        resetForm({ values: initialValues });
        setFieldValue('cardId', card.id);
      }}
    >
      <div className={cardStyles({ ...card, cardId })}>
        {card.type} •••• {card.number}
        <span className={styles.expDate}>
          {t('payment.expire')} {card.expirationDate}
        </span>
      </div>
    </EnhancedRadio>
  ));

  return (
    <Fragment>
      {list}
      <EnhancedRadio
        key="newCard"
        name="paymentDetails"
        id="newCard"
        label="payment.pay-with-another-card"
        value=""
        highlight={false}
        checked={!cardId}
        onChange={() => {
          setFieldValue('cardId', '');
          !!submitCount && resetForm({ values: initialValues });
        }}
      >
        <p className={styles.optionTitle}>
          {t('payment.pay-with-another-card')}
        </p>
        {!cardId && <NewCard {...props} />}
      </EnhancedRadio>
    </Fragment>
  );
};
