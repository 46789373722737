import React from 'react';
import { compose } from 'recompose';
import { Modal } from 'reactstrap';
import withGoBack from '../withGoBack';

const EnhancedModal = (Component) => (props) => (
  <Modal isOpen centered fade={false} size={props.modalSize} role="region">
    <Component {...props} />
  </Modal>
);

export default compose(withGoBack, EnhancedModal);
