// @flow
import React, { Fragment, type Node } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { withShadowBox } from './../../hoc';
import { t, date, TermsAndConditions } from './../../components';
import { HEALTH_BRIDGE } from '../../config/terms.config';
import styles from './terms.module.css';

const TermsAndConditionsPage = (): Node => (
  <Fragment>
    <Row>
      <Col>
        <h2 className={styles.lastUpdate}>
          {t('terms-and-conditions.last-update', {
            updated: date({
              value: HEALTH_BRIDGE.LAST_UPDATE,
              year: 'numeric',
              month: 'long',
              day: '2-digit'
            })
          })}
        </h2>
        <h1 className={styles.terms}>{t('terms-and-conditions.terms')}</h1>
      </Col>
    </Row>
    <TermsAndConditions />
  </Fragment>
);

const Terms = withShadowBox(TermsAndConditionsPage);

export default (props: Object): Node => (
  <Container>
    <Terms {...props} />
  </Container>
);
