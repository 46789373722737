// @flow
import React, { Fragment, type Node } from 'react';
import { type Route } from 'react-router-dom';
import { Col, Form, Row } from 'reactstrap';
import { t, Button, Status, ErrorView, Input } from '../../../../components';
import BackToLogin from '../BackToLogin';
import type { AuthModel } from '../../../../models/auth/types';
import type { Formik } from '../../../../utils/types';
import styles from '../auth.module.css';

type Props = {
  auth: AuthModel,
  formik: Formik,
  toLogin: Route,
  viewError: boolean
};

export default (props: Props): Node => {
  const {
    formik,
    formik: { values, handleSubmit },
    auth: {
      data: { checkInbox },
      error
    },
    toLogin,
    viewError
  } = props;

  const resetPassword: Node = (
    <Fragment>
      <BackToLogin {...props} />
      <Row>
        <Col>
          <h1 className={styles.heading}>
            {t('auth.forgot-password.your-password')}
          </h1>
          <p className={styles.subHeading}>
            {t('auth.forgot-password.provide-email')}
          </p>
        </Col>
      </Row>
      {viewError && <ErrorView error={error} className="mb-3" />}
      <Form onSubmit={handleSubmit} className={styles.form}>
        <Row>
          <Col>
            <Input
              name="email"
              title="auth.forgot-password.email"
              {...formik}
            />
          </Col>
        </Row>
        <Button
          primary
          type="submit"
          title="auth.forgot-password.reset-password"
        />
      </Form>
    </Fragment>
  );

  const checkInboxView: Node = (
    <Status header="auth.feedback.check-inbox" icon="sent-email">
      <p>
        {t('auth.feedback.sent-temporary-password', { email: values.email })}
      </p>
      <Button
        primary
        link={toLogin}
        title="auth.forgot-password.back-to-sign-in"
      />
    </Status>
  );

  return (
    <Row className={styles.forgotPassword}>
      <Col xs={11} md={10} lg={checkInbox ? 8 : 6} xl={5}>
        {checkInbox ? checkInboxView : resetPassword}
      </Col>
    </Row>
  );
};
