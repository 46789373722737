// @flow
import { any, equals } from 'ramda';
import { type Match } from 'react-router-dom';
import { withProps, type HOC } from 'recompose';
import {
  ENTITY_TYPE,
  INTEREST_REVERSAL_TYPE
} from '../../../../config/payment.config';
import type { MemberModel } from '../../../../models/member/types';
import {
  claimsTable,
  reversalTable,
  paymentInterestReversalTable,
  claimLinesTable
} from '../configs';

type Props = {
  match: Match,
  member: MemberModel
};

export default (withProps((props: Props): Object => {
  const {
    match: {
      params: { entityType, user, memberId, tab }
    },
    member: {
      data: { selectedTransaction }
    }
  } = props;
  const { interestReversalType } = selectedTransaction;
  const { linkedClaims = [], revertedEvents = [], claimLines = [] } =
    selectedTransaction || {};

  const basePath: string = `/${user}/${memberId}/${tab}`;

  const paymentInterestReversal = any(equals(interestReversalType))([
    INTEREST_REVERSAL_TYPE.PAYMENT
  ]);

  const reversal = any(equals(entityType))([
    ENTITY_TYPE.LATE_FEE_REVERSAL,
    ENTITY_TYPE.PAPER_FEE_REVERSAL,
    ENTITY_TYPE.INTEREST_REVERSAL
  ]);

  const claimLinesLinkable = any(equals(entityType))([
    ENTITY_TYPE.CLAIM,
    ENTITY_TYPE.CREDIT_HOLD_INDEMNIFICATION,
    ENTITY_TYPE.CREDIT_HOLD_HRA,
    ENTITY_TYPE.CREDIT_HOLD_WELLNESS
  ]);

  if (claimLinesLinkable) {
    return {
      table: claimsTable({ data: linkedClaims, basePath }),
      claimLinesTable: claimLinesTable({ data: claimLines })
    };
  }

  if (paymentInterestReversal) {
    return {
      table: paymentInterestReversalTable({
        data: revertedEvents,
        entityType,
        basePath
      })
    };
  }

  if (reversal) {
    return {
      table: reversalTable({ data: revertedEvents, entityType, basePath })
    };
  }

  return null;
}): HOC<*, Object>);
