import React, { useState, Fragment } from 'react';
import { Col, Container } from 'reactstrap';
import { identity } from 'ramda';
import classNames from 'classnames';
import { t, number, date } from '../../../../components';
import styles from './family-members-widget.module.css';
import { accessToDependent18 } from 'utils/getMemberAccessFromState';
import { GET_SCREEN_SIZE, SCREEN_SIZES } from '../../../../config';

export default (props) => {
  const {
    history,
    match: { params: { user, tab, memberId } = {} } = {},
    member: {
      data: {
        familyMember: { id },
        member: {
          familyMembers: { hasAccess },
          familyMembers,
          type: currentMemberType
        }
      }
    }
  } = props;

  let [carouselPage, setCarouselPage] = useState(0);
  const SCREEN_SIZE = GET_SCREEN_SIZE();
  const IS_MOBILE_VIEW = SCREEN_SIZE === SCREEN_SIZES.MOBILE;
  const IS_TABLET_VIEW = SCREEN_SIZE === SCREEN_SIZES.TABLET;
  const IS_SMALL_DESKTOP_VIEW = SCREEN_SIZE === SCREEN_SIZES.SMALL_DESKTOP;

  const goToMember = (config) => {
    const { id: mId } = config;
    const { push = identity } = history || {};

    const hasAccessToDependent18 = accessToDependent18(currentMemberType);

    if (hasAccess || hasAccessToDependent18) {
      push(`/${user}/${mId || 'me'}/${tab}`);
    }
  };

  const memberTileStyle = (mem) => {
    return classNames({
      // Dynamic Styles
      [styles.currentMemberTile]: mem.id === +memberId || mem.id === id,
      [styles.memberTile]: true
    });
  };

  const currentMemberBubble = (mem) => {
    if (mem.id === +memberId || mem.id === id) {
      return classNames({ [styles.firstNameLetterBubbleActive]: true });
    }
  };

  const handleGoToMember = (member) => {
    goToMember({
      user: member.user,
      id: member.id,
      tab: member.tab,
      hasAccess,
      type: member.type
    });
  };

  const firstNameLetter = (memberFirstName) => {
    return memberFirstName ? memberFirstName.trim().charAt(0) : null;
  };

  const currentMember = (mem) => {
    return mem.id === +memberId || mem.id === id ? (
      <p>
        {mem.firstName} {mem.lastName}
      </p>
    ) : (
      <p>{mem.firstName}</p>
    );
  };

  let maxCards = 4;
  if (IS_MOBILE_VIEW) {
    maxCards = 1;
  } else if (IS_TABLET_VIEW) {
    maxCards = 2;
  } else if (IS_SMALL_DESKTOP_VIEW) {
    maxCards = 3;
  }
  const carouselCount = Math.ceil(familyMembers.length / maxCards);

  const carousel = [];
  const tiles = [];

  let startIdx = 0;
  let endIdx = maxCards;
  for (let i = 0; i < carouselCount; i++) {
    carousel.push(familyMembers.slice(startIdx, endIdx));
    startIdx += maxCards;
    endIdx += maxCards;
  }

  const showCarousel = () => {
    carousel[carouselPage].forEach((member) => {
      const { balanceDetails, firstName, accountNumber } = member;
      const {
        disputedClaimBalance,
        disputedClaimInterest,
        status,
        buyClaimOptOut,
        balance,
        minimumPaymentDue,
        dueDate
      } = balanceDetails || {};
      const formatDate = (value) =>
        date({
          value,
          year: 'numeric',
          month: 'long',
          day: '2-digit'
        });

      const active = balanceDetails && status === 'ACTIVE';
      const inactive = balanceDetails && status === 'INACTIVE';
      const newBalance =
        disputedClaimBalance || disputedClaimInterest
          ? balance - (disputedClaimBalance + disputedClaimInterest)
          : balance;

      tiles.push(
        <div
          className={memberTileStyle(member)}
          onClick={() => handleGoToMember(member)}
          onKeyDown={() => {}}
          role="menuitem"
          tabIndex="0"
        >
          <div className={styles.tileHeader}>
            <div
              className={
                currentMemberBubble(member) || styles.firstNameLetterBubble
              }
            >
              {firstNameLetter(firstName)}
            </div>
            <div className={styles.memberName}>
              {currentMember(member)}
              {accountNumber !== undefined && <p className={styles.you}>You</p>}
            </div>

            <div className={styles.iconContainer}>
              {member.hasAccess && buyClaimOptOut && (
                <div className={styles.optOut} />
              )}
              {!member.hasAccess && <div className={styles.noAccess} />}
            </div>
          </div>
          {member.hasAccess &&
            (!balanceDetails ||
              (newBalance === 0 && !minimumPaymentDue && inactive)) && (
              <div className={styles.balanceDetails}>
                {t('home.balance-status.tile-account-inactive', {
                  name: firstName
                })}
              </div>
            )}
          {member.hasAccess &&
            (!balanceDetails ||
              (newBalance === 0 &&
                (!minimumPaymentDue ||
                  (minimumPaymentDue && minimumPaymentDue.original === 0)) &&
                active)) && (
              <div className={styles.balanceDetails}>
                {t('home.balance-status.fm-no-balance-due', {
                  name: firstName
                })}
              </div>
            )}
          {member.hasAccess &&
            balanceDetails &&
            newBalance === 0 &&
            minimumPaymentDue &&
            minimumPaymentDue.original !== 0 && (
              <div className={styles.balanceDetails}>
                {t('home.balance-status.fm-balance-paid-in-full', {
                  name: firstName
                })}
              </div>
            )}
          {member.hasAccess && balanceDetails && newBalance !== 0 && (
            <>
              <div className={styles.balanceDue}>{number(newBalance)}</div>
              <div className={styles.balanceDetails}>
                {balanceDetails && minimumPaymentDue && (
                  <Fragment>
                    {t('home.payment-due-by', {
                      amount: number(minimumPaymentDue.remaining),
                      date: formatDate(dueDate)
                    })}
                  </Fragment>
                )}
              </div>
            </>
          )}
        </div>
      );
    });
    return tiles;
  };

  return (
    <Fragment>
      <Col>
        <div id={styles.previousArrowContainer}>
          {!!carouselPage && !IS_MOBILE_VIEW && !IS_TABLET_VIEW && (
            <button
              id={styles.previousArrow}
              onClick={() => setCarouselPage((carouselPage -= 1))}
            />
          )}
          {familyMembers.length > maxCards &&
            (IS_MOBILE_VIEW || IS_TABLET_VIEW) && (
              <button
                id={styles.previousArrow}
                disabled={carouselPage === 0}
                onClick={() => {
                  if (IS_MOBILE_VIEW) {
                    goToMember({ ...carousel[carouselPage - 1][0] });
                  }
                  setCarouselPage((carouselPage -= 1));
                }}
              />
            )}
        </div>
      </Col>
      {IS_MOBILE_VIEW ? (
        <div className={styles.memberTileContainer}>{showCarousel()}</div>
      ) : (
        <Container className={styles.memberTileContainer}>
          {showCarousel()}
        </Container>
      )}
      <Col>
        {((familyMembers.length > maxCards && !IS_MOBILE_VIEW) ||
          (familyMembers.length > maxCards && IS_MOBILE_VIEW)) &&
          carouselPage < carouselCount - 1 && (
            <button
              id={styles.nextArrow}
              onClick={() => {
                if (IS_MOBILE_VIEW) {
                  goToMember({ ...carousel[carouselPage + 1][0] });
                }
                return setCarouselPage((carouselPage += 1));
              }}
            />
          )}
      </Col>
    </Fragment>
  );
};
