// @flow
import React, { type Node } from 'react';
import { t } from './../../components/i18n';
import { useRemoveToast } from '../../hooks';
import styles from './toast.module.css';
import type { Actions } from '../../utils/types';
import type { Toast } from '../../models/toasts';

type Props = {
  actions: Actions,
  toast: Toast
};

export default (props: Props): Node => {
  const {
    toast: { message, type, rawMessage, icon }
  } = props;

  useRemoveToast(props);

  return (
    <div className={`${styles.toast} ${styles[type]} ${styles[icon]}`}>
      <h1 className={styles.message}>{rawMessage ? message : t(message)}</h1>
    </div>
  );
};
