// @flow
import React, { type Node } from 'react';
import {
  branch,
  compose,
  lifecycle,
  renderComponent,
  type HOC
} from 'recompose';
import { NotFound } from 'components/ErrorView';

type ErrorType = {
  message: string,
  stack: string
};

type ErrorStackType = {
  componentStack: string
};

type Props = {
  error: ErrorType,
  errorStack: ErrorStackType,
  errorMainLayout?: string
};

export default (compose(
  lifecycle({
    componentDidCatch(error: ErrorType, errorStack: ErrorStackType): void {
      this.setState({ error, errorStack });
    }
  }),
  branch(
    ({ error }) => error,
    renderComponent((props: Props): Node => <NotFound {...props} />)
  )
): HOC<*, any>);
