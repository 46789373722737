// @flow
import React, { useState, type Node } from 'react';
import { Row, Col, Form } from 'reactstrap';
import { Checkbox, Button, t } from '../../../../../components';
import { withFormik } from '../../../../../hoc';
import EchecksList from './EchecksList';
import NewEcheck from './NewEcheck';
import PaymentError from '../PaymentError';
import DeleteEcheck from '../../../../Modals/DeleteEcheckModal';
import eCheckFormConfig from './eCheckFormConfig';
import styles from '../../../payment.module.css';
import { PAYMENT_TYPE } from '../../../../../config/payment.config';

import type { Formik, Models } from '../../../../../utils/types';

export const EcheckDetails = (props: Models | Formik): Node => {
  const {
    formik,
    formik: {
      handleSubmit,
      values: { echeckId }
    },
    payment: { echecks, error, paymentType, method, editMode }
  } = props;

  const [isDeleteEcheck, setIsDeleteEcheck] = useState(false);

  const oneTimePayment = paymentType === PAYMENT_TYPE.ONE_TIME_PAYMENT;

  const getPaymentTypeDetails = (paymentType) => {
    if (paymentType === PAYMENT_TYPE.ONE_TIME_PAYMENT) {
      return 'payment.pay';
    } else if (paymentType === PAYMENT_TYPE.RECURRING_PAYMENT) {
      return editMode
        ? 'payment.update-recurring-payment'
        : 'payment.create-recurring-payment';
    } else if (paymentType === PAYMENT_TYPE.SCHEDULED_PAYMENT) {
      return 'payment.create-scheduled-payment';
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <PaymentError {...error} />
      {echecks.length ? <EchecksList {...props} /> : <NewEcheck {...props} />}
      <Row data-testid="echeck-details">
        {echeckId && (
          <Col>
            <Button
              title="payment.delete-echeck"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setIsDeleteEcheck(true);
              }}
            />
          </Col>
        )}
        <Col className={styles.submitButton}>
          {oneTimePayment && !echeckId && (
            <Checkbox
              className="saveCheckbox"
              name="saveToProfile"
              label={t('payment.save-payment')}
              {...formik}
            />
          )}
          {!oneTimePayment && !echeckId && (
            <span className={styles.savedPaymentInfo}>
              {t(`payment.${method}-saved`)}
            </span>
          )}
          <Button
            primary
            type="submit"
            title={getPaymentTypeDetails(paymentType)}
          />
        </Col>
      </Row>
      {isDeleteEcheck && (
        <DeleteEcheck {...props} closeModal={() => setIsDeleteEcheck(false)} />
      )}
    </Form>
  );
};

export default withFormik(eCheckFormConfig)(EcheckDetails);
