// @flow
import React, { useEffect, type Node } from 'react';
import { Container, Row, Col } from 'reactstrap';
import type { ContextRouter } from 'react-router-dom';
import { Status, Button, t, number } from '../../../components';
import { useLoadAcceptJS, useSetData } from '../hooks';
import withStepsConfig from '../hoc/withStepsConfig';
import styles from '../payment.module.css';
import type { Actions, Models } from '../../../utils/types';
import { AMOUNT_OPTIONS, PAYMENT_TYPE } from '../../../config/payment.config';
import PaymentDialBox from './PaymentDialogBox/PaymentDialogBox';

const INITIAL_DATA = {
  type: AMOUNT_OPTIONS.FULL_STATEMENT,
  paymentAmount: '',
  maxAmountLastStatement: '',
  maxAmountFull: '',
  maxAmountMin: '',
  maxAmount: '',
  otherAmount: '',
  paymentDay: '',
  cardId: null,
  hasReccuringPayment: false
};

export const Payment = (props: ContextRouter | Models | Actions): Node => {
  const {
    actions: {
      payment: { setData },
      member: { getMember, getMemberScheduledTransactions }
    },
    payment: {
      successView,
      step,
      steps,
      paymentAmount,
      otherAmount,
      schedulePaymentDate,
      paymentDay,
      paymentType,
      hasReccuringPayment
    },
    member: {
      data: {
        list,
        familyMember: {
          firstName,
          balanceDetails: {
            recurringPayment,
            balance,
            currentDisputedQuickPay,
            currentBalanceQuickPay,
            disputedClaimBalance,
            disputedClaimInterest,
            disputedClaimCount
          }
        },
        selectedTransaction
      }
    },
    history: { push },
    match: {
      params: { user, memberId, entityId }
    },
    flags: { scheduledPaymentMember }
  } = props;

  const currentQpdAmount =
    balance - (disputedClaimBalance + disputedClaimInterest);
  const disputedQpd =
    currentDisputedQuickPay &&
    currentDisputedQuickPay.amount <= 0 &&
    disputedClaimCount > 0
      ? { amount: currentQpdAmount, discountAmount: 0 }
      : currentDisputedQuickPay;

  const currentQuickPay =
    disputedClaimCount > 0 && currentDisputedQuickPay
      ? disputedQpd
      : currentBalanceQuickPay;

  const amount = number(paymentAmount);

  const date = new Date(schedulePaymentDate);
  const dateWithMonth = date.toLocaleString('en-us', {
    month: 'long',
    day: '2-digit'
  });

  const goBackToHome: Function = (): void => {
    setData({ successView: false, step: 0, editMode: false }).then(() =>
      push(`/${user}/${memberId}/activity`)
    );
  };

  const goToScheduledTransactionList: Function = (): void => {
    setData({ successView: false, step: 0, editMode: false }).then(() =>
      push(`/${user}/${memberId}/scheduled-transactions`)
    );
  };

  const goToEditScheduledTransaction: Function = (id): void => {
    push(`/member/${memberId}/edit-scheduled-payment/${id}`);
  };

  useLoadAcceptJS(props);
  useSetData(props);

  const getOtherAmount = () =>
    entityId ? selectedTransaction.amount : recurringPayment.amount;

  useEffect((): void => {
    getMember(memberId);
  }, [successView, paymentType, getMember, memberId]);

  useEffect((): void => {
    if (scheduledPaymentMember) {
      const args: PaginationParams = {
        size: 10,
        page: 0
      };
      getMemberScheduledTransactions(args);
    }
  }, []);

  useEffect((): void => {
    if (step === 0) {
      if (!!recurringPayment) {
        const hasMaxAmount = !!recurringPayment.maxAmount;
        const data = {
          hasReccuringPayment: !!recurringPayment,
          type:
            recurringPayment.type === AMOUNT_OPTIONS.PAY_IN_FULL
              ? AMOUNT_OPTIONS.FULL_STATEMENT
              : recurringPayment.type,
          paymentAmount: recurringPayment.amount,
          maxAmountLastStatement:
            hasMaxAmount && recurringPayment.type === AMOUNT_OPTIONS.PAY_IN_FULL
              ? recurringPayment.maxAmount
              : '',
          maxAmountFull:
            hasMaxAmount && recurringPayment.type === AMOUNT_OPTIONS.PAY_IN_FULL
              ? recurringPayment.maxAmount
              : '',
          maxAmountMin:
            hasMaxAmount &&
            recurringPayment.type === AMOUNT_OPTIONS.MINIMUM_PAYMENT_DUE
              ? recurringPayment.maxAmount
              : '',
          maxAmount:
            hasMaxAmount &&
            (recurringPayment.type === AMOUNT_OPTIONS.PAY_IN_FULL ||
              AMOUNT_OPTIONS.MINIMUM_PAYMENT_DUE)
              ? recurringPayment.maxAmount
              : '',
          otherAmount:
            recurringPayment.type === AMOUNT_OPTIONS.OTHER
              ? getOtherAmount()
              : '',
          paymentDay: recurringPayment.paymentDay
        };
        const cardId = recurringPayment.card ? recurringPayment.card.id : null;
        const echeckId = recurringPayment.echeck
          ? recurringPayment.echeck.id
          : null;
        data.cardId = cardId;
        data.echeckId = echeckId;
        setData(data);
      } else {
        setData(INITIAL_DATA);
      }
    }
  }, [recurringPayment]);

  useEffect(() => {
    if (step === 0 && selectedTransaction.amount) {
      const data = {
        type:
          selectedTransaction.type === AMOUNT_OPTIONS.PAY_IN_FULL
            ? AMOUNT_OPTIONS.FULL_STATEMENT
            : selectedTransaction.type,
        paymentAmount: selectedTransaction.amount,
        otherAmount:
          selectedTransaction.type === AMOUNT_OPTIONS.OTHER
            ? getOtherAmount()
            : '',
        paymentDay: selectedTransaction.transactionDate
      };
      const cardId = selectedTransaction.card
        ? selectedTransaction.card.id
        : null;
      const echeckId = selectedTransaction.echeck
        ? selectedTransaction.echeck.id
        : null;
      data.cardId = cardId;
      data.echeckId = echeckId;
      setData(data);
    } else if (step === 0) {
      setData(INITIAL_DATA);
    }
  }, [selectedTransaction]);

  const oneTimeSuccess: Node = (
    <Status header="payment.success" className={styles.paymentSuccess}>
      <p>{t('payment.success-desc', { amount })}</p>
      <Button primary title="payment.go-home" onClick={goBackToHome} />
    </Status>
  );

  const memberLabel: string = hasReccuringPayment
    ? 'payment.recurring-edit-success-desc'
    : 'payment.recurring-creation-success-desc';

  const familyMemberLabel: string = hasReccuringPayment
    ? 'payment.recurring-edit-fm-success-desc'
    : 'payment.recurring-creation-fm-success-desc';

  const currentStep = balance <= 0 ? 0 : 1;

  const recurringSuccess: Node = (
    <Status
      header="payment.recurring-success"
      className={styles.paymentSuccess}
    >
      <p>
        {t(memberId !== 'me' ? familyMemberLabel : memberLabel, {
          date: paymentDay,
          name: firstName
        })}
      </p>
      <Button primary title="payment.go-home" onClick={goBackToHome} />
      <Button
        title="payment.edit-recurring-payment"
        onClick={() =>
          setData({
            successView: false,
            step: currentStep,
            editMode: true,
            disableEditPaymentType: false
          })
        }
      />
    </Status>
  );

  const scheduledSuccess: Node = (
    <Status
      header="payment.scheduled-success"
      className={styles.paymentSuccess}
    >
      <p>
        {t('payment.scheduled-success-desc', {
          otherAmount: otherAmount,
          dateWithMonth: dateWithMonth
        })}
      </p>
      <Button primary title="payment.go-home" onClick={goBackToHome} />
    </Status>
  );

  const stepsView: Array<Node> = steps.map(
    (stepItem, i) =>
      i <= step && (
        <stepItem.component
          stepIndex={i}
          key={stepItem.header}
          step={stepItem}
          currentQuickPay={currentQuickPay}
          {...props}
        />
      )
  );

  const successPaymentView: Node = (paymentType) => {
    if (paymentType === PAYMENT_TYPE.ONE_TIME_PAYMENT) {
      return oneTimeSuccess;
    } else if (paymentType === PAYMENT_TYPE.RECURRING_PAYMENT) {
      return recurringSuccess;
    } else if (paymentType === PAYMENT_TYPE.SCHEDULED_PAYMENT) {
      return scheduledSuccess;
    }
  };

  const getUnprocessedScheduledPayment = () => {
    const { content } = list;
    if (content && content.length) {
      return content.filter((item) => item.isEditableOrDeletable);
    }
    return [];
  };

  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];

  const schedulePaymentQuickEditView = () => {
    const unprocessedScheduledPayment = getUnprocessedScheduledPayment();
    const hasUnprocessedScheduledPayment = unprocessedScheduledPayment.length;
    const hasMultiUnprocessedScheduledPayment =
      unprocessedScheduledPayment.length > 1;
    if (scheduledPaymentMember && hasUnprocessedScheduledPayment) {
      if (hasMultiUnprocessedScheduledPayment) {
        return (
          <PaymentDialBox
            title="payment.scheduled-dialog-title"
            buttonTitle="payment.scheduled-dialog-button-view"
            dialog="payment.scheduled-dialog-text-multi"
            onClick={goToScheduledTransactionList}
          />
        );
      } else {
        const scheduledPayment = unprocessedScheduledPayment[0];

        const id = scheduledPayment.scheduledPaymentId;
        const date = new Date(scheduledPayment.startAfter);
        date.setDate(date.getDate() + 1);
        const values = {
          amount: number(scheduledPayment.amount),
          day: date.getDate(),
          month: months[date.getMonth()]
        };
        return (
          <PaymentDialBox
            title="payment.scheduled-dialog-title"
            buttonTitle="payment.scheduled-dialog-button-edit"
            dialog="payment.scheduled-dialog-text-single"
            values={values}
            onClick={() => goToEditScheduledTransaction(id)}
          />
        );
      }
    }
  };

  const activateQuickEditView =
    scheduledPaymentMember && step === 0 && !entityId;
  return (
    <Container
      className={styles.paymentContainer}
      data-testid="payment-container"
    >
      {activateQuickEditView && schedulePaymentQuickEditView()}
      {successView ? (
        <Row>
          <Col md={{ size: 10, offset: 1 }}>
            {successPaymentView(paymentType)}
          </Col>
        </Row>
      ) : (
        stepsView
      )}
    </Container>
  );
};

export default withStepsConfig(Payment);
