// @flow

import type { Actions, FormikConfig, Models } from '../../../utils/types';

export default ({
  mapPropsToValues: (props: Actions | Models): Object => {
    const {
      payment: { method }
    } = props;

    return { method };
  },
  handleSubmit: (values: Object, props: Object): void => {
    const {
      props: {
        actions: {
          payment: { setData, setError }
        },
        payment: { step }
      }
    } = props;

    setData({ ...values, step: step + 1 });
    setError();
  },
  enableReinitialize: true,
  validateOnChange: false
}: FormikConfig);
