// @flow
import React, { type Node } from 'react';
import classNames from 'classnames';
import { Button, t } from '../../../../components';
import styles from '../../payment.module.css';

type Props = {
  day?: number,
  label?: string,
  small?: boolean,
  checked?: boolean,
  disabled: boolean,
  stroke?: boolean,
  statementDate?: boolean,
  dueDate?: boolean,
  onChange?: Function,
  showTitle?: boolean
};

export default (props: Props): Node => {
  const {
    day,
    label,
    small = false,
    checked = false,
    disabled = false,
    stroke = false,
    statementDate,
    dueDate,
    onChange,
    showTitle,
    medium = false,
    hidden = false
  } = props;

  const dateSelector: string = classNames({
    [styles.selectDate]: true,
    [styles.small]: small,
    [styles.medium]: medium,
    [styles.hidden]: hidden,
    [styles.checked]: checked,
    [styles.disabled]: disabled,
    [styles.stroke]: stroke,
    [styles.statementDate]: statementDate,
    [styles.dueDate]: dueDate
  });

  const button: Node = (
    <Button
      type="button"
      className={dateSelector}
      title={day}
      onClick={!disabled ? onChange : null}
      showTitle={showTitle}
    />
  );

  return label ? (
    <div className={styles.legend}>
      {button}
      {<span>{t(label)}</span>}
    </div>
  ) : (
    button
  );
};
