// @flow

export default {
  data: {
    userLocation: null,
    email: '',
    registered: false,
    verificationToken: '',
    sentEmail: false,
    checkInbox: false
  },
  error: null
};
