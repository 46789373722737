// @flow
import effects from './effects';
import reducers from './reducers';
import state from './state';

export default {
  state,
  reducers,
  effects
};
