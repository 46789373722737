// @flow
import { useEffect, useRef, useState } from 'react';
import type { RefObject } from '../utils/types';

export default (): Object => {
  const inputEl: RefObject = useRef(null);
  const [isFixedTarget, setFixed]: Object = useState(false);

  const setValue = (): void =>
    setFixed(window.scrollY >= inputEl.current.scrollHeight);

  useEffect(() => {
    window.addEventListener('scroll', () => inputEl.current && setValue());

    return () => window.removeEventListener('scroll', setValue);
  }, [inputEl]);

  return { inputEl, isFixedTarget };
};
