// @flow
import axios, { AxiosInstance } from 'axios';
import errorInterceptor from './errorInterceptor';
import timeoutInterceptor from './timeoutInterceptor';
import type { RequestOptions, ResponseConfig, Response } from './types';
import CONFIG from '../config';
import { isIE11 } from './browserSpecific';

const http: AxiosInstance = axios.create({
  baseURL: CONFIG.BASE_PATH,
  withCredentials: true,
  timeout: CONFIG.REQUEST_TIMEOUT,
  headers: { 'Content-Type': 'application/json' },
  responseType: 'json'
});

// IE11 does not handle the timeout interceptor correctly
http.interceptors.response.use(
  !isIE11 ? timeoutInterceptor : (r) => r,
  errorInterceptor
);

const successHandler = (response: ResponseConfig): Response => {
  if (response.status < 400) {
    return response.data;
  }

  throw Error(`Response status ${response.status} \n`);
};

const errorHandler = (error): Error => {
  throw error;
};

export const successHandlerWithHeader = (
  response: ResponseConfig
): Response => {
  if (response.status < 400) {
    return {
      data: response.data,
      headers: response.headers
    };
  }

  throw Error(`Response status ${response.status} \n`);
};

export default {
  performGET({
    headers,
    path,
    params = {}
  }: RequestOptions): Promise<ResponseConfig> {
    return http
      .get(path, { headers, params })
      .then(successHandler, errorHandler);
  },
  performGETImage({
    path,
    params = {}
  }: RequestOptions): Promise<ResponseConfig> {
    const headers = {
      'Content-Type': 'image/png'
    };
    return http
      .get(path, { headers, params, responseType: 'blob' })
      .then(successHandler, errorHandler);
  },
  performGETFile({
    path,
    params = {}
  }: RequestOptions): Promise<ResponseConfig> {
    const headers = {
      'Content-Type': 'application/pdf',
      'Content-Disposition': 'attachment'
    };
    return http
      .get(path, { headers, params, responseType: 'blob' })
      .then(successHandler, errorHandler);
  },
  performGETPass({
    path,
    params = {}
  }: RequestOptions): Promise<ResponseConfig> {
    const headers = {
      'Content-Type': 'application/vnd.apple.pkpass',
      'Content-Disposition': 'attachment',
      Accepts:
        'text/html,application/signed-exchange,application/vnd.apple.pkpass'
    };
    return http
      .get(path, { headers, params, responseType: 'blob' })
      .then(successHandler, errorHandler);
  },
  performPOST({
    headers,
    path,
    params,
    data,
    timeout = CONFIG.REQUEST_TIMEOUT
  }: RequestOptions): Promise<ResponseConfig> {
    return http
      .post(path, data, { headers, params, timeout: timeout })
      .then(successHandler, errorHandler);
  },
  performPUT({
    headers,
    path,
    params,
    data
  }: RequestOptions): Promise<ResponseConfig> {
    return http
      .put(path, data, { headers, params })
      .then(successHandler, errorHandler);
  },
  performPATCH({
    headers,
    path,
    data
  }: RequestOptions): Promise<ResponseConfig> {
    return http
      .patch(path, data, { headers })
      .then(successHandler, errorHandler);
  },
  performDELETE({
    path,
    params = {}
  }: RequestOptions): Promise<ResponseConfig> {
    return http.delete(path, { params }).then(successHandler, errorHandler);
  },
  performGETResource({
    headers,
    path,
    params = {},
    timeout
  }: RequestOptions): Promise<ResponseConfig> {
    return http
      .get(path, { headers, params, responseType: 'blob', timeout: timeout })
      .then(successHandlerWithHeader, errorHandler);
  }
};
