import { withProps } from 'recompose';
import { t } from '../../components';
import { withAppLayout } from '../../hoc';

const Statement = () => null;

export default withAppLayout([
  withProps(
    ({
      actions: { member: { getMemberStatement } } = {},
      member: {
        data: {
          familyMember: { firstName }
        }
      },
      match: {
        params: { user, statementId, memberId }
      }
    }) => ({
      statementView: true,
      headerConfig: {
        breadcrumbConfig: [
          {
            title:
              memberId === 'me'
                ? t('common.home')
                : t('common.family-member', { name: firstName }),
            linkTo: `/${user}/${memberId}/activity`
          },
          { title: t('common.statement') }
        ]
      },
      dropDownConfig: {
        className: 'settings',
        statement: {
          generalSettings: {
            nav: true,
            inNavbar: true
          },
          dropDownToggle: {
            label: '',
            nav: true
          },
          dropDownMenu: {
            right: true
          },
          items: [
            {
              title: 'statement.make-payment',
              link: `/member/${statementId}/payment`
            },
            {
              title: 'statement.download-statement',
              onClick: () => getMemberStatement({ statementId, memberId })
            }
          ]
        }
      }
    })
  )
])(Statement);
