// @flow
import React, { Fragment, type Node } from 'react';
import { t } from '../../../../components';
import ErrorView from '../../../../components/ErrorView/ErrorView';
import styles from './AccessErrors.module.css';
import ERRORS_CODE from '../utils';

type AccessErrors = {
  children: Node,
  errorCode: string,
  name: string,
  pin: string
};

export default ({ children, errorCode, name, pin, email }: AccessErrors) => {
  const errorProps = {
    type: 'ERROR',
    className: styles.errorView
  };

  switch (errorCode) {
    case ERRORS_CODE.PIN_DOES_NOT_MATCH:
      return (
        <Fragment>
          <ErrorView
            {...errorProps}
            header={t('home.access-modal-errors.invalid-pin.title')}
          >
            <p>{t('home.access-modal-errors.invalid-pin.description')}</p>
          </ErrorView>

          {children}
        </Fragment>
      );

    case ERRORS_CODE.PIN_MAX_ATTEMPTS_EXCEEDED:
      return (
        <ErrorView
          {...errorProps}
          header={t('home.access-modal-errors.request-invalid.title')}
        >
          <p>
            {t('home.access-modal-errors.request-invalid.description', {
              name: <b>{name}</b>
            })}
          </p>
        </ErrorView>
      );

    case ERRORS_CODE.DUPLICATION:
      return (
        <Fragment>
          <ErrorView
            {...errorProps}
            header={t('home.access-modal-errors.duplication-pin.title')}
          >
            <p>
              {t('home.access-modal-errors.duplication-pin.description', {
                pin: <b>{pin}</b>
              })}
            </p>
          </ErrorView>

          {children}
        </Fragment>
      );

    case ERRORS_CODE.VALIDATION_ERROR:
      return (
        <Fragment>
          <ErrorView
            {...errorProps}
            header={t('home.access-modal-errors.validation-error.title')}
          >
            <p>
              {t('home.access-modal-errors.validation-error.description', {
                email: <b>{email}</b>
              })}
            </p>
          </ErrorView>

          {children}
        </Fragment>
      );

    default:
      return children;
  }
};
