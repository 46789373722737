// @flow
import { emailSchema } from '../../../../utils/validation';
import type { FormikConfig } from '../../../../utils/types';

export default ({
  mapPropsToValues: (): Object => ({ email: '' }),
  handleSubmit: (values: Object, props: Object): void => {
    const {
      props: {
        actions: {
          auth: { resetPassword }
        }
      }
    } = props;

    resetPassword(values);
  },
  validationSchema: emailSchema,
  validateOnChange: false
}: FormikConfig);
