import { find, path, pipe } from 'ramda';
import Cookies from 'js-cookie';
import browserHistory from '../browserHistory';
import { basePathFinder } from '../hoc/withGoBack';
import { MEMBER_TYPES } from '../config/member.config';

export const accessToDependent18 = (memberType) => {
  return [
    MEMBER_TYPES.SPOUSE,
    MEMBER_TYPES.EMPLOYEE,
    MEMBER_TYPES.DEPENDENT
  ].includes(memberType);
};

const ACCEPT_ACCESS = 'accept-access';

export const getSkippedRequest = () =>
  JSON.parse(Cookies.get(ACCEPT_ACCESS) || '{}');

export const showAcceptAccessModal = (props) => {
  const { hipaaAccesses, params } = props;
  const { pending = [] } = hipaaAccesses || {};

  if (pending.length) {
    for (let i = 0; i < pending.length; i++) {
      const { member, type, initiator } = pending[i] || {};
      const { id } = member || {};

      const allSkippedRequests = getSkippedRequest();

      const skippedRequest = allSkippedRequests[`${type}_${id}`];

      if (!skippedRequest && !initiator) {
        const basePath = basePathFinder(params);
        const { push } = browserHistory;

        const link = type === 'GRANT' ? 'accept-grant-access' : ACCEPT_ACCESS;

        push(`${basePath}/${id}/${link}`);
        break;
      }
    }
  }
};

export const setSkippedRequest = ({ type, id }) => {
  const obj = getSkippedRequest();
  Cookies.set(ACCEPT_ACCESS, { ...obj, [`${type}_${id}`]: true });
};

export const getFamilyMemberFromProps = (state, familyMemberId) =>
  pipe(
    path(['member', 'data', 'member', 'familyMembers']),
    find(({ id }) => id === +familyMemberId)
  )(state);

export default (state, familyMemberId) =>
  pipe(
    path(['member', 'data', 'member', 'hipaaAccesses', 'pending']),
    find(({ member: { id } }) => id === +familyMemberId)
  )(state);
