// @flow
import React, { type Node } from 'react';
import { CARD_TYPES } from '../../../../../../config/payment.config';
import type { Formik } from '../../../../../../utils/types';

const path1 = [
  'M0,1.99728341 L2.14338747e-05,14.0026779 C2.14338747e-05,15.1050859 0.893693392,15.9987675 1.99610136',
  ',15.9987793 L25.0035344,15.9990692 C26.1059507,15.9990811 26.9996454,15.1054056 26.9996572,14.0029893 C26',
  '.9996572,14.0029821 26.9996572,14.002975 26.9996572,14.0029678 L26.9997231,1.99610136 C26.9997231,0.893685',
  '022 26.1060381,-1.99644804e-14 25.0036218,-1.97619698e-14 C25.0035927,-1.97619645e-14 25.0035635,6.37629872',
  'e-10 25.0035344,1.91292937e-09 L1.99610137,0.00118204498 C0.893719152,0.00123030723 8.73911576e-05,0.894901',
  '192 8.73911576e-05,1.99728341 Z M3.21427895,4.65485021 L5.78411053,4.65485021 C6.13070526,4.66790284 6.40',
  '994211,4.77695547 6.50628947,5.14285021 L7.06518947,7.79758705 C6.51609474,6.40895547 5.19281053,5.2535870',
  '5 3.19296316,4.77527126 L3.21427895,4.65485021 Z M6.32382632,10.709587 L4.83939474,5.70074494 C5.90646316',
  ',6.37527126 6.81536842,7.44558705 7.13553158,8.19042915 L7.30861579,8.80895547 L8.93202632,4.71253442 L10',
  '.6884474,4.71253442 L8.07811579,10.7083239 L6.32382632,10.709587 Z M11.9793316,10.7137976 L10.3218158,10.7',
  '137976 L11.3581895,4.70621863 L13.0165579,4.70621863 L11.9793316,10.7137976 Z M14.8637842,10.8039028 C14.1',
  '207158,10.7963239 13.4049316,10.6510607 13.0169842,10.4830607 L13.2501789,9.13863968 L13.4637632,9.234218',
  '63 C14.0081684,9.45863968 14.3607316,9.54958705 15.0245053,9.54958705 C15.5007,9.54958705 16.011,9.365587',
  '05 16.0156895,8.96306073 C16.0186737,8.69990284 15.8021053,8.51211336 15.1566632,8.21737652 C14.5274211,7.',
  '93021863 13.6944,7.44853442 13.7046316,6.58579758 C13.7135842,5.41821863 14.8680474,4.60348179 16.5063789,',
  '4.60348179 C17.1484105,4.60348179 17.6629737,4.7340081 17.9912368,4.85569231 L17.7674211,6.1580081 L17.617',
  '7842,6.08853442 C17.3125421,5.96727126 16.9199053,5.85021863 16.3772053,5.85863968 C15.7283526,5.85863968 1',
  '5.4273737,6.12558705 15.4273737,6.37527126 C15.4239632,6.65653442 15.7786579,6.84179758 16.3584474,7.12053',
  '442 C17.3146737,7.549166 17.7567632,8.069166 17.7507947,8.75253442 C17.7380053,9.99885021 16.6069895,10.8',
  '039028 14.8637842,10.8039028 Z M22.0472053,10.7163239 C22.0472053,10.7163239 21.8958632,10.0257976 21.8455',
  '579,9.81611336 C21.6042632,9.81611336 19.9126421,9.81358705 19.7225053,9.81358705 C19.6581316,9.97569231 1',
  '9.3742053,10.7163239 19.3742053,10.7163239 L17.6335579,10.7163239 L20.0946789,5.21106073 C20.2694684,4.819',
  '90284 20.5657579,4.71253442 20.9630842,4.71253442 L22.2437368,4.71253442 L23.5853526,10.7159028 L22.0472',
  '053,10.7163239 Z'
].join('');

const path2 = [
  'M21.5799632,6.51789474 L21.4682684,6 C21.3838579,6.22610526 21.2384842,6.58947368 21.2478632,6.57347368 C2',
  '1.2478632,6.57347368 20.7239211,7.90694737 20.5875,8.25263158 L21.9640737,8.25263158 C21.8975684,7.951578',
  '95 21.5799632,6.51789474 21.5799632,6.51789474 Z'
].join('');

const path3 = [
  'M16.4710744,4.49296987 C15.6702457,4.49296987 14.9366494,4.79565911 14.3699115,5.29570617 C14.8516922,6.02',
  '63736 15.134068,6.90894003 15.134068,7.85882353 C15.134068,8.80870703 14.8516922,9.69127346 14.3699115,10.',
  '4219409 C14.9366494,10.9219879 15.6702457,11.2246772 16.4710744,11.2246772 C18.261517,11.2246772 19.71809,',
  '9.71479484 19.71809,7.85882353 C19.71809,6.00285222 18.261517,4.49296987 16.4710744,4.49296987 Z'
].join('');

const path4 = [
  'M0,2.31096382 L0.000546250252,13.8111521 C0.000546250252,15.0511572 1.00560905,16.0564645 2.2456141,16.056',
  '7661 L24.7540077,16.063333 C25.994226,16.0636347 26.9998662,15.0584836 27.0001678,13.8182652 C27.0001679,13',
  '.8180832 27.0001679,13.8179011 27.0001679,13.8177191 L26.9997895,2.30894713 C26.9997895,1.06872875 25.9943',
  '938,0.0633330988 24.7541755,0.0633330988 C24.7541196,0.0633330988 24.7540636,0.0633331009 24.7540077,0.0633',
  '33105 L2.24561404,0.0653497944 C1.00546116,0.0654424254 0.000167738459,1.07081094 0.000167738459,2.3109638',
  '2 Z M16.4710744,12.4126255 C15.3770211,12.4126255 14.3758707,11.9949429 13.6060606,11.3065664 C12.8362505,1',
  '1.9948637 11.8351001,12.4126255 10.7410468,12.4126255 C8.31869679,12.4126255 6.34802571,10.3698296 6.348025',
  '71,7.85882353 C6.34802571,5.3478175 8.31869679,3.30502152 10.7410468,3.30502152 C11.8351001,3.30502152 12',
  '.8362505,3.72270416 13.6060606,4.41108063 C14.3758707,3.72278336 15.3770211,3.30502152 16.4710744,3.3050215',
  '2 C18.8934244,3.30502152 20.8640955,5.3478175 20.8640955,7.85882353 C20.8640955,10.3698296 18.8934244,12',
  '.4126255 16.4710744,12.4126255 Z'
].join('');

const path5 = [
  'M19.4546074,6 L16.875,6 L16.875,6.99998438 L17.9999824,6.99998438 C18.3104824,6.99998438 18.5625,7.224 18.',
  '5625,7.5 C18.5625,7.776 18.3104824,8.00001562 17.9999824,8.00001562 L16.875,8.00001562 L16.875,9 L19.45',
  '46074,9 L21.1421074,7.5 L19.4546074,6 Z'
].join('');

const path6 = [
  'M0,2.22222222 L0,13.7777778 C1.50300834e-16,15.0050772 0.994922778,16 2.22222222,16 L24.7777778,16 C26.0050',
  '772,16 27,15.0050772 27,13.7777778 L27,2.22222222 C27,0.994922778 26.0050772,2.18637959e-16 24.7777778,4.',
  '4408921e-16 L2.22222222,0 C0.994922778,2.2545125e-16 2.93788376e-16,0.994922778 4.4408921e-16,2.22222222 Z ',
  'M14.6249824,9.50001562 C14.6249824,9.77601562 14.3729648,10.0000312 14.0624648,10.0000312 C13.7519648,10.00',
  '00312 13.4999473,9.77601562 13.4999473,9.50001562 L13.4999473,7.24303125 L11.7269648,9.76504687 C11.522197',
  '3,10.0570312 10.9777148,10.0570312 10.7729473,9.76504687 L8.99996484,7.24303125 L8.99996484,9.50001562 C8.9',
  '9996484,9.77601562 8.74794727,10.0000312 8.43744727,10.0000312 L7.31246484,10.0000312 C7.05485742,10.0000312',
  ' 6.82873242,9.84403125 6.766875,9.62104687 L6.59142773,9 L3.93751758,9 C3.86775,9 3.80367773,8.985 3.74176758,8.964',
  '98437 L3.31541016,9.72398437 C3.216375,9.89896875 3.01841016,9.99998437 2.81253516,9.99998437 C2.72816016,9.999',
  '98437 2.64267773,9.98296875 2.56051758,9.94696875 C2.28260742,9.82298437 2.17017773,9.5229375 2.3085,9.27',
  '6 L4.55851758,5.27601562 C4.65528516,5.10703125 4.849875,5.00001562 5.0625,5.00001562 L6.18748242,5.00001',
  '562 C6.44508984,5.00001562 6.67121484,5.15601562 6.73307227,5.379 L7.752375,9 L7.87498242,9 L7.87498242,5.',
  '49998437 C7.87498242,5.277 8.04035742,5.08096875 8.28225,5.01998437 C8.52414258,4.95796875 8.78064258,5.0',
  '46 8.91453516,5.235 L11.2500352,8.55698437 L13.5855352,5.235 C13.7171602,5.046 13.9748203,4.95796875 14.2',
  '178203,5.01998437 C14.4597129,5.08096875 14.6250879,5.27695312 14.6250879,5.49998437 L14.6250879,9.50001562',
  ' L14.6249824,9.50001562 Z M24.5857676,9.85298438 C24.4755,9.95095313 24.3314824,9.99998438 24.1875176,9.999',
  '98438 C24.0435527,9.99998438 23.8994824,9.95095313 23.7892676,9.85396875 L21.9375,8.20701563 L20.0857324,9.',
  '85303125 C19.98,9.94701563 19.8370898,10.0000313 19.6874824,10.0000313 L16.3124824,10.0000313 C16.0019824,1',
  '0.0000313 15.7499648,9.77601563 15.7499648,9.50001563 L15.7499648,5.50003125 C15.7499648,5.22403125 16.0019',
  '824,5.00001563 16.3124824,5.00001563 L19.6874824,5.00001563 C19.8370898,5.00001563 19.98,5.05303125 20.085',
  '7324,5.14603125 L21.9375,6.792 L23.7892676,5.14598438 C24.0086426,4.95098437 24.3652852,4.95098437 24.58466',
  '02,5.14598438 C24.8040352,5.34098438 24.8040352,5.65795313 24.5846602,5.853 L22.7328926,7.5 L24.5857676,9',
  '.14601563 C24.8051426,9.34101563 24.8051426,9.65798438 24.5857676,9.85298438 Z'
].join('');

const path7 = [
  'M95.22,0 L117.78,0 C119.006072,-2.25225799e-16 120,0.993927855 120,2.22 L120,13.78 C120,15.0060721 119.006072,',
  '16 117.78,16 L95.22,16 C93.9939279,16 93,15.0060721 93,13.78 L93,2.22 C93,0.993927855 93.9939279,6.69315009e-',
  '16 95.22,4.4408921e-16 Z M98.9300945,5.01225618 L97,5.01225618 L97,12 L98.919762,12 C99.9385991,12 100.67568',
  ',11.7494187 101.321679,11.1932947 C102.088021,10.5332804 102.543259,9.53717889 102.543259,8.50871338 C102.54',
  '3259,6.44575001 101.058069,5.01225618 98.9300945,5.01225618 Z M100.359887,9.82519903 C100.017091,10.1185812 99',
  '.5761799,10.244207 98.8746984,10.244207 L98.5841727,10.244207 L98.5841727,6.76613411 L98.8746984,6.76613411 C',
  '99.5760931,6.76613411 100.00042,6.88400405 100.359887,7.19040845 C100.735157,7.50648374 100.959173,7.9952945 ',
  '100.959173,8.49990425 C100.959173,9.00460974 100.73507,9.51180478 100.359887,9.82519903 Z M103.336517,11.999',
  '0425 L104.908794,11.9990425 L104.908794,5 L103.336517,5 L103.336517,11.9990425 Z M108.478371,7.70555084 C107.',
  '674867,7.41571143 107.438956,7.22277241 107.438956,6.8630345 C107.438956,6.44220721 107.860157,6.11923782 108',
  '.436433,6.11923782 C108.837838,6.11923782 109.166307,6.27990863 109.517091,6.6613821 L110.21536,5.76830903 C1',
  '09.638998,5.27595546 108.951061,5.02451235 108.19974,5.02451235 C106.988493,5.02451235 106.062214,5.84778267 1',
  '06.062214,6.94164638 C106.062214,7.86708341 106.493662,8.33846743 107.748497,8.78026427 C108.272589,8.9609471',
  '2 108.539411,9.08054058 108.673125,9.16269526 C108.940729,9.33380297 109.075312,9.57298991 109.075312,9.8541',
  '1594 C109.075312,10.3979837 108.631969,10.7987033 108.035896,10.7987033 C107.398581,10.7987033 106.884821,1',
  '0.4887561 106.576061,9.90563018 L105.713948,10.7210489 C106.328255,11.6036851 107.069243,11.9974147 108.0873',
  '85,11.9974147 C109.475154,11.9974147 110.451271,11.0920855 110.451271,9.79647361 C110.451185,8.73219708 109',
  '.999941,8.25028041 108.478371,7.70555084 Z M112.839643,8.49559544 C112.839643,7.40776407 113.635246,6.54964025',
  ' 114.647831,6.54964025 C115.16081,6.54964025 115.551882,6.73645118 116,7.18609964 L115.999219,5.42082729 C115',
  '.471219,5.13271141 115.034996,5.01311794 114.516461,5.01311794 C112.690907,5.01311794 111.227859,6.56361995 11',
  '1.227859,8.50344705 C111.227859,10.4659672 112.655221,11.9850628 114.489544,11.9850628 C115.00808,11.9850628 ',
  '115.452985,11.8759062 115.999219,11.5965995 L116,9.83046535 C115.567771,10.273124 115.186945,10.4512215 114.',
  '696889,10.4512215 C113.61076,10.4512215 112.839643,9.64279266 112.839643,8.49559544 Z'
].join('');

export default (props: Formik): Node => {
  const {
    formik: {
      values: { cardType }
    }
  } = props;

  return (
    <g id="Payment-cards" mask="url(#mask-5)">
      <g transform="translate(22.000000, 20.200000)">
        <g
          id="visa-pay-logo"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
          transform="translate(31.000000, 0.000000)"
        >
          <path
            d={path1}
            id="Shape1"
            fill={cardType === CARD_TYPES.VISA.KEY ? '#25a584' : '#6D7288'}
            fillRule="nonzero"
          />
          <path
            d={path2}
            id="Shape2"
            fill={cardType === CARD_TYPES.VISA.KEY ? '#25a584' : '#6D7288'}
            fillRule="nonzero"
          />
        </g>
        <g id="mastercard" strokeWidth="1" fill="none" fillRule="evenodd">
          <path
            d={path3}
            id="XMLID_12_"
            fill={
              cardType === CARD_TYPES.MASTER_CARD.KEY ? '#25a584' : '#6D7288'
            }
            fillRule="nonzero"
          />
          <path
            d={path4}
            id="XMLID_13_"
            fill={
              cardType === CARD_TYPES.MASTER_CARD.KEY ? '#25a584' : '#6D7288'
            }
            fillRule="nonzero"
          />
        </g>
        <g
          id="amex"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
          transform="translate(62.000000, 0.000000)"
        >
          <path
            d={path5}
            id="Shape3"
            fill={cardType === CARD_TYPES.AMEX.KEY ? '#25a584' : '#6D7288'}
            fillRule="nonzero"
          />
          <polygon
            id="Shape4"
            fill={cardType === CARD_TYPES.AMEX.KEY ? '#25a584' : '#6D7288'}
            fillRule="nonzero"
            points="5.747625 6 5.410125 6 4.28508984 8.00001562 6.31014258 8.00001562"
          />
          <path
            d={path6}
            id="Shape5"
            fill={cardType === CARD_TYPES.AMEX.KEY ? '#25a584' : '#6D7288'}
            fillRule="nonzero"
          />
        </g>
        <path
          d={path7}
          id="Discover"
          fill={cardType === CARD_TYPES.DISCOVER.KEY ? '#25a584' : '#6D7288'}
          fillRule="nonzero"
        />
      </g>
    </g>
  );
};
