// @flow
import * as FileSaver from 'file-saver';

export default (data: string, fileName: string): void => {
  const isChromeIOS: boolean = /CriOS\/[\d]+/.test(navigator.userAgent);

  const blob: Blob = new Blob([data], { type: 'application/pdf' });

  if (isChromeIOS) {
    const reader: FileReader = new FileReader();
    reader.onload = () => {
      window.location.href = window.URL.createObjectURL(blob);
    };
    reader.readAsDataURL(blob);
  } else {
    FileSaver.saveAs(blob, `${fileName}.pdf`);
  }
};
