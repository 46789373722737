// @flow
import Home from './components/Home';
import { withAccessModals, withAppLayout } from '../../hoc';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

import {
  withHandleModals,
  withHeaderConfig,
  withStatusConfig,
  withRoutesConfig
} from './hoc';

export default withAppLayout([
  withLDConsumer(),
  withRoutesConfig,
  withHeaderConfig,
  withStatusConfig,
  withHandleModals,
  withAccessModals
])(Home);
