// @flow
import { withProps, type HOC } from 'recompose';
import { MEMBER_STATUS } from '../../../config/member.config';

export default (withProps((props: Object): Object => {
  const {
    member: {
      data: {
        familyMember: { balanceDetails }
      }
    }
  } = props;

  const {
    balance,
    status,
    lastPaymentId,
    buyClaimOptOut,
    dueDate,
    minimumPaymentDue,
    recurringPayment
  } = balanceDetails || {};

  const ACTIVE_OR_ACTIVE_BALANCE: boolean =
    status === MEMBER_STATUS.ACTIVE || status === MEMBER_STATUS.ACTIVE_BALANCE;

  const OPT_OUT: boolean = ACTIVE_OR_ACTIVE_BALANCE && buyClaimOptOut;

  const ACTIVE_WITH_NO_CLAIMS: boolean =
    ACTIVE_OR_ACTIVE_BALANCE &&
    balance === 0 &&
    dueDate === null &&
    minimumPaymentDue === null &&
    lastPaymentId === null &&
    !OPT_OUT;

  const BALANCE_PAID_IN_FULL: boolean =
    ACTIVE_OR_ACTIVE_BALANCE &&
    ((balance === 0 && dueDate !== null && minimumPaymentDue !== null) ||
      (balance === 0 && lastPaymentId !== null)) &&
    !OPT_OUT;

  const INACTIVE_BALANCE: boolean = status === MEMBER_STATUS.INACTIVE_BALANCE;
  const WAITING: boolean = status === MEMBER_STATUS.WAITING;
  const DEFAULTED: boolean = status === MEMBER_STATUS.DEFAULTED;
  const INACTIVE: boolean = status === MEMBER_STATUS.INACTIVE;

  const NO_BALANCE: boolean =
    INACTIVE || WAITING || BALANCE_PAID_IN_FULL || ACTIVE_WITH_NO_CLAIMS;

  const FAILED_RECURRING: boolean =
    recurringPayment &&
    recurringPayment.lastPaymentTransaction &&
    recurringPayment.lastPaymentTransaction.failed;

  return {
    status: {
      OPT_OUT,
      ACTIVE_WITH_NO_CLAIMS,
      BALANCE_PAID_IN_FULL,
      INACTIVE_BALANCE,
      WAITING,
      DEFAULTED,
      INACTIVE,
      NO_BALANCE,
      FAILED_RECURRING
    }
  };
}): HOC<*, Object>);
