// @flow
import React, { type AbstractComponent, type Node } from 'react';
import classNames from 'classnames';
import styles from './shadow-box.module.css';

type boxConfig = {
  small: boolean,
  active: boolean
};

type Props = {
  boxConfig: boxConfig
};

export default (Component: AbstractComponent<Object>) => (
  props: Props
): Node => {
  const { boxConfig: { small = true, active = false } = {} } = props;

  const shadowBoxStyles: string = classNames({
    [styles.shadowBox]: true,
    [styles.small]: small,
    [styles.active]: active
  });

  return (
    <div className={shadowBoxStyles}>
      <Component {...props} />
    </div>
  );
};
