import React from 'react';
import { Col, Form, FormFeedback, Row } from 'reactstrap';
import classNames from 'classnames';
import { Button, t } from '../../../../components';
import styles from '../../payment.module.css';
import PaymentDay from '../PaymentDate/PaymentDay';
import CalendarPicker from './CalendarPicker';

export default (props) => {
  const {
    formik: { handleSubmit, errors }
  } = props;

  const submitStyles = classNames(styles.submitButton, 'align-items-end');
  const legendCalendarStyles = classNames(styles.dates, styles.calendarLegend);
  return (
    <Form onSubmit={handleSubmit}>
      <Row>
        <Col className={styles.dates}>
          <CalendarPicker {...props} startDate={new Date()} />
          {errors.schedulePaymentDate && (
            <FormFeedback className={styles.dateError}>
              {t(errors.schedulePaymentDate)}
            </FormFeedback>
          )}
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={6} className={legendCalendarStyles}>
          <PaymentDay showTitle={false} label="home.past-days" disabled small />
          <PaymentDay
            showTitle={false}
            label="payment.payment-due-date"
            dueDate
            small
            disabled
          />
          <PaymentDay
            showTitle={false}
            label="payment.payment-available-days"
            small
            disabled
          />
        </Col>
        <Col sm={12} md={6} className={submitStyles}>
          <Button primary type="submit" title="payment.to-payment-amount" />
        </Col>
      </Row>
    </Form>
  );
};
