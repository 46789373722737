import {
  setSkippedRequest,
  showAcceptAccessModal
} from '../../../../utils/getMemberAccessFromState';

export const ACCESS_TYPE = {
  GRANT: 'GRANT',
  REQUEST: 'REQUEST'
};

export const handleGoBack = async ({
  type,
  handlers: { goBack },
  match: {
    params,
    params: { familyMemberId }
  },
  member: {
    data: {
      member: { hipaaAccesses }
    }
  }
}) => {
  setSkippedRequest({ type, id: familyMemberId });

  await goBack();

  showAcceptAccessModal({ hipaaAccesses, params });
};

export const denyAccess = ({
  type,
  actions: { member },
  handlers: { goBack },
  match: {
    params,
    params: { familyMemberId }
  }
}) => {
  const action =
    type === ACCESS_TYPE.GRANT ? member.rejectGrantAccess : member.rejectAccess;

  action(familyMemberId).then(async ({ hipaaAccesses }) => {
    await goBack();

    showAcceptAccessModal({ hipaaAccesses, params });
  });
};

const TYPES = {
  PIN_MAX_ATTEMPTS_EXCEEDED: 'PIN_MAX_ATTEMPTS_EXCEEDED',
  PIN_DOES_NOT_MATCH: 'PIN_DOES_NOT_MATCH',
  PIN_CONFIRMED: 'PIN_CONFIRMED',
  DUPLICATION: 'DUPLICATION',
  VALIDATION_ERROR: 'VALIDATION_ERROR'
};

export const hasHIPPAError = (props) => {
  const { data } = props || {};
  const { error } = data || {};
  const { code } = error || {};
  return Boolean(TYPES[code]);
};

export default TYPES;
