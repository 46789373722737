import React, { useState } from 'react';
import {
  Nav,
  Navbar,
  NavItem,
  NavLink,
  NavbarToggler,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { t } from 'components/i18n';
import ROUTES from 'config/routes.config';
import classNames from 'classnames';
import styles from 'components/Dropdown/dropdowm.module.css';
import { GET_SCREEN_SIZE, SCREEN_SIZES } from 'config';

const Navigation = (props) => {
  const {
    location: { pathname },
    history: { push },
    member: {
      data: {
        member: { age, status }
      }
    },
    dropDownConfig: {
      settings: { items }
    }
  } = props;

  const SCREEN_SIZE = GET_SCREEN_SIZE();
  const IS_MOBILE_VIEW = SCREEN_SIZE === SCREEN_SIZES.MOBILE;

  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);
  const active = (path, exact = false) => {
    return exact
      ? classNames({ active: pathname === path })
      : classNames({ active: pathname.includes(path) });
  };

  const menu = items.map((menuItem, i) => {
    const { title, onClick, link, cy, divider } = menuItem;
    const key = `${title}${i}`;
    const label = t(title);

    switch (true) {
      case !!divider:
        return <DropdownItem divider key={`divider${i}`} />;
      case !!link:
        return (
          <DropdownItem
            tag={Link}
            to={link}
            data-cy={cy}
            role="menuitem"
            aria-label={label}
            key={key}
          >
            {label}
          </DropdownItem>
        );
      case !!onClick:
        return (
          <DropdownItem
            onClick={(e) => onClick(e, { index: i, ...menuItem })}
            role="menuitem"
            key={key}
          >
            {label}
          </DropdownItem>
        );
      default:
        return (
          <DropdownItem role="menuitem" key={key}>
            {label}
          </DropdownItem>
        );
    }
  });

  return !IS_MOBILE_VIEW ? (
    <div>
      <Navbar light expand="md">
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav d-flex justify-content-center tabs>
            <NavItem>
              <NavLink
                className={active(ROUTES.BASE_PATH, true)}
                onClick={() => {
                  push(ROUTES.BASE_PATH);
                }}
              >
                {t('common.home')}
              </NavLink>
            </NavItem>
            {age > 17 &&
              status !== 'INACTIVE' &&
              status !== 'INACTIVE_BALANCE' && (
                <NavItem>
                  <NavLink
                    className={active(`membership-card`)}
                    onClick={() => {
                      push(`${pathname}/membership-card`);
                    }}
                  >
                    {t('home.membership-card')}
                  </NavLink>
                </NavItem>
              )}
            <NavItem>
              <NavLink
                className={active(`membership-resources`)}
                onClick={() => {
                  push(`/member/me/membership-resources`);
                }}
              >
                {t('home.membership-resources')}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={active(`about`)}
                onClick={() => {
                  push(`${pathname}/about`);
                }}
              >
                {t('home.about')}
              </NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  ) : (
    <div className={styles.mobileNav}>
      <Nav navbar>
        <UncontrolledDropdown className={styles.dropDown}>
          <DropdownToggle outline color="link" className={styles.mobileMenu} />
          <DropdownMenu>
            <DropdownItem
              onClick={() => {
                push(ROUTES.BASE_PATH);
              }}
            >
              {t('common.home')}
            </DropdownItem>
            {age > 17 &&
              status !== 'INACTIVE' &&
              status !== 'INACTIVE_BALANCE' && (
                <DropdownItem
                  onClick={() => {
                    push(`${pathname}/membership-card`);
                  }}
                >
                  {t('home.membership-card')}
                </DropdownItem>
              )}
            <DropdownItem
              onClick={() => {
                push('/member/me/membership-resources');
              }}
            >
              {t('home.membership-resources')}
            </DropdownItem>
            <DropdownItem
              onClick={() => {
                push(`${pathname}/about`);
              }}
            >
              {t('home.about')}
            </DropdownItem>
            <DropdownItem divider />
            {menu}
          </DropdownMenu>
        </UncontrolledDropdown>
      </Nav>
    </div>
  );
};

export default Navigation;
