// @flow
import React, { type Node } from 'react';
import { Col, Form, Row, FormGroup } from 'reactstrap';
import { compose } from 'recompose';
import { t, Button, EnhancedRadio } from '../../../components';
import { withFormik } from '../../../hoc';
import { withStepSummary, withCheckHandlers } from '../hoc';
import { useGetPaymentOptions } from '../hooks';
import { PAYMENT_METHODS } from '../../../config/payment.config';
import methodFormConfig from './methodFormConfig';
import styles from '../payment.module.css';
import type { Formik } from '../../../utils/types';
import type { Handlers, Step } from '../../../models/payment/types';

type Props = {
  handlers: Handlers,
  formik: Formik,
  step: Step
};

const PaymentMethod = (props: Props): Node => {
  const {
    handlers: { helperTextStyle },
    formik: {
      setFieldValue,
      values: { method },
      handleSubmit
    },
    step
  } = props;

  useGetPaymentOptions(props);

  return (
    <Form onSubmit={handleSubmit}>
      <Row>
        <Col>
          <FormGroup tag="fieldset">
            <legend className="sr-only">{t(step.header)}</legend>
            <EnhancedRadio
              name="method"
              id="paymentMethodCard"
              label="payment.CARD"
              value={PAYMENT_METHODS.CARD}
              checked={method === PAYMENT_METHODS.CARD}
              onChange={() => setFieldValue('method', PAYMENT_METHODS.CARD)}
            >
              <Row className={styles.cards}>
                <Col className={styles.labelText}>{t('payment.CARD')}</Col>
              </Row>
            </EnhancedRadio>
            <EnhancedRadio
              name="method"
              id="paymentMethodEcheck"
              label="payment.ECHECK"
              value={PAYMENT_METHODS.ECHECK}
              checked={method === PAYMENT_METHODS.ECHECK}
              onChange={() => setFieldValue('method', PAYMENT_METHODS.ECHECK)}
            >
              <Row>
                <Col md={4} className={styles.labelText}>
                  {t('payment.ECHECK')}
                </Col>
                <Col md={8} className={styles.selectContent}>
                  <p
                    className={helperTextStyle(method, PAYMENT_METHODS.ECHECK)}
                  >
                    {t('payment.echeck-helper')}
                  </p>
                </Col>
              </Row>
            </EnhancedRadio>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col className={styles.submitButton}>
          <Button primary type="submit" title="payment.to-payment-details" />
        </Col>
      </Row>
    </Form>
  );
};

export default compose(
  withFormik(methodFormConfig),
  withCheckHandlers,
  withStepSummary
)(PaymentMethod);
