// @flow
import { compose, withProps, withHandlers, type HOC } from 'recompose';
import { ACCOUNT_TYPES } from '../../../config/payment.config';
import withNamespace from '../../../hoc/withNamespace';
import type { Formik } from '../../../utils/types';

export default (compose(
  withHandlers({
    switchAccountType: ({ formik: { setFieldValue } }: Formik) => (
      e: Event,
      { value }: Object
    ): void => setFieldValue('accountType', value, false)
  }),
  withProps(({ switchAccountType }) => {
    const accountTypeButtonsConfig: Array<Object> = Object.keys(
      ACCOUNT_TYPES
    ).map((value: string): Object => ({
      title: `payment.${value}`,
      value: ACCOUNT_TYPES[value],
      onClick: switchAccountType
    }));

    return { accountTypeButtonsConfig };
  }),
  withNamespace('handlers', ['switchAccountType']),
  withNamespace('payment', ['accountTypeButtonsConfig'])
): HOC<*, any>);
