// @flow
import React, { type Node } from 'react';
import { Col, Row } from 'reactstrap';
import classNames from 'classnames';
import { t } from 'components';
import styles from './status.module.css';

type Props = {
  icon?: string,
  header: Node | string,
  children: Node,
  className?: string
};

export default (props: Props): Node => {
  const { header, className, icon = 'success', children } = props;

  const statusStyles: string = classNames(
    styles.status,
    styles[icon],
    className
  );
  const messageStyles: string = classNames({
    [styles.message]: true,
    [styles.withContent]: !children
  });

  return (
    <div className={statusStyles} data-testid="status-component">
      <Row>
        <Col>
          <h1 className={messageStyles}>
            {typeof header === 'string' ? t(header) : header}
          </h1>
        </Col>
      </Row>
      {children && (
        <Row>
          <Col>
            <div className={styles.description}>{children}</div>
          </Col>
        </Row>
      )}
    </div>
  );
};
