// @flow
import state from './state';
import reducers from './reducers';
import effects from './effects';

export default {
  state,
  reducers,
  effects
};
