// @flow
import React, { Fragment, type AbstractComponent, type Node } from 'react';
import { compose, withProps, type HOC } from 'recompose';
import { Modal, Loader } from '../../components';
import withMapStateDispatch from '../withMapStateDispatch';
import type { Models } from '../../utils/types';

const withLoader = (Component: AbstractComponent<Object>) => (
  props: Models
): Node => {
  const {
    loader: { loading }
  } = props;

  return (
    <Fragment>
      <Component {...props} />
      {loading && <Modal {...props} />}
    </Fragment>
  );
};

export default (compose(
  withMapStateDispatch,
  withProps(
    (): Object => ({
      modalConfig: {
        size: 'sm',
        body: {
          render: Loader
        }
      }
    })
  ),
  withLoader
): HOC<*, any>);
