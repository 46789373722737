import React, { Fragment, type Node } from 'react';
import { Link } from 'react-router-dom';
import { date, number, time } from '../../../components';

export const parseAmount = (value) =>
  Math.sign(value) === 1 ? <span>+{number(value)}</span> : number(value);

export const parseAmountAbs = (value) => <span>{number(Math.abs(value))}</span>;

export const truncateDescription = (str, maxLength) =>
  str.length > maxLength ? `${str.substring(0, maxLength - 1)}...` : str;

export const formatDate: Function = (value: string): Node =>
  date({
    value,
    day: '2-digit',
    month: '2-digit',
    year: 'numeric'
  });

export const linkTo: Function = (to, value, hash = false): Node => (
  <Link to={to}>{hash ? `#${value}` : formatDate(value)}</Link>
);

export const dateTime: Function = (value: string): Node => (
  <Fragment>
    <span className="pr-1">{formatDate(value)}</span>
    <span>{time(value, 'HH:mm A')}</span>
  </Fragment>
);

export const parseDiscount: Function = (value: number | string): Node =>
  value && value !== 0 ? parseAmount(+value) : null;

export const parsePeriod: Function = (values: Object): Node => {
  const { datePeriodDto } = values;
  const { startDate, endDate } = datePeriodDto || {};

  return (
    <span>
      {formatDate(startDate)} - {formatDate(endDate)}
    </span>
  );
};

export const OOP_TYPE = {
  COPAYMENT: 'Co-Payment',
  DEDUCTIBLE_COINSURANCE: 'Deductible/Co-Insurance'
};
