// @flow
import { useEffect, useRef, useState } from 'react';
import type { RefObject } from '../../utils/types';

export default (): Object => {
  const inputEl: RefObject = useRef(null);
  const [isOpen, setOpen]: Object = useState(false);

  const clickOutside = (event: Event, element): void =>
    setOpen(!(element && !element.contains(event.target)));

  useEffect((): Function => {
    document.addEventListener('mousedown', (e: Event): void =>
      clickOutside(e, inputEl.current)
    );
    return (): void => document.removeEventListener('mousedown', clickOutside);
  }, [inputEl]);

  return { inputEl, isOpen, setOpen };
};
