import React, { useState, useEffect, Fragment } from 'react';
import { FormGroup, Input, Label, Col, Row } from 'reactstrap';
import { t, number } from '../../../../components';
import styles from '../../payment.module.css';

export default (props) => {
  const {
    formik,
    payment: {
      maxAmount,
      maxAmountMin,
      maxAmountFull,
      maxAmountLastStatement,
      editMode
    }
  } = props;

  const { changeHandler, errors, setFieldValue, values } = formik;

  const [isChecked, setChecked] = useState(false);

  let inputName;
  let paymentValue;
  switch (values.type) {
    case 'MINIMUM_PAYMENT_DUE':
      inputName = 'maxAmountMin';
      paymentValue = values.maxAmountMin;
      break;
    case 'FULL_STATEMENT':
      inputName = 'maxAmountLastStatement';
      paymentValue = values.maxAmountLastStatement;
      break;
    default:
      inputName = 'maxAmountFull';
      paymentValue = values.maxAmountFull;
      break;
  }

  useEffect(() => {
    (maxAmountFull && values.type === 'FULL') ||
    (maxAmountMin && values.type === 'MINIMUM_PAYMENT_DUE') ||
    (maxAmountLastStatement && values.type === 'FULL_STATEMENT')
      ? setChecked(true)
      : setChecked(false);
  }, [values.type, maxAmountMin, maxAmountFull, maxAmountLastStatement]);

  useEffect((): void => {
    if (editMode && maxAmount) {
      setChecked(true);
    }
  }, []);

  const resetOtherMaxAmounts = (targetInput) => {
    const maxAmounts = [
      'maxAmountFull',
      'maxAmountMin',
      'maxAmountLastStatement'
    ];
    return maxAmounts.filter((type) => type !== targetInput);
  };

  return (
    <Fragment>
      <Row>
        <Col>
          <FormGroup check className="maxAmount custom-checkbox my-2">
            <Input
              type="checkbox"
              name="useMaximum"
              id="useMaximum"
              className="custom-control-input"
              value={isChecked}
              checked={isChecked}
              onChange={() => {
                setChecked(!isChecked);
                setFieldValue(inputName, '', true);
                resetOtherMaxAmounts(inputName).forEach((el) =>
                  setFieldValue(el, '', true)
                );
              }}
            />
            <Label for="useMaximum" className="custom-control-label">
              Not to Exceed Payment Limit
            </Label>
            {isChecked && (
              <Fragment>
                <div id={styles.maxAmountDollarSign}>
                  <p>$</p>
                </div>
                <Input
                  type="text"
                  id={styles.maxAmount}
                  name={inputName}
                  value={paymentValue}
                  onChange={changeHandler}
                  {...formik}
                />
              </Fragment>
            )}
            {errors[inputName] &&
              errors[inputName].title === 'errors.min-amount' && (
                <div id={styles.maxAmount_error}>
                  {t('errors.min-amount', { min: <b>{number(1)}</b> })}
                </div>
              )}
            {errors[inputName] &&
              errors[inputName].title === 'errors.max-amount-recurring' && (
                <div id={styles.maxAmount_error}>
                  {t('errors.max-amount-recurring', {
                    max: <b>{number(10000)}</b>
                  })}
                </div>
              )}
          </FormGroup>
        </Col>
      </Row>
    </Fragment>
  );
};
