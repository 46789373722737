// @flow
import React, { type Node } from 'react';
import { t } from '../i18n';
import Tooltip from '../Tooltip';
import styles from './table.module.css';

export default (config: Object): Node => {
  const { label, className, id, onClick, expand, canExpand } = config;
  const { title, tooltip, quantity } = label || {};

  if (title && canExpand) {
    return (
      <div
        role="button"
        tabIndex="0"
        onKeyPress={onClick}
        onClick={onClick}
        id={id}
        className={`${styles.expandedTableTitle} ${
          expand ? styles.expand : ''
        } ${styles[className]}`}
      >
        {t(title)} {tooltip && <Tooltip {...tooltip} />}{' '}
        {quantity && ` - ${quantity}`}
      </div>
    );
  }

  return (
    title && (
      <h1 className={`${styles.title} ${styles[className]}`}>
        {t(title)} {tooltip && <Tooltip {...tooltip} />}{' '}
        {quantity && ` - ${quantity}`}
      </h1>
    )
  );
};
