// @flow
import 'react-app-polyfill/ie9';
import 'core-js/stable';
import 'nodelist-foreach-polyfill';
import 'intl';
import React from 'react';
import { createRoot } from 'react-dom/client';
import * as ReactGA from 'react-ga';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import history from './browserHistory';
import App from './App';
import CONFIG from './config';
import { checkA11y, whyDidYouRender, unregister } from './utils';

import store from './store';
import './styles/index.css';

declare var module: {
  [hot: string]: {
    accept: (string, Function) => void
  }
};

const isProduction = process.env.NODE_ENV === 'production';
const container: HTMLElement = (document.getElementById('root'): any);
const rootElement = createRoot(container);

!isProduction && whyDidYouRender(React);
!isProduction && checkA11y();

ReactGA.initialize(CONFIG.GOOGLE_API.TRACKING_ID, { debug: !isProduction });

history.listen((location) => {
  setTimeout(() => {
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
  }, 100);
});

const injectGA = () => {
  //  <!-- Google tag (gtag.js) -->
  if (typeof window == 'undefined') {
    return;
  }

  window.dataLayer = window.dataLayer || [];

  function gtag() {
    window.dataLayer.push(arguments);
  }
  gtag('js', new Date());

  gtag('config', 'G-FCVNC29JZK');

  return null;
};

const render = (Root) => {
  rootElement.render(
    <Provider store={store}>
      <script
        async
        src="https://www.googletagmanager.com/gtag/js?id=G-FCVNC29JZK"
      />
      <script>{injectGA()}</script>
      <ConnectedRouter history={history}>
        <Root />
      </ConnectedRouter>
    </Provider>
  );
};

render(App);

if (module.hot) {
  module.hot.accept('./App', () => {
    const NextApp = require('./App').default;
    render(NextApp);
  });
}

unregister();
