import { compose } from 'recompose';
import formConfig from './formConfig';
import { withModal, withMapStateDispatch, withFormik } from 'hoc';
import Form from './Form';

export default compose(
  withModal,
  withMapStateDispatch,
  withFormik(formConfig)
)(Form);
