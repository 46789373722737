// @flow
import React, { type Node } from 'react';
import { Col, Row } from 'reactstrap';
import { t } from '../i18n';
import { ErrorType } from 'utils/types';
import styles from './error.module.css';

type Props = {
  error?: {
    data: ErrorType
  },
  header?: Node,
  children?: Node,
  className?: string,
  type?: string
};

export default (props: Props): Node => {
  const { error = {}, header, children, className, type = 'ERROR' } = props;

  let errorMessage = 'errors.';

  if (error && error.code) {
    errorMessage = `errors.${error.code}_`;
  }
  if (error && error.data) {
    errorMessage =
      error.data.error === null
        ? `errors.${error.data.code}_`
        : `errors.${error.data.error.code}_`;
  }

  return (
    <Row className={className}>
      <Col>
        <div className={`${styles.error} ${styles[type]}`}>
          <h1 className={styles.message}>
            {header || t(`${errorMessage}HEADING`)}
          </h1>
          <div className={styles.errorContent}>
            {children || t(`${errorMessage}MESSAGE`)}
          </div>
        </div>
      </Col>
    </Row>
  );
};
