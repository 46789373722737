// @flow
import React, { Fragment, type Node } from 'react';
import { Col, Row } from 'reactstrap';
import { number, t, EnhancedRadio } from '../../../../components';
import {
  AMOUNT_OPTIONS,
  PAYMENT_TYPE
} from '../../../../config/payment.config';
import styles from '../../payment.module.css';
import MaxAmount from './MaxAmount';
import type { MemberModel } from '../../../../models/member/types';
import type { Handlers, PaymentModel } from '../../../../models/payment/types';
import type { Formik } from '../../../../utils/types';

type Props = {
  member: MemberModel,
  payment: PaymentModel,
  handlers: Handlers,
  formik: Formik
};

export default (props: Props): Node => {
  const {
    currentQuickPay,
    member: {
      data: {
        familyMember: {
          balanceDetails: { minimumPaymentDue }
        }
      }
    },
    payment: { paymentType },
    handlers: { setAmount, isChecked },
    formik: { setFieldValue }
  } = props;

  const oneTimePayment = paymentType === PAYMENT_TYPE.ONE_TIME_PAYMENT;

  const recurringMPD = minimumPaymentDue
    ? minimumPaymentDue.original >= currentQuickPay.amount
      ? currentQuickPay.amount
      : minimumPaymentDue.original
    : null;

  const MPD =
    minimumPaymentDue && oneTimePayment
      ? minimumPaymentDue.remaining
      : recurringMPD;

  return (
    <Fragment>
      <Row>
        <Col>
          <EnhancedRadio
            name="type"
            id="minimumPaymentDue"
            label="payment.minimum-payment-due"
            value={AMOUNT_OPTIONS.MINIMUM_PAYMENT_DUE}
            checked={isChecked(AMOUNT_OPTIONS.MINIMUM_PAYMENT_DUE)}
            onChange={() => {
              setFieldValue('type', AMOUNT_OPTIONS.MINIMUM_PAYMENT_DUE);
              setFieldValue('repaymentAccepted', false, true);
              setAmount(MPD);
            }}
          >
            <Row>
              <Col className={styles.labelText}>
                {t('payment.minimum-payment-due')}
                {oneTimePayment && (
                  <div className={styles.amount}>{number(MPD)}</div>
                )}
                {!oneTimePayment &&
                  isChecked(AMOUNT_OPTIONS.MINIMUM_PAYMENT_DUE) && (
                    <MaxAmount {...props} />
                  )}
              </Col>
              {!oneTimePayment && (
                <Col className={styles.selectContent}>
                  <p className={styles.helperText}>
                    {t('payment.recurring-mpd-message')}
                  </p>
                </Col>
              )}
            </Row>
          </EnhancedRadio>
        </Col>
      </Row>
    </Fragment>
  );
};
