// @flow
import React, { Fragment, type Node } from 'react';
import MemberTile from './MemberTile';

export default (props: Props): Node => {
  return (
    <Fragment>
      <MemberTile {...props} />
    </Fragment>
  );
};
