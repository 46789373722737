// @flow
import { compose, withHandlers, withStateHandlers, type HOC } from 'recompose';
import { withNamespace } from '../../../hoc';
import { CARD_TYPES } from '../../../config/payment.config';
import type { Formik } from '../../../utils/types';

export default (compose(
  withHandlers({
    getCardType: ({ formik: { setFieldValue } }: Formik) => (
      value: string
    ): void => {
      let cardType: Object;

      switch (true) {
        case CARD_TYPES.VISA.REGEXP.test(value):
          cardType = CARD_TYPES.VISA;
          break;
        case CARD_TYPES.MASTER_CARD.REGEXP.test(value):
          cardType = CARD_TYPES.MASTER_CARD;
          break;
        case CARD_TYPES.AMEX.REGEXP.test(value):
          cardType = CARD_TYPES.AMEX;
          break;
        case CARD_TYPES.DISCOVER.REGEXP.test(value):
          cardType = CARD_TYPES.DISCOVER;
          break;
        default:
          cardType = {};
          break;
      }

      setFieldValue('cardType', cardType.KEY, true);
    }
  }),
  withStateHandlers(
    ({ highlightCardNumber = false }: Object) => ({
      highlightCardNumber
    }),
    {
      setHighlight: () => (value) => ({ highlightCardNumber: value })
    }
  ),
  withNamespace('handlers', ['getCardType', 'setHighlight']),
  withNamespace('payment', ['cardType', 'highlightCardNumber'])
): HOC<*, any>);
