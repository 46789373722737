import { object, string } from 'yup';
import REG_EXP from '../../config/regular-expressions.config';

const usernameSchema = object({
  login: string()
    .required()
    .min(8, 'errors.okta-login-min')
    .max(100, 'errors.okta-login-max')
    .matches(REG_EXP.oktaUserName, 'errors.okta-login-invalid-character')
});

export default usernameSchema