// @flow
import React, { type Node } from 'react';
import classNames from 'classnames';
import Radio from '../Radio';
import styles from './enhanced-radio.module.css';

export type Props = {
  key?: string,
  name: string,
  id: string | number,
  checked: boolean,
  children?: Node,
  label?: string | Object,
  highlight?: string | boolean,
  onChange: () => void
};

export default (props: Props): Node => {
  const {
    checked = false,
    children,
    label = '',
    highlight = 'true',
    onChange
  } = props;

  const styleClasses = classNames({
    [styles.extendedRadio]: true,
    [styles.checked]: highlight && checked
  });

  return (
    <div className={styleClasses}>
      <Radio
        {...props}
        label={label}
        highlight={highlight || ''}
        className={styles.radio}
        children={null}
        inline
      />
      <div
        className={styles.content}
        role="button"
        tabIndex={-1}
        onKeyDown={() => {}}
        onClick={onChange}
      >
        {children}
      </div>
    </div>
  );
};
