// @flow
import { eCheckSchema } from '../../../../../utils/validation';
import { AUTHORIZE, PAYMENT_TYPE } from '../../../../../config/payment.config';
import type { FormikConfig, Models } from '../../../../../utils/types';

const payWithNewECheck = (values: Object, props: Object) => {
  const {
    props: {
      actions: {
        loader: { stop },
        payment: { payByEcheck, setError, saveEcheck }
      },
      payment: { paymentType }
    }
  } = props;

  window.Accept.dispatchData(
    { authData: AUTHORIZE, bankData: values },
    ({ messages = {}, opaqueData = {} }) => {
      if (messages.resultCode === 'Error') {
        const { message = [] } = messages;
        setError({ error: message, storeTo: 'accept' });
        stop();
      } else {
        setError({ error: [], storeTo: 'accept' });
        if (paymentType === PAYMENT_TYPE.ONE_TIME_PAYMENT) {
          values.saveToProfile
            ? saveEcheck(opaqueData)
            : payByEcheck(opaqueData);
        } else {
          saveEcheck(opaqueData);
        }
      }
    }
  );
};

const payWithSavedECheck = (values: Object, props: Object) => {
  const {
    props: {
      actions: {
        payment: { payBySavedEcheck, recurringPayment, scheduledPayment }
      },
      payment: { editMode, paymentType }
    }
  } = props;

  const method: string = editMode ? 'PUT' : 'POST';

  if (paymentType === PAYMENT_TYPE.ONE_TIME_PAYMENT) {
    payBySavedEcheck(values.echeckId);
  } else if (paymentType === PAYMENT_TYPE.RECURRING_PAYMENT) {
    recurringPayment({
      paymentProfileId: values.echeckId,
      method
    });
  } else if (paymentType === PAYMENT_TYPE.SCHEDULED_PAYMENT) {
    scheduledPayment({
      paymentProfileId: values.echeckId,
      method
    });
  }
};

export default ({
  mapPropsToValues: (props: Models): Object => {
    const { payment } = props;

    return payment;
  },
  validationSchema: eCheckSchema,
  handleSubmit: (values: Object, props: Object): void => {
    const {
      props: {
        actions: {
          loader: { start },
          payment: { setData, setError }
        },
        payment: { paymentType }
      }
    } = props;

    setData(values);
    setError();

    if (paymentType && paymentType === PAYMENT_TYPE.RECURRING_PAYMENT) {
      start('loaders.saving-payment');
    } else {
      start('loaders.processing-payment');
    }

    if (!values.echeckId) {
      payWithNewECheck(values, props);
    }

    if (values.echeckId) {
      payWithSavedECheck(values, props);
    }
  },
  enableReinitialize: true,
  validateOnChange: false
}: FormikConfig);
