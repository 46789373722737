// @flow
import React, { type Node } from 'react';
import { compose } from 'recompose';
import { FormGroup, Input, FormFeedback } from 'reactstrap';
import { is } from 'ramda';
import { t } from '../i18n';
import styles from './form.module.css';
import withStyleClasses from './withStyleClasses';
import type { Formik } from '../../utils/types';
import TooltipLabel from './ToolTipLabel';
import tabTo from '../../utils/tabTo';

type Props = {
  type?: string,
  name: string,
  title?: string,
  placeholder?: string,
  autoComplete?: string,
  disabled?: boolean,
  maxLength?: number,
  fieldReferences?: Object,
  autoTab?: (e: Event) => void,
  required?: boolean,
  styleClasses?: string,
  toolTip?: string
};

const InputElement = (props: Props & Formik): Node => {
  const {
    type = 'text',
    name = null,
    title = null,
    placeholder = null,
    values,
    touched,
    errors,
    value,
    changeHandler,
    handleBlur,
    onFocus,
    autoComplete = 'off',
    disabled = false,
    maxLength,
    fieldReferences,
    required = false,
    styleClasses,
    toolTip = ''
  } = props;

  return (
    name && (
      <FormGroup
        required={required}
        cssModule={styles}
        className={styles[styleClasses]}
        data-cy={name}
      >
        {title && (
          <TooltipLabel forName={name} title={title} toolTip={toolTip} />
        )}

        <Input
          type={type}
          name={name}
          id={name}
          value={value || values[name]}
          invalid={touched[name] && !!errors[name]}
          valid={touched[name] && !errors[name]}
          onChange={async (e) => {
            const { selectionStart, selectionEnd } = e.target;

            await changeHandler(e);
            // Fix for Safari, so the cursor won't jump to the end
            if (selectionStart && selectionEnd) {
              e.target.setSelectionRange(selectionStart, selectionEnd);
            }
            if (props.tabTo) tabTo(e, props.tabTo.field, props.tabTo.length);
          }}
          onBlur={handleBlur}
          disabled={disabled}
          onFocus={onFocus}
          placeholder={placeholder}
          innerRef={fieldReferences && fieldReferences[name]}
          autoComplete={autoComplete}
          maxLength={maxLength}
        />
        {errors[name] && (
          <FormFeedback>
            {is(Object, errors[name])
              ? t(errors[name].title, errors[name].values)
              : t(errors[name])}
          </FormFeedback>
        )}
      </FormGroup>
    )
  );
};

export default compose(withStyleClasses)(InputElement);
