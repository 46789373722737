import { memberRegistrationWithOktaSchema } from '../../../../utils/validation';
import { parseSearchQuery } from '../../../../utils';

export default {
  mapPropsToValues: (): Object => ({
    email: '',
    confirmEmail: '',
    oktaLogin: ''
  }),
  handleSubmit: (values: Object, props: Object): void => {
    const {
      props: {
        actions: {
          auth: { registerWithOkta }
        },
        location: { search }
      }
    } = props;
    const { verificationToken } = parseSearchQuery(search);
    registerWithOkta({ ...values, verificationToken });
  },
  validationSchema: memberRegistrationWithOktaSchema,
  validateOnChange: false
};
