// @flow
export type LoaderModel = {
  message: string,
  loading: boolean
};

export type LoaderActions = {
  start: (state: LoaderModel, message: string) => LoaderModel,
  stop: (state: LoaderModel) => LoaderModel
};

export default {
  state: {
    message: 'loaders.loading',
    loading: false
  },
  reducers: {
    start: (state: LoaderModel, message: string = state.message): LoaderModel =>
      Object.assign({}, state, { loading: true, message }),
    stop: (state: LoaderModel): LoaderModel =>
      Object.assign({}, state, { loading: false, message: '' })
  }
};
