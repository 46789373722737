export const isIE11 =
  window.navigator.userAgent.indexOf('Trident/') > 0 &&
  parseInt(
    window.navigator.userAgent.substring(
      window.navigator.userAgent.indexOf('rv:') + 3,
      window.navigator.userAgent.indexOf(
        '.',
        window.navigator.userAgent.indexOf('rv:')
      )
    ),
    10
  );
