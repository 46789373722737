// @flow
import { lazy } from 'react';
import { withProps, type HOC } from 'recompose';

import AcceptAccess from '../../Modals/AccessModals/AcceptAccess';
import AcceptGrant from '../../Modals/AccessModals/AcceptGrant';
import RevokeAccess from '../../Modals/AccessModals/RevokeAccess';

import ROUTES from '../../../config/routes.config';

const About = lazy(() => import('../../Modals/About'));
const MembershipCard = lazy(() => import('../../Modals/MembershipCard'));
const MembershipResources = lazy(() => import('../../MembershipResources'));

const Unauthorized = lazy(() => import('../../Modals/Unauthorized'));

export default (withProps((): Object => ({
  routes: [
    { path: `${ROUTES.SETTINGS}/about`, component: About },
    { path: `${ROUTES.SETTINGS}/membership-card`, component: MembershipCard },
    {
      path: `${ROUTES.MEMBERSHIP_RESOURCES}/membership-resources`,
      component: MembershipResources
    },
    {
      path: `${ROUTES.SETTINGS}/:familyMemberId(\\d+)/pending-request`,
      component: AcceptAccess
    },
    {
      path: `${ROUTES.SETTINGS}/:familyMemberId(\\d+)/pending-grant`,
      component: AcceptGrant
    },
    {
      path: `${ROUTES.SETTINGS}/:familyMemberId(\\d+)/revoke-access`,
      component: RevokeAccess
    },
    {
      path: `${ROUTES.SETTINGS}/unauthorized`,
      component: Unauthorized
    }
  ]
})): HOC<*, any>);
