// @flow
import React, { Fragment, type Node } from 'react';
import { Col, Form, Row } from 'reactstrap';
import classNames from 'classnames';
import { Calendar } from 'react-date-range';
import type { Route } from 'react-router-dom';
import {
  Button,
  ButtonGroup,
  ErrorView,
  Input,
  MaskInput,
  Status,
  t
} from '../../../../components';
import BackToLogin from '../BackToLogin';
import useClickOutside from '../../useClickOutside';
import { HEALTH_BRIDGE } from '../../../../config/terms.config';
import styles from '../auth.module.css';
import type { Formik } from '../../../../utils/types';
import type { AuthModel, Handlers } from '../../../../models/auth/types';

type Props = {
  formik: Formik,
  auth: AuthModel,
  toLogin: Route,
  calendar: Object,
  handlers: Handlers,
  viewError: boolean
};

export default (props: Props): Node => {
  const {
    formik,
    formik: {
      touched,
      values,
      errors,
      setFieldTouched,
      handleBlur,
      handleSubmit,
      setFieldValue
    },
    toLogin,
    calendar: { minDate, maxDate, dateValue },
    handlers: { formatDateDisplay, maskSsn },
    auth: {
      data: { email, sentEmail, registered },
      error
    },
    viewError,
    validatorSsn = 'ssn',
    validatorAccountNumber = 'account-number'
  } = props;

  const { isOpen, inputEl, setOpen } = useClickOutside();

  const datePickerWrapperStyles: string = classNames({
    [styles.datePickerWrapper]: true,
    [styles.open]: isOpen
  });

  const datePickerStyles: string = classNames({
    [styles.datePicker]: true,
    [styles.open]: isOpen,
    [styles.invalid]: touched.dateOfBirth && !!errors.dateOfBirth
  });

  const validatorButtonConfig = [validatorSsn, validatorAccountNumber].map(
    (value: string): Object => ({
      title: `auth.sign-in.${value}`,
      value: value,
      onClick: () => {
        setFieldValue('validatorType', value);
        setFieldValue('accountNumber', '');
        setFieldValue('ssnMask', '');
      }
    })
  );

  const currentValidator = () => values.validatorType || validatorSsn;

  const signUp: Node = (
    <Fragment>
      {!registered && <BackToLogin {...props} />}
      <Row>
        <Col>
          <h1 className={styles.heading}>
            <strong>HealthBridge</strong>
            <span className={styles.secondary}>
              {t('auth.sign-in.member-verification')}
            </span>
          </h1>
          <p className={styles.subHeading}>
            {t('auth.sign-in.confirm-message-1')}&#x2120;
            {t('auth.sign-in.confirm-message-2')}
          </p>
        </Col>
      </Row>
      {viewError && (
        <ErrorView error={error} className="mb-3">
          <p className="mb-2">{t('errors.not-found-desc-1')}</p>
          <p className="mb-0">{t('errors.not-found-desc-2')}</p>
          <p className="mb-0">
            {t('errors.not-found-desc-3', {
              phone: HEALTH_BRIDGE.PHONE_NUMBER
            })}
          </p>
        </ErrorView>
      )}
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col>
            <Input
              name="firstName"
              title="auth.sign-in.first-name"
              {...formik}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Input name="lastName" title="auth.sign-in.last-name" {...formik} />
          </Col>
        </Row>
        <Row>
          <Col>
            <ButtonGroup
              name="validatorType"
              config={validatorButtonConfig}
              className={styles.validationTypeSwitcher}
              value={currentValidator()}
              {...formik}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            {currentValidator() === validatorAccountNumber ? (
              <Input
                name="accountNumber"
                title="auth.sign-in.account-number"
                toolTip={'auth.sign-in.account-number-tooltip'}
                {...formik}
              />
            ) : (
              <MaskInput
                name="ssnMask"
                className="ssnMask"
                label="auth.sign-in.ssn"
                placeholder="XXX-XX-XXXX"
                maskConfig={{
                  mask: 'XXX-XX-9999',
                  formatChars: { '9': '\\d|\\*' }
                }}
                sup="*"
                {...formik}
                onPaste={(e) => e.preventDefault()}
                beforeMaskedValueChange={maskSsn}
              />
            )}
          </Col>
        </Row>
        <Row>
          <Col md={6} className="pb-3">
            <Input name="zip" title="auth.sign-in.zip" {...formik} />
          </Col>
          <Col md={6} className="pb-3">
            <div className={datePickerWrapperStyles} ref={inputEl}>
              <MaskInput
                name="dateOfBirth"
                className="dateMask"
                label="auth.sign-in.birthDate"
                title="auth.sign-in.birthDate"
                placeholder="mm/dd/yyyy"
                onFocus={() => setOpen(true)}
                value={formatDateDisplay(values.dateOfBirth)}
                maskConfig={{
                  mask: '99/99/9999',
                  formatChars: { '9': '\\d|\\*' }
                }}
                onPaste={(e) => e.preventDefault()}
                {...formik}
              />
              {isOpen && (
                <Calendar
                  date={dateValue}
                  minDate={minDate}
                  maxDate={maxDate}
                  className={datePickerStyles}
                  color="#25A584"
                  onBlur={handleBlur}
                  dragSelectionEnabled={false}
                  onChange={(value) => {
                    setFieldValue('dateOfBirth', value, false);
                    setOpen(false);
                    setFieldTouched('dateOfBirth', true, true);
                  }}
                />
              )}
            </div>
          </Col>
        </Row>
        <Button
          primary
          type="submit"
          className={styles.verify}
          title="auth.sign-in.verify"
        />
      </Form>
    </Fragment>
  );

  const userAlreadyRegistered: Node = (
    <Status header="auth.feedback.check-inbox" icon="sent-email">
      {t('auth.feedback.user-registered', { email })}
    </Status>
  );

  const userAlreadyExits: Node = (
    <Status header="auth.feedback.user-exist">
      <p>{t('auth.feedback.user-exist-message', { email })}</p>
      <Button link={toLogin} title="auth.sign-in.log-in" />
    </Status>
  );

  const isSignUpView: boolean = !sentEmail && !registered;
  const signUpStyles: string = classNames({
    [styles.signUp]: isSignUpView,
    [styles.sentEmail]: sentEmail,
    [styles.registered]: registered
  });

  return (
    <Row className={signUpStyles}>
      <Col md={10} lg={8} xl={5}>
        {isSignUpView && signUp}
        {sentEmail && userAlreadyRegistered}
        {registered && userAlreadyExits}
      </Col>
      {isSignUpView && (
        <Col xs={12} sm={12} md={12} xl={12} className={styles.ssnMessage}>
          <p>{t('auth.sign-in.ssn-tooltip-heading')}</p>
          <p>{t('auth.sign-in.ssn-tooltip-description')}</p>
        </Col>
      )}
    </Row>
  );
};
