// @flow
import React from 'react';
import { t } from '../components/i18n';

const basePath: string = 'home.authorization-agreement';

export default (name: string = ''): Array<Object> => [
  {
    key: t(`${basePath}.agreement-heading1`, {
      name: <b>{name.toUpperCase()}</b>
    })
  },
  {
    key: <b>{t(`${basePath}.agreement-serves-includes`)}</b>,
    terms: [
      {
        key: (
          <b>
            {t(`${basePath}.agreement-serves-includes-1`, {
              counter: 1
            })}
          </b>
        )
      },
      {
        key: (
          <b>
            {t(`${basePath}.agreement-serves-includes-2`, {
              counter: 2
            })}
          </b>
        )
      },
      {
        key: (
          <b>
            {t(`${basePath}.agreement-serves-includes-2`, {
              counter: 3
            })}
          </b>
        )
      }
    ]
  },
  {
    key: t(`${basePath}.agreement-purpose`)
  },
  {
    key: t(`${basePath}.agreement-understand-1`)
  },
  {
    key: t(`${basePath}.agreement-understand-2`)
  },
  {
    key: t(`${basePath}.agreement-condition`)
  },
  {
    key: t(`${basePath}.agreement-not-receive`)
  },
  {
    key: t(`${basePath}.agreement-not-receive`)
  },
  {
    key: <b>{t(`${basePath}.automatically-expire`)}</b>
  }
];
