// @flow
import React, {
  Fragment,
  Suspense,
  type AbstractComponent,
  type Node
} from 'react';
import { Redirect, Route, Switch } from 'react-router';
import type { Match, Route as RouteType } from 'react-router-dom';

type Props = {
  match: Match,
  routes: Array<RouteType>
};

export default (Component: AbstractComponent<Object>) => (
  props: Props
): Node => {
  const {
    match: { url },
    routes
  } = props;

  return (
    <Fragment>
      <Component {...props} />
      <Suspense fallback={null}>
        <Switch>
          {routes.map((route) => (
            <Route {...route} key={route.path} />
          ))}
          <Redirect from={`${url}/*`} to={url} />
        </Switch>
      </Suspense>
    </Fragment>
  );
};
