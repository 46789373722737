// @flow
import { lifecycle, type HOC } from 'recompose';
import Cookies from 'js-cookie';
import { STATEMENT } from '../../../config/member.config';

export default (lifecycle({
  componentDidMount() {
    const {
      member: {
        data: {
          member: { statementsType, tosAccepted = true }
        }
      },
      location: { pathname },
      history: { push }
    } = this.props;

    const statementModalWasOpen: string = Cookies.set('statementModalWasOpen');
    const showStatementModal: boolean =
      statementModalWasOpen === 'false' || !statementModalWasOpen;
    const isTermsPath: boolean = pathname.includes('terms-and-conditions');

    if (
      tosAccepted &&
      statementsType === STATEMENT.PAPER &&
      showStatementModal
    ) {
      Cookies.set('statementModalWasOpen', true);
      push(isTermsPath ? './statement-type' : `${pathname}/statement-type`);
    }

    if (!tosAccepted && !isTermsPath) {
      push(`${pathname}/terms-and-conditions`);
    }
  }
}): HOC<*, Object>);
