// @flow
import { type HOC, withProps } from 'recompose';
import LANGUAGES from '../../../config/languages.config';
import type { Actions } from '../../../utils/types';
import type { MemberModel } from '../../../models/member/types';

type Props = {
  location: Location,
  actions: Actions,
  member: MemberModel
};

export default (withProps((props: Props): Object => {
  const {
    actions: {
      auth: { logout },
      member: { updateSettings }
    },
    member: {
      data: {
        member: {
          language = 'EN',
          statementsType,
          timeZoneId,
          contactInfo,
          secondaryEmail
        },
        languages
      }
    }
  } = props;
  const { communicationPhone, email }: Object = contactInfo || {};
  const languagesOptions = languages.map((lang: Object) => ({
    title: LANGUAGES[lang].label,
    value: lang,
    onClick: (e, item) =>
      updateSettings({
        language: item.value,
        statementType: statementsType,
        timeZoneId,
        communicationPhone:
          communicationPhone && communicationPhone.number !== null
            ? communicationPhone.number
            : null,
        communicationEmail: secondaryEmail ? secondaryEmail.email : email
      })
  }));
  const profileSettingsLink = {
    title: 'home.profile-settings',
    link: '/settings'
  };
  const logoutLink = {
    title: 'home.log-out',
    onClick: logout
  };

  const items = [profileSettingsLink, logoutLink];

  return {
    dropDownConfig: {
      languages: {
        className: 'languages',
        generalSettings: {
          nav: true,
          inNavbar: true
        },
        dropDownToggle: {
          label: LANGUAGES[language].label,
          caret: true,
          nav: true
        },
        dropDownMenu: {
          right: true
        },
        translateOptions: false,
        items: languagesOptions
      },
      settings: {
        className: 'profileSettings',
        generalSettings: {
          nav: true,
          inNavbar: true
        },
        dropDownToggle: { nav: true },
        dropDownMenu: {
          right: true
        },
        items
      }
    }
  };
}): HOC<*, any>);
