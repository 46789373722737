// @flow
import React, { type Node } from 'react';
import type { Formik } from '../../../../../../utils/types';

export default (props: Formik): Node => {
  const {
    formik: {
      touched,
      errors,
      values: { expirationDate }
    }
  } = props;

  return (
    <g
      id="expiration"
      mask="url(#mask-5)"
      fontFamily="Roboto, sans-serif"
      fontWeight="normal"
    >
      <g transform="translate(168.480000, 105.620000)">
        <text id="MM/YY" fontSize="11" x="0" y="22.6">
          <tspan
            fill={
              touched.expirationDate && errors.expirationDate
                ? '#c9364d'
                : '#41444F'
            }
          >
            {expirationDate || 'MM/YY'}
          </tspan>
        </text>
        <text id="EXPIRES" fontSize="6" letterSpacing="1.285714">
          <tspan x="1" y="6" fill="#A3A7BA">
            EXPIRES
          </tspan>
        </text>
      </g>
    </g>
  );
};
