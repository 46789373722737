// @flow
import React, { type Node } from 'react';
import { Col, Row } from 'reactstrap';
import { EnhancedRadio, number, t } from '../../../../components';
import { AMOUNT_OPTIONS } from '../../../../config/payment.config';
import styles from '../../payment.module.css';
import type { Formik } from '../../../../utils/types';
import type { Handlers, PaymentModel } from '../../../../models/payment/types';
import type { MemberModel } from '../../../../models/member/types';

type Props = {
  handlers: Handlers,
  member: MemberModel,
  payment: PaymentModel,
  formik: Formik
};

export default (props: Props): Node => {
  const {
    currentQuickPay,
    handlers: { isChecked, helperTextStyle, setAmount },
    member: {
      data: {
        familyMember: {
          balanceDetails: { balance, disputedClaimCount },
          id,
          firstName
        }
      }
    },
    formik: {
      setFieldValue,
      values: { type }
    }
  } = props;
  const currentBalance =
    disputedClaimCount > 0
      ? currentQuickPay.discountAmount + currentQuickPay.amount
      : balance;

  return (
    <Row>
      <Col>
        <h1 className={styles.optionHeading}>
          {t('payment.current-balance')}
          <span>{t('payment.current-balance-description')}</span>
        </h1>
        <EnhancedRadio
          name="type"
          id="fullBalance"
          label={
            id
              ? { id: 'payment.pay-fm-in-full', name: firstName }
              : 'payment.pay-in-full'
          }
          value={AMOUNT_OPTIONS.FULL}
          checked={
            isChecked(AMOUNT_OPTIONS.FULL) ||
            isChecked(AMOUNT_OPTIONS.PAY_IN_FULL)
          }
          onChange={() => {
            setFieldValue('type', AMOUNT_OPTIONS.FULL);
            setFieldValue('repaymentAccepted', true, true);
            setAmount(currentQuickPay.amount);
          }}
        >
          <Row>
            <Col className={styles.labelText}>
              {id
                ? t('payment.pay-fm-in-full', { name: firstName })
                : t('payment.pay-in-full')}

              <div className={styles.amount}>
                {number(currentQuickPay.amount)}
                {currentQuickPay.discountAmount > 0 && (
                  <small>{number(currentBalance)}</small>
                )}
              </div>
            </Col>
            {currentQuickPay.discountAmount > 0 && (
              <Col className={styles.selectContent}>
                <p className={helperTextStyle(type, AMOUNT_OPTIONS.FULL)}>
                  {t('payment.full-amount-helper', {
                    number: number(currentQuickPay.discountAmount)
                  })}
                </p>
              </Col>
            )}
          </Row>
        </EnhancedRadio>
      </Col>
    </Row>
  );
};
