// @flow
import React, { type Node } from 'react';
import { Button } from 'reactstrap';
// NOTE: injectIntl can be used alternatively as the hook useIntl() but only in functional components in version 3
import { injectIntl, type IntlShape } from 'react-intl';
import { is, omit } from 'ramda';
import { Link } from 'react-router-dom';
import styles from './button.module.css';

type Props = {
  primary?: boolean,
  title: string,
  size?: string,
  onClick?: ({}) => mixed,
  disabled?: boolean,
  link?: string,
  active?: boolean,
  intl: IntlShape,
  type: string,
  icon: string,
  className: string,
  showTitle?: boolean
};

const ButtonComponent = (props: Props): Node => {
  const {
    primary = false,
    title = 'placeholders.button',
    disabled = false,
    size,
    onClick,
    link,
    href = undefined,
    target = undefined,
    download = undefined,
    active = false,
    icon,
    intl: { formatMessage },
    type,
    className,
    showTitle = true
  } = props;

  const btnProps = {
    color: primary ? 'primary' : 'secondary',
    outline: !primary,
    size,
    title: is(String, title)
      ? formatMessage({ id: `member-app.${title}` })
      : title,
    disabled,
    onClick,
    href,
    target,
    download,
    tag: link && Link,
    to: link || null,
    active,
    type,
    className
  };

  return icon ? (
    <Button
      className={`${styles.icon} ${styles[icon]}`}
      outline
      onClick={onClick}
    />
  ) : (
    <Button {...omit(['title'], btnProps)}>
      {showTitle && btnProps.title}
    </Button>
  );
};

export default injectIntl(ButtonComponent);
