// @flow
import React, { Fragment, type Node } from 'react';
import { date, number, t, time } from 'components';
import styles from 'containers/Settings/settings.module.css';
import type { Address as AddressType, MemberModel } from 'models/member/types';
import { Link } from 'react-router-dom';
import type { Actions } from 'utils/types';

export const SimpleValue: Function = ({ value }): string => value || '—';

export const SimpleDate: Function = ({ value }): Node | string =>
  value
    ? date({
        value,
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      })
    : '—';

export const Phone: Function = ({ phone }): Node => (
  <Fragment>
    <span>{(phone && phone.number) || '—'}</span>
    {phone && phone.extension && (
      <Fragment>
        <span className={`${styles.label} ${styles.phone}`}>
          {t('settings.settings-blocks.contact-info.extension')}
        </span>
        <span>{phone.extension}</span>
      </Fragment>
    )}
  </Fragment>
);

export const Address: Function = ({
  address
}: {
  address: AddressType
}): Node => {
  const { address1, address2, city, state, country } = address;

  return (
    <div className={styles.address}>
      <p>{address1}</p>
      {address2 && <p>{address2}</p>}
      <p>
        {city}, {state}, {country}
      </p>
    </div>
  );
};

type Props = {
  actions: Actions,
  member: MemberModel
};

export const formatDate: Function = (value: string): Node =>
  date({
    value,
    day: '2-digit',
    month: '2-digit',
    year: 'numeric'
  });

export const dateTime: Function = (value: string): Node => (
  <Fragment>
    <span className="pr-1">{formatDate(value)}</span>
    <span>{time(value, 'HH:mm A')}</span>
  </Fragment>
);

export const period: Function = (values: Object, props: Props): Node => {
  const { period: periodData, entityId } = values;
  const {
    actions: {
      member: { getMemberStatement }
    },
    member: {
      data: {
        familyMember: { id }
      }
    }
  } = props;

  return (
    <Link
      to="#"
      onClick={(e) => {
        e.preventDefault();
        getMemberStatement({
          billingPeriod: periodData.startDate,
          id: entityId,
          memberId: id
        });
      }}
    >
      {formatDate(periodData.startDate)} - {formatDate(periodData.endDate)}
    </Link>
  );
};

export const linkToTransaction = (to: string, date: string): Node => (
  <Link to={to}>{formatDate(date)}</Link>
);

export const parseAmount = (amount: number): Node =>
  Math.sign(amount) === 1 ? <span>+{number(amount)}</span> : number(amount);

export const parseDiscount: Function = (value: number | string): Node =>
  value && value !== 0 ? parseAmount(+value) : null;

export const cardDescription = ({
  accountType,
  accountNumber,
  expirationDate
}: Object) => (
  <Fragment>
    <span className="pr-1">{`${accountType} •••• ${accountNumber}`}</span>
    {t('home.details-view.payment.expire')}
    <span className="pl-1">{expirationDate}</span>
  </Fragment>
);
