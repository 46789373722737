// @flow
import React, { type Node, useState, useLayoutEffect } from 'react';
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';
import { t } from '../i18n';
import Button from '../Button';
import Table from '../Table';
import TableTitle from '../Table/TableTitle';
import styles from './modals.module.css';
import { featureFlagKeys } from '../../config';

const BLOCK_TYPE: Object = {
  HEAD: 'HEAD',
  BODY: 'BODY',
  FOOTER: 'FOOTER'
};

const renderModalBlock = (data: Object): Node => {
  const { type, config, props } = data;
  const { message, render, toggle, tag, cy, className } = config || {};

  const bodySimpleMessage: Node = type === BLOCK_TYPE.BODY && (
    <Row>
      <Col>
        <p>{t(message)}</p>
      </Col>
    </Row>
  );

  return (
    config && (
      <data.component
        toggle={toggle}
        tag={tag}
        data-cy={cy}
        className={styles[className]}
        close={toggle}
      >
        {message && (bodySimpleMessage || t(message))}
        {render && render(props)}
      </data.component>
    )
  );
};

const renderTable = (options: Object, expand, setExpand): Node => {
  const { className } = options;

  return (
    <Row className={styles[className]}>
      <Col>
        <TableTitle
          onClick={() => setExpand(!expand)}
          expand={expand}
          canExpand={true}
          {...options}
        />
        <Table expand={expand} canExpand={true} {...options} />
      </Col>
    </Row>
  );
};

type Props = {
  modalConfig: Object
};

export default (props: Props): Node => {
  const { modalConfig = {}, loader, flags } = props;

  const {
    size = 'md',
    toggle,
    header,
    body,
    table,
    claimLinesTable,
    footer,
    disableButton = false
  } = modalConfig;

  const claimLinesFlag = flags && flags[featureFlagKeys.memberClaimLines];
  const [expandLinkedClaims, setExpandLinkedClaims] = useState(false);
  const [expandClaimLines, setExpandClaimLines] = useState(false);

  const [showLoadingModal, setShowLoadingModal] = useState(true);
  const hasDelay =
    !!loader && (loader.message === 'loaders.loading' || loader.message === '');

  useLayoutEffect(() => {
    if (hasDelay) {
      setShowLoadingModal(false);
      setTimeout(() => {
        setShowLoadingModal(true);
      }, 1000);
    }
  }, []);

  if (!showLoadingModal) {
    return null;
  }

  return (
    <Modal
      isOpen
      centered
      fade={false}
      backdrop={false}
      modalClassName="modalBackground"
      toggle={toggle}
      size={size}
    >
      {header &&
        !header.hide &&
        renderModalBlock({
          component: ModalHeader,
          config: { ...header, cy: 'modal-header' },
          props
        })}
      <div className={styles.modalInfo}>
        {body && !body.hide
          ? renderModalBlock({
              component: ModalBody,
              config: { ...body, cy: 'modal-body' },
              props
            })
          : body.fallback}

        {claimLinesFlag &&
          claimLinesTable &&
          claimLinesTable.data &&
          claimLinesTable.data.length > 0 &&
          renderTable(claimLinesTable, expandClaimLines, setExpandClaimLines)}
        {claimLinesFlag &&
          table &&
          table.data &&
          table.data.length > 0 &&
          renderTable(table, expandLinkedClaims, setExpandLinkedClaims)}
      </div>
      {footer && !footer.hide && (
        <ModalFooter data-cy="modal-footer">
          <Row className={styles.modalFooter}>
            <Col className={styles.innerFooter}>
              {footer.buttons.map(
                (button) =>
                  !button.hide && (
                    <Button
                      className={styles.buttonPadding}
                      disabled={disableButton}
                      key={button.title}
                      {...button}
                    />
                  )
              )}
            </Col>
          </Row>
        </ModalFooter>
      )}
    </Modal>
  );
};
