// @flow
export default {
  communicationPhone: new RegExp(/^\d{3}-\d{3}-\d{4}$/),
  communicationEmail: new RegExp(
    /^([_A-Za-z0-9-+]+\.?)+(\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\.[A-Za-z0-9]+)*(\.[A-Za-z]{2,})$/
  ),
  isNumber: new RegExp(/^\d+(\.\d+)?$/),
  visa: new RegExp(/^4(\d|\s){15}(?:[0-9]{3})?$/),
  mastercard: new RegExp(
    /^5[1-5](\d|\s){17}|2(?:2(?:2[1-9]|[3-9]\d)|[3-6]\d\d|7(?:[01]\d|20))(\d|\s){11}\d{4}$/
  ),
  amex: new RegExp(/^3[47](\d|\s){16}$/),
  discover: new RegExp(
    /^65[4-9](\d|\s){16}|64[4-9](\d|\s){16}|6011(\d|\s){15}|(622(?:12[6-9]|1[3-9][0-9]|[2-8][0-9][0-9]|9[01][0-9]|92[0-5])(\d|\s){13})$/
  ),
  cardExpiration: new RegExp(/(0[1-9]|1[0-2])\/(1[9]|[2-4][0-9]|5[0])/),
  threeDigits: new RegExp(/^(\d){3}$/),
  fourDigits: new RegExp(/^(\d){4}$/),
  fiveDigits: new RegExp(/^(\d){5}$/),
  nineDigits: new RegExp(/^(\d){9}$/),
  lettersWithSpace: new RegExp(/^[a-zA-Z ]+$/),
  accountNumber: new RegExp(/^(\d){5,17}$/),
  email: new RegExp(
    /^([_A-Za-z0-9-+]+\.?)+(\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\.[A-Za-z0-9]+)*(\.[A-Za-z]{2,})$/
  ),
  ssnMask: new RegExp(/^\bXXX-XX-\b(\d){4}$/),
  twoDigitsAfterDot: new RegExp(/\d+\.\d{1,2}$/),
  oktaUserName: new RegExp(/^[a-zA-Z0-9.@_+-]+$/)
};
