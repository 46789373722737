// flow
import React, { type Node } from 'react';
import { compose } from 'recompose';
import { Row, Col } from 'reactstrap';
import CardDetails from './CardDetails';
import EcheckDetails from './EcheckDetails';
import { PAYMENT_METHODS } from '../../../../config/payment.config';
import { withStepSummary, withCheckHandlers } from '../../hoc';
import type { PaymentModel } from '../../../../models/payment/types';

const PaymentDetails = (props: PaymentModel): Node => {
  const {
    payment: { method }
  } = props;

  return (
    <Row>
      <Col>
        {method === PAYMENT_METHODS.CARD ? (
          <CardDetails {...props} />
        ) : (
          <EcheckDetails {...props} />
        )}
      </Col>
    </Row>
  );
};

export default compose(withStepSummary, withCheckHandlers)(PaymentDetails);
