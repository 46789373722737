// @flow
import React, { type Node } from 'react';
// NOTE: injectIntl can be used alternatively as the hook useIntl() but only in functional components in version 3
import { injectIntl, type intlShape } from 'react-intl';
import { values } from 'ramda';
import { compose } from 'recompose';
import { Nav, NavItem, NavLink } from 'reactstrap';
import classNames from 'classnames';
import { withShadowBox } from '../../../../hoc';
import styles from './navigation.module.css';
import type { Setting } from '../../../../utils/types';

type Props = {
  settings: Object,
  location: Location,
  intl: intlShape
};

const renderNavItems = (props: Props): Node => {
  const {
    settings,
    location: { hash: currentHash },
    intl: { formatMessage }
  } = props;

  return values(settings).map(({ title, icon }: Setting): Node => {
    const navLinkStyles: string = classNames({
      [styles.icon]: true,
      [styles[icon]]: true,
      [styles.active]: currentHash.includes(icon)
    });
    const titleMessage: Node = formatMessage({ id: `member-app.${title}` });

    return (
      <NavItem className={styles.navItem} key={icon}>
        <NavLink
          href={`#${icon}`}
          title={titleMessage}
          className={navLinkStyles}
          data-cy={`item-${icon}`}
        >
          {titleMessage}
        </NavLink>
      </NavItem>
    );
  });
};

const Navigation: Function = (props: Props): Node => (
  <div className={styles.aside}>
    <Nav vertical className="pt-2 pb-2" data-cy="sidebar-container">
      {renderNavItems(props)}
    </Nav>
  </div>
);

export default compose(injectIntl, withShadowBox)(Navigation);
