import { ref, string, object } from 'yup';
import REG_EXP from '../../config/regular-expressions.config';
import { emailSchemaDefinition } from './emailSchema';

export const oktaLogin = string()
  .min(8, 'errors.okta-login-min')
  .max(100, 'errors.okta-login-max')
  .matches(REG_EXP.oktaUserName, 'errors.okta-login-invalid-character')
  .required('errors.okta-login-required');

export default object({
  ...emailSchemaDefinition,
  oktaLogin,
  confirmEmail: string()
    .oneOf([ref('email')], 'errors.email-confirm')
    .required('errors.email-required')
});
