import React from 'react';
import { compose, withStateHandlers } from 'recompose';
import formConfig from './formConfig';
import { withModal, withMapStateDispatch, withFormik } from '../../../../hoc';
import Form from './components/AccessForm';
import Agreement from './components/Agreement';
import withHandleAccessErrors from '../utils/withHandleAccessErrors';

const AccessForm = compose(
  withModal,
  withMapStateDispatch,
  withHandleAccessErrors,
  withFormik(formConfig)
)(Form);

export default withStateHandlers(
  () => ({
    isAgreementShowing: false
  }),
  {
    showAgreement: () => (isAgreementShowing) => ({ isAgreementShowing })
  }
)(({ isAgreementShowing, ...props }: { isAgreementShowing: boolean }) => {
  if (isAgreementShowing) return <Agreement {...props} />;

  return <AccessForm {...props} />;
});
