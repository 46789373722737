// @flow
import { paymentDateSchema } from '../../../../utils/validation';
import type { FormikConfig, Models } from '../../../../utils/types';

export default ({
  mapPropsToValues: (props: Models): Object => {
    const {
      payment: { paymentDay }
    } = props;

    return { paymentDay };
  },
  handleSubmit: (values: Object, props: Object): void => {
    const {
      props: {
        actions: {
          payment: { setData, setError }
        },
        payment: { step }
      }
    } = props;

    setData({ ...values, step: step + 1 });
    setError();
  },
  validationSchema: paymentDateSchema,
  enableReinitialize: true,
  validateOnChange: false
}: FormikConfig);
