import React, { Fragment, type Node } from 'react';
import type { Formik } from '../../utils/types';
import { t } from '../i18n';
import styles from './form.module.css';
import Tooltip from '../Tooltip';
import { Label } from 'reactstrap';

type Props = {
  forName: string,
  title: string,
  toolTip?: string
};

/**
 * Allows the specification of a tooltip in addition to just a title and for-component reference
 *
 * @param props
 *  forName - being the reference to which this label relates
 *  title - being the internationalized label to be looked up for displaying as label content
 *  toolTip = the optional internationalized label to be looked up for displaying ToolTip content
 * @returns {*}
 */

export default (props: Props & Formik): Node => {
  const { forName, title, toolTip = '' } = props;
  return (
    <Fragment>
      <Label for={forName}>{t(title)}</Label>
      {toolTip !== '' && (
        <i id={`${forName}_info`} className={styles.infoButton}>
          <Tooltip target={`${forName}_info`} message={t(toolTip)} />
        </i>
      )}
    </Fragment>
  );
};
