// @flow
import { compose } from 'recompose';
import { withFormik } from '../../../../hoc';
import { withStepSummary } from '../../hoc';
import PaymentType from './PaymentType';
import paymentTypeFormConfig from './paymentTypeFormConfig';

export default compose(
  withFormik(paymentTypeFormConfig),
  withStepSummary
)(PaymentType);
