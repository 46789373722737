// @flow
import React, { Fragment, type Node } from 'react';
import { EnhancedRadio, t } from '../../../../../components';
import NewEcheck from './NewEcheck';
import styles from '../../../payment.module.css';
import type { Models, Formik } from '../../../../../utils/types';
import type { Echeck } from '../../../../../models/payment/types';

export default (props: Models | Formik): Node => {
  const {
    formik: {
      setFieldValue,
      resetForm,
      initialValues,
      values: { echeckId },
      submitCount
    },
    payment: { echecks }
  } = props;

  const list: Array<Echeck> = echecks.map((check: Echeck): Node => (
    <EnhancedRadio
      key={check.id}
      name="paymentDetails"
      id={check.id}
      label={{
        id: 'payment.pay-with-card',
        card: `${check.routingNumber} •••• ${check.accountNumber}`
      }}
      value={check.id}
      checked={check.id === echeckId}
      onChange={() => {
        resetForm({ values: initialValues });
        setFieldValue('echeckId', check.id);
      }}
    >
      <div className={styles.eCheck}>
        {check.routingNumber} •••• {check.accountNumber}
      </div>
    </EnhancedRadio>
  ));

  return (
    <Fragment>
      {list}
      <EnhancedRadio
        key="newEcheck"
        name="paymentDetails"
        id="newEcheck"
        value=""
        label="payment.pay-with-another-acc"
        highlight={false}
        checked={!echeckId}
        onChange={() => {
          setFieldValue('echeckId', '');
          !!submitCount && resetForm({ values: initialValues });
        }}
      >
        <p className={styles.optionTitle}>
          {t('payment.pay-with-another-acc')}
        </p>
        {!echeckId && <NewEcheck {...props} />}
      </EnhancedRadio>
    </Fragment>
  );
};
