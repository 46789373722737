// @flow
import React, {
  useEffect,
  type AbstractComponent,
  type Element,
  useState
} from 'react';
import { type Match } from 'react-router-dom';
import { type Actions } from 'utils/types';
import type { MemberModel } from 'models/member/types.js';
import type { TableConfig } from 'components/Table/types';
import {
  modalLayout,
  scheduledPaymentModalLayout
} from 'containers/Modals/DetailsView/configs';
import type Handlers from '../../types';
import { ENTITY_TYPE } from 'config/payment.config';

type Props = {
  actions: Actions,
  match: Match,
  handlers: Handlers,
  member: MemberModel,
  table?: TableConfig
};

export default (Component: AbstractComponent<Object>) => (
  props: Props
): Element<*> => {
  const {
    actions: {
      member: { updateStore, setError }
    },
    match: {
      params: { entityId, entityType, tab }
    },
    handlers: { getSelectedTransaction, getSelectedScheduledPayment }
  } = props;

  const [isConfirmationSelected, setIsConfirmationSelected] = useState(false);

  useEffect((): Function => {
    if (tab === ENTITY_TYPE.SCHEDULED_PAYMENT_TRANSACTIONS) {
      getSelectedScheduledPayment();
    } else {
      getSelectedTransaction();
    }

    return (): void => {
      updateStore({ selectedTransaction: {} });
      setError();
    };
  }, [entityType, entityId, getSelectedTransaction, updateStore, setError]);

  return isScheduledPaymentTab(tab) ? (
    <Component
      {...props}
      modalConfig={scheduledPaymentModalLayout({
        ...props,
        isDeleting: isConfirmationSelected,
        setIsDeleting: setIsConfirmationSelected
      })}
    />
  ) : (
    <Component {...props} modalConfig={modalLayout(props)} />
  );
};

const isScheduledPaymentTab = (tab: String): Boolean => {
  return tab === 'scheduled-transactions';
};
