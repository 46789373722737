import { compose } from 'recompose';
import { withModal, withFormik, withMapStateDispatch } from '../../../../hoc';
import RevokeAccess from './RevokeAccess';
import formConfig from './formConfig';

export default compose(
  withModal,
  withMapStateDispatch,
  withFormik(formConfig)
)(RevokeAccess);
