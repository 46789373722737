// @flow
import { withProps, type HOC } from 'recompose';
import ROUTES from '../../../config/routes.config';
import { t } from '../../../components';

export default (withProps((): Object => ({
  headerConfig: {
    fixedHeader: true,
    breadcrumbConfig: [
      {
        title: t('common.home'),
        linkTo: ROUTES.BASE_PATH
      },
      { title: t('terms-and-conditions.terms') }
    ]
  },
  boxConfig: { small: false }
})): HOC<*, any>);
