// @flow
import { object } from 'yup';
import { pin } from '../../../../utils/validation/requestAccessSchema';
import ERRORS_CODE from '../utils';

const initialValue = {
  pin: '',
  confirmedPin: '* * * *'
};

export default {
  mapPropsToValues: () => initialValue,
  validationSchema: object({ pin }),
  handleSubmit: async (values, { props }) => {
    const {
      actions: { member },
      match: { params },
      showAgreement,
      showError
    } = props;

    const { confirmPin, getMember } = member;
    const { familyMemberId, memberId } = params;

    await confirmPin({
      id: familyMemberId,
      pin: values.pin
    })
      .then(() => showAgreement(true))
      .catch(({ data }) => {
        const { error = {} } = data || {};
        showError(error.code);
        if (error.code === ERRORS_CODE.PIN_MAX_ATTEMPTS_EXCEEDED)
          getMember(memberId);
      });
  },
  enableReinitialize: true,
  validateOnChange: true
};
