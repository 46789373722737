// @flow
import { lazy } from 'react';
import { withProps, type HOC } from 'recompose';
import ROUTES from '../../../config/routes.config';

const About = lazy(() => import('../../Modals/About'));
const MembershipCard = lazy(() => import('../../Modals/MembershipCard'));
const Unauthorized = lazy(() => import('../../Modals/Unauthorized'));

export default (withProps((): Object => ({
  routes: [
    { path: `${ROUTES.TERMS}/about`, component: About },
    { path: `${ROUTES.TERMS}/membership-card`, component: MembershipCard },
    {
      path: `${ROUTES.TERMS}/unauthorized`,
      component: Unauthorized
    }
  ]
})): HOC<*, any>);
