// @flow
import { withProps, type HOC } from 'recompose';

export default (withProps((): Object => ({
  headerConfig: {
    withMembers: true,
    fixedHeader: true,
    withShadow: false
  }
})): HOC<*, Object>);
