import React, { Fragment } from 'react';
import { Row, Col } from 'reactstrap';
import { t, Button, Modal } from '../../../components';
import { compose, withProps } from 'recompose';
import type { History } from 'react-router-dom';
import { withMapStateDispatch } from '../../../hoc';
import type { Handlers } from './types';
import type { Actions } from '../../../utils/types';
import type { MemberModel } from '../../../models/member/types';
import styles from './deleteCard.module.css';

type Props = {
  history: History,
  handlers: Handlers,
  actions: Actions,
  member: MemberModel
};

export const getDescription = (card = {}) => {
  if (card.hasRecurringPayments || card.hasScheduledPayments) {
    return t('delete-card.recurring-scheduled-payment-affected');
  }
  return t('delete-card.description');
};

export default compose(
  withMapStateDispatch,
  withProps((props: Props): Object => {
    const {
      actions: {
        member: { updateStore },
        payment: { deletePaymentProfile }
      },
      closeModal,
      history: { push },
      formik: {
        setFieldValue,
        values: { cardId }
      },
      payment: { cards, cardId: paymentCardId }
    } = props;
    let description = t('delete-card.description');
    let cardDetails = null;

    if (cardId && cards.length) {
      cardDetails = cards.find(({ id }) => cardId === id);
      description = getDescription(cardDetails);
    }

    return {
      modalConfig: {
        toggle: closeModal,
        header: {
          message: 'delete-card.title',
          toggle: closeModal,
          tag: 'h1'
        },
        size: 'md',
        body: {
          render: (): Node => (
            <Fragment>
              <Row data-testid="delete-card-modal">
                <Col>
                  <p>{description}</p>
                </Col>
              </Row>
              <Row>
                <Col className={styles.actionFooter}>
                  <Button title="delete-card.cancel" onClick={closeModal} />
                  <Button
                    primary
                    title="delete-card.confirm"
                    onClick={() => {
                      deletePaymentProfile(cardId).then(() => {
                        setFieldValue('cardId', null);
                        if (paymentCardId === cardId) {
                          updateStore({ selectedTransaction: {} });
                          push('/member/me');
                        }
                        closeModal();
                      });
                    }}
                  />
                </Col>
              </Row>
            </Fragment>
          )
        }
      }
    };
  })
)(Modal);
