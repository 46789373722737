// @flow
import React, { type Node } from 'react';
import { Col, Row } from 'reactstrap';
import classNames from 'classnames';
import { t } from '../../../components';
import styles from '../settings.module.css';
import { withShadowBox } from '../../../hoc';
import type { Setting } from '../../../utils/types';

type Props = {
  children: Node,
  topContent: Node,
  settings: Setting
};

const InfoItem = (props: Props): Node => {
  const {
    settings: { config = [], title, icon, render }
  } = props;

  const items: Array<Node> = config.map(
    (item, index, array): Node => {
      const margin: string = classNames({ 'mb-4': index !== array.length - 1 });

      return (
        <Row key={index}>
          <Col className={margin}>
            {item.title && (
              <h2 className={styles.label} data-cy={`label-${icon}`}>
                {t(item.title)}
              </h2>
            )}
            <div data-cy={`data-${icon}`}>{item.render}</div>
          </Col>
        </Row>
      );
    }
  );

  return (
    <div className={styles.information} data-cy={`${icon}-container`}>
      <Row>
        <Col>
          <h1 className={styles.heading} data-cy={`${icon}-header`}>
            {t(title)}
          </h1>
        </Col>
      </Row>
      {render ? render : items}
    </div>
  );
};

export default withShadowBox(InfoItem);
