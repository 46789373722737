// @flow
import React, { type Node } from 'react';
import { Col, Form, Row, FormGroup } from 'reactstrap';
import classNames from 'classnames';
import type { ContextRouter } from 'react-router-dom';
import { t, Button, EnhancedRadio } from '../../../../components';
import { PAYMENT_TYPE } from '../../../../config/payment.config';
import styles from '../../payment.module.css';
import type { Actions, Formik, Models } from '../../../../utils/types';

export default (props: Actions | Models | Formik | ContextRouter): Node => {
  const {
    actions: {
      payment: { setData }
    },
    history: { push },
    match: { url },
    payment: { editMode, hasReccuringPayment },
    member: {
      data: {
        familyMember: {
          balanceDetails: { recurringPayment }
        }
      }
    },
    formik: {
      values,
      values: { paymentType },
      handleSubmit
    },
    step,
    flags: { scheduledPaymentMember }
  } = props;

  const submitStyles: string = classNames({
    [styles.submitButton]: true,
    'align-items-end': false,
    'justify-content-between': hasReccuringPayment
  });

  return (
    <Form onSubmit={handleSubmit}>
      <Row>
        <Col>
          <FormGroup tag="fieldset">
            <legend className="sr-only">{t(step.header)}</legend>
            <EnhancedRadio
              name="paymentType"
              id="paymentTypeOneTime"
              label="payment.payment-type-ONE_TIME_PAYMENT"
              value={PAYMENT_TYPE.ONE_TIME_PAYMENT}
              checked={paymentType === PAYMENT_TYPE.ONE_TIME_PAYMENT}
              onChange={() =>
                setData({
                  ...values,
                  editMode: false,
                  paymentType: PAYMENT_TYPE.ONE_TIME_PAYMENT
                })
              }
            >
              <Row>
                <Col className={styles.labelText}>
                  {t('payment.one-time-payment')}
                </Col>
                <Col className={styles.selectContent}>
                  <p className={styles.helperText}>
                    {t(
                      editMode
                        ? 'payment.edit-one-time-message'
                        : 'payment.one-time-message'
                    )}
                  </p>
                </Col>
              </Row>
            </EnhancedRadio>
            <EnhancedRadio
              name="paymentType"
              id="paymentTypeRecurring"
              label="payment.payment-type-RECURRING_PAYMENT"
              value={PAYMENT_TYPE.RECURRING_PAYMENT}
              checked={paymentType === PAYMENT_TYPE.RECURRING_PAYMENT}
              onChange={() =>
                setData({
                  ...values,
                  editMode: hasReccuringPayment,
                  paymentType: PAYMENT_TYPE.RECURRING_PAYMENT
                })
              }
            >
              <Row>
                <Col className={styles.labelText}>
                  {t(
                    hasReccuringPayment
                      ? 'payment.edit-recurring-payment'
                      : 'payment.recurring-payment'
                  )}
                </Col>
                <Col className={styles.selectContent}>
                  <p className={styles.helperText}>
                    {t(
                      hasReccuringPayment
                        ? 'payment.edit-recurring-message'
                        : 'payment.recurring-message',
                      {
                        date: recurringPayment ? recurringPayment.paymentDay : 0
                      }
                    )}
                  </p>
                </Col>
              </Row>
            </EnhancedRadio>
            {scheduledPaymentMember && (
              <EnhancedRadio
                name="paymentType"
                id="paymentTypeScheduled"
                label="payment.payment-type-SCHEDULED_PAYMENT"
                value={PAYMENT_TYPE.SCHEDULED_PAYMENT}
                checked={paymentType === PAYMENT_TYPE.SCHEDULED_PAYMENT}
                onChange={() =>
                  setData({
                    ...values,
                    editMode: false,
                    paymentType: PAYMENT_TYPE.SCHEDULED_PAYMENT
                  })
                }
              >
                <Row>
                  <Col className={styles.labelText}>
                    {t('payment.scheduled-payment')}
                  </Col>
                  <Col className={styles.selectContent}>
                    <p className={styles.helperText}>
                      {t('payment.scheduled-payment-message')}
                    </p>
                  </Col>
                </Row>
              </EnhancedRadio>
            )}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col className={submitStyles}>
          {hasReccuringPayment && (
            <Button
              type="button"
              title="payment.delete-recurring-payment"
              onClick={() => push(`${url}/delete-recurring-payment`)}
            />
          )}
          <Button
            primary
            type="submit"
            title={`${
              paymentType === PAYMENT_TYPE.SCHEDULED_PAYMENT
                ? 'payment.to-payment-date'
                : 'payment.to-payment-amount'
            }`}
          />
        </Col>
      </Row>
    </Form>
  );
};
