// @flow
import { compose, withHandlers, type HOC } from 'recompose';
import type { Match } from 'react-router-dom';
import { withNamespace } from 'hoc';
import type { Actions } from 'utils/types';
import type { MemberModel } from 'models/member/types';

type Props = {
  actions: Actions,
  match: Match,
  member: MemberModel
};

export default (compose(
  withHandlers({
    getSelectedScheduledPayment: (props: Props) => async (): Promise<any> => {
      const {
        actions: {
          member: { getMemberScheduledTransactionById }
        },
        match: {
          params: { entityId }
        }
      } = props;
      await getMemberScheduledTransactionById(entityId);
    }
  }),
  withNamespace('handlers', ['getSelectedScheduledPayment'])
): HOC<*, *>);
