// @flow
import { object } from 'yup';
import { pin } from '../../../../utils/validation/requestAccessSchema';
import { showAcceptAccessModal } from '../../../../utils/getMemberAccessFromState';
import ERRORS_CODE from '../utils';

const initialValue = {
  pin: ''
};

export default {
  mapPropsToValues: () => initialValue,
  validationSchema: object({ pin }),
  handleSubmit: (values, { props }) => {
    const {
      handlers: { goBack },
      actions: { member },
      match: { params },
      showError
    } = props;

    const { acceptGrantAccess, getMember } = member;
    const { familyMemberId, memberId } = params;

    acceptGrantAccess({
      id: familyMemberId,
      ...values
    })
      .then(async ({ hipaaAccesses }) => {
        await goBack();

        showAcceptAccessModal({ hipaaAccesses, params });
      })
      .catch(({ data }) => {
        const { error = {} } = data || {};
        showError(error.code);
        if (error.code === ERRORS_CODE.PIN_MAX_ATTEMPTS_EXCEEDED)
          getMember(memberId);
      });
  },
  enableReinitialize: true,
  validateOnChange: true
};
