// @flow
import React, { Fragment, type Node } from 'react';
import type { PaymentModel } from '../../../../../../models/payment/types';
import type { Formik } from '../../../../../../utils/types';

type Props = {
  payment: PaymentModel,
  formik: Formik
};

export default (props: Props): Node => {
  const {
    payment: { highlightCardNumber },
    formik: {
      touched,
      errors,
      values: { cardNumber }
    }
  } = props;

  return (
    <Fragment>
      {highlightCardNumber && (
        <rect
          transform="translate(10, 50)"
          fill="#25a584"
          fillOpacity="0.2"
          stroke="#25a584"
          width="210"
          height="40"
        />
      )}
      {!highlightCardNumber && touched.cardNumber && errors.cardNumber && (
        <rect
          transform="translate(10, 50)"
          fill="#c9364d"
          fillOpacity="0.2"
          stroke="#c9364d"
          width="210"
          height="40"
        />
      )}
      <g id="card-number" mask="url(#mask-5)">
        <g transform="translate(22.000000, 55.000000)">
          <text id="XXXX" fontSize="14" fontWeight="400">
            <tspan x="0" y="25" fill="#41444F">
              {cardNumber ? cardNumber.slice(0, 4) : 'XXXX'}
            </tspan>
          </text>
          <text
            id="X1"
            fontSize="6"
            fontWeight="normal"
            letterSpacing="1.285714"
          >
            <tspan x="1" y="6" fill="#A3A7BA">
              CARD NUMBER
            </tspan>
          </text>
          <text id="X2" fontSize="14" fontWeight="400">
            <tspan x="50.4" y="25" fill="#41444F">
              {cardNumber.length > 5 ? cardNumber.slice(5, 9) : 'XXXX'}
            </tspan>
          </text>
          <text id="X3" fontSize="14" fontWeight="400">
            <tspan x="100.8" y="25" fill="#41444F">
              {cardNumber.length > 10 ? cardNumber.slice(10, 15) : 'XXXX'}
            </tspan>
          </text>
          <text id="X4" fontSize="14" fontWeight="400">
            <tspan x="152" y="25" fill="#41444F">
              {cardNumber.length > 15 ? cardNumber.slice(15) : 'XXXX'}
            </tspan>
          </text>
        </g>
      </g>
    </Fragment>
  );
};
