// @flow
import { object, boolean } from 'yup';

const initialValue = {
  confirmHIPAA: ''
};

export default (handleSubmit) => ({
  mapPropsToValues: () => initialValue,
  validationSchema: object({
    confirmHIPAA: boolean().required()
  }),
  handleSubmit,
  enableReinitialize: true,
  validateOnChange: false
});
