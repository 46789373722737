import { object, string } from 'yup';
import { date as formatDate } from '../../components/i18n';
import REG_EXP from '../../config/regular-expressions.config';
import { dateValidation } from './../index';
import { DateTime } from 'luxon';

const maxDate = new Date();
const minDate = DateTime.fromJSDate(new Date())
  .minus({ years: 93 })
  .minus({ days: 1 })
  .toJSDate();
const parseDate = (value) =>
  formatDate({
    value,
    day: '2-digit',
    month: '2-digit',
    year: 'numeric'
  });

export default object({
  firstName: string().required('errors.first-name-required'),
  lastName: string().required('errors.last-name-required'),
  accountNumber: string().when(['validatorType'], {
    is: 'account-number',
    then: string().required('errors.account-number-required'),
    otherwise: string().notRequired()
  }),
  ssnMask: string().when(['validatorType'], {
    is: 'ssn',
    then: string()
      .test(
        'maskPlaceholder',
        'errors.ssn-required',
        (value) => value !== 'XXX-XX-XXXX'
      )
      .matches(REG_EXP.ssnMask, 'errors.invalid-ssn-format'),
    otherwise: string().notRequired()
  }),
  zip: string().required('errors.zip-required'),
  dateOfBirth: string()
    .required('errors.birth-date')
    .min(minDate, {
      title: 'errors.birth-date-min',
      values: {
        date: parseDate(minDate)
      }
    })
    .max(maxDate, {
      title: 'errors.birth-date-max',
      values: {
        date: parseDate(maxDate)
      }
    })
    .test('is-valid-date', 'errors.invalid-date', (value = '') => {
      const newDate =
        value && value.length > 11
          ? DateTime.fromJSDate(new Date(value)).toFormat('MM/dd/yyyy')
          : value;
      return dateValidation(newDate);
    })
});
