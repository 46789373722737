import React from 'react';
import { object, string, number, ref, boolean } from 'yup';
import { t, number as formatNumber } from '../../components';
import { AMOUNT_OPTIONS, PAYMENT_TYPE } from '../../config/payment.config';
import REG_EXP from '../../config/regular-expressions.config';
import { MAX_AMOUNT_DECIMAL, MIN_AMOUNT_DECIMAL } from '../../config';

const PAYMENT_ERROR_INVALID = 'payment.errors.invalid-number';
const ERRORS_MIN_AMOUNT = 'errors.min-amount';
const ERRORS_MAX_AMOUNT_RECCURING = 'errors.max-amount-recurring';

export default object().shape({
  paymentType: string().nullable(),
  type: string().nullable(),
  amount: number().nullable(),
  otherAmount: number()
    .nullable()
    .meta({ type: 'currency' })
    .when(['type'], {
      is: (type) => type === AMOUNT_OPTIONS.OTHER,
      then: number()
        .required('payment.errors.amount-required')
        .test('isNumber', PAYMENT_ERROR_INVALID, function isNumber() {
          return Number.isInteger(+this.options.originalValue)
            ? REG_EXP.isNumber.test(this.options.originalValue)
            : REG_EXP.twoDigitsAfterDot.test(this.options.originalValue);
        })
        .typeError(PAYMENT_ERROR_INVALID)
        .min(0.01, ({ min }) => ({
          title: ERRORS_MIN_AMOUNT,
          values: { min: formatNumber(min) }
        })),
      otherwise: number().notRequired()
    })
    .when(['paymentType'], {
      is: (paymentType) => paymentType === PAYMENT_TYPE.ONE_TIME_PAYMENT,
      then: number()
        .typeError(PAYMENT_ERROR_INVALID)
        .max(ref('balanceDue'), ({ max }) => ({
          title: 'errors.max-amount',
          values: {
            fullBalance: <b>{t('errors.balance')}</b>,
            max: <b>{formatNumber(max)}</b>
          }
        })),
      otherwise: number()
        .typeError(PAYMENT_ERROR_INVALID)
        .max(MAX_AMOUNT_DECIMAL, ({ max }) => ({
          title: ERRORS_MAX_AMOUNT_RECCURING,
          values: { max: formatNumber(max) }
        }))
    }),
  repaymentAccepted: boolean().when(['type'], {
    is: (type) =>
      type === AMOUNT_OPTIONS.MINIMUM_PAYMENT_DUE ||
      type === AMOUNT_OPTIONS.OTHER,
    then: boolean()
      .oneOf([true], 'errors.repayment-option')
      .required('errors.repayment-option'),
    otherwise: boolean().notRequired()
  }),
  maxAmountFull: number()
    .meta({ type: 'currency' })
    .min(MIN_AMOUNT_DECIMAL, ({ min }) => ({
      title: ERRORS_MIN_AMOUNT,
      values: { min: formatNumber(min) }
    }))
    .max(MAX_AMOUNT_DECIMAL, ({ max }) => ({
      title: ERRORS_MAX_AMOUNT_RECCURING,
      values: { max: formatNumber(max) }
    })),
  maxAmountMin: number()
    .meta({ type: 'currency' })
    .min(MIN_AMOUNT_DECIMAL, ({ min }) => ({
      title: ERRORS_MIN_AMOUNT,
      values: { min: formatNumber(min) }
    }))
    .max(MAX_AMOUNT_DECIMAL, ({ max }) => ({
      title: ERRORS_MAX_AMOUNT_RECCURING,
      values: { max: formatNumber(max) }
    })),
  maxAmountLastStatement: number()
    .meta({ type: 'currency' })
    .min(MIN_AMOUNT_DECIMAL, ({ min }) => ({
      title: ERRORS_MIN_AMOUNT,
      values: { min: formatNumber(min) }
    }))
    .max(MAX_AMOUNT_DECIMAL, ({ max }) => ({
      title: ERRORS_MAX_AMOUNT_RECCURING,
      values: { max: formatNumber(max) }
    }))
});
