// @flow
import React, { Fragment, type Node } from 'react';
import { Col, FormText, Row } from 'reactstrap';
import { Input } from 'components/Form';
import { Button, t } from 'components';
import styles from 'containers/Settings/settings.module.css';
import type { Actions, Formik } from 'utils/types';
import type { MemberModel } from 'models/member/types';
import { featureFlagKeys } from '../../../config';

type Props = {
  actions: Actions,
  member: MemberModel,
  formik: Formik,
  boxConfig: Object
};

export default (props: Props): Node => {
  const {
    actions: {
      member: { resendCommunicationEmail, cancelCommunicationEmail }
    },
    flags,
    member: {
      data: {
        member: {
          contactInfo: {
            email,
            login,
            secondaryEmail,
            communicationEmailMissing
          }
        }
      }
    },
    formik
  } = props;
  const { email: secondEmail } = secondaryEmail || {};

  const cancelEditEmail =
    secondaryEmail &&
    secondEmail &&
    secondEmail !== email &&
    secondEmail !== login;

  const emailMissing =
    communicationEmailMissing && flags[featureFlagKeys.oktaLoginMigration];

  return (
    <Fragment>
      <Row>
        <Col sm={12} md={7}>
          {emailMissing && (
            <Row>
              <Col>
                <div className={styles.emailSent}>
                  <p className="m-0">
                    {t(
                      'settings.settings-blocks.contact-info.communication-email-missing'
                    )}
                  </p>
                </div>
              </Col>
            </Row>
          )}
          <Input
            name="communicationEmail"
            className="communication"
            title="settings.settings-blocks.contact-info.email"
            type="email"
            {...formik}
          />
          <FormText className="pt-1" data-cy="communication-description">
            {t('settings.settings-blocks.contact-info.email-reset')}
          </FormText>
        </Col>
      </Row>
      {cancelEditEmail && (
        <Row>
          <Col>
            <div className={styles.emailSent}>
              <p className="m-0">
                {t('settings.settings-blocks.contact-info.email-sent', {
                  email: <b>{secondEmail}</b>
                })}
              </p>
              <p>{t('settings.settings-blocks.contact-info.change-request')}</p>
              <Button
                type="button"
                title="settings.settings-blocks.contact-info.cancel-changed-email"
                size="sm"
                onClick={cancelCommunicationEmail}
              />
              <Button
                type="button"
                title="settings.settings-blocks.contact-info.reset-confirmation"
                onClick={resendCommunicationEmail}
                size="sm"
              />
            </div>
          </Col>
        </Row>
      )}
    </Fragment>
  );
};
