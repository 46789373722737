import { settingsSchemaSms } from '../../utils/validation';
import type { Models } from '../../utils/types';

const settingsFormConfig = {
  mapPropsToValues: (props: Models): Object => {
    const {
      member: {
        data: { member }
      }
    } = props;

    const {
      contactInfo: { email, secondaryEmail, communicationPhone },
      statementsType: statementType,
      language,
      smsMessagingConfig: smsMessagingConfigMember,
      timeZoneId
    } = member;

    return {
      communicationPhone:
        communicationPhone && communicationPhone.number !== null
          ? communicationPhone.number
          : '',
      communicationEmail: secondaryEmail ? secondaryEmail.email : email,
      statementType,
      language,
      timeZoneId,
      smsMessagingConfig: smsMessagingConfigMember?.config?.status
    };
  },

  validationSchema: settingsSchemaSms,

  handleSubmit: (values: Object, props: Object): void => {
    const {
      props: {
        member: {
          data: { member }
        },
        actions: {
          member: { updateSettings }
        }
      }
    } = props;
    const {
      contactInfo: { communicationPhone }
    } = member;
    const isSameCommunicationNumber =
      communicationPhone?.number === values.communicationPhone;
    const isSmsKeepEnabled =
      member.smsMessagingConfig?.config?.status === 'ENABLED' &&
      values.smsMessagingConfig === 'ENABLED';
    const regularSmsValue =
      values.smsMessagingConfig === 'DISABLED' ? 'DISABLED' : 'CONFIRMING';
    const smsMessagingConfig = {
      config: {
        configTypeKey: 'SMS_MESSAGING',
        status:
          isSameCommunicationNumber && isSmsKeepEnabled
            ? 'ENABLED'
            : regularSmsValue
      }
    };

    updateSettings(
      Object.assign({}, values, {
        communicationPhone: values.communicationPhone || null,
        communicationEmail: values.communicationEmail || null,
        smsMessagingConfig
      })
    );
  },
  enableReinitialize: true,
  validateOnChange: true
};

export default settingsFormConfig;
