import { compose } from 'recompose';
import { withModal, withMapStateDispatch, withFormik } from '../../../../hoc';
import AcceptGrant from './AcceptGrant';
import formConfig from './formConfig';
import withHandleAccessErrors from '../utils/withHandleAccessErrors';

export default compose(
  withModal,
  withMapStateDispatch,
  withHandleAccessErrors,
  withFormik(formConfig)
)(AcceptGrant);
