// @flow
import React, { Fragment, useEffect, useState } from 'react';
import { Col, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { find, path, pipe } from 'ramda';
import { t, Button } from '../../../../components';
import styles from '../../../../components/Modal/modals.module.css';

type RevokeAccess = {
  handlers: { goBack: Function },
  formik: Object,
  match: Object
};

export default (props: RevokeAccess) => {
  const {
    handlers: { goBack },
    formik: { handleSubmit },
    match: {
      params: { familyMemberId }
    }
  } = props;

  const [name, setName] = useState();

  useEffect(() => {
    const item = pipe(
      path(['member', 'data', 'member', 'familyMembers']),
      find(({ id }) => id === +familyMemberId)
    )(props);

    if (item) {
      const { firstName, lastName } = item;
      setName(`${firstName} ${lastName}`);
    }
  }, [familyMemberId, props]);

  return (
    <Fragment>
      <ModalHeader tag="h1" toggle={goBack} data-cy="modal-header">
        {t('home.revoke-access-modal.heading')}
      </ModalHeader>

      <ModalBody data-cy="modal-body">
        <Row>
          <Col>
            {t('home.revoke-access-modal.description', {
              name: <b>{name}</b>
            })}
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter className={styles.modalControls} data-cy="modal-footer">
        <Button
          primary
          type="submit"
          onClick={handleSubmit}
          title="home.revoke-access-modal.revoke"
        />
        <Button
          title="home.revoke-access-modal.cancel"
          cy="close"
          onClick={goBack}
        />
      </ModalFooter>
    </Fragment>
  );
};
