// @flow
import React, { Fragment, lazy, Suspense, type Node, useEffect } from 'react';
import classNames from 'classnames';
import { Container } from 'reactstrap';
import type { Match, History } from 'react-router-dom';
import useUpdateFreshMemberDataOnPropsChange from '../useUpdateFreshMemberDataOnPropsChange';
import FamilyMembers from './FamilyMembers';
import styles from './home.module.css';
import type {
  MemberModel,
  ScrollHandler,
  StatusTypes
} from '../../../models/member/types';
import type { Actions } from '../../../utils/types';
import { accessToDependent18 } from '../../../utils/getMemberAccessFromState';
import { MEMBER_TYPES } from 'config/member.config';
import { useRollbarPerson } from '@rollbar/react';

const Balance = lazy(() => import('./Balance'));
const Status = lazy(() => import('./Status'));
const NoAccess = lazy(() => import('./Status/NoAccess'));
const Lists = lazy(() => import('./Lists'));

type Props = {
  actions: Actions,
  history: History,
  match: Match,
  member: MemberModel,
  status: StatusTypes,
  scroll: ScrollHandler
};

const Home = (props: Props): Node => {
  const {
    actions: {
      member: { getFamilyMember, getMember, getMemberSuppressedStatements }
    },
    member: {
      data: {
        member: {
          accountNumber,
          id: currentMemberId,
          tosAccepted = true,
          type: currentMemberType
        },
        familyMember: { hasAccess, balanceDetails, id }
      }
    },
    match: {
      params: { memberId }
    },
    status: { NO_BALANCE },
    scroll: { inputEl }
  } = props;

  const trackId = accountNumber || 'not initialized';

  useRollbarPerson({ id: trackId });

  const { balance } = balanceDetails || {};

  const headerStyles: string = classNames({
    [styles.header]: true,
    [styles.negativeBalance]: balance < 0
  });

  useEffect(() => {
    if (hasAccess && tosAccepted) {
      getMemberSuppressedStatements(id || currentMemberId);
    }
  }, [currentMemberId, id, getMemberSuppressedStatements, tosAccepted]);

  useUpdateFreshMemberDataOnPropsChange({
    getFamilyMember,
    getMember,
    familyMemberId: id,
    memberId
  });

  const hasAccessToDependent18 = accessToDependent18(currentMemberType);

  const isCurrentMemberDependent = () =>
    currentMemberType === MEMBER_TYPES.DEPENDENT;

  return (
    <Fragment>
      <div className={styles.memberTilesArea} data-testid="home-container">
        <FamilyMembers {...props} errorMainLayout={false} />
      </div>
      <div data-cy="balance" className={headerStyles} ref={inputEl}>
        <Container>
          <Suspense fallback={null}>
            {!hasAccess &&
            (isCurrentMemberDependent || hasAccessToDependent18) ? (
              <NoAccess {...props} />
            ) : (
              <Fragment>
                <Status {...props} />
                {!NO_BALANCE && <Balance {...props} />}
              </Fragment>
            )}
          </Suspense>
        </Container>
      </div>
      <Suspense fallback={null}>{hasAccess && <Lists {...props} />}</Suspense>
    </Fragment>
  );
};

export default Home;
