import React from 'react';
import { Col, Row } from 'reactstrap';
import { Button, t } from '../../../../components';
import styles from '../../payment.module.css';

export default (props) => {
  const { title, buttonTitle, dialog, values, onClick } = props;

  return (
    <Row>
      <Col className={styles.dialogBox}>
        <div className={styles.icon}></div>
        <div data-testid="dialog-title" className={styles.dialogTitle}>
          {<span>{t(title)}</span>}
        </div>
        {values ? (
          <div data-testid="dialog-text" className={styles.dialogText}>
            {t(dialog, values)}
          </div>
        ) : (
          <div data-testid="dialog-text" className={styles.dialogText}>
            {t(dialog)}
          </div>
        )}

        <Button onClick={onClick} type="button" title={buttonTitle} />
      </Col>
    </Row>
  );
};
