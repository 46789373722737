// @flow
import React, { type Node } from 'react';
import type { Formik } from '../../../../../../utils/types';

export default (props: Formik): Node => {
  const {
    formik: {
      values: { fullName },
      touched,
      errors
    }
  } = props;

  return (
    <g
      id="card-holder"
      mask="url(#mask-5)"
      fontFamily="Roboto, sans-serif"
      fontWeight="normal"
    >
      <g transform="translate(22.000000, 106.000000)">
        <text id="John-Doe" fontSize="11">
          <tspan
            x="0"
            y="21.6"
            fill={touched.fullName && errors.fullName ? '#c9364d' : '#41444F'}
          >
            {fullName || 'John Doe'}
          </tspan>
        </text>
        <text id="Cardholder-Name" fontSize="6" letterSpacing="1.285714">
          <tspan x="1" y="6" fill="#A3A7BA">
            CARDHOLDER NAME
          </tspan>
        </text>
      </g>
    </g>
  );
};
