// @flow

export default {
  data: {
    member: {},
    familyMember: {},
    list: {},
    selectedTransaction: {},
    languages: [],
    suppressedStatements: {
      config: {
        suppressed: false
      },
      updatedAt: ''
    }
  },
  error: null
};
