// @flow
import React, { type Node } from 'react';
import { t } from '../components/i18n';

export const HEALTH_BRIDGE = {
  URL: 'www.myhealthbridge.com',
  PRIVACY: 'www.myhealthbridge.com/privacy',
  SECURITY_EMAIL: 'security@myhealthbridge.com',
  MEMBER_HELP_EMAIL: 'memberhelp@myhealthbridge.com',
  PHONE_NUMBER: '(800) 931-8890',
  LAST_UPDATE: '2018-06-21',
  ADDRESS: {
    CITY: 'GRAND RAPIDS, MI 49512',
    STREET: '3200 BROADMOOR AVE SE'
  }
};

const HEALTH_BRIDGE_PRIVACY = 'Health Bridge Privacy';

const setLink = ({ href, title }: Object): Node => (
  <a
    href={`https://${href}`}
    target="_blank"
    title={title || 'Health Bridge'}
    rel="noopener noreferrer"
  >
    {href}
  </a>
);

const setMailLink = ({ href }: Object): Node => (
  <a href={`mailto:${href}`} title={href}>
    {href}
  </a>
);

const addTerm = (id: string, options: Object) =>
  t(`terms-and-conditions.terms-and-conditions.${id}`, options);

export const TERMS_AND_CONDITIONS: Array<Object> = [
  {
    key: addTerm('term-p1', {
      link: setLink({ href: HEALTH_BRIDGE.URL }),
      healthBridge: <b>{addTerm('health-bridge')}</b>,
      our: <b>{addTerm('our')}</b>,
      we: <b>{addTerm('we')}</b>,
      webSite: <b>{addTerm('web-site')}</b>
    })
  },
  { key: addTerm('term-p2') },
  {
    key: addTerm('term-p3', { account: <b>{addTerm('account')}</b> }),
    terms: [
      {
        key: addTerm('term-1', {
          heading: (
            <b>
              {addTerm('convenience', {
                counter: 1
              })}
            </b>
          ),
          law: <b>{addTerm('law')}</b>
        })
      },
      {
        key: addTerm('term-2', {
          heading: (
            <b>
              {addTerm('site', {
                counter: 2
              })}
            </b>
          )
        })
      },
      {
        key: (
          <b>
            {addTerm('term-3', {
              counter: 3
            })}
          </b>
        ),
        subTerms: [
          {
            key: addTerm('term-4', {
              heading: (
                <b>
                  {addTerm('definitions', {
                    counter: 'a'
                  })}
                </b>
              )
            }),
            subTerms: [
              {
                key: addTerm('term-sub1', {
                  counter: <b>I</b>
                })
              },
              {
                key: addTerm('term-5', {
                  counter: <b>II</b>
                })
              },
              {
                key: addTerm('term-6', {
                  counter: <b>III</b>,
                  link: setLink({
                    href: HEALTH_BRIDGE.PRIVACY,
                    title: HEALTH_BRIDGE_PRIVACY
                  })
                })
              },
              {
                key: addTerm('term-7', {
                  counter: <b>IV</b>
                })
              },
              {
                key: addTerm('term-8', {
                  counter: <b>V</b>
                })
              },
              {
                key: addTerm('term-9', {
                  counter: <b>VI</b>
                })
              },
              {
                key: addTerm('term-10', {
                  counter: <b>VII</b>
                })
              },
              {
                key: addTerm('term-11', {
                  counter: <b>VIII</b>
                })
              }
            ]
          },
          {
            key: addTerm('term-12', {
              heading: (
                <b>
                  {addTerm('access', {
                    counter: 'b'
                  })}
                </b>
              ),
              link: setLink({ href: HEALTH_BRIDGE.URL }),
              phone: HEALTH_BRIDGE.PHONE_NUMBER
            })
          },
          {
            key: addTerm('term-13', {
              heading: (
                <b>
                  {addTerm('password', {
                    counter: 'c'
                  })}
                </b>
              ),
              link: setMailLink({ href: HEALTH_BRIDGE.SECURITY_EMAIL }),
              phone: HEALTH_BRIDGE.PHONE_NUMBER
            })
          },
          {
            key: addTerm('term-14', {
              heading: (
                <b>
                  {addTerm('reliance', {
                    counter: 'd'
                  })}
                </b>
              )
            })
          },
          {
            key: addTerm('term-15', {
              heading: (
                <b>
                  {addTerm('security', {
                    counter: 'e'
                  })}
                </b>
              )
            }),
            subTerms: [
              {
                key: addTerm('term-sub2', {
                  counter: <b>1</b>
                })
              },
              {
                key: addTerm('term-16', {
                  counter: <b>2</b>
                })
              },
              {
                key: addTerm('term-17', {
                  counter: <b>3</b>
                })
              },
              {
                key: addTerm('term-18', {
                  counter: <b>4</b>
                })
              }
            ]
          },
          {
            key: (
              <b>
                {addTerm('term-19', {
                  counter: 'f'
                })}
              </b>
            ),
            subTerms: [
              {
                key: addTerm('term-20', {
                  counter: <b>I</b>,
                  link: setMailLink({ href: HEALTH_BRIDGE.SECURITY_EMAIL }),
                  phone: HEALTH_BRIDGE.PHONE_NUMBER
                })
              },
              {
                key: addTerm('term-21', {
                  counter: <b>II</b>,
                  phone: HEALTH_BRIDGE.PHONE_NUMBER
                })
              }
            ]
          },
          {
            key: addTerm('term-22', {
              heading: (
                <b>
                  {addTerm('permissible', {
                    counter: 'g'
                  })}
                </b>
              )
            }),
            subTerms: [
              {
                key: addTerm('term-sub3', {
                  counter: <b>I</b>
                })
              },
              {
                key: addTerm('term-23', {
                  counter: <b>II</b>
                })
              },
              {
                key: addTerm('term-24', {
                  counter: <b>III</b>
                })
              },
              {
                key: addTerm('term-25', {
                  counter: <b>IV</b>
                })
              }
            ]
          },
          {
            key: addTerm('term-26', {
              heading: (
                <b>
                  {addTerm('payments', {
                    counter: 'h'
                  })}
                </b>
              )
            })
          },
          {
            key: addTerm('term-27', {
              heading: (
                <b>
                  {addTerm('cancel', {
                    counter: 'i'
                  })}
                </b>
              )
            })
          },
          {
            key: addTerm('term-28', {
              heading: (
                <b>
                  {addTerm('disclosure', {
                    counter: 'j'
                  })}
                </b>
              ),
              link: setLink({
                href: HEALTH_BRIDGE.PRIVACY,
                title: HEALTH_BRIDGE_PRIVACY
              })
            }),

            subTerms: [
              {
                key: addTerm('term-sub4', {
                  counter: <b>I</b>
                })
              },
              {
                key: addTerm('term-29', {
                  counter: <b>II</b>
                })
              },
              {
                key: addTerm('term-30', {
                  counter: <b>III</b>
                })
              },
              {
                key: addTerm('term-31', {
                  counter: <b>IV</b>
                })
              },
              {
                key: addTerm('term-32', {
                  counter: <b>V</b>
                })
              },
              {
                key: addTerm('term-33', {
                  counter: <b>VI</b>
                })
              },
              {
                key: addTerm('term-34', {
                  counter: <b>VII</b>
                })
              }
            ]
          },
          {
            key: addTerm('term-35', {
              heading: (
                <b>
                  {addTerm('insufficient', {
                    counter: 'k'
                  })}
                </b>
              )
            })
          },
          {
            key: addTerm('term-36', {
              heading: (
                <b>
                  {addTerm('liability', {
                    counter: 'i'
                  })}
                </b>
              )
            }),
            subTerms: [
              {
                key: addTerm('term-sub5', {
                  counter: <b>I</b>
                })
              },
              {
                key: addTerm('term-37', {
                  counter: <b>II</b>
                })
              },
              {
                key: addTerm('term-38', {
                  counter: <b>III</b>
                })
              },
              {
                key: addTerm('term-39', {
                  counter: <b>IV</b>
                })
              },
              {
                key: addTerm('term-40', {
                  counter: <b>V</b>
                })
              },
              {
                key: addTerm('term-41', {
                  counter: <b>VI</b>
                })
              },
              {
                key: addTerm('term-42', {
                  counter: <b>VII</b>
                })
              },
              {
                key: addTerm('term-43', {
                  counter: <b>VIII</b>
                })
              }
            ]
          },
          {
            key: addTerm('term-44', {
              heading: (
                <b>
                  {addTerm('accurate', {
                    counter: 'm'
                  })}
                </b>
              )
            })
          },
          {
            key: addTerm('term-45', {
              heading: (
                <b>
                  {addTerm('termination', {
                    counter: 'n'
                  })}
                </b>
              ),
              phone: HEALTH_BRIDGE.PHONE_NUMBER
            })
          },
          {
            key: addTerm('term-46', {
              heading: (
                <b>
                  {addTerm('related', {
                    counter: 'o'
                  })}
                </b>
              )
            })
          },
          {
            key: addTerm('term-47', {
              heading: (
                <b>
                  {addTerm('errors', {
                    counter: 'p'
                  })}
                </b>
              ),
              phone: HEALTH_BRIDGE.PHONE_NUMBER
            })
          }
        ]
      },
      {
        key: addTerm('term-48', {
          heading: (
            <b>
              {addTerm('disclaimers', {
                counter: 4
              })}
            </b>
          )
        }),
        subTerms: [
          {
            key: addTerm('term-50', {
              heading: (
                <b>
                  {addTerm('indemnification', {
                    counter: 'a'
                  })}
                </b>
              )
            })
          },
          {
            key: addTerm('term-51', {
              heading: (
                <b>
                  {addTerm('advice', {
                    counter: 'b'
                  })}
                </b>
              )
            })
          }
        ]
      },
      {
        key: addTerm('term-52', {
          heading: (
            <b>
              {addTerm('limitation', {
                counter: 5
              })}
            </b>
          )
        })
      },
      {
        key: addTerm('term-53', {
          heading: (
            <b>
              {addTerm('privacy', {
                counter: 6
              })}
            </b>
          ),
          link: setLink({
            href: HEALTH_BRIDGE.PRIVACY,
            title: HEALTH_BRIDGE_PRIVACY
          })
        })
      },
      {
        key: (
          <b>
            {addTerm('term-54', {
              counter: 7
            })}
          </b>
        ),
        subTerms: [
          {
            key: addTerm('term-55', {
              counter: <b>a</b>
            })
          },
          {
            key: addTerm('term-56', {
              counter: <b>b</b>
            })
          }
        ]
      },
      {
        key: addTerm('term-57', {
          heading: (
            <b>
              {addTerm('copyright', {
                counter: 8
              })}
            </b>
          )
        })
      },
      {
        key: addTerm('term-58', {
          heading: (
            <b>
              {addTerm('local', {
                counter: 9
              })}
            </b>
          )
        })
      },
      {
        key: addTerm('term-59', {
          heading: (
            <b>
              {addTerm('availability', {
                counter: 10
              })}
            </b>
          )
        })
      },
      {
        key: addTerm('term-60', {
          heading: (
            <b>
              {addTerm('transferability', {
                counter: 11
              })}
            </b>
          )
        })
      },
      {
        key: addTerm('term-61', {
          heading: (
            <b>
              {addTerm('service', {
                counter: 12
              })}
            </b>
          )
        })
      },
      {
        key: addTerm('term-62', {
          heading: (
            <b>
              {addTerm('comments', {
                counter: 13
              })}
            </b>
          ),
          link: setLink({
            href: HEALTH_BRIDGE.PRIVACY,
            title: HEALTH_BRIDGE_PRIVACY
          })
        })
      },
      {
        key: addTerm('term-63', {
          heading: (
            <b>
              {addTerm('limitation', {
                counter: 14
              })}
            </b>
          ),
          link: setLink({
            href: HEALTH_BRIDGE.PRIVACY,
            title: HEALTH_BRIDGE_PRIVACY
          })
        })
      }
    ]
  },
  {
    heading: addTerm('term-heading1')
  },
  { key: addTerm('term-p5') },
  {
    key: addTerm('term-p6')
  }
];
