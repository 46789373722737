// @flow
import { http } from '../../utils';
import store from '../../store';
import {
  INIT_TIMEOUT,
  PAYMENT_METHODS,
  PAYMENT_STATUSES,
  PAYMENT_TYPE,
  AMOUNT_OPTIONS,
  REPEAT_TIMEOUT,
  MAX_RETRIES
} from '../../config/payment.config';
import type { OpaqueData } from './types';
import type { Models, Response } from '../../utils/types';
import initialState from './state';

const RECURRING_PAYMENT_PATH = '/recurring-payments';
const PAYMENT_REQUEST_TIMEOUT = 10000;

function isFullPaymentType(paymentType) {
  return (
    paymentType === AMOUNT_OPTIONS.FULL ||
    paymentType === AMOUNT_OPTIONS.PAY_IN_FULL ||
    paymentType === AMOUNT_OPTIONS.FULL_STATEMENT
  );
}

export function onAfterPayment(response, state) {
  const { error, result } = response;
  const {
    dispatch: {
      loader,
      payment: { resetRetries, setError, getTransactionStatus }
    }
  } = store;
  if (error && error.apiError) {
    setError({ error: { status: error.apiError }, storeTo: 'transaction' });
    loader.stop();
  } else {
    resetRetries();
    setTimeout(() => {
      getTransactionStatus(result, state);
    }, INIT_TIMEOUT);
  }
}

export function setPaymentError(error) {
  const {
    dispatch: {
      loader,
      payment: { setError }
    }
  } = store;
  loader.stop();

  if (error.apiError) {
    setError({ error: { status: error.apiError }, storeTo: 'transaction' });
  } else {
    setError({
      error,
      storeTo: 'payment',
      duplicationKey: 'PAYMENT_PROFILE'
    });
  }
}

export function logErrorIfNoTransactionId() {
  const {
    dispatch: {
      loader,
      payment: { setError }
    }
  } = store;
  setError({
    error: { status: 'failed' },
    storeTo: 'transaction',
    duplicationKey: 'PAYMENT_PROFILE'
  });
  console.error('No transaction id after success response');
  loader.stop();
}

export function getCards(): Promise<void> {
  const {
    dispatch: {
      loader,
      payment: { setData, setError }
    }
  } = store;
  return http
    .performGET({ path: '/payment-profiles/cards' })
    .then((res: Response) => setData({ cards: res.result }))
    .catch((error: Response) => {
      loader.stop();
      setError({ error, storeTo: 'payment' });
    });
}

export function getEchecks(): Promise<void> {
  const {
    dispatch: {
      payment: { setData, setError }
    }
  } = store;
  return http
    .performGET({ path: '/payment-profiles/echecks' })
    .then((res: Response) => setData({ echecks: res.result }))
    .catch((error: Response) => setError({ error, storeTo: 'payment' }));
}

export function getTransactionStatus(params: Object, state): void {
  const { id, errorCallback, successCallback } = params;
  if (!id) {
    logErrorIfNoTransactionId();
    return;
  }
  const {
    dispatch: {
      payment: { setPaymentError }
    }
  } = store;

  const {
    payment: { retries }
  } = state;

  http
    .performGET({ path: `/payments/transactions/${id}` })
    .then(({ result }: Response) => {
      const {
        dispatch: {
          loader,
          payment: {
            setError,
            resetRetries,
            setData,
            getTransactionStatus,
            addRetry
          }
        }
      } = store;
      const { status } = result;
      if (status) {
        if (~PAYMENT_STATUSES.SUCCESS.indexOf(status)) {
          successCallback ? successCallback() : setData({ successView: true });
        }
        if (~PAYMENT_STATUSES.FAIL.indexOf(status)) {
          errorCallback
            ? errorCallback()
            : setError({ error: result, storeTo: 'transaction' });
        }

        loader.stop();
        resetRetries();
        if (
          ![...PAYMENT_STATUSES.FAIL, ...PAYMENT_STATUSES.SUCCESS].includes(
            status
          )
        ) {
          console.error(
            `Transaction: ${id}, status: ${status}, is not in the list of success or failed statuses`
          );
        }
      } else {
        if (retries >= MAX_RETRIES) {
          loader.stop();
          setError({
            error: { status: 'failed' },
            storeTo: 'transaction'
          });
          resetRetries();
          console.error(
            `For transaction: ${id}, we reached maximum of retries to get the status`
          );
        } else {
          setTimeout(() => {
            getTransactionStatus(params, state);
          }, REPEAT_TIMEOUT);
          addRetry();
        }
      }
    }, setPaymentError)
    .catch(setPaymentError);
}

export const handleNonSavedPayment = (state, transactionStatus: Function) => (
  result
) => {
  if (!result.id) {
    logErrorIfNoTransactionId.bind(this)(state)();
  } else {
    this.resetRetries(state);
    setTimeout(() => {
      transactionStatus(result, state);
    }, INIT_TIMEOUT);
  }
};

export function payByCard({ dataValue }: OpaqueData, state: Models): void {
  const {
    payment: {
      otherAmount,
      amount,
      expirationDate,
      saveToProfile,
      repaymentAccepted,
      type
    },
    member: {
      data: {
        familyMember: { id }
      }
    }
  } = state;

  const {
    dispatch: {
      payment: { onAfterPayment, setError, setPaymentError }
    }
  } = store;

  const path: string = id ? `/payments/${id}/cards` : '/payments/cards';

  setError();

  http
    .performPOST({
      path,
      params: {
        repaymentAccepted: isFullPaymentType(type) ? true : repaymentAccepted
      },
      data: {
        amount: +(otherAmount || amount),
        expirationDate,
        opaqueDataValue: dataValue,
        saveToProfile
      },
      timeout: PAYMENT_REQUEST_TIMEOUT
    })
    .then(onAfterPayment, setPaymentError)
    .catch(setPaymentError);
}

export function payBySavedCard(cardId: number, state: Models): void {
  const {
    payment: { amount, otherAmount, repaymentAccepted, type },
    member: {
      data: {
        familyMember: { id }
      }
    }
  } = state;

  const {
    dispatch: {
      payment: { onAfterPayment, setError, setPaymentError }
    }
  } = store;

  const path: string = id
    ? `/payments/${id}/cards/${cardId}`
    : `/payments/cards/${cardId}`;
  // eslint-disable-next-line no-console
  console.log({ path });

  setError();

  http
    .performPOST({
      path,
      params: {
        repaymentAccepted: isFullPaymentType(type) ? true : repaymentAccepted
      },
      data: {
        amount: +(otherAmount || amount)
      },
      timeout: PAYMENT_REQUEST_TIMEOUT
    })
    .then(onAfterPayment, setPaymentError)
    .catch(setPaymentError);
}

export function payByEcheck({ dataValue }: OpaqueData, state: Models): void {
  const {
    payment: {
      otherAmount,
      amount,
      routingNumber,
      saveToProfile,
      repaymentAccepted,
      type
    },
    member: {
      data: {
        familyMember: { id }
      }
    }
  } = state;

  const {
    dispatch: {
      payment: { onAfterPayment, setError, setPaymentError }
    }
  } = store;

  const path: string = id ? `/payments/${id}/echecks` : '/payments/echecks';

  setError();

  http
    .performPOST({
      path,
      params: {
        repaymentAccepted: isFullPaymentType(type) ? true : repaymentAccepted
      },
      data: {
        amount: +(otherAmount || amount),
        routingNumber,
        opaqueDataValue: dataValue,
        saveToProfile
      }
    })
    .then(onAfterPayment, setPaymentError)
    .catch(setPaymentError);
}

export function payBySavedEcheck(echeckId: number, state: Models): void {
  const {
    payment: { amount, otherAmount, repaymentAccepted, type },
    member: {
      data: {
        familyMember: { id }
      }
    }
  } = state;

  const {
    dispatch: {
      payment: { onAfterPayment, setError, setPaymentError }
    }
  } = store;

  const path: string = id
    ? `/payments/${id}/echecks/${echeckId}`
    : `/payments/echecks/${echeckId}`;

  setError();

  http
    .performPOST({
      path,
      params: {
        repaymentAccepted: isFullPaymentType(type) ? true : repaymentAccepted
      },
      data: {
        amount: +(otherAmount || amount)
      },
      timeout: PAYMENT_REQUEST_TIMEOUT
    })
    .then(onAfterPayment, setPaymentError)
    .catch(setPaymentError);
}

export function saveCard(data: OpaqueData, state: Models): Promise<void> {
  const { dataValue } = data;
  const {
    payment: { expirationDate, paymentType, editMode, cards }
  } = state;
  const {
    dispatch: {
      toasts,
      payment: {
        setData,
        payBySavedCard,
        recurringPayment,
        scheduledPayment,
        setPaymentError
      }
    }
  } = store;

  return http
    .performPOST({
      path: '/payment-profiles/cards',
      data: {
        expirationDate,
        opaqueDataValue: dataValue
      },
      timeout: PAYMENT_REQUEST_TIMEOUT
    })
    .then(({ result }: Response) => {
      toasts.showToast({ message: 'payment.saved-payment-profile' });

      setData({ cards: [...cards, result] });

      const method: string = editMode ? 'PUT' : 'POST';

      if (paymentType === PAYMENT_TYPE.ONE_TIME_PAYMENT) {
        payBySavedCard(result.id);
      } else if (paymentType === PAYMENT_TYPE.RECURRING_PAYMENT) {
        recurringPayment({
          paymentProfileId: result.id,
          method
        });
      } else if (paymentType === PAYMENT_TYPE.SCHEDULED_PAYMENT) {
        scheduledPayment({
          paymentProfileId: result.id,
          method
        });
      }
    }, setPaymentError)
    .catch(setPaymentError);
}

export function saveEcheck(data: OpaqueData, state: Models): Promise<void> {
  const { dataValue } = data;
  const {
    payment: { routingNumber, paymentType, editMode, echecks }
  } = state;
  const {
    dispatch: {
      toasts,
      payment: {
        payBySavedEcheck,
        setData,
        recurringPayment,
        scheduledPayment,
        setPaymentError
      }
    }
  } = store;

  return http
    .performPOST({
      path: '/payment-profiles/echecks',
      data: {
        routingNumber,
        opaqueDataValue: dataValue
      },
      timeout: PAYMENT_REQUEST_TIMEOUT
    })
    .then(({ result }: Response) => {
      toasts.showToast({ message: 'payment.saved-payment-profile' });

      setData({ echecks: [...echecks, result] });
      const method: string = editMode ? 'PUT' : 'POST';

      if (paymentType === PAYMENT_TYPE.ONE_TIME_PAYMENT) {
        payBySavedEcheck(result.id);
      } else if (paymentType === PAYMENT_TYPE.RECURRING_PAYMENT) {
        recurringPayment({
          paymentProfileId: result.id,
          method
        });
      } else if (paymentType === PAYMENT_TYPE.SCHEDULED_PAYMENT) {
        scheduledPayment({
          paymentProfileId: result.id,
          method
        });
      }
    }, setPaymentError)
    .catch(setPaymentError);
}

export function getRecurringPayment(
  args: Object,
  state: Models
): Promise<void> {
  const {
    member: {
      data: {
        familyMember: { id }
      }
    }
  } = state;

  const {
    dispatch: {
      payment: { setData, setPaymentError }
    }
  } = store;
  return http
    .performGET({
      path: RECURRING_PAYMENT_PATH,
      params: {
        memberId: id
      }
    })
    .then(({ result }: Response) => {
      switch (result.type) {
        case 'MINIMUM_PAYMENT_DUE':
          result.maxAmountMin = result.maxAmount;
          break;
        case 'FULL_STATEMENT':
        case 'PAY_IN_FULL':
          result.maxAmountLastStatement = result.maxAmount;
          break;
        default:
          result.maxAmountFull = result.maxAmount;
          break;
      }

      setData({
        ...result,
        type: result.type === 'PAY_IN_FULL' ? 'FULL_STATEMENT' : result.type,
        otherAmount: result.amount,
        repaymentAccepted: true,
        method: result.card ? PAYMENT_METHODS.CARD : PAYMENT_METHODS.ECHECK,
        [result.card ? 'cardId' : 'echeckId']: result.card
          ? result.card.id
          : result.echeck.id
      });
    }, setPaymentError)
    .catch(setPaymentError);
}

export function recurringPayment(
  { paymentProfileId, method }: Object,
  state: Models
): Promise<void> {
  const {
    member: {
      data: {
        familyMember: { id }
      }
    },
    payment: {
      type,
      paymentDay,
      repaymentAccepted,
      otherAmount,
      amount,
      maxAmountFull,
      maxAmountMin,
      maxAmountLastStatement
    }
  } = state;

  const {
    dispatch: {
      loader,
      payment: { resetPaymentDetails, setPaymentError }
    }
  } = store;

  const recurringType =
    type === 'FULL' || type === 'FULL_STATEMENT' ? 'PAY_IN_FULL' : type;
  const acceptRepayment = isFullPaymentType(type) ? true : repaymentAccepted;

  let setMaxAmount;
  switch (type) {
    case 'FULL':
      setMaxAmount = parseFloat(maxAmountFull);
      break;
    case 'MINIMUM_PAYMENT_DUE':
      setMaxAmount = parseFloat(maxAmountMin);
      break;
    case 'FULL_STATEMENT':
      setMaxAmount = parseFloat(maxAmountLastStatement);
      break;
    default:
      setMaxAmount = null;
      break;
  }

  let sendData;

  if (setMaxAmount) {
    sendData = {
      paymentProfileId,
      type: recurringType,
      paymentDay,
      amount: +(otherAmount || amount),
      maxAmount: setMaxAmount
    };
  } else {
    sendData = {
      paymentProfileId,
      type: recurringType,
      paymentDay,
      amount: +(otherAmount || amount)
    };
  }

  return http[`perform${method}`]({
    path: RECURRING_PAYMENT_PATH,
    params: {
      repaymentAccepted: acceptRepayment,
      memberId: id
    },
    data: { ...sendData }
  })
    .then(({ result }: Response) =>
      resetPaymentDetails({
        successView: true,
        [result.card ? 'cardId' : 'echeckId']: result.card
          ? result.card.id
          : result.echeck.id
      })
    )
    .then(() => loader.stop())
    .catch(setPaymentError);
}

export function deleteRecurringPayment(
  args: Object,
  state: Models
): Promise<void> {
  const {
    payment: { cards, echecks },
    member: {
      memberId,
      data: {
        familyMember: { id = memberId }
      }
    }
  } = state;
  const {
    dispatch: {
      loader,
      toasts: { showToast },
      payment: { setData, setPaymentError }
    }
  } = store;

  setData({ disableButton: true });
  const initial = {
    ...initialState,
    cards,
    echecks,
    disableButton: false
  };
  return http
    .performDELETE({
      path: RECURRING_PAYMENT_PATH,
      params: { memberId: id }
    })
    .then(() => {
      setData(initial);
      showToast({ message: 'toasts.recurring-payment-removed' });
    })
    .then(() => loader.stop())
    .catch(setPaymentError);
}

export function payRecurringPayment(
  params: Object,
  state: Models
): Promise<void> {
  const {
    dispatch: {
      member,
      loader: { start, stop },
      toasts: { showToast },
      payment: {
        getTransactionStatus,
        resetRetries,
        getRecurringPayment,
        setPaymentError
      }
    }
  } = store;

  const {
    member: {
      data: {
        familyMember: { id }
      }
    },
    retries
  } = state;

  start('loaders.processing-payment');
  member.setData({
    familyMember: { balanceDetails: { recurringPayment: null } }
  });

  return http
    .performPOST({
      path: '/recurring-payments/pay',
      params: { memberId: id },
      timeout: PAYMENT_REQUEST_TIMEOUT
    })
    .then(({ result }: Response) => {
      resetRetries();
      setTimeout(() => {
        getTransactionStatus(
          {
            ...result,
            successCallback: () => {
              member.getMember(id);
              showToast({
                message: 'toasts.recurring-payment-pay'
              });
            },
            errorCallback: () => {
              getRecurringPayment().then(() =>
                showToast({
                  message: 'toasts.recurring-payment-failed',
                  type: 'ERROR'
                })
              );
            },
            retries
          },
          state
        );
      }, INIT_TIMEOUT);
    })
    .then(() => stop())
    .catch(setPaymentError);
}

export function scheduledPayment(
  { paymentProfileId, method }: Object,
  state: Models
): Promise<void> {
  const {
    payment: { otherAmount, schedulePaymentDate, editMode },
    member: {
      data: {
        selectedTransaction: { scheduledPaymentId },
        familyMember: { id }
      }
    }
  } = state;

  const {
    dispatch: {
      loader,
      member,
      payment: { resetPaymentDetails, setPaymentError }
    }
  } = store;

  const pathPrefix = '/payments/scheduled';
  const path = editMode ? `${pathPrefix}/${scheduledPaymentId}` : pathPrefix;

  const year = schedulePaymentDate.getFullYear();
  const month = schedulePaymentDate.getMonth() + 1;
  const day = schedulePaymentDate.getDate();

  return http[`perform${method}`]({
    path: path,
    data: {
      type: 'OTHER_AMOUNT',
      amount: otherAmount,
      paymentDay: day,
      paymentProfileId: paymentProfileId,
      startAfter: `${year}-${month <= 9 ? '0' + month : month}-${
        day <= 9 ? '0' + day : day
      }`
    },
    params: {
      memberId: id
    }
  })
    .then(({ result }: Response) => {
      member.updateStore({ selectedTransaction: {} });

      return resetPaymentDetails({
        successView: true,
        [result.card ? 'cardId' : 'echeckId']: result.card
          ? result.card.id
          : result.echeck.id
      });
    })
    .then(() => loader.stop())
    .catch(setPaymentError);
}

export function deleteScheduledPayment(scheduledPaymentId): Promise<void> {
  const {
    dispatch: {
      loader,
      toasts: { showToast },
      payment: { setPaymentError }
    }
  } = store;

  const path = `/payments/scheduled/${scheduledPaymentId}`;

  return http
    .performDELETE({ path: path })
    .then(
      showToast({
        message: 'home.scheduled-payment-details-view.deleted-successfully'
      })
    )
    .then(() => loader.stop())
    .catch(setPaymentError);
}

export function deletePaymentProfile(cardId): Promise<void> {
  const {
    dispatch: {
      loader,
      toasts: { showToast },
      payment: { getCards, getEchecks, setPaymentError }
    }
  } = store;
  const path = `/payment-profiles/${cardId}`;

  return http
    .performDELETE({ path: path })
    .then(() => {
      showToast({
        message: 'delete-card.success-delete'
      });
      return Promise.all([getCards(), getEchecks()]);
    })
    .then(() => {
      loader.stop();
    })
    .catch(setPaymentError);
}

export default () => ({
  getCards,
  getEchecks,
  getTransactionStatus,
  handleNonSavedPayment,
  payByCard,
  payBySavedCard,
  payByEcheck,
  payBySavedEcheck,
  saveCard,
  saveEcheck,
  getRecurringPayment,
  recurringPayment,
  deleteRecurringPayment,
  payRecurringPayment,
  scheduledPayment,
  deleteScheduledPayment,
  onAfterPayment,
  setPaymentError,
  logErrorIfNoTransactionId,
  deletePaymentProfile
});
