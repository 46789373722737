import { object, string } from 'yup';
import REG_EXP from '../../config/regular-expressions.config';

export const pin = string()
  .matches(REG_EXP.fourDigits, {
    message: {
      title: 'home.request-access-modal.form.pin.errors.invalid-pin'
    }
  })
  .required('home.request-access-modal.form.pin.errors.required-pin');

export default object({ pin });
