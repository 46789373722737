import Settings from './Settings';
import { withAppLayout, withFormik } from '../../hoc';
import settingsFormConfigSms from './settingsFormConfigSms';
import { withHeaderConfig, withRoutesConfig, withSettings } from './hoc';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

export default withAppLayout(
  [
    withLDConsumer(),
    withHeaderConfig,
    withRoutesConfig,
    withFormik(settingsFormConfigSms),
    withSettings
  ],
  'member'
)(Settings);
