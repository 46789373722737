// @flow
import { compose, withHandlers, type HOC } from 'recompose';
import { any, equals, flatten, is, values } from 'ramda';
import type { Match } from 'react-router-dom';
import { withNamespace } from '../../../../hoc';
import {
  ENTITY_TYPE,
  ENTITY_TYPE_DETAILS
} from '../../../../config/payment.config';
import type { Actions } from '../../../../utils/types';
import type { MemberModel, Transaction } from '../../../../models/member/types';

type Props = {
  actions: Actions,
  match: Match,
  member: MemberModel
};

export default (compose(
  withHandlers({
    getSelectedTransaction: (props: Props) => async (): Promise<any> => {
      const {
        actions: {
          member: { updateStore, getTransactionDetails }
        },
        match: {
          params: { entityId, entityType }
        },
        member: {
          data: {
            list: { content }
          }
        }
      } = props;
      const list: Array<Transaction> = is(Array, content)
        ? content
        : compose(flatten, values)(content);

      const fromLocalStore = any(equals(entityType))([
        ENTITY_TYPE.ADJUSTMENT,
        ENTITY_TYPE.OVER_PAYMENT_REFUND
      ]);

      if (fromLocalStore) {
        const selectedTransaction: Transaction | void = list.find(
          ({ entityId: eId, entityType: eType }) =>
            eId === +entityId && eType === entityType
        );

        await updateStore({ selectedTransaction });
      } else {
        await getTransactionDetails({
          entityId,
          path: ENTITY_TYPE_DETAILS[entityType]
        });
      }
    }
  }),
  withNamespace('handlers', ['getSelectedTransaction'])
): HOC<*, *>);
