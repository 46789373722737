// @flow
import { lifecycle, type HOC } from 'recompose';
import { showAcceptAccessModal } from '../utils/getMemberAccessFromState';

export default (lifecycle({
  componentDidMount() {
    const {
      member: {
        data: {
          member: { hipaaAccesses }
        }
      },
      match: { params }
    } = this.props;

    showAcceptAccessModal({ hipaaAccesses, params });
  }
}): HOC<*, any>);
