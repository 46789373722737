// @flow
import { memberRegistrationSchema } from '../../../../utils/validation';
import { parseSearchQuery } from '../../../../utils';
import type { FormikConfig } from '../../../../utils/types';

export default ({
  mapPropsToValues: (): Object => ({ email: '', confirmEmail: '' }),
  handleSubmit: (values: Object, props: Object): void => {
    const {
      props: {
        actions: {
          auth: { register }
        },

        location: { search }
      }
    } = props;

    const { verificationToken } = parseSearchQuery(search);

    register({ ...values, verificationToken });
  },
  validationSchema: memberRegistrationSchema,
  validateOnChange: false
}: FormikConfig);
