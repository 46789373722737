// @flow
import { withProps, type HOC } from 'recompose';
import { t } from '../../../components';
import ROUTES from '../../../config/routes.config';

export default (withProps((): Object => ({
  headerConfig: {
    breadcrumbConfig: [
      {
        title: t('common.home'),
        linkTo: ROUTES.BASE_PATH
      },
      { title: t('common.settings') }
    ],
    fixedHeader: true,
    editForm: true
  },
  boxConfig: { small: true }
})): HOC<*, any>);
