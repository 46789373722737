import { compose } from 'recompose';
import PaymentCalendar from './PaymentCalendar';
import { withFormik } from '../../../../hoc';
import { withStepSummary } from '../../hoc';
import paymentCalendarFormConfig from './paymentCalendarFormConfig';

export default compose(
  withFormik(paymentCalendarFormConfig),
  withStepSummary
)(PaymentCalendar);
