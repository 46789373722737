// @flow
import React, { type Node } from 'react';
import { Table } from 'reactstrap';
import TableHeader from './TableHeader';
import TableRow from './TableRow';
import withNoData from '../../hoc/withNoData';
import { TableConfig } from './types';

const TableComponent = (props: TableConfig): Node => {
  const { header, body, data, expand, canExpand } = props;

  return (
    <Table
      hover
      responsive
      className={`${canExpand ? 'collapse' : ''} ${expand ? 'show' : ''}`}
    >
      <TableHeader header={header} />
      <tbody>
        {data.map(
          (dataItem: Object, index: number, list: Array<Object>): Node => (
            <TableRow
              key={index}
              row={{ data: dataItem, index, list }}
              config={body}
            />
          )
        )}
      </tbody>
    </Table>
  );
};

export default withNoData(['data'])(TableComponent);
