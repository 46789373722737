// @flow
import React, { type Node } from 'react';
import classNames from 'classnames';
import styles from '../../payment.module.css';

type Props = {
  message: Node | string,
  error?: boolean
};

export default (props: Props): Node => {
  const { message, error = false } = props;

  const messageStyle: string = classNames({
    [styles.message]: true,
    [styles.error]: error
  });

  return <span className={messageStyle}>{message}</span>;
};
