// @flow
import React, { type Node } from 'react';
import { Col, Row } from 'reactstrap';
import { EnhancedRadio, number, t } from '../../../../components';
import { AMOUNT_OPTIONS } from '../../../../config/payment.config';
import { DateTime } from 'luxon';
import styles from '../../payment.module.css';
import MaxAmount from './MaxAmount';
import type { Handlers, PaymentModel } from '../../../../models/payment/types';
import type { Formik } from '../../../../utils/types';
import type { MemberModel } from '../../../../models/member/types';
import { PAYMENT_TYPE } from 'config/payment.config';

type Props = {
  handlers: Handlers,
  member: MemberModel,
  payment: PaymentModel,
  formik: Formik
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (props: Props): Node => {
  const {
    handlers: { isChecked, helperTextStyle, setAmount },
    member: {
      data: {
        familyMember: {
          balanceDetails: {
            lastStatementQuickPay,
            lastStatementBalance,
            currentStatementQuickPay,
            lastStatementEndDate
          },
          id,
          firstName
        }
      }
    },
    formik: {
      values: { type },
      setFieldValue
    },
    payment: { hasStatement, paymentType }
  } = props;

  const previousStatementEndDate = DateTime.fromSQL(lastStatementEndDate).plus({
    days: 1
  });
  const previousStatementEndDateAdjusted = previousStatementEndDate.toFormat(
    'MM/dd/yyyy'
  );

  const oneTimePayment = paymentType === PAYMENT_TYPE.ONE_TIME_PAYMENT;
  const quickPay = hasStatement
    ? currentStatementQuickPay
      ? currentStatementQuickPay.amount
      : lastStatementQuickPay.amount
    : null;
  const discount = hasStatement
    ? currentStatementQuickPay
      ? currentStatementQuickPay.discountAmount
      : lastStatementQuickPay.discountAmount
    : null;
  const statementBalance = hasStatement
    ? currentStatementQuickPay
      ? (
          currentStatementQuickPay.amount +
          currentStatementQuickPay.discountAmount
        ).toFixed(2)
      : lastStatementBalance
    : null;

  return (
    <Row>
      <Col>
        {hasStatement && oneTimePayment && (
          <h1 className={styles.optionHeading}>
            {t('payment.statement-balance')}
            <span>
              {t('payment.statement-balance-description', {
                date: previousStatementEndDateAdjusted
              })}
            </span>
          </h1>
        )}
        <EnhancedRadio
          name="type"
          id="fullStatementBalance"
          label={
            id
              ? { id: 'payment.pay-fm-statement-in-full', name: firstName }
              : 'payment.pay-statement-in-full'
          }
          value={AMOUNT_OPTIONS.FULL_STATEMENT}
          checked={isChecked(AMOUNT_OPTIONS.FULL_STATEMENT)}
          onChange={() => {
            setFieldValue('type', AMOUNT_OPTIONS.FULL_STATEMENT);
            setFieldValue('repaymentAccepted', true, true);
            setAmount(quickPay);
          }}
        >
          <Row>
            <Col className={styles.labelText} sm={12} md={6}>
              {id
                ? t('payment.pay-fm-statement-in-full', {
                    name: firstName
                  })
                : t('payment.pay-statement-in-full')}
              {oneTimePayment && (
                <div className={styles.amount}>
                  {number(quickPay)}
                  <small>{number(statementBalance)}</small>
                </div>
              )}
              {isChecked(AMOUNT_OPTIONS.FULL_STATEMENT) && !oneTimePayment && (
                <MaxAmount {...props} />
              )}
            </Col>
            {hasStatement && (
              <Col className={styles.selectContent}>
                <p
                  className={helperTextStyle(
                    type,
                    AMOUNT_OPTIONS.FULL_STATEMENT
                  )}
                >
                  {t('payment.full-amount-helper', {
                    number: number(discount)
                  })}
                </p>
              </Col>
            )}
          </Row>
        </EnhancedRadio>
      </Col>
    </Row>
  );
};
