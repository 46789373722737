import React from 'react';
import { Col, Form, FormFeedback, Row } from 'reactstrap';
import { range } from 'ramda';
import classNames from 'classnames';
import { DateTime } from 'luxon';
import { Button, t } from '../../../../components';
import styles from '../../payment.module.css';
import PaymentDay from './PaymentDay';

const dateRange = range(1, 32);

export default (props) => {
  const {
    member: {
      data: {
        familyMember: {
          balanceDetails: { dueDate, nextStatementDate }
        }
      }
    },
    formik: {
      values: { paymentDay },
      setFieldValue,
      setFieldTouched,
      handleSubmit,
      errors
    }
  } = props;

  const submitStyles = classNames(styles.submitButton, 'align-items-end');

  const paymentDates = dateRange.map((day) => {
    const statementDateValue =
      nextStatementDate && DateTime.fromSQL(nextStatementDate).c.day;
    const dueDateValue = dueDate && DateTime.fromSQL(dueDate).c.day;
    const disabled = statementDateValue
      ? [29, 30, 31, statementDateValue].includes(day)
      : [29, 30, 31].includes(day);

    return (
      <PaymentDay
        day={day}
        key={day}
        dueDate={day === dueDateValue}
        stroke={disabled}
        disabled={disabled}
        checked={paymentDay === day}
        statementDate={day === statementDateValue}
        onChange={async () => {
          await setFieldValue('paymentDay', +day, true);
          await setFieldTouched('paymentDay', false, true);
        }}
      />
    );
  });

  return (
    <Form onSubmit={handleSubmit}>
      <Row>
        <Col className={styles.dates}>
          {paymentDates}
          {errors.paymentDay && (
            <FormFeedback className={styles.dateError}>
              {t(errors.paymentDay)}
            </FormFeedback>
          )}
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={6} className={styles.dates}>
          <PaymentDay
            showTitle={false}
            label="payment.unavailable-dates"
            stroke
            disabled
            small
          />
          <PaymentDay
            showTitle={false}
            label="payment.statement-date"
            disabled
            stroke
            statementDate
            small
          />
          <PaymentDay
            showTitle={false}
            label="payment.payment-due-date"
            dueDate
            small
          />
          <PaymentDay
            showTitle={false}
            label="payment.available-dates"
            disabled
            small
          />
        </Col>
        <Col sm={12} md={6} className={submitStyles}>
          <Button primary type="submit" title="payment.to-payment-method" />
        </Col>
      </Row>
    </Form>
  );
};
