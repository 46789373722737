// @flow
import React from 'react';
import { Col, ModalBody, Row } from 'reactstrap';
import { identity } from 'ramda';
import { t, Button, Checkbox } from '../../../components';
import styles from '../../../components/Modal/modals.module.css';
import Agreements from './Agreements';

type AuthorizationAgreement = {
  actions: Object,
  handlers: Object,
  formik: Object,
  name: string,
  handleGoBack: Function
};

export default (props: AuthorizationAgreement) => {
  const { formik, handleGoBack = identity, name } = props;
  const {
    values: { confirmHIPAA },
    handleSubmit
  } = formik;

  return (
    <ModalBody>
      <Row>
        <Col>
          <h1 className={styles.heading}>
            {t('home.authorization-agreement.title')}
          </h1>
          <p className={styles.subHeading}>
            {t('home.authorization-agreement.please-read')}
          </p>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className={styles.terms}>
            <Agreements name={name} />
          </div>
          <div className={styles.termsControls}>
            <Row>
              <Col>
                <Checkbox
                  id="confirmHIPAA"
                  name="confirmHIPAA"
                  className="confirmHIPAA"
                  label={t('home.authorization-agreement.confirmHIPAA')}
                  {...formik}
                />
              </Col>
            </Row>

            <Row>
              <Col className={styles.termsButtons}>
                <Button
                  primary
                  disabled={!confirmHIPAA}
                  type="submit"
                  title="home.authorization-agreement.agree"
                  onClick={handleSubmit}
                />
                <Button
                  title="home.authorization-agreement.disagree"
                  onClick={() => handleGoBack(props)}
                />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </ModalBody>
  );
};
