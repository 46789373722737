// @flow
export const EST_TIME_ZONE = 'America/Detroit';
export const UTC_TIME_ZONE = 'UTC';

const generateTimeZone = (code: string, name: string): Object => ({
  code,
  name
});

export default [
  generateTimeZone('America/New_York', 'New York (Eastern Time)'),
  generateTimeZone('America/Detroit', 'Detroit (Eastern Time)'),
  generateTimeZone(
    'America/Kentucky/Louisville',
    'Kentucky (Eastern Time - Louisville)'
  ),
  generateTimeZone(
    'America/Kentucky/Monticello',
    'Kentucky (Eastern Time - Monticello)'
  ),
  generateTimeZone(
    'America/Indiana/Indianapolis',
    'Indiana (Eastern Time - Indianapolis)'
  ),
  generateTimeZone(
    'America/Indiana/Vincennes',
    'Indiana (Eastern Time - Vincennes)'
  ),
  generateTimeZone(
    'America/Indiana/Winamac',
    'Indiana (Eastern Time - Winamac)'
  ),
  generateTimeZone(
    'America/Indiana/Marengo',
    'Indiana (Eastern Time - Marengo)'
  ),
  generateTimeZone(
    'America/Indiana/Petersburg',
    'Indiana (Eastern Time - Petersburg)'
  ),
  generateTimeZone('America/Indiana/Vevay', 'Indiana (Eastern Time - Vevay)'),
  generateTimeZone('America/Chicago', 'Chicago (Central Time)'),
  generateTimeZone(
    'America/Indiana/Tell_City',
    'Indiana (Central Time - Tell City)'
  ),
  generateTimeZone('America/Indiana/Knox', 'Indiana (Central Time -  Knox)'),
  generateTimeZone('America/Menominee', 'Menominee (Central Time)'),
  generateTimeZone(
    'America/North_Dakota/Center',
    'North Dakota (Central Time - Center)'
  ),
  generateTimeZone(
    'America/North_Dakota/New_Salem',
    'North Dakota (Central Time - New Salem)'
  ),
  generateTimeZone(
    'America/North_Dakota/Beulah',
    'North Dakota (Central Time - Beulah)'
  ),
  generateTimeZone('America/Denver', 'Denver (Mountain Time)'),
  generateTimeZone('America/Boise', 'Boise (Mountain Time)'),
  generateTimeZone('America/Phoenix', 'Phoenix (Mountain Standard Time)'),
  generateTimeZone('America/Los_Angeles', 'Los Angeles (Pacific Time)'),
  generateTimeZone('America/Anchorage', 'Anchorage (Alaska Time)'),
  generateTimeZone('America/Juneau', 'Juneau (Alaska Time)'),
  generateTimeZone('America/Sitka', 'Sitka (Alaska Time)'),
  generateTimeZone('America/Yakutat', 'Yakutat (Alaska Time)'),
  generateTimeZone('America/Nome', 'Nome (Alaska Time)'),
  generateTimeZone('America/Adak', 'Adak (Adak Time - Aleutian Islands)'),
  generateTimeZone(
    'America/Metlakatla',
    'Metlakatla (Metlakatla Time - Annette Island)'
  ),
  generateTimeZone('Pacific/Honolulu', 'Honolulu (Hawaii)')
];
