// @flow
import React from 'react';
import { compose } from 'recompose';
import { Input, FormFeedback, FormGroup, Label } from 'reactstrap';
// NOTE: injectIntl can be used alternatively as the hook useIntl() but only in functional components in version 3
import { injectIntl, type intlShape } from 'react-intl';
import withStyleClasses from './withStyleClasses';
import styles from './form.module.css';
import type { Formik } from '../../utils/types';
import classNames from 'classnames';

type Props = {
  name: string,
  label?: string,
  disabled?: boolean,
  intl: intlShape,
  children?: Node,
  styleClasses: string
};

const Checkbox = (props: Props & Formik) => {
  const {
    name = 'checkbox',
    label = '',
    value,
    disabled = false,
    intl: { formatMessage },
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    styleClasses
  } = props;

  return (
    <FormGroup
      cssModule={styles}
      className={classNames([
        styles[styleClasses],
        'custom-checkbox',
        'custom-control'
      ])}
    >
      <Input
        className="custom-control-input"
        type="checkbox"
        id={name}
        name={name}
        value={value || values[name]}
        invalid={touched[name] && !!errors[name]}
        checked={values[name]}
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={disabled}
      >
        {errors[name] && (
          <FormFeedback>
            {formatMessage({ id: `member-app.${errors[name]}` })}
          </FormFeedback>
        )}
      </Input>
      <Label className={'custom-control-label'} for={name}>
        {label}
      </Label>
    </FormGroup>
  );
};

export default compose(injectIntl, withStyleClasses)(Checkbox);
