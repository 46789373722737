// @flow
import {
  AMOUNT_OPTIONS,
  PAYMENT_METHODS,
  PAYMENT_TYPE
} from '../../../../config/payment.config';
import type { Actions, FormikConfig, Models } from '../../../../utils/types';

export default ({
  mapPropsToValues: (props: Actions | Models): Object => {
    const {
      payment: { paymentType }
    } = props;

    return {
      paymentType
    };
  },
  handleSubmit: (values: Object, props: Object): void => {
    const {
      props: {
        actions: {
          payment: { setData, setError }
        },
        payment: { step, editMode, otherAmount, method, cardId, echeckId, type }
      }
    } = props;

    const recurringPayment: boolean =
      values.paymentType === PAYMENT_TYPE.RECURRING_PAYMENT;
    const editRecurringPayment: boolean = editMode && recurringPayment;

    const recurringPaymentType: string =
      type !== AMOUNT_OPTIONS.MINIMUM_PAYMENT_DUE ||
      type !== AMOUNT_OPTIONS.OTHER
        ? AMOUNT_OPTIONS.FULL_STATEMENT
        : AMOUNT_OPTIONS.MINIMUM_PAYMENT_DUE;

    const recurringType: string = editRecurringPayment
      ? type
      : recurringPaymentType;
    const optionType: string = recurringPayment
      ? recurringType
      : AMOUNT_OPTIONS.FULL;

    const amount: number | string =
      editRecurringPayment && type === AMOUNT_OPTIONS.OTHER ? otherAmount : '';

    setData({
      ...values,
      type: optionType,
      saveToProfile: recurringPayment,
      step: step + 1,
      amount,
      otherAmount: amount,
      method: editRecurringPayment ? method : PAYMENT_METHODS.CARD,
      cardId: editRecurringPayment ? cardId : '',
      echeckId: editRecurringPayment ? echeckId : '',
      repaymentAccepted: editMode
    });
    setError();
  },
  enableReinitialize: true,
  validateOnChange: false
}: FormikConfig);
