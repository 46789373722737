// @flow
import React, { Fragment, type Node } from 'react';
import { Col, Row } from 'reactstrap';
import styles from './terms-and-conditions.module.css';
import { HEALTH_BRIDGE, TERMS_AND_CONDITIONS } from '../../config/terms.config';

const renderSubTerm = (
  terms: Array<Object>,
  callback: (tag: string, config: Object) => Node
): Node =>
  terms && (
    <ul>
      {terms.map((termItem, i) => callback('li', { ...termItem, index: i }))}
    </ul>
  );

const renderTerm = (
  Tag: string,
  { index, key, heading, subTerms }: Object
): Node => (
  <Tag key={index}>
    {key || heading}
    {renderSubTerm(subTerms, renderTerm)}
  </Tag>
);

export const TermsList = ({ termsConfig }: Object) => (
  <Row>
    <Col className={styles.terms}>
      {termsConfig.map(
        (term: Object, i: number): Node => {
          const key = +new Date() * i;
          return (
            <Fragment key={key}>
              {term.heading && renderTerm('h2', term)}
              {term.key && renderTerm('p', term)}
              {renderSubTerm(term.terms, renderTerm)}
            </Fragment>
          );
        }
      )}
    </Col>
  </Row>
);

export default (): Node => (
  <Fragment>
    <TermsList termsConfig={TERMS_AND_CONDITIONS} />
    <Row>
      <Col className={styles.address}>
        <p>HEALTHBRIDGE</p>
        <p>{HEALTH_BRIDGE.ADDRESS.STREET}</p>
        <p>{HEALTH_BRIDGE.ADDRESS.CITY}</p>
        <p>Attn: CUSTOMER SERVICE</p>
        <p>{HEALTH_BRIDGE.PHONE_NUMBER}</p>
      </Col>
    </Row>
  </Fragment>
);
