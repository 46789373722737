import { object, number } from 'yup';

const PAYMENT_DATE_REQUIRED = 'errors.payment-date-required';

export default object({
  paymentDay: number()
    .typeError(PAYMENT_DATE_REQUIRED)
    .min(1, PAYMENT_DATE_REQUIRED)
    .required(PAYMENT_DATE_REQUIRED)
});
