// @flow
import React, { type Node } from 'react';
import { type Match } from 'react-router-dom';
import { Loader } from 'components';
import type { MemberModel, PaginationParams } from 'models/member/types';
import type { Handlers } from 'containers/Modals/types';
import type { TableConfig } from 'components/Table/types';
import MODAL_CONFIG from './details';
import { ENTITY_TYPE } from 'config/payment.config.js';
import { buildContent } from './modalLayout';

type Props = {
  match: Match,
  member: MemberModel,
  handlers: Handlers,
  table?: TableConfig
};

export default (props: Props): Object => {
  const {
    handlers: { goBack },
    member: {
      data: { selectedTransaction }
    },
    actions: {
      payment: { deleteScheduledPayment }
    },
    table,
    history: { push },
    match: {
      params: { memberId, entityId }
    },
    isDeleting,
    setIsDeleting
  } = props;

  const goToEditFlow = () => {
    push(`/member/${memberId}/edit-scheduled-payment/${entityId}`);
  };

  const args: PaginationParams = {
    size: 10,
    page: 0,
    memberId: memberId !== 'me' ? memberId : null
  };

  const goToDeleteFlow = () => {
    deleteScheduledPayment(entityId)
      .then(() => props.actions.member.getMemberScheduledTransactions(args))
      .then(goBack());
  };

  const toggleIsDeletingFlag = () => {
    setIsDeleting(!isDeleting);
  };
  const heading: string = 'home.scheduled-payment-details-view.header';
  return {
    size: 'md',
    toggle: goBack,
    header: {
      toggle: goBack,
      message: heading,
      tag: 'h1'
    },
    body: {
      fallback: <Loader message="loaders.loading" />,
      render: (propsData: Props): Node => {
        const {
          member: {
            data: { selectedTransaction: transaction }
          }
        } = propsData;

        const CONFIG = MODAL_CONFIG;
        return CONFIG[ENTITY_TYPE.SCHEDULED_PAYMENT_TRANSACTIONS].map(
          buildContent(transaction, props, isDeleting)
        );
      }
    },
    table,
    footer: {
      buttons: [
        {
          hide: isDeleting,
          title: 'home.scheduled-payment-details-view.close-button',
          onClick: goBack
        },
        {
          hide: !selectedTransaction.isEditableOrDeletable || isDeleting,
          title: 'home.scheduled-payment-details-view.edit-button',
          onClick: goToEditFlow
        },
        {
          hide: !selectedTransaction.isEditableOrDeletable || isDeleting,
          title: 'home.scheduled-payment-details-view.delete-button',
          onClick: toggleIsDeletingFlag
        },
        {
          hide: !isDeleting,
          title: 'home.scheduled-payment-details-view.cancel-button',
          onClick: toggleIsDeletingFlag
        },
        {
          hide: !isDeleting,
          title: 'home.scheduled-payment-details-view.confirm-button',
          onClick: goToDeleteFlow
        }
      ]
    }
  };
};
