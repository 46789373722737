// @flow
import React, { type Node } from 'react';
import { type Route, History } from 'react-router-dom';
import { t } from '../i18n';
import Button from '../Button';
import withRedirectToLogin from '../../hoc/withRedirectToLogin';
import styles from './error.module.css';

type Props = {
  toLogin: Route,
  history: History
};

const NotFound = ({ toLogin, history: { push } }: Props): Node => (
  <div className={styles.notFoundContainer}>
    <div className="w-100">
      <div className={styles.notFound}>
        <h1>{t('errors.not-found')}</h1>
        <p>{t('errors.refresh-description')}</p>
        <Button primary title="errors.refresh" onClick={() => push(toLogin)} />
      </div>
    </div>
  </div>
);

export default withRedirectToLogin(NotFound);
