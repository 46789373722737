import { object, string, number } from 'yup';
import { CARD_TYPES } from '../../config/payment.config';
import REG_EXP from '../../config/regular-expressions.config';

export default object().shape({
  cardId: number().nullable(),
  cardType: string().nullable(),
  cardNumber: string().when(['cardId'], {
    is: (cardId) => !cardId,
    then: string()
      .test('creditCard', 'payment.errors.invalid-card-number', (value) =>
        [
          REG_EXP.visa.test(value),
          REG_EXP.mastercard.test(value),
          REG_EXP.amex.test(value),
          REG_EXP.discover.test(value)
        ].some((valid) => valid)
      )
      .required('payment.errors.card-num-required'),
    otherwise: string().notRequired()
  }),
  expirationDate: string().when(['cardId'], {
    is: (cardId) => !cardId,
    then: string()
      .matches(REG_EXP.cardExpiration, {
        message: 'payment.errors.invalid-expiration'
      })
      .required('payment.errors.required-expiration'),
    otherwise: string().notRequired()
  }),
  cardCode: string()
    .when(['cardId'], {
      is: (cardId) => !cardId,
      then: string().required('payment.errors.cvv-required'),
      otherwise: string().notRequired()
    })
    .when(['cardType'], {
      is: (cardType) => cardType === CARD_TYPES.AMEX.KEY,
      then: string().matches(REG_EXP.fourDigits, {
        message: {
          title: 'payment.errors.invalid-cvv',
          values: { max: 4 }
        }
      }),
      otherwise: string().matches(REG_EXP.threeDigits, {
        message: {
          title: 'payment.errors.invalid-cvv',
          values: { max: 3 }
        }
      })
    }),
  fullName: string()
    .matches(REG_EXP.lettersWithSpace, {
      message: 'payment.errors.invalid-cardholder'
    })
    .nullable(),
  zip: string()
    .matches(REG_EXP.fiveDigits, {
      message: 'payment.errors.invalid-zip'
    })
    .when(['cardId'], {
      is: (cardId) => !cardId,
      then: string().required('payment.errors.zip-required'),
      otherwise: string().notRequired()
    })
});
