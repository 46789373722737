// @flow
import React, { type Node } from 'react';
// NOTE: injectIntl can be used alternatively as the hook useIntl() but only in functional components in version 3
import { injectIntl, type intlShape } from 'react-intl';
import { Input, FormFeedback, FormGroup, Label } from 'reactstrap';
import type { Formik } from '../../utils/types';

type Props = {
  name: string,
  title?: string,
  options: Array<Node>,
  defaultOption: Object,
  intl: intlShape
};

const Select = (props: Props & Formik): Node => {
  const {
    name,
    title,
    values,
    touched,
    handleChange,
    handleBlur,
    errors,
    options,
    defaultOption,
    intl: { formatMessage }
  } = props;

  return (
    <FormGroup tag="fieldset" data-cy={name}>
      {title && (
        <Label className="mr-1" for={`select-${name}`}>
          {formatMessage({ id: `member-app.${title}` })}
        </Label>
      )}
      <Input
        className="custom-select"
        type="select"
        id={`select-${name}`}
        name={name}
        invalid={touched[name] && !!errors[name]}
        value={values[name]}
        onChange={handleChange}
        onBlur={handleBlur}
      >
        <option value={defaultOption.value}>
          {formatMessage({ id: `member-app.${defaultOption.title}` })}
        </option>
        {options}
      </Input>
      {errors[name] && (
        <FormFeedback>
          {formatMessage({ id: `member-app.${errors[name]}` })}
        </FormFeedback>
      )}
    </FormGroup>
  );
};

export default injectIntl(Select);
