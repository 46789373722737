import { object, string } from 'yup';
import REG_EXP from '../../config/regular-expressions.config';

export const emailSchemaDefinition = {
  email: string()
    .matches(REG_EXP.email, {
      message: 'errors.invalid-email',
      excludeEmptyString: true
    })
    .required('errors.email-required')
};

export default object(emailSchemaDefinition);
