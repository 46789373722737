// @flow
import { compose, withHandlers, type HOC } from 'recompose';
import { type History, type Match } from 'react-router-dom';
import withNamespace from './withNamespace';

export const basePathFinder = ({
  user = '',
  memberId = '',
  tab = '',
  edit
}: Object): string => {
  const basePath = [user, memberId, tab, edit]
    .filter((path) => !!path)
    .join('/');

  return `/${basePath}`;
};

export default (compose(
  withHandlers({
    goBack: (props: Match | History) => (): void | null => {
      const {
        history: {
          push,
          location: { pathname }
        },
        match: { params }
      } = props;

      const basePath: string = basePathFinder(params);

      return !pathname.includes('/unauthorized') ? push(basePath) : null;
    }
  }),
  withNamespace('handlers', ['goBack'])
): HOC<*, any>);
