// @flow
import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import classNames from 'classnames';

type Props = {
  config: Array<{
    linkTo: string,
    title: string
  }>
};

const renderPath = (config) =>
  config.map(({ title, linkTo }, i: number) => (
    <BreadcrumbItem
      key={i}
      className={classNames({ active: !linkTo })}
      to={linkTo}
      active={!linkTo}
    >
      {linkTo ? <Link to={linkTo}>{title}</Link> : title}
    </BreadcrumbItem>
  ));

export default ({ config }: Props) => (
  <Breadcrumb tag="nav" data-cy="breadcrumb">
    {config && renderPath(config)}
  </Breadcrumb>
);
