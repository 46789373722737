// @flow
import React, { type Node } from 'react';
// NOTE: injectIntl can be used alternatively as the hook useIntl() but only in functional components in version 3
import { injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import {
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Nav
} from 'reactstrap';
import styles from './dropdowm.module.css';
import type { DropDownProps, DropDownItem } from './types';

const DropDown = (props: DropDownProps): Node => {
  const {
    generalSettings,
    dropDownToggle,
    dropDownMenu,
    items,
    translateOptions = true,
    className,
    intl: { formatMessage },
    cy
  } = props;

  const menu = items.map((menuItem: DropDownItem, i: number): Node => {
    const { title, onClick, link, cy, divider } = menuItem;
    const key = `${title}${i}`;

    const label: Node =
      translateOptions && title
        ? formatMessage({ id: `member-app.${title}` }, title)
        : title;

    switch (true) {
      case !!divider:
        return <DropdownItem divider key={`divider${i}`} />;
      case !!link:
        return (
          <DropdownItem
            tag={Link}
            to={link}
            data-cy={cy}
            role="menuitem"
            aria-label={label}
            key={key}
          >
            {label}
          </DropdownItem>
        );
      case !!onClick:
        return (
          <DropdownItem
            onClick={(e) => onClick(e, { index: i, ...menuItem })}
            role="menuitem"
            key={key}
          >
            {label}
          </DropdownItem>
        );
      default:
        return (
          <DropdownItem role="menuitem" key={key}>
            {label}
          </DropdownItem>
        );
    }
  });

  return (
    <Nav navbar className={styles[className]}>
      <UncontrolledDropdown {...generalSettings} className={styles.dropDown}>
        <DropdownToggle
          {...dropDownToggle}
          className={styles.toggle}
          aria-label={dropDownToggle.label}
          data-cy={cy}
        >
          <span className={styles.label}>{dropDownToggle.label}</span>
        </DropdownToggle>
        <DropdownMenu {...dropDownMenu} data-cy={`${cy}-menu`}>
          {menu}
        </DropdownMenu>
      </UncontrolledDropdown>
    </Nav>
  );
};

export default injectIntl(DropDown);
