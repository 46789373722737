import * as FileSaver from 'file-saver';

export default (r): void => {
  const blob = new Blob([r.data]);
  const headers = r.headers;
  const fileNameHeaderParameter = headers['content-disposition'].split(';')[1];
  const fileName = fileNameHeaderParameter.split('=')[1];

  FileSaver.saveAs(blob, fileName);
};
