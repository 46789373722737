// @flow
export const ACCEPTJS_URL: string = process.env.REACT_APP_ACCEPT_JS_URL;

export const AUTHORIZE: Object = {
  clientKey: '6tMJ6u3rDQ9RzEhvV6G3p8k5N4GDqFewEWSN4qeh22PpcS575C2zGUn6ZZAGg2pk',
  apiLoginID: '2aEE42qm'
};

export const INIT_TIMEOUT: number = 500;
export const REPEAT_TIMEOUT: number = 2000;
export const MAX_RETRIES: number = 5;

export const AMOUNT_OPTIONS: Object = {
  FULL: 'FULL',
  PAY_IN_FULL: 'PAY_IN_FULL',
  FULL_STATEMENT: 'FULL_STATEMENT',
  MINIMUM_PAYMENT_DUE: 'MINIMUM_PAYMENT_DUE',
  OTHER: 'OTHER_AMOUNT'
};

export const ACCOUNT_TYPES: Object = {
  CHECKING: 'checking',
  SAVINGS: 'savings'
};

export const PAYMENT_METHODS: Object = {
  CARD: 'CARD',
  ECHECK: 'ECHECK'
};

export const PAYMENT_STATUSES: Object = {
  SUCCESS: ['APPROVED', 'HELD_FOR_REVIEW'],
  FAIL: ['ERROR', 'DECLINED']
};

export const ENTITY_TYPE: Object = {
  LATE_FEE: 'late_fee',
  CLAIM: 'claim',
  ADJUSTMENT: 'adjustment',
  PAPER_FEE: 'paper_fee',
  ECHECK: 'echeck',
  REVERSAL: 'reversal',
  CREDIT_CARD: 'credit_card',
  INTEREST: 'interest',
  PAPER_CHECK: 'paper_check',
  OVER_PAYMENT_REFUND: 'over_payment_refund',
  QUICK_PAY_DISCOUNT: 'quick_pay_discount',
  QUICK_PAY_DISCOUNT_REVERSAL: 'quick_pay_discount_reversal',
  LATE_FEE_REVERSAL: 'late_fee_reversal',
  PAPER_FEE_REVERSAL: 'paper_fee_reversal',
  INTEREST_REVERSAL: 'interest_reversal',
  MANUAL_ADJUSTMENT: 'manual_adjustment',
  PARTIAL_ADJUSTMENT: 'partial_adjustment',
  CLAIM_DISPUTED: 'claim_disputed',
  CREDIT_HOLD_INDEMNIFICATION: 'CREDIT_HOLD_INDEMNIFICATION',
  CREDIT_HOLD_WELLNESS: 'CREDIT_HOLD_WELLNESS',
  CREDIT_HOLD_HRA: 'CREDIT_HOLD_HRA',
  SCHEDULED_PAYMENT_TRANSACTIONS: 'scheduled-transactions',
  CLAIM_ADJUSTMENT: 'CLAIM_ADJUSTMENT',
  CLAIM_ADJUSTMENT_REVERSAL: 'CLAIM_ADJUSTMENT_REVERSAL',
  CLAIM_ADJUSTMENT_QPD: 'CLAIM_ADJUSTMENT_QPD',
  CLAIM_ADJUSTMENT_QPD_REVERSAL: 'CLAIM_ADJUSTMENT_QPD_REVERSAL',
  PAYMENT_ASSISTANCE: 'PAYMENT_ASSISTANCE',
  FINANCIAL_ASSISTANCE: 'FINANCIAL_ASSISTANCE',
  COB: 'COB',
  POINT_OF_SERVICE_COLLECTION: 'POINT_OF_SERVICE_COLLECTION',
  STANDARD_ADJUSTMENT: 'STANDARD_ADJUSTMENT',
  PRESCRIPTION_ASSISTANCE: 'PRESCRIPTION_ASSISTANCE'
};

export const PARTIAL_ADJUSTMENT_TYPES = [
  ENTITY_TYPE.CLAIM_ADJUSTMENT,
  ENTITY_TYPE.CLAIM_ADJUSTMENT_REVERSAL,
  ENTITY_TYPE.CLAIM_ADJUSTMENT_QPD,
  ENTITY_TYPE.CLAIM_ADJUSTMENT_QPD_REVERSAL,
  ENTITY_TYPE.PAYMENT_ASSISTANCE,
  ENTITY_TYPE.FINANCIAL_ASSISTANCE,
  ENTITY_TYPE.COB,
  ENTITY_TYPE.POINT_OF_SERVICE_COLLECTION,
  ENTITY_TYPE.STANDARD_ADJUSTMENT,
  ENTITY_TYPE.PRESCRIPTION_ASSISTANCE
];

const PARTIAL_ADJUSTMENT_DETAILS_STR = 'partial-adjustments';

export const ENTITY_TYPE_DETAILS: Object = {
  [ENTITY_TYPE.CLAIM]: 'claims',
  [ENTITY_TYPE.CREDIT_CARD]: 'cards',
  [ENTITY_TYPE.ECHECK]: 'echecks',
  [ENTITY_TYPE.PAPER_CHECK]: 'paper-checks',
  [ENTITY_TYPE.REVERSAL]: 'reversals',
  [ENTITY_TYPE.QUICK_PAY_DISCOUNT]: 'quick-pay-discounts',
  [ENTITY_TYPE.QUICK_PAY_DISCOUNT_REVERSAL]: 'reversal-quick-pay-discounts',
  [ENTITY_TYPE.LATE_FEE]: 'late-fees',
  [ENTITY_TYPE.PAPER_FEE]: 'paper-fees',
  [ENTITY_TYPE.INTEREST]: 'interests',
  [ENTITY_TYPE.LATE_FEE_REVERSAL]: 'reversal-late-fees',
  [ENTITY_TYPE.PAPER_FEE_REVERSAL]: 'reversal-paper-fees',
  [ENTITY_TYPE.INTEREST_REVERSAL]: 'reversal-interests',
  [ENTITY_TYPE.MANUAL_ADJUSTMENT]: 'manual-adjustments',
  [ENTITY_TYPE.CLAIM_ADJUSTMENT]: PARTIAL_ADJUSTMENT_DETAILS_STR,
  [ENTITY_TYPE.CLAIM_ADJUSTMENT_REVERSAL]: PARTIAL_ADJUSTMENT_DETAILS_STR,
  [ENTITY_TYPE.CLAIM_ADJUSTMENT_QPD]: PARTIAL_ADJUSTMENT_DETAILS_STR,
  [ENTITY_TYPE.CLAIM_ADJUSTMENT_QPD_REVERSAL]: PARTIAL_ADJUSTMENT_DETAILS_STR,
  [ENTITY_TYPE.PARTIAL_ADJUSTMENT]: PARTIAL_ADJUSTMENT_DETAILS_STR,
  [ENTITY_TYPE.PAYMENT_ASSISTANCE]: PARTIAL_ADJUSTMENT_DETAILS_STR,
  [ENTITY_TYPE.FINANCIAL_ASSISTANCE]: PARTIAL_ADJUSTMENT_DETAILS_STR,
  [ENTITY_TYPE.COB]: PARTIAL_ADJUSTMENT_DETAILS_STR,
  [ENTITY_TYPE.POINT_OF_SERVICE_COLLECTION]: PARTIAL_ADJUSTMENT_DETAILS_STR,
  [ENTITY_TYPE.STANDARD_ADJUSTMENT]: PARTIAL_ADJUSTMENT_DETAILS_STR,
  [ENTITY_TYPE.PRESCRIPTION_ASSISTANCE]: PARTIAL_ADJUSTMENT_DETAILS_STR,
  [ENTITY_TYPE.CREDIT_HOLD_INDEMNIFICATION]: 'adjudication',
  [ENTITY_TYPE.CREDIT_HOLD_WELLNESS]: 'adjudication',
  [ENTITY_TYPE.CREDIT_HOLD_HRA]: 'adjudication'
};

export const INTEREST_REVERSAL_TYPE = {
  CLAIMS: 'claim adjustment interest reversal',
  PAYMENT: 'payment interest reversal'
};

export const CLAIM_TYPES: Object = {
  NEW: 'NEW',
  ADJUSTMENT: 'ADJUSTMENT'
};

export const CARD_TYPES: Object = {
  VISA: {
    KEY: 'Visa',
    REGEXP: /^4(\d|\s)*$/
  },
  MASTER_CARD: {
    KEY: 'MasterCard',
    REGEXP: /^5[1-5](\d|\s)*$/
  },
  AMEX: {
    KEY: 'AmericanExpress',
    REGEXP: /^3[47](\d|\s)*$/
  },
  DISCOVER: {
    KEY: 'Discover',
    REGEXP: /^6(?:011|5[0-9][0-9])(\d|\s)*$/
  }
};

export const PAYMENT_TYPE: Object = {
  ONE_TIME_PAYMENT: 'ONE_TIME_PAYMENT',
  RECURRING_PAYMENT: 'RECURRING_PAYMENT',
  SCHEDULED_PAYMENT: 'SCHEDULED_PAYMENT'
};
