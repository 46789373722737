// @flow
import axios from 'axios';
import store from '../../store';
import browserHistory from '../../browserHistory';
import { IP_PATH } from '../../config/member.config';
import { http } from '../../utils';
import type { Models, Response } from '../../utils/types';

export function getIP(): Promise<void> {
  const {
    dispatch: {
      auth: { setData }
    }
  } = store;
  return axios
    .get(IP_PATH)
    .then(({ data }: Response) => setData({ userLocation: data }))
    .catch((error: Response) =>
      setData({ userLocation: { ip: 'not available', error } })
    );
}

export function signUp(data: Object, state: Models): Promise<void> {
  const {
    auth: {
      data: { userLocation }
    }
  } = state;

  const {
    dispatch: {
      auth: { setData, setError },
      loader
    }
  } = store;

  loader.start('loaders.verifying');

  setError({ error: null });

  return http
    .performPOST({
      path: '/verify',
      data,
      headers: {
        'User-IP': userLocation?.ip,
        'User-Location': JSON.stringify(userLocation)
      }
    })
    .then(({ result }: Response): void => {
      const { verificationToken } = result;

      if (verificationToken) {
        browserHistory.push(
          `./registration?verificationToken=${verificationToken}`
        );
      }

      setData(result);
    })
    .catch((error: Response) => setError({ error }))
    .then(() => loader.stop());
}

export function register(data: Object, state: Models): Promise<void> {
  const { email, verificationToken } = data;
  const {
    auth: {
      data: { userLocation }
    }
  } = state;

  const {
    dispatch: {
      auth: { setData, setError },
      loader
    }
  } = store;

  loader.start('loaders.registering-email');

  setError({ error: null });

  return http
    .performPOST({
      path: `/registration?vt=${verificationToken}`,
      data: { email },
      headers: {
        'User-IP': userLocation?.ip,
        'User-Location': JSON.stringify(userLocation)
      }
    })
    .then(() => setData({ checkInbox: true }))
    .catch((error: Response) => setError({ error }))
    .then(() => loader.stop());
}

export function registerWithOkta(data: Object, state: Models): Promise<void> {
  const { email, oktaLogin, verificationToken } = data;
  const {
    auth: {
      data: { userLocation }
    }
  } = state;

  const {
    dispatch: {
      auth: { setData, setError },
      loader
    }
  } = store;

  loader.start('loaders.registering-email');

  setError({ error: null });

  return http
    .performPOST({
      path: `/registration?vt=${verificationToken}`,
      data: { email, oktaLogin },
      headers: {
        'User-IP': userLocation?.ip,
        'User-Location': JSON.stringify(userLocation)
      }
    })
    .then(() => setData({ checkInbox: true }))
    .catch((error: Response) => setError({ error }))
    .then(() => loader.stop());
}

export function confirmRegistration(
  confirmationToken: string,
  state: Models
): Promise<void> {
  const {
    auth: {
      data: { userLocation }
    }
  } = state;
  const {
    dispatch: {
      auth: { setData, setError }
    }
  } = store;

  setError({ error: null });

  return http
    .performPOST({
      path: `/confirm-registration?vt=${confirmationToken}`,
      headers: {
        'User-IP': userLocation?.ip,
        'User-Location': JSON.stringify(userLocation)
      }
    })
    .then(({ result }: Response) => setData({ email: result }))
    .catch((error: Response) => setError({ error }));
}

export function resetPassword(data: Object, state: Models): Promise<void> {
  const {
    auth: {
      data: { userLocation }
    }
  } = state;

  const {
    dispatch: {
      auth: { setData, setError },
      loader
    }
  } = store;

  loader.start('loaders.resetting-password');

  setError({ error: null });

  return http
    .performPOST({
      path: '/forgot-password',
      data,
      headers: {
        'User-IP': userLocation?.ip,
        'User-Location': JSON.stringify(userLocation)
      }
    })
    .then(() => setData({ checkInbox: true }))
    .catch((error: Response) => setError({ error }))
    .then(() => loader.stop());
}

export const logout = async () => {
  try {
    return await http.performPOST({ path: `${window.location.origin}/logout` });
  } catch (e) {
    return e;
  }
};

export default (): Object => ({
  getIP,
  signUp,
  register,
  registerWithOkta,
  confirmRegistration,
  resetPassword,
  logout
});
