// @flow
import React, { useEffect, type Node } from 'react';
import Cookies from 'js-cookie';
import type { History, Location } from 'react-router-dom';
import Loader from './Loader';
import withMapStateDispatch from '../hoc/withMapStateDispatch';
import type { Actions } from '../utils/types';

type Props = {
  actions: Actions,
  location: Location,
  history: History
};

const Processing = (props: Props): Node => {
  const {
    actions: {
      auth: { logout }
    },
    location: { state = {} },
    history: { push }
  } = props;

  useEffect((): void => {
    ['statementModalWasOpen', 'accept-access', 'accept-grant-access'].forEach(
      (key) => Cookies.remove(key)
    );

    state.logout ? logout() : push('/');
  }, [logout, push, state.logout]);

  return (
    <div className="d-flex h-100 justify-content-center align-items-center">
      <Loader />
    </div>
  );
};

export default withMapStateDispatch(Processing);
