// @flow
import type { Models } from './types';
import browserHistory from '../browserHistory';
import { mergeDeepRight } from 'ramda';

const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const setError = (state: Models, config: Object): Models => {
  const { error, storeTo, duplicationKey } = config || {};
  let err = error || {};

  if (!Object.keys(err).length) {
    return Object.assign(state, { error: null });
  }

  if (err.status === 409 && duplicationKey) {
    err = mergeDeepRight(error, {
      data: { error: { code: `DUPLICATION_${duplicationKey}` } }
    });
  }

  const errorData = storeTo ? { [storeTo]: err } : err;

  return mergeDeepRight(state, { error: errorData });
};

export default (error: Object): Promise<Error> => {
  if (!error.response) {
    return Promise.reject(error);
  }
  const {
    response: {
      status,
      data: { error: errorDetails }
    }
  } = error;

  switch (status) {
    case 400:
      if (!errorDetails.message) {
        browserHistory.push('/400');
      }
      break;
    case 401:
      browserHistory.push('/401');
      break;
    case 403:
      browserHistory.push('/403');
      break;
    case 404:
      if (
        errorDetails &&
        errorDetails.message &&
        isLoggedMemberNotFoundMessage(errorDetails.message)
      ) {
        browserHistory.push('/logout');
      } else {
        browserHistory.push('/404');
      }
      break;
    case 500:
      browserHistory.push('/500');
      break;
    case 501:
      browserHistory.push('/501');
      break;
    case 503:
      browserHistory.push('/503');
      break;
    case 504:
      browserHistory.push('/504');
      break;
    default:
      if (status > 500) {
        browserHistory.push('/500');
      }
      break;
  }

  console.error(error, error.response);

  return Promise.reject(error.response);
};

const isLoggedMemberNotFoundMessage = (message) => {
  var splitMessage = message.split('No member found ');

  if (splitMessage.length === 1) {
    return false;
  }

  var email = splitMessage[1];

  return email.match(EMAIL_REGEX);
};
