// @flow
export default (paramsString: string): Object => {
  const params: Object = {};
  const queries: Array<string> = paramsString.substring(1).split('&');

  queries.map((qs: string): boolean => {
    const pair: Array<string> = qs.split('=');
    params[pair[0]] = decodeURIComponent(pair[1]);
    return false;
  });

  return params;
};
