// @flow
import { compose, withProps, type HOC } from 'recompose';
import { number } from '../../../components';
import { withNamespace } from '../../../hoc';
import PaymentDate from '../components/PaymentDate';
import PaymentCalendar from '../components/PaymentCalendar';
import PaymentMethod from '../components/PaymentMethod';
import PaymentDetails from '../components/PaymentDetails';
import PaymentAmount from '../components/PaymentAmount';
import PaymentType from '../components/PaymentType';
import { AMOUNT_OPTIONS, PAYMENT_TYPE } from '../../../config/payment.config';
import type { Models } from '../../../utils/types';
import type { Step } from '../../../models/payment/types';
import { withGetScheduledPaymentDetailsDetails } from 'hoc';
import { useEffect } from 'react';

export default (compose(
  withGetScheduledPaymentDetailsDetails,
  withProps((props: Models): Object => {
    const {
      actions: {
        payment: { setData }
      },
      payment: {
        paymentType,
        type,
        paymentDay,
        method,
        otherAmount,
        amount,
        editMode,
        successView,
        schedulePaymentDate
      },
      handlers: { getSelectedScheduledPayment },
      member: {
        data: {
          familyMember: {
            id,
            firstName,
            balanceDetails: { balance }
          }
        }
      },
      match: {
        params: { entityId, memberId }
      }
    } = props;

    const isEditingScheduledPayment =
      window.location.pathname ===
      `/member/${memberId}/edit-scheduled-payment/${entityId}`;

    useEffect(() => {
      if (isEditingScheduledPayment) {
        getSelectedScheduledPayment();
        setData({ editMode: true });
        setData({ paymentType: PAYMENT_TYPE.SCHEDULED_PAYMENT });
      }
    }, [entityId]);

    const defaultPaymentType =
      balance <= 0 && !successView
        ? PAYMENT_TYPE.RECURRING_PAYMENT
        : paymentType;
    if (defaultPaymentType !== paymentType) {
      setData({ paymentType: defaultPaymentType });
    }

    const paymentAmount = otherAmount || amount;
    const showPaymentType = balance > 0 && !isEditingScheduledPayment;
    const replacementType = type === 'PAY_IN_FULL' ? 'FULL' : type;
    const paymentAmountLabel: string = id
      ? `payment.payment-amount-member-${replacementType}`
      : `payment.payment-amount-${replacementType}`;

    const editRecurringLabel: string = editMode
      ? 'payment.edit-recurring-payment'
      : `payment.payment-type-${defaultPaymentType}`;
    const oneTimePayment = paymentType === PAYMENT_TYPE.ONE_TIME_PAYMENT;

    const balanceAmount =
      paymentAmount !== 0 && oneTimePayment
        ? number(+paymentAmount)
        : otherAmount;

    const getBalanceAmount = editMode ? otherAmount : balanceAmount;

    const showDateScheduledPayment =
      defaultPaymentType === PAYMENT_TYPE.SCHEDULED_PAYMENT ||
      isEditingScheduledPayment;

    const steps: Array<Step> = [
      {
        component: PaymentAmount,
        header: 'payment.payment-amount',
        label: {
          id:
            type === AMOUNT_OPTIONS.OTHER && !oneTimePayment
              ? 'payment.monthly-payment'
              : paymentAmountLabel,
          name: firstName,
          balance: getBalanceAmount
        }
      },
      {
        component: PaymentMethod,
        header: 'payment.payment-method',
        label: `payment.${method}`
      },
      { component: PaymentDetails, header: 'payment.payment-details' }
    ];

    if (showPaymentType) {
      steps.unshift({
        component: PaymentType,
        header: 'payment.payment-type',
        label:
          oneTimePayment || showDateScheduledPayment
            ? `payment.payment-type-${defaultPaymentType}`
            : editRecurringLabel
      });
    }

    const showDate = defaultPaymentType === PAYMENT_TYPE.RECURRING_PAYMENT;
    const paymentDateLabel = 'payment.payment-date-label';

    if (showDateScheduledPayment) {
      const schedulePaymentDay = schedulePaymentDate
        ? schedulePaymentDate.getDate()
        : '';
      steps.splice(1, 0, {
        component: PaymentCalendar,
        header: 'payment.schedule-payment-date',
        label: { id: paymentDateLabel, date: schedulePaymentDay }
      });
    }

    if (showDate && showPaymentType) {
      steps.splice(2, 0, {
        component: PaymentDate,
        header: 'payment.payment-date',
        label: { id: paymentDateLabel, date: paymentDay }
      });
    } else if (!showPaymentType && showDate) {
      steps.splice(1, 0, {
        component: PaymentDate,
        header: 'payment.payment-date',
        label: { id: paymentDateLabel, date: paymentDay }
      });
    }

    return {
      steps,
      paymentAmount
    };
  }),
  withNamespace('payment', ['steps', 'paymentAmount', 'editMode'])
): HOC<*, any>);
