// @flow
import React, { type Node, Fragment } from 'react';
import AccessMembers from './AccessMembers';
import { ACCESS_STATUSES } from 'config/member.config';
import type { Actions } from '../../../../utils/types';
import type { MemberModel } from '../../../../models/member/types';

type Props = {
  actions: Actions,
  member: MemberModel,
  boxConfig: Object,
  handleDownload: Function
};

export default (props: Props): Node => {
  const {
    actions: {
      member: { getAgreement }
    },
    member: {
      data: {
        member: {
          hipaaAccesses: { accessesToMe, accessesToOthers, pending }
        }
      }
    }
  } = props;

  return (
    <Fragment>
      <AccessMembers
        title="pending-access"
        status={ACCESS_STATUSES.pending}
        members={pending}
      />
      <AccessMembers
        title="who-has-access"
        status={ACCESS_STATUSES.accessesToMe}
        members={accessesToMe}
        handleDownload={getAgreement}
      />
      <AccessMembers
        title="has-has-access"
        status={ACCESS_STATUSES.accessesToOthers}
        members={accessesToOthers}
      />
    </Fragment>
  );
};
