export default {
  handleSubmit: (values: Object, props: Object) => {
    const {
      props: {
        handlers: { goBack },
        actions: {
          member: { revokeAccess }
        },
        match: {
          params: { familyMemberId }
        }
      }
    } = props;

    revokeAccess(familyMemberId).then(goBack);
  }
};
