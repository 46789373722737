// @flow
import React, { Fragment, type Node } from 'react';
import { t, ErrorView } from 'components';
import type { PaymentError } from 'models/payment/types';

export default (props: PaymentError): Node => {
  const { accept, payment, transaction } = props;

  return (
    <Fragment>
      {accept && !!accept.length && (
        <ErrorView className="mb-3" header={t('acceptjs.error-header')}>
          {accept.map((err) => (
            <p className="m-0" key={err.code}>
              {t(`acceptjs.${err.code}`)}
            </p>
          ))}
        </ErrorView>
      )}
      {transaction && (
        <ErrorView
          className="mb-3"
          header={t(`payment.errors.transaction-${transaction.status}`)}
        >
          <p className="m-0">
            {t(`payment.errors.transaction-${transaction.status}-desc`)}
          </p>
        </ErrorView>
      )}
      {payment && <ErrorView className="mb-3" error={payment} />}
    </Fragment>
  );
};
