// @flow
import React, { type Node, useEffect } from 'react';
import { compose } from 'recompose';
import { Redirect, Route, Switch } from 'react-router';
import type { Route as RouteType } from 'react-router-dom';
import {
  SignUp,
  Registration,
  RegistrationWithOkta,
  RegistrationConfirmed,
  ForgotPassword
} from 'containers/Auth/components';
import { withErrorBoundary } from 'hoc';
import withBackground from 'containers/Auth/hoc';
import ROUTES from 'config/routes.config';
import type { AuthModel } from 'models/auth/types';
import type { Actions } from 'utils/types';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { featureFlagKeys } from 'config';

type Props = {
  actions: Actions,
  auth: AuthModel,
  location: Location,
  toLogin: RouteType,
  viewError: boolean
};

const Auth = (props: Props): Node => {
  const {
    actions: {
      auth: { getIP }
    },
    flags,
    toLogin
  } = props;

  useEffect((): void => {
    getIP();
  }, [getIP]);
  return (
    <Switch>
      <Route
        path={`${ROUTES.AUTH}/sign-up`}
        render={() => <SignUp {...props} />}
      />
      <Route
        path={`${ROUTES.AUTH}/confirm-registration`}
        render={() => <RegistrationConfirmed {...props} />}
      />
      <Route
        path={`${ROUTES.AUTH}/registration`}
        render={() =>
          flags[featureFlagKeys.oktaLoginMigration] ? (
            <RegistrationWithOkta {...props} />
          ) : (
            <Registration {...props} />
          )
        }
      />
      <Route
        path={`${ROUTES.AUTH}/forgot-password`}
        render={() => <ForgotPassword {...props} />}
      />
      <Redirect from={`${ROUTES.AUTH}/*`} to={toLogin} />
    </Switch>
  );
};

export default compose(
  withLDConsumer(),
  withErrorBoundary,
  withBackground
)(Auth);
