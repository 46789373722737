// @flow
import { DateTime } from 'luxon';
import browserHistory from '../../browserHistory';
import store from '../../store';
import {
  http,
  saveFile,
  openDigitalCard,
  openMembershipCard,
  sortByYear,
  downloadResource
} from '../../utils';
import { STATEMENT } from '../../config/member.config';
import ROUTES from '../../config/routes.config';
import { hasHIPPAError } from '../../containers/Modals/AccessModals/utils';
import type { Models, Response } from '../../utils/types';
import type { FamilyMember } from './types';

const SUCCESS_MESSAGE = 'toasts.successfully-saved';

const setHIPPAError = (error) => {
  const {
    dispatch: {
      member: { setError }
    }
  } = store;
  if (hasHIPPAError(error)) {
    throw error;
  } else {
    setError({ error });
  }
};

export function getLanguages(): Promise<void> {
  const {
    dispatch: {
      member: { setError, updateStore }
    }
  } = store;
  return http
    .performGET({ path: '/languages' })
    .then(({ result }: Response) => updateStore({ languages: result }))
    .catch((error: Response) => setError({ error }));
}

export function getOldMembershipCard(): Promise<void> {
  const {
    dispatch: {
      member: { updateStore }
    }
  } = store;
  return http.performGET({ path: '/membership-card' }).then((response = {}) =>
    updateStore({
      membershipCards: response.result
    })
  );
}

export const handleError = (error: Response, setError, showToast) => {
  const {
    data: {
      error: {
        code
      }
    }
  } = error;
  setError({ error });
  showToast({
    message: `errors.${code}`,
    type: 'FAIL',
    icon: 'ERROR'
  });
}

export function getZelisMembershipCard() {
  const {
    dispatch: {
      member: { updateStore }
    }
  } = store;
  return http
    .performGETImage({ path: '/membership-plastic-card' })
    .then((img) => {
      updateStore({
        frontPngContent: img
      });
    });
}

export function getMember(memberId: mixed): Promise<void> {
  const {
    dispatch: {
      member: { getFamilyMember, setError, updateStore }
    }
  } = store;
  return http
    .performGET({ path: '/me' })
    .then(({ result }: Response) => {
      const familyMember: FamilyMember = {
        ...result,
        hasAccess: true,
        id: null
      };

      updateStore({
        member: {
          ...result,
          familyMembers: [{ ...familyMember }, ...result.familyMembers]
        }
      });

      memberId && memberId !== 'me'
        ? getFamilyMember(memberId)
        : updateStore({ familyMember });

      return result;
    })
    .catch((error: Response) => setError({ error }));
}

export function getMemberSuppressedStatements(memberId): Promise<void> {
  const {
    dispatch: {
      member: { setError, updateStore }
    }
  } = store;
  return http
    .performGET({ path: `${memberId}/configuration/SUPPRESSED_STATEMENTS` })
    .then(({ result }: Response) => {
      updateStore({
        suppressedStatements: result
      });
      return result;
    })
    .catch((error: Response) => setError({ error }));
}

export function getFamilyMember(id: number): Promise<void> {
  const {
    dispatch: {
      member: { setError, updateStore }
    }
  } = store;
  return http
    .performGET({ path: `/family-members/${id}` })
    .then(({ result }: Response) => updateStore({ familyMember: result }))
    .catch((error: Response) => setError({ error }));
}

export function getMemberStatements(
  params: Object,
  state: Models
): Promise<void> {
  const {
    member: {
      data: {
        list: { content }
      }
    }
  } = state;
  const {
    dispatch: {
      member: { setError, updateStore }
    }
  } = store;

  return http
    .performGET({
      path: '/statements',
      params: { ...params }
    })
    .then(({ result }: Response) => {
      const sortedListByYear: Object = sortByYear(content, result, [
        'createdAt'
      ]);
      updateStore({
        list: { ...result, content: sortedListByYear }
      });
    })
    .catch((error: Response) => setError({ error }));
}

export function getMemberStatement(data: Object, state: Models): Promise<void> {
  const { id, memberId, billingPeriod } = data;
  const {
    member: {
      data: {
        familyMember: { firstName, lastName }
      }
    }
  } = state;
  const {
    dispatch: {
      member: { setError }
    }
  } = store;
  const statementOptions = DateTime.fromSQL(billingPeriod).toFormat(
    'MMMM_yyyy'
  );

  const fileName: string = `Statement_${statementOptions}_${firstName}_${lastName}`;

  return http
    .performGETFile({
      path: `/statements/${id}/pdf`,
      params: { memberId }
    })
    .then((result: Response) => saveFile(result, fileName))
    .catch((error: Response) => setError({ error }));
}

export function getMemberActivities(
  params: Object,
  state: Models
): Promise<void> {
  const {
    member: {
      data: {
        list: { content }
      }
    }
  } = state;
  const {
    dispatch: {
      member: { setError, updateStore }
    }
  } = store;

  return http
    .performGET({
      path: '/activity',
      params: { ...params, sort: 'eventDate,desc' }
    })
    .then(({ result }: Response) => {
      const sortedList = sortByYear(content || [], result, ['eventDate']);
      updateStore({ list: { ...result, content: sortedList } });
    })
    .catch((error: Response) => setError({ error }));
}

export function getMemberResources(): Promise<void> {
  const {
    dispatch: {
      member: { setError }
    }
  } = store;
  return http
    .performGET({
      path: `/resources`
    })
    .then(({ result }: Response) => {
      return result;
    })
    .catch((error: Response) => setError({ error }));
}

export function getMemberTransactions(
  params: Object,
  state: Models
): Promise<void> {
  const {
    member: {
      data: {
        list: { content }
      }
    }
  } = state;
  const {
    dispatch: {
      member: { setError, updateStore }
    }
  } = store;
  return http
    .performGET({
      path: '/transactions',
      params: { ...params, sort: 'eventDate,desc' }
    })
    .then(({ result }: Response) => {
      const sortedListByYear: Object = sortByYear(content, result, [
        'eventDate'
      ]);
      updateStore({ list: { ...result, content: sortedListByYear } });
    })
    .catch((error: Response) => setError({ error }));
}

export function getMemberScheduledTransactions(params: Object): Promise<void> {
  const {
    dispatch: {
      member: { setError, updateStore }
    }
  } = store;
  return http
    .performGET({
      path: '/payments/scheduled',
      params: { ...params, sort: 'startAfter,DESC' }
    })
    .then(({ result }: Response) => {
      const sortedListByYear: Object = sortByYear([], result, ['startAfter']);
      return updateStore({
        list: { ...result, content: sortedListByYear }
      });
    })
    .catch((error: Response) => setError({ error }));
}

export function getMemberScheduledTransactionById(
  scheduledId: number
): Promise<void> {
  const {
    dispatch: {
      member: { setError, updateStore }
    }
  } = store;
  return http
    .performGET({
      path: `/payments/scheduled/${scheduledId}`
    })
    .then(({ result }: Response) => {
      updateStore({ selectedTransaction: result });
    })
    .catch((error: Response) => setError({ error }));
}

export function updateScheduledPayment(
  data: Object,
  scheduledPaymentId: number
): Promise<void> {
  const {
    dispatch: {
      member: { setError }
    }
  } = store;
  return http
    .performPUT({
      path: `/payments/scheduled/${scheduledPaymentId}`,
      data
    })
    .then(() => {
      browserHistory.push(ROUTES.HOME);
    })
    .catch((error: Response) => {
      setError({ error });
    });
}

export function updateSettings(data: Object): Promise<void> {
  const {
    dispatch: {
      toasts: { showToast },
      member: { setData, setError }
    }
  } = store;

  return http
    .performPUT({ path: '/settings', data })
    .then(() => {
      setData({
        member: {
          ...data,
          statementsType: data.statementType,
          contactInfo: {
            secondaryEmail: { email: data.communicationEmail || '' },
            communicationPhone: {
              number: data.communicationPhone
            }
          }
        }
      });
      showToast({ message: SUCCESS_MESSAGE });
    })
    .catch((error: Response) => handleError(error, setError, showToast));
}

export function updateLogin({ username }: { username: string }): Promise<void> {
  const {
    dispatch: {
      member: { setError },
      toasts: { showToast }
    }
  } = store;
  return http
    .performPUT({
      path: `/login`,
      data: { login: username }
    })
    .then(() => {
      showToast({ message: 'toasts.change-username-successful' });
      setTimeout(() => {
        browserHistory.push(ROUTES.LOG_OUT);
      }, 3000);
    }, (error: Response) => handleError(error, setError, showToast))
    .catch((error: Response) => handleError(error, setError, showToast));
}

export function setPaperless(): Promise<void> {
  const {
    dispatch: {
      member: { setData, setError },
      toasts: { showToast }
    }
  } = store;

  return http
    .performPUT({ path: '/settings/paperless' })
    .then(() => {
      setData({ member: { statementsType: STATEMENT.ELECTRONIC } });
      showToast({ message: SUCCESS_MESSAGE });
    })
    .catch((error: Response) => setError({ error }));
}

export function confirmCommunicationEmail(vt: string): Promise<void> {
  const {
    dispatch: {
      member: { setError }
    }
  } = store;
  return http
    .performPOST({ path: `/confirm-communication-email?vt=${vt}` })
    .catch((error: Response) => setError({ error }));
}

export function resendCommunicationEmail(): Promise<void> {
  const {
    dispatch: {
      member: { setError },
      toasts: { showToast }
    }
  } = store;

  return http
    .performPOST({ path: '/settings/communication-email' })
    .then(() =>
      showToast({
        message: 'settings.settings-blocks.contact-info.email-resent'
      })
    )
    .catch((error: Response) => setError({ error }));
}

export function resendCommunicationSms(): Promise<void> {
  const {
    dispatch: {
      member: { setError },
      toasts: { showToast }
    }
  } = store;

  return http
    .performPOST({ path: '/resend-sms-confirmation' })
    .then(() =>
      showToast({
        message: 'settings.settings-blocks.contact-info.sms-resent'
      })
    )
    .catch((error: Response) => setError({ error }));
}

export function cancelCommunicationEmail(): Promise<void> {
  const {
    dispatch: {
      member: { setData, setError },
      toasts: { showToast }
    }
  } = store;

  return http
    .performDELETE({ path: '/settings/communication-email' })
    .then(() => {
      setData({
        member: {
          contactInfo: {
            secondaryEmail: null
          }
        }
      });
      showToast({
        message: 'settings.settings-blocks.contact-info.cancel-change-email'
      });
    })
    .catch((error: Response) => setError({ error }));
}

export function agreeWithTerms(): Promise<void> {
  const {
    dispatch: {
      member: { setData, setError }
    }
  } = store;
  return http
    .performPOST({ path: '/accept-tos' })
    .then(() => setData({ member: { tosAccepted: true } }))
    .catch((error: Response) => setError({ error }));
}

export function getTransactionDetails(data: Object): Promise<void> {
  const {
    dispatch: {
      member: { setError, updateStore }
    }
  } = store;
  const { entityId, path } = data;

  return http
    .performGET({ path: `/transactions/${path}/${entityId}` })
    .then(({ result }: Response) =>
      updateStore({ selectedTransaction: result })
    )
    .catch((error: Response) => setError({ error }));
}

export function getAccess({
  id,
  // eslint-disable-next-line no-unused-vars
  familyMembers,
  ...data
}: Object): Promise<void> {
  const {
    dispatch: {
      member,
      toasts: { showToast }
    }
  } = store;

  return http
    .performPOST({
      path: `/family-members/${id}/access/request`,
      data
    })
    .then(() => member.getFamilyMember(id))
    .then(() => showToast({ message: 'toasts.request-successful' }))
    .catch(setHIPPAError.bind(this));
}

export function getGrantAccess({
  id,
  // eslint-disable-next-line no-unused-vars
  familyMembers,
  ...data
}: Object): Promise<void> {
  const {
    dispatch: {
      toasts: { showToast },
      member: { getFamilyMember }
    }
  } = store;

  return http
    .performPOST({
      path: `/family-members/${id}/access/grant`,
      data
    })
    .then(() => getFamilyMember(id))
    .then(() => showToast({ message: 'toasts.request-grant-successful' }))
    .catch(setHIPPAError.bind(this));
}

export function acceptAccess({ id }: Object): Promise<void> {
  const {
    dispatch: {
      toasts: { showToast },
      member
    }
  } = store;

  return http
    .performPOST({
      path: `/family-members/${id}/access/accept-request`
    })
    .then(() => showToast({ message: 'toasts.access-accepted' }))
    .then(() => member.getMember('me'))
    .catch(({ data: { error } }) =>
      showToast({
        type: 'SYSTEM_ERROR',
        rawMessage: true,
        ...error
      })
    );
}

export function confirmPin(data: Object): Promise<void> {
  const {
    dispatch: { member }
  } = store;

  const { id, ...params } = data;
  return http
    .performPOST({ path: `/family-members/${id}/access/pin-confirm`, params })
    .then(() => member.getMember('me'))
    .catch(setHIPPAError.bind(this));
}

export function acceptGrantAccess(data: Object): Promise<void> {
  const {
    dispatch: {
      member,
      toasts: { showToast }
    }
  } = store;
  const { id, ...params } = data;

  return http
    .performPOST({
      path: `/family-members/${id}/access/accept-grant`,
      params
    })
    .then(() => showToast({ message: 'toasts.access-accepted' }))
    .then(() => member.getMember('me'))
    .catch(setHIPPAError.bind(this));
}

export function rejectAccess(id: number): Promise<void> {
  const {
    dispatch: {
      member,
      toasts: { showToast }
    }
  } = store;

  return http
    .performPOST({
      path: `/family-members/${id}/access/reject-request`
    })
    .then(() => showToast({ message: 'toasts.access-rejected' }))
    .then(() => member.getMember('me'))
    .catch((error) => member.setError({ error }));
}

export function rejectGrantAccess(id: number): Promise<void> {
  const {
    dispatch: {
      member,
      toasts: { showToast }
    }
  } = store;

  return http
    .performPOST({
      path: `/family-members/${id}/access/reject-grant`
    })
    .then(() => showToast({ message: 'toasts.access-rejected' }))
    .then(() => member.getMember('me'))
    .catch((error) => member.setError({ error }));
}

export function revokeAccess(id: number): Promise<void> {
  const {
    dispatch: {
      member,
      toasts: { showToast }
    }
  } = store;

  return http
    .performPOST({
      path: `/family-members/${id}/access/revoke`
    })
    .then(() => showToast({ message: 'toasts.access-revoked' }))
    .then(() => member.getMember('me'))
    .catch((error) => member.setError({ error }));
}

export function getAgreement(id: number): Promise<void> {
  const {
    dispatch: { member }
  } = store;
  const fileName = 'HIPAA_Authorization_Agreement';
  return http
    .performGETFile({
      path: `/family-members/${id}/access/agreement`
    })
    .then((result) => saveFile(result, fileName))
    .catch((error) => member.setError({ error }));
}

export function getMobilePass(): Promise<void> {
  const {
    dispatch: { member }
  } = store;
  return http
    .performGETPass({ path: `/mobile/pass` })
    .then((r) => openDigitalCard(r, 'DigitalMembershipCard'))
    .catch((error) => member.setError({ error }));
}

export function getZelisPdfMembershipCard() {
  const {
    dispatch: { member }
  } = store;
  return http
    .performGETImage({ path: '/generate-zelis-card' })
    .then((r) => openMembershipCard(r, 'MembershipCard'))
    .catch((error) => member.setError({ error }));
}

export function getOldPdfMembershipCard(): Promise<void> {
  const {
    dispatch: { member }
  } = store;
  return http
    .performGETFile({ path: `/generate-membership-card` })
    .then((r) => openMembershipCard(r, 'MembershipCard'))
    .catch((error) => member.setError({ error }));
}

export function getResourceFile(ordinal: number) {
  return http
    .performGETResource({ path: `/resources/${ordinal}`, timeout: 300000 })
    .then((r) => downloadResource(r));
}

export default () => ({
  getLanguages,
  getMember,
  getZelisMembershipCard,
  getOldMembershipCard,
  getFamilyMember,
  getMemberStatements,
  getMemberStatement,
  getMemberActivities,
  getMemberResources,
  getMemberTransactions,
  getMemberScheduledTransactions,
  getMemberScheduledTransactionById,
  updateLogin,
  updateSettings,
  setPaperless,
  confirmCommunicationEmail,
  resendCommunicationEmail,
  resendCommunicationSms,
  cancelCommunicationEmail,
  agreeWithTerms,
  getTransactionDetails,
  getAccess,
  getGrantAccess,
  acceptAccess,
  confirmPin,
  acceptGrantAccess,
  rejectAccess,
  rejectGrantAccess,
  revokeAccess,
  getAgreement,
  getMobilePass,
  getResourceFile,
  getMemberSuppressedStatements
});
