// @flow
import React, { type Node } from 'react';
import { values } from 'ramda';
import { Col, Container, Form, Row } from 'reactstrap';
import InfoItem from 'containers/Settings/components/InfoItem';
import Navigation from './components/Navigation';
import styles from './settings.module.css';
import type { Formik } from '../../utils/types';

type Props = {
  formik: Formik,
  settings: Object
};

export default (props: Props): Node => {
  const {
    formik: { handleSubmit },
    settings
  } = props;

  return (
    <Container>
      <Row>
        <Col md={4} className={styles.navigationWrapper}>
          <div className={styles.navigation}>
            <Navigation {...props} />
          </div>
        </Col>
        <Col md={12} lg={8}>
          <Form onSubmit={handleSubmit}>
            {values(settings).map((item) => (
              <div className={styles.anchor} id={item.icon} key={item.icon}>
                <InfoItem {...props} settings={item} />
              </div>
            ))}
          </Form>
        </Col>
      </Row>
    </Container>
  );
};
